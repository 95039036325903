export const SUBMISSIONS_OTHER = 'Other';

export interface ISelectItem {
  label: string;
  value: string;
}

export enum SELECT_LABEL {
  NumOfSubm = `ASSIGNMENT_EDITOR.SCORING.SELECT_LABELS.NUMBER_OF_SUBMISSIONS`,
  SubmitAnsw = `ASSIGNMENT_EDITOR.SCORING.SELECT_LABELS.SUBMIT_ANSWERS`,
  ScoreUsing = `ASSIGNMENT_EDITOR.SCORING.SELECT_LABELS.SCORE_USING`,
  gradeBook = `ASSIGNMENT_EDITOR.SCORING.SELECT_LABELS.GRADE_BOOK`,
  pointsAdjust = `ASSIGNMENT_EDITOR.SCORING.SELECT_LABELS.POINTS_ADJUST`,
  saltStat = `ASSIGNMENT_EDITOR.SCORING.SELECT_LABELS.SALT_STAT`,
}

export enum FEEDBACK_ITEM {
  answerKey,
  helpContent,
  practiceAnotherVersion,
  studentsPreviousResp,
  answerFeedback,
  studentScore,
}

import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import FacultyOmniBar from 'facultyComponents/facultyHeader/FacultyOmniBar';
import { StudentFooter as FacultyFooter } from 'wa-ui-components';
import MasqueradeHeader from '../../../components/masqueradeHeader/MasqueradeHeader';
import { CxpQuestionManager } from './CxpQuestionManager';
import { ACTIONS, ActionType, getLoadingAction } from 'context/data/actionCreators';
import { API_NAME_CONSTANTS } from '../../../service/registry';
import { useFetchedDataContext } from 'context/data/FetchedData';
import { LoaderComponent } from 'wa-ui-components';
import { BackToClassLink } from 'facultyComponents/backToClass/BackToClassLink';

const FacultyMain = styled.main`
  box-sizing: border-box;
  min-height: calc(100vh - 8.75rem);
`;

const BackToClassLinkWrapper = styled.div`
  padding: 0.626rem;
`;

export const Root = (): ReactElement => {
  const { getter } = useFetchedDataContext();
  const apiDataState: ActionType | null = getter(API_NAME_CONSTANTS.USER);
  const { data: userData, status: userStatus } = apiDataState
    ? apiDataState
    : getLoadingAction(API_NAME_CONSTANTS.USER);

  if (userStatus === ACTIONS.LOADING) {
    return <LoaderComponent />;
  }

  return (
    <BrowserRouter>
      {userData.masqueraded && (
        <MasqueradeHeader fullName={userData.fullName} userName={userData.userName} schoolName={userData.schoolName} />
      )}
      <FacultyOmniBar userData={userData} userStatus={userStatus} />
      <BackToClassLinkWrapper>
        <BackToClassLink classContext={userData.activeClassContext} />
      </BackToClassLinkWrapper>
      <FacultyMain>
        <div id="mainContent">
          <Switch>
            <Route exact path="/app/questions/cxp/editor" render={() => <CxpQuestionManager />} />
          </Switch>
        </div>
      </FacultyMain>

      <FacultyFooter disableLink="0" />
    </BrowserRouter>
  );
};

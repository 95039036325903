import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import FacultyOmniBar from 'facultyComponents/facultyHeader/FacultyOmniBar';
import { StudentFooter as FacultyFooter } from 'wa-ui-components';
import MasqueradeHeader from '../../components/masqueradeHeader/MasqueradeHeader';
import { LoaderComponent } from 'wa-ui-components';
import { useFetchedDataContext } from 'context/data/FetchedData';
import { ACTIONS, ActionType, getLoadingAction } from 'context/data/actionCreators';
import { API_NAME_CONSTANTS } from '../../service/registry';
import { Header } from './Header';

const data = {
  assignment: {
    id: '23295342',
    name: 'Homework 2.4 - Addition of Fractions and Mixed Numbers',
  },
};

const FacultyMain = styled.main`
  box-sizing: border-box;
  min-height: calc(100vh - 8.75rem);
`;

export const Root = (): ReactElement => {
  const { getter } = useFetchedDataContext();
  const apiDataState: ActionType | null = getter(API_NAME_CONSTANTS.USER);
  const { data: userData, status: userStatus } = apiDataState
    ? apiDataState
    : getLoadingAction(API_NAME_CONSTANTS.USER);

  if (userStatus === ACTIONS.LOADING) {
    return <LoaderComponent />;
  }

  return (
    <BrowserRouter>
      {userData.masqueraded && (
        <MasqueradeHeader fullName={userData.fullName} userName={userData.userName} schoolName={userData.schoolName} />
      )}
      <FacultyOmniBar userData={userData} userStatus={userStatus} />
      <FacultyMain>
        <Switch>
          <Route
            exact
            path="/app/extensions/user/:userId/deployment/:deploymentId"
            render={() => <Header assignment={data.assignment} />}
          />
          <Route
            exact
            path="/app/extensions/user/:userId/section/:sectionId"
            render={() => <Header assignment={data.assignment} />}
          />
        </Switch>
      </FacultyMain>

      <FacultyFooter disableLink="0" />
    </BrowserRouter>
  );
};

/* istanbul ignore file */
//todo: add test code, once we have our first end point, until then ignore
import { InputParamsType } from 'context/data/typings';
const GET_HEADER = {
  method: 'GET',
};

type RequestType = { path: (inputParams: InputParamsType) => string; header: { method: string } };

type RegistryType = {
  [key: string]: RequestType;
};

const API_NAME_CONSTANTS = {
  USER: 'user',
  EBOOKS: 'ebooks',
  HEADER_LINKS: 'headerLinks',
  SUB_HEADER_LINKS: 'subHeaderLinks',
  FACULTY_CLASSES_LINKS: 'facultyClassesLinks',
  DIRECTOR: 'director',
};

const registry: RegistryType = {
  [API_NAME_CONSTANTS.USER]: {
    path: () => 'user',
    header: GET_HEADER,
  },
  [API_NAME_CONSTANTS.EBOOKS]: {
    path: () => 'ebooks/header',
    header: GET_HEADER,
  },
  [API_NAME_CONSTANTS.HEADER_LINKS]: {
    path: () => 'links/header',
    header: GET_HEADER,
  },
  [API_NAME_CONSTANTS.FACULTY_CLASSES_LINKS]: {
    path: (inputParams: InputParamsType) => {
      return `links/instructor/${inputParams.userId}/classes`;
    },
    header: GET_HEADER,
  },
  [API_NAME_CONSTANTS.DIRECTOR]: {
    path: (inputParams: InputParamsType) => {
      return `director/setting/${inputParams.settingName}`;
    },
    header: GET_HEADER,
  },
};

const mockRegistry: RegistryType = {};

const BASE_URL = '/wa-ui-services/';
const BASE_MOCK_URL = 'http://localhost:3001/';

type GetRequestForReturnType = [string, RequestInit] | null;

/**
 * This function address two concerns
 * 1. Blocking consumer from accidental mutation of API Registry, by cloning request data
 * 2. Providing Mock Data if Server Data is not ready yet
 * @param apiName: string
 */
const getRequestFor = (apiName: string, inputParams: InputParamsType): GetRequestForReturnType => {
  const isServerData = !!registry[apiName];
  let requestData: RequestType;
  let baseAPIUrl: string;
  if (isServerData) {
    requestData = registry[apiName];
    baseAPIUrl = BASE_URL;
  } else {
    requestData = mockRegistry[apiName];
    baseAPIUrl = BASE_MOCK_URL;
  }

  if (!requestData) {
    return null;
  }

  const path: string = requestData.path(inputParams);

  const url = `${baseAPIUrl}${path}`;
  const headerData: RequestInit = { ...requestData.header };
  return [url, headerData];
};

export { getRequestFor, GetRequestForReturnType, API_NAME_CONSTANTS };

import React, { useState } from 'react';
import {
  Card,
  Container,
  Flex,
  FlexBehavior,
  FlexWrap,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Tooltip,
} from 'react-magma-dom';
import { ContentCopyIcon } from 'react-magma-icons';
import { HEADERS } from 'context/CxpQuestionContextProvider/types';
import styled from '@emotion/styled';

const ListLi = styled.li`
  font-weight: 300;
  line-height: 30px;
`;

const Guidance = (): JSX.Element => {
  const initTooltip = 'Copy header to paste into Excel sheet';
  const [showToastCopied, setShowToastCopied] = useState<string>(initTooltip);

  const repoVal = 'cxptest1_r';
  const cxpIdVal = '12345';
  const questionNameVal = 'Bookcode4 1.1.001';

  const handleClickCopyItem = () => {
    navigator.clipboard
      .writeText(`${HEADERS.GEYSER_REPO}\t${HEADERS.CXP_ITEM_CXP_ID}\t${HEADERS.WA_QUESTION}`)
      .then(() => setShowToastCopied('Headers copied to your clipboard!'));
  };

  return (
    <>
      <div>To import questions, your Exel spreadsheet must have these columns:</div>
      <ul>
        <ListLi>
          <b>{HEADERS.GEYSER_REPO}</b>
        </ListLi>
        <ListLi>
          <b>{HEADERS.CXP_ITEM_CXP_ID}</b>
        </ListLi>
        <ListLi>
          <b>{HEADERS.WA_QUESTION}</b>
        </ListLi>
        <ListLi>
          <b>{HEADERS.LIMIT_ONE_SUBMISSION}(Optional)</b>
        </ListLi>
      </ul>
      <div style={{ marginLeft: '30px' }}>
        <div>Example Row:</div>
        <Flex behavior={FlexBehavior.container} spacing={2} style={{ marginBottom: '10px' }} wrap={FlexWrap.wrap}>
          <Flex behavior={FlexBehavior.item} xs>
            <Card style={{ minWidth: 'fit-content' }}>
              <Table hasVerticalBorders testId="exampleImportTableId">
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>{HEADERS.GEYSER_REPO}</TableHeaderCell>
                    <TableHeaderCell>{HEADERS.CXP_ITEM_CXP_ID}</TableHeaderCell>
                    <TableHeaderCell>{HEADERS.WA_QUESTION}</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{repoVal}</TableCell>
                    <TableCell>{cxpIdVal}</TableCell>
                    <TableCell>{questionNameVal}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Flex>
          <Flex behavior={FlexBehavior.item} style={{ width: 'fit-content' }} xs>
            <div style={{ width: 'fit-content' }}>
              <Tooltip
                testId="copyIconId"
                content={showToastCopied}
                onClick={() => handleClickCopyItem()}
                onKeyPress={() => handleClickCopyItem()}
                onMouseOut={() => setShowToastCopied(initTooltip)}
                onBlur={() => setShowToastCopied(initTooltip)}
                style={{ width: 'fit-content' }}
              >
                <Container tabIndex={0} onSubmit={() => handleClickCopyItem()}>
                  <ContentCopyIcon size={30} />
                </Container>
              </Tooltip>
            </div>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Guidance;

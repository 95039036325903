import React, { IframeHTMLAttributes, RefObject, forwardRef, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { Loader } from 'wa-ui-components';

export interface IFrameLoaderProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  id?: string;
  initialHeight?: number;
  onLoadHandler?: () => void;
  resizeToFit?: boolean;
  testId?: string;
}

const IframePreview = styled.iframe<{ iframeHeight: number }>`
  box-sizing: content-box;
  border: none;
  height: ${({ iframeHeight }) => iframeHeight}px;
  width: 100%;
`;

export const IFrameLoader = forwardRef<HTMLIFrameElement, IFrameLoaderProps>((props, ref): JSX.Element => {
  const { id, initialHeight = 150, onLoadHandler, resizeToFit = false, testId, ...rest } = props;

  const localRef = useRef<HTMLIFrameElement>(null);
  const iframeRef = ref ? (ref as RefObject<HTMLIFrameElement>) : localRef;

  const [iframeHeight, setIframeHeight] = useState(initialHeight);
  const [loading, setLoading] = useState(true);
  const [reloaded, setReloaded] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(props.src);

  useEffect(() => {
    if (!loading && resizeToFit) {
      resizeFrame();
    }
    // make sure iframe is visible before triggering onLoadHandler
    if (!loading && onLoadHandler) {
      onLoadHandler();
    }
  }, [loading]);

  useEffect(() => {
    if (reloaded && resizeToFit) {
      resizeFrame();
    }
  }, [reloaded]);

  useEffect(() => {
    if (iframeSrc != props.src) {
      setLoading(true);
      setIframeSrc(props.src);
    }
  }, [props.src]);

  const resizeFrame = () => {
    const iframeDocumentRoot = iframeRef?.current?.contentDocument?.documentElement;
    if (iframeDocumentRoot && iframeDocumentRoot.scrollHeight > iframeHeight) {
      setIframeHeight(iframeDocumentRoot.scrollHeight);
    }
  };

  const iframeLoadHandler = () => {
    if (loading) {
      setReloaded(false);
      setLoading(false);
    } else {
      // handle a reload of the iframe
      setReloaded(true);
    }
  };

  return (
    <Loader loading={loading} testId={`${testId}_loader`}>
      <IframePreview
        id={id}
        iframeHeight={iframeHeight}
        ref={iframeRef}
        onLoad={iframeLoadHandler}
        data-testid={`${testId}_frame`}
        {...rest}
      />
    </Loader>
  );
});

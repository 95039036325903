import { IFlattenedCxpResultQuestion } from 'context/CxpQuestionContextProvider/types';

export const ROWS_PER_PAGE = 50;
export const GENERAL_ERROR =
  'Search request failed, try again. If this persists create a support ticket and escalate to engineering.';
export const GENERAL_REPORT_ERROR =
  'Unable to download the report, try again. If this persists create a support ticket and escalate to engineering.';
export const GENERAL_TEXTBOOK_ERROR =
  'Unable to download textbook data, try again. If this persists create a support ticket and escalate to engineering.';
export const NOT_FOUND_CXP_ITEMS = 'No results found for your search criteria.';
export const NEXT_PAGE = 'next';
export const PREVIOUS_PAGE = 'previous';
export const CHANGE_VALUE_ONE_SUBMISSION_ERROR = `One Submission CXP Metadata update failed, try again. If this persists create a support ticket and escalate to engineering.`;

export enum direction {
  NEUTRAL = 'neutral',
  ASC = 'asc',
  DESC = 'desc',
}
export interface ISort {
  sortDirection: direction;
  paint: JSX.Element;
}

export interface ISortData {
  sortedQuestions: IFlattenedCxpResultQuestion[];
  page: number;
  pages: number;
}

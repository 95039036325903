import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import FeedbackItem from '../helpers/FeedbackTabItem/FeedbackItem';
import { FEEDBACK_ITEM } from '../helpers/types';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { IAssignmentSettings, Submission } from 'context/AssignmentContextProvider/types';

const FeedbackLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem;
`;

const FeedbackTab = (): ReactElement => {
  const {
    state: {
      assignmentSettings: { currentAssignmentTemplate },
    },
  } = useAssignmentContext();

  const {
    submissionAndWorkSettings: { submission },
  } = currentAssignmentTemplate?.settings as IAssignmentSettings;

  return (
    <FeedbackLayout data-testid="feedbackTabLayout">
      <FeedbackItem labelTextKey={FEEDBACK_ITEM.answerKey} />
      <FeedbackItem labelTextKey={FEEDBACK_ITEM.answerFeedback} />
      {submission === Submission.QUESTION_PART ? null : <FeedbackItem labelTextKey={FEEDBACK_ITEM.studentScore} />}
      <FeedbackItem labelTextKey={FEEDBACK_ITEM.helpContent} />
      <FeedbackItem labelTextKey={FEEDBACK_ITEM.practiceAnotherVersion} />
      <FeedbackItem labelTextKey={FEEDBACK_ITEM.studentsPreviousResp} />
    </FeedbackLayout>
  );
};

export default FeedbackTab;

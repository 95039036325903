import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  AlertVariant,
  Button,
  ButtonColor,
  ButtonShape,
  ButtonVariant,
  Dropdown,
  DropdownAlignment,
  DropdownButton,
  DropdownContent,
  DropdownMenuItem,
  Heading,
  magma,
  Toast,
  TypographyContextVariant,
  TypographyVisualStyle,
} from 'react-magma-dom';
import { DeleteIcon, MoreHorizIcon } from 'react-magma-icons';
import styled from '@emotion/styled';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { getIsScheduled, saveAssignment } from '../apiHelpers';
import { getFieldValue } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { TrashModal } from './TrashModal';
import { PrivacySettingModal } from './PrivacySettingModal';
import { VersionHistoryModal } from './VersionHistoryModal';
import { Theme } from 'wa-ui-components';
import {
  setAccordionState,
  setAssignmentEditorContext,
  setAssignmentEditorWorkflow,
  setVersionHistory,
  setDuplicateAssignmentEditorContext,
  setErrors,
  setIsCustomTemplate,
  setPrivacySetting,
  setQuestionsData,
  setResetInitialState,
} from 'context/AssignmentContextProvider/actions';
import { getContents, getSettings } from 'utils/dataConverter/dataConverter';
import {
  AssignmentEditorWorkflow,
  IAccordionState,
  IMasterySet,
  IQuestionElement,
  Permission,
  QuestionElementType,
  ShowMyWorkMode,
  Submission,
  TrashableStatus,
} from 'context/AssignmentContextProvider/types';

const BarLayout = styled.div`
  align-items: center;
  border-bottom: 0.06rem solid ${({ theme }) => theme.colors.darkGreyContrastWithLightGrey};
  display: flex;
  justify-content: space-between;
  height: 5.5rem;
  padding: 1.5rem 1rem;
`;
const LastSaved = styled.div`
  font-family: 'Open Sans', Helvetica, sans-serif;
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.85rem;
  font-weight: 300;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
const BorderLine = styled.div`
  border-top: 0.06rem solid ${({ theme }) => theme.colors.darkGreyContrastWithLightGrey};
  margin: 0 0.5rem;
`;

const DropdownMenuWrapper = styled(DropdownContent)`
  left: unset;
  right: 0;
`;

const AssignmentId = styled.span`
  color: ${magma.colors.neutral03};
  padding-left: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: 0;
  vertical-align: middle;
`;

const BarAlert = styled.span`
  display: block;
  font-weight: normal;
  position: relative;
  width: 100%;
  align-items: flex-start;
  padding-top: 1.5rem;
  margin-bottom: -1.5rem;
`;

export const AssignmentEditorBar = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    state: {
      accordionState,
      errors,
      userId,
      assignmentEditorContext: {
        assignmentInfo: { id, name, authorId, templateId },
        assignmentInfo,
        canSaveAssignment,
      },
      assignmentEditorContext,
      assignmentSettings: { currentAssignmentTemplate, isCustomTemplate, assignmentDynamicInfo },
      questionsData,
      workflow,
      isSettingsLoading,
    },
    dispatch,
  } = useAssignmentContext();

  const history = useHistory();

  const [showToast, updateShowToast] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageForAlert, setMessageForAlert] = useState({ message: '', variant: '' });
  const [showTrashAssignmentModal, setShowTrashAssignmentModal] = useState(false);
  const [showPrivacySettingModal, setShowPrivacySettingModal] = useState(false);
  const [showVersionHistoryModal, setShowVersionHistoryModal] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [isTrashModalLoading, setIsTrashModalLoading] = useState(false);
  const [pageHeaderAssignmentName, setPageHeaderAssignmentName] = useState(assignmentInfo.name);
  const [shouldUpdatePageHeader, setShouldUpdatePageHeader] = useState(false);

  // Update page header when transitioning from initial state to loaded context OR after successful save
  useEffect(() => {
    if (!pageHeaderAssignmentName || shouldUpdatePageHeader) {
      setPageHeaderAssignmentName(assignmentInfo.name);
    }
  }, [shouldUpdatePageHeader, assignmentInfo.name]);

  const navigateToSchedule = () => {
    window.location.href = window.location.origin + '/web/Faculty/Course-Choose/schedule?aid=' + id;
  };
  const hasQuestion = questionsData.length > 0;

  const openPreviewWindow = () => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', window.location.origin + '/v4cgi/control.pl');
    form.setAttribute('target', 'APreview');

    const controlInput = document.createElement('input');
    controlInput.type = 'hidden';
    controlInput.name = 'control';
    controlInput.value = 'assignment/editor';
    form.appendChild(controlInput);

    const rmInput = document.createElement('input');
    rmInput.type = 'hidden';
    rmInput.name = 'rm';
    rmInput.value = 'preview';
    form.appendChild(rmInput);

    const fromInput = document.createElement('input');
    fromInput.type = 'hidden';
    fromInput.name = 'from';
    fromInput.value = 'new';
    form.appendChild(fromInput);

    const aidInput = document.createElement('input');
    aidInput.type = 'hidden';
    aidInput.name = 'aid';
    aidInput.value = id.toString();
    form.appendChild(aidInput);

    const contentsInput = document.createElement('input');
    contentsInput.type = 'hidden';
    contentsInput.name = 'contents';
    contentsInput.value = getContents(questionsData, assignmentInfo);
    form.appendChild(contentsInput);

    if (templateId) {
      const templateInput = document.createElement('input');
      templateInput.type = 'hidden';
      templateInput.name = 'template_id';
      templateInput.value = templateId.toString();
      form.appendChild(templateInput);
    } else {
      const settingsInput = document.createElement('input');
      settingsInput.type = 'hidden';
      settingsInput.name = 'settings';
      settingsInput.value = getSettings(currentAssignmentTemplate, id, authorId);
      form.appendChild(settingsInput);
    }

    document.body.appendChild(form);

    window.open('dummy', 'APreview');
    form.submit();
    document.body.removeChild(form);
  };

  const getQuestionsDataWithUpdatedShowMyWork = (): IQuestionElement[] => {
    questionsData.forEach(questionElement => {
      if (
        questionElement.elementType === QuestionElementType.QUESTION &&
        questionElement.showMyWorkMode === ShowMyWorkMode.WAS_DISABLED
      ) {
        questionElement.showMyWorkMode = ShowMyWorkMode.DISABLED;
      }
    });
    return questionsData;
  };

  const canSave = (): boolean => {
    let newAccordionState: IAccordionState = accordionState;
    let hasErrors = false;
    if (!hasQuestion) {
      newAccordionState = { ...accordionState, questionsOpen: true };
      dispatch(
        setErrors({
          field: 'questions.empty',
          value: t('ASSIGNMENT_EDITOR.BAR.SAVE_MESSAGE_ALERT.QUESTIONS_REQUIRED'),
        })
      );
      hasErrors = true;
    }
    if (getFieldValue(errors, 'info.assignmentName') || getFieldValue(errors, 'info.instructionsField')) {
      newAccordionState = { ...newAccordionState, infoOpen: true };
      hasErrors = true;
    }
    if (!assignmentInfo.name && !getFieldValue(errors, 'info.assignmentName')) {
      newAccordionState = { ...newAccordionState, infoOpen: true };
      dispatch(
        setErrors({
          field: 'info.assignmentName',
          value: t('ASSIGNMENT_EDITOR.INFORMATION.ERRORS.NAME.REQUIRED'),
        })
      );
      hasErrors = true;
    }
    const questionErrors = getFieldValue(errors, 'questions');
    if (questionErrors && Object.getOwnPropertyNames(questionErrors).length) {
      hasErrors = true;
    }
    if (getFieldValue(errors, 'settings.submitAnswerBy')) {
      newAccordionState = { ...newAccordionState, settingsOpen: true, questionsOpen: true };
      hasErrors = true;
    }
    if (hasErrors) {
      dispatch(setAccordionState(newAccordionState));
      return false;
    }
    return true;
  };
  const handleSaveAssignment = async () => {
    setLoading(true);
    setDisabled(true);
    if (canSave()) {
      try {
        const result = await saveAssignment(
          userId,
          id,
          isCustomTemplate,
          currentAssignmentTemplate,
          assignmentInfo,
          getQuestionsDataWithUpdatedShowMyWork()
        );
        setMessageForAlert({
          message: t('ASSIGNMENT_EDITOR.BAR.SAVE_MESSAGE_ALERT.SUCCESS', { name }),
          variant: AlertVariant.success,
        });
        updateShowToast(true);

        if (workflow === AssignmentEditorWorkflow.CREATE || workflow === AssignmentEditorWorkflow.DUPLICATE) {
          //dispatch the results to the global store and switch to edit mode
          const assignmentEditorContextData = assignmentEditorContext;
          const assignmentId = result.data.result.assignmentId;
          assignmentEditorContextData.assignmentInfo.id = assignmentId;
          if (userId) {
            assignmentEditorContextData.assignmentInfo.authorId = userId;
          }

          history.push(`/app/assignment/${assignmentId}/edit`);

          dispatch(setAssignmentEditorContext(assignmentEditorContextData));
          dispatch(setAssignmentEditorWorkflow(AssignmentEditorWorkflow.EDIT));
        }
        if (result.data.result.versionHistory) {
          dispatch(setVersionHistory(result.data.result.versionHistory));
        }
        setShouldUpdatePageHeader(true);
        dispatch(setResetInitialState(true));
      } catch (error) {
        setErrorAlert(true);
        setMessageForAlert({
          message: t('ASSIGNMENT_EDITOR.BAR.SAVE_MESSAGE_ALERT.UNKNOWN_ERROR'),
          variant: AlertVariant.danger,
        });
        updateShowToast(true);
      }
    } else {
      setMessageForAlert({
        message: t('ASSIGNMENT_EDITOR.BAR.SAVE_MESSAGE_ALERT.GENERIC'),
        variant: AlertVariant.danger,
      });
      updateShowToast(true);
    }
    setShouldUpdatePageHeader(false);
    setDisabled(false);
    setLoading(false);
  };

  const openTrashAssignmentModal = async () => {
    setIsTrashModalLoading(true);
    setShowTrashAssignmentModal(true);
    await getIsScheduled(id, userId).then(res => setIsScheduled(res.data.result));
    setIsTrashModalLoading(false);
  };

  const closeTrashAssignmentModal = () => {
    setIsTrashModalLoading(false);
    setShowTrashAssignmentModal(false);
  };

  const handleDuplicate = () => {
    history.push(`/app/assignment/${id}/duplicate`);
    !canSaveAssignment && dispatch(setIsCustomTemplate(true));
    dispatch(setDuplicateAssignmentEditorContext(assignmentEditorContext));
    let hasMastery;
    questionsData.forEach(element => {
      if (element.elementType === QuestionElementType.MASTERY_SET) {
        (element as IMasterySet).id = null;
        hasMastery = true;
      }
    });
    if (hasMastery) {
      dispatch(setQuestionsData(questionsData));
    }
    if (currentAssignmentTemplate.settings.submissionAndWorkSettings.submission === Submission.ASSIGNMENT) {
      getQuestionsDataWithUpdatedShowMyWork();
    }
    setMessageForAlert({
      message: t('ASSIGNMENT_EDITOR.BAR.DUPLICATE_MESSAGE_ALERT.SUCCESS'),
      variant: AlertVariant.success,
    });
    const duplicateAccordionState: IAccordionState = {
      infoOpen: true,
      settingsOpen: true,
      questionsOpen: true,
    };
    dispatch(setAccordionState(duplicateAccordionState));
    updateShowToast(true);
    dispatch(setResetInitialState(true));
  };

  const renderHeadingContent = () => {
    switch (workflow) {
      case AssignmentEditorWorkflow.CREATE:
        return t('ASSIGNMENT_EDITOR.BAR.CREATE_ASSIGNMENT');
      case AssignmentEditorWorkflow.DUPLICATE:
        return `${pageHeaderAssignmentName} (${t('ASSIGNMENT_EDITOR.BAR.COPY')})`;
      case AssignmentEditorWorkflow.EDIT:
      default:
        return (
          <>
            {pageHeaderAssignmentName} <AssignmentId>({assignmentInfo.id})</AssignmentId>
          </>
        );
    }
  };
  const openPrivacySettingModal = () => {
    setShowPrivacySettingModal(true);
  };

  const closePrivacySettingModal = () => {
    setShowPrivacySettingModal(false);
  };

  const applyPrivacySettingChanges = (privacySetting: string, groupId: number) => {
    if (privacySetting && Object.values(Permission).includes(privacySetting)) {
      dispatch(setPrivacySetting({ privacySetting, groupId }));
    }
    setShowPrivacySettingModal(false);
  };

  const openVersionHistoryModal = () => {
    setShowVersionHistoryModal(true);
  };

  const closeVersionHistoryModal = () => {
    setShowVersionHistoryModal(false);
  };

  const canEdit = canSaveAssignment || workflow !== AssignmentEditorWorkflow.EDIT;

  const getLastSavedDate = () => {
    // Taking the first element from versionHistory array which will be the last saved date
    const versionHistory = assignmentInfo.versionHistory;
    return versionHistory.length ? versionHistory[0].date : null;
  };

  return (
    <>
      <BarLayout data-testid="editorBar">
        <Info>
          <Heading
            level={2}
            contextVariant={TypographyContextVariant.expressive}
            visualStyle={TypographyVisualStyle.headingMedium}
            noMargins
            testId="editorBarName"
            css
          >
            {renderHeadingContent()}
          </Heading>
          {workflow === AssignmentEditorWorkflow.EDIT ? (
            <LastSaved>{t('ASSIGNMENT_EDITOR.BAR.LAST_SAVED', { lastSaved: getLastSavedDate() })}</LastSaved>
          ) : null}
        </Info>
        {assignmentEditorContext.trashableStatus !== TrashableStatus.IN_TRASH ? (
          <div>
            {workflow === AssignmentEditorWorkflow.EDIT ? (
              <>
                <Dropdown alignment={DropdownAlignment.start}>
                  <DropdownButton
                    aria-label={t('ASSIGNMENT_EDITOR.BAR.ASSIGNMENT_SETTING_DROPDOWN') || 'translate me'}
                    testId="assignmentSettingDropdown"
                    color={ButtonColor.secondary}
                    shape={ButtonShape.fill}
                    variant={ButtonVariant.outline}
                    icon={<MoreHorizIcon />}
                  />
                  <DropdownMenuWrapper>
                    <DropdownMenuItem data-testid="duplicateButton" onClick={handleDuplicate}>
                      {t('ASSIGNMENT_EDITOR.BAR.MAKE_COPY')}
                    </DropdownMenuItem>
                    <DropdownMenuItem data-testid="privacySettingButton" onClick={openPrivacySettingModal}>
                      {t('ASSIGNMENT_EDITOR.BAR.PRIVACY_SETTING')}
                    </DropdownMenuItem>
                    <DropdownMenuItem data-testid="versionHistoryButton" onClick={openVersionHistoryModal}>
                      {t('ASSIGNMENT_EDITOR.BAR.VERSION_HISTORY')}
                    </DropdownMenuItem>

                    {userId === authorId && (
                      <>
                        <BorderLine />
                        <DropdownMenuItem
                          icon={<DeleteIcon color={Theme.colors.darkGrey} size={10} aria-hidden="true" />}
                          onClick={openTrashAssignmentModal}
                          data-testid="openModalButton"
                        >
                          {t('ASSIGNMENT_EDITOR.BAR.TRASH')}
                        </DropdownMenuItem>
                      </>
                    )}
                  </DropdownMenuWrapper>
                </Dropdown>
                <Button
                  color={ButtonColor.secondary}
                  variant={ButtonVariant.solid}
                  onClick={navigateToSchedule}
                  testId="scheduleButton"
                >
                  Schedule
                </Button>
              </>
            ) : null}
            <Button
              color={ButtonColor.secondary}
              variant={ButtonVariant.solid}
              onClick={openPreviewWindow}
              testId="previewButton"
              aria-label={t('ASSIGNMENT_EDITOR.BAR.PREVIEW_LABEL') || 'translate me'}
            >
              {t('ASSIGNMENT_EDITOR.BAR.PREVIEW')}
            </Button>

            {canEdit ? (
              <Button
                variant={ButtonVariant.solid}
                onClick={handleSaveAssignment}
                testId="saveButton"
                disabled={disabled || isSettingsLoading}
                isLoading={loading || isSettingsLoading}
              >
                {t('ASSIGNMENT_EDITOR.BAR.SAVE')}
              </Button>
            ) : (
              <Button
                variant={ButtonVariant.solid}
                onClick={handleDuplicate}
                testId="makeACopyButton"
                disabled={disabled || isSettingsLoading}
                isLoading={loading || isSettingsLoading}
              >
                {t('ASSIGNMENT_EDITOR.BAR.MAKE_COPY')}
              </Button>
            )}
          </div>
        ) : null}
      </BarLayout>
      {!canEdit && !isSettingsLoading ? (
        <BarAlert>
          <Alert variant={AlertVariant.info} testId="permissionAlert">
            {' '}
            {t('ASSIGNMENT_EDITOR.BAR.NO_EDITING_PERMISSION_ALERT')}{' '}
          </Alert>
        </BarAlert>
      ) : null}

      <TrashModal
        show={showTrashAssignmentModal}
        close={closeTrashAssignmentModal}
        assignmentId={id}
        userId={userId}
        isScheduled={isScheduled}
        isLoading={isTrashModalLoading}
      />
      <PrivacySettingModal
        groups={assignmentDynamicInfo.groups.map(group => ({ label: group.name, value: group.id }))}
        show={showPrivacySettingModal}
        close={closePrivacySettingModal}
        privacySetting={currentAssignmentTemplate.settings.assignmentPermissions}
        groupId={currentAssignmentTemplate.settings.groupId}
        applyPrivacySettingChanges={applyPrivacySettingChanges}
      />
      <VersionHistoryModal
        versionHistory={assignmentInfo.versionHistory}
        name={assignmentInfo.name}
        id={assignmentInfo.id}
        show={showVersionHistoryModal}
        close={closeVersionHistoryModal}
      />
      {showToast ? (
        <Toast
          role="alert"
          onDismiss={() => updateShowToast(false)}
          variant={messageForAlert.variant as AlertVariant}
          testId={errorAlert ? 'error_alert' : 'success_alert'}
        >
          {messageForAlert.message}
        </Toast>
      ) : null}
    </>
  );
};

import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

import { Heading, Hyperlink } from 'react-magma-dom';
import { applicationHostName } from '../../utils/utils';
import logo from '../../assets/images/cengage-webassign-logo.svg';

const UnknownLayout = styled.div`
  padding: 3rem 6rem;
  display: flex;
  flex-wrap: wrap;
  h3 {
    opacity: 0.7;
  }
  h2 {
    margin-bottom: 2rem;
  }
`;

const Logo = styled.img`
  height: 4rem;
  margin-left: -1rem;
`;

const H = styled(Heading)`
  width: 100%;
  margin: 1rem 0;
`;

const UnknownPage = (): ReactElement => {
  return (
    <UnknownLayout>
      <Logo src={logo} alt="Cengage | WebAssign logo" title="Cengage | WebAssign logo" />
      <H level={3} css>
        404 Error
      </H>
      <H level={1} css>
        Sorry, page not found
      </H>
      <H level={2} css>
        This page might have been moved, deleted, or it never existed
      </H>

      <Hyperlink style={{ height: '1.75rem' }} styledAs="Button" to={applicationHostName}>
        Go to home page
      </Hyperlink>
    </UnknownLayout>
  );
};

export default UnknownPage;

import React from 'react';
import { Hyperlink, magma } from 'react-magma-dom';
import { ArrowBackIcon } from 'react-magma-icons';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const LinkWrapper = styled.div`
  display: inline-block;
  margin-left: 0.7rem;

  a {
    color: ${({ theme }) => theme.colors.primaryBlue};
    background-color: transparent;
    border: none;
    font: 0.95rem ${({ theme }) => theme.OpenSans};
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:link,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.darkBlue};
      font-weight: 700;
    }

    & > svg {
      margin-right: 0.625rem;
      vertical-align: text-bottom;
    }
  }
`;

export const BackToClassLink: React.FC<{ classContext: string }> = props => {
  const { classContext } = props;
  const { t } = useTranslation();

  const activeClassContext = classContext ? JSON.parse(classContext) : undefined;
  if (!activeClassContext) {
    return null;
  }
  const shouldShowBackToClassLink = () => {
    return activeClassContext?.course && activeClassContext?.section;
  };

  let navLinkTitle = '';
  if (activeClassContext.course?.name?.length > 0) {
    navLinkTitle += `${activeClassContext.course?.name?.trim()}`;
  }
  if (activeClassContext.section?.name?.length > 0) {
    navLinkTitle += `, ${t('NAVIGATION.SECTION')} ${activeClassContext.section?.name?.trim()}`;
  }

  return (
    <LinkWrapper>
      {shouldShowBackToClassLink() ? (
        <Hyperlink
          to={`/v4cgi/faculty.pl?action=course/overview&course=${activeClassContext.course?.id},${activeClassContext.section?.id}`}
          testId="backLink"
          aria-label={`${t('NAVIGATION.NAVIGATE_BACK')} ${navLinkTitle}`}
        >
          <ArrowBackIcon color={magma.colors.primary} size={20} aria-hidden={true} />
          {navLinkTitle}
        </Hyperlink>
      ) : null}
    </LinkWrapper>
  );
};

import mapper from '@cookbook/mapper-js';
import { IAssignmentData, IMappedMasteryQuestions, IMappedQuestionPools, IMappedQuestionsSettings } from './types';
import {
  IAssignmentTemplate,
  IQuestionElement,
  IAssignmentInfo,
  IBox,
  IAssignmentQuestion,
} from 'context/AssignmentContextProvider/types';
import {
  isAssignmentQuestion,
  isMasterySet,
  isQuestionPool,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { getMasteryPoints, getPoolPoints } from 'facultyComponents/assignmentEditor/helpers/commonHelpers';

export const getSettings = (currentAssignmentTemplate: IAssignmentTemplate, id: number, authorId: number): string => {
  const settings = mapper(map => ({
    tolerance: map('currentAssignmentTemplate.settings.numericalToleranceSettings.numericalTolerance').value,
    percent_tolerance: map('currentAssignmentTemplate.settings.numericalToleranceSettings.isToleranceInPercent').value,
    show_my_work_points: map('currentAssignmentTemplate.settings.submissionAndWorkSettings.showMyWorkPointValue').value,
    show_my_work_mode: map('currentAssignmentTemplate.settings.submissionAndWorkSettings.showMyWorkMode').value,
    symbolic_syntax: map(
      'currentAssignmentTemplate.settings.displayAndSyntaxErrorsSettings.isIncorrectSubmissionsWithSyntaxErrorsForSymbolicQuestions'
    ).value,
    comments: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showGraderComments.showAfterDueDate').value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showGraderComments.showBeforeDueDate').value,
    },
    override_sigfigs: map('currentAssignmentTemplate.settings.significantFiguresSettings.significantFiguresOverridden')
      .value,
    qp_scores: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showQuestionPartScore.showAfterDueDate').value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showQuestionPartScore.showBeforeDueDate').value,
    },
    key: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showKey.showAfterDueDate').value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showKey.showBeforeDueDate').value,
      condition: map('currentAssignmentTemplate.settings.templateFeedback.showKey.specifiedNumberOfSubmissions').value,
    },
    mark: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showMark.showAfterDueDate').value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showMark.showBeforeDueDate').value,
      condition: map('currentAssignmentTemplate.settings.templateFeedback.showMark.specifiedNumberOfSubmissions').value,
    },
    sigfigs_tolerance: map('currentAssignmentTemplate.settings.significantFiguresSettings.significantFiguresTolerance')
      .value,
    algebraic_syntax: map(
      'currentAssignmentTemplate.settings.displayAndSyntaxErrorsSettings.isIncorrectSubmissionsWithSyntaxErrorsForAlgebraicQuestions'
    ).value,
    category: map('currentAssignmentTemplate.settings.categoryId').value,
    grade_which: map('currentAssignmentTemplate.settings.submissionAndWorkSettings.scoringAnswers').value,
    random_order: map('currentAssignmentTemplate.settings.isQuestionOrderRandomized').value,
    units_credit: map('currentAssignmentTemplate.settings.numericalToleranceSettings.partialCreditForUnitsPercentage')
      .value,
    sigfigs_credit: map(
      'currentAssignmentTemplate.settings.significantFiguresSettings.significantFiguresPartialCreditPercentage'
    ).value,
    permission: map('currentAssignmentTemplate.settings.assignmentPermissions').value,
    name: map('currentAssignmentTemplate.name').value,
    description: map('currentAssignmentTemplate.description').value,
    point_adjustments: map('currentAssignmentTemplate.settings.conditionalPointsFormula').value,
    numerical_syntax: map(
      'currentAssignmentTemplate.settings.displayAndSyntaxErrorsSettings.isIncorrectSubmissionsWithSyntaxErrorsForNumericalQuestions'
    ).value,
    save_is_submit: map('currentAssignmentTemplate.settings.saveIsSubmit').value,
    det_submissions: map(
      'currentAssignmentTemplate.settings.questionRandomizationSettings.randomizationSubmissionsCount'
    ).value,
    display_all: map('currentAssignmentTemplate.settings.displayAllQuestions').value,
    responses: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showResponses.showAfterDueDate').value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showResponses.showBeforeDueDate').value,
    },
    det_solution: map('currentAssignmentTemplate.settings.questionRandomizationSettings.showSolution').value,
    override_tolerance: map('currentAssignmentTemplate.settings.numericalToleranceSettings.toleranceOverridden').value,
    show_my_work_value: map('currentAssignmentTemplate.settings.submissionAndWorkSettings.showMyWorkPointValue').value,
    format_tips: map('currentAssignmentTemplate.settings.displayAndSyntaxErrorsSettings.answerFormatTips').value,
    solution: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showSolution.showAfterDueDate').value,
      condition: map('currentAssignmentTemplate.settings.templateFeedback.showSolution.specifiedNumberOfSubmissions')
        .value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showSolution.showBeforeDueDate').value,
    },
    det_key: map('currentAssignmentTemplate.settings.questionRandomizationSettings.showAnswerKey').value,
    randomization: map('currentAssignmentTemplate.settings.questionRandomizationSettings.randomization').value,
    group: map('currentAssignmentTemplate.settings.groupId').value,
    pav: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion.showAfterDueDate')
        .value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion.showBeforeDueDate')
        .value,
      condition: map(
        'currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion.specifiedNumberOfSubmissions'
      ).value,
    },
    symbolic_icon: map(
      'currentAssignmentTemplate.settings.displayAndSyntaxErrorsSettings.symbolicFormattingHelpIconEnabled'
    ).value,
    rich: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion.showAfterDueDate')
        .value,
      condition: map(
        'currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion.specifiedNumberOfSubmissions'
      ).value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion.showBeforeDueDate')
        .value,
    },
    submission_type: map('currentAssignmentTemplate.settings.submissionAndWorkSettings.submission').value,
    public: map('currentAssignmentTemplate.settings.isPublic').value,
    override_syntax: map('currentAssignmentTemplate.settings.displayAndSyntaxErrorsSettings.syntaxErrorsOverridden')
      .value,
    submissions_allowed: map('currentAssignmentTemplate.settings.submissionAndWorkSettings.allowedSubmissionsCount')
      .value,
    sigfigs_icon: map(
      'currentAssignmentTemplate.settings.significantFiguresSettings.isSignificantFiguresHelpIconClickable'
    ).value,
    a_scores: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showAssignmentScore.showAfterDueDate').value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showAssignmentScore.showBeforeDueDate').value,
    },
    q_scores: {
      after: map('currentAssignmentTemplate.settings.templateFeedback.showQuestionScore.showAfterDueDate').value,
      before: map('currentAssignmentTemplate.settings.templateFeedback.showQuestionScore.showBeforeDueDate').value,
    },
    salt_display: map('currentAssignmentTemplate.settings.saltStatus').value,
    show_names: map('currentAssignmentTemplate.settings.displayAndSyntaxErrorsSettings.questionNameDisplay').value,
    sigfigs_help: map('currentAssignmentTemplate.settings.significantFiguresSettings.significantFiguresHelpIconEnabled')
      .value,
    sigfigs_on: map('currentAssignmentTemplate.settings.significantFiguresSettings.significantFiguresCheckEnabled')
      .value,
    user: map('authorId').value,
    id: map('id').value,
  }));
  const assignmentData: IAssignmentData = settings({ currentAssignmentTemplate, authorId, id });
  return JSON.stringify(assignmentData);
};

export const getContents = (questionsData: IQuestionElement[], assignmentInfo: IAssignmentInfo): string => {
  const contents = {
    contents: questionsData.map((item: IQuestionElement) => {
      if (isAssignmentQuestion(item)) {
        return {
          elementType: item.elementType,
          id: item.question.id,
          show_my_work_mode: item.showMyWorkMode,
          show_my_work_points: item.showMyWorkPointValue,
          items: item.boxes.map((item: IBox): IMappedQuestionsSettings => {
            return {
              points: item.gradingSettings.pointsValue,
              submissions: item.gradingSettings.allowedSubmissionsCount,
              condition: item.gradingSettings.conditionalPointsFormula,
            };
          }),
        };
      }

      if (isQuestionPool(item)) {
        const questionIds = item.questions.map((item: IAssignmentQuestion) => {
          return item.question.id;
        });
        return {
          elementType: item.elementType,
          //to the left of the colon we insert the number of questions in the pools that will be shown in the assignment preview, then we make a string of question ids separated by an underscore
          id: `${item.includedNumberOfQuestions}:${questionIds.join('_')}`,
          choose: item.includedNumberOfQuestions,
          size: item.numberOfQuestions,
          show_my_work_mode: item.showMyWorkMode,
          show_my_work_points: item.showMyWorkPointValue,
          submissions: item.gradingSettings.allowedSubmissionsCount,
          points: getPoolPoints(item),
          condition: item.gradingSettings.conditionalPointsFormula,
          items: item.questions.map((item: IAssignmentQuestion): IMappedQuestionPools => {
            return {
              id: item.question.id,
              percent_tolerance: item.numericalToleranceSettings.isToleranceInPercent,
            };
          }),
        };
      }
      if (isMasterySet(item)) {
        const questionIds = item.questions.map((item: IAssignmentQuestion) => {
          return item.question.id;
        });
        return {
          elementType: item.elementType,
          //a string used in the new assignment previewer to easily identify which questions are in the group, how many to display and how many we need to achieve mastery
          id: `${item.subsetSize}:${item.masteryThreshold}:${questionIds.join('_')}`,
          choose: item.subsetSize,
          size: item.numberOfQuestions,
          points: getMasteryPoints(item),
          show_my_work_mode: item.showMyWorkMode,
          show_my_work_points: item.showMyWorkPointValue,
          submissions: item.gradingSettings.allowedSubmissionsCount,
          items: item.questions.map((item: IAssignmentQuestion): IMappedMasteryQuestions => {
            return {
              id: item.question.id,
            };
          }),
        };
      }
      return '';
    }),
    name: assignmentInfo.name,
    heading: assignmentInfo.instructions,
    code: '',
    author: assignmentInfo.authorEmail,
  };
  return JSON.stringify(contents);
};

import React, { createRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  Flex,
  FlexAlignItems,
  FlexBehavior,
  IconButton,
  Tab,
} from 'react-magma-dom';
import { ArrowBackIcon, ArrowForwardIcon, ListAltIcon } from 'react-magma-icons';
import { defaultTabHeightRem } from '../Drawer/Drawer';
import { IFrameLoader } from 'sharedComponents/IFrameLoader/IFrameLoader';
import {
  IMasterySet,
  IQuestion,
  IQuestionElement,
  IQuestionPool,
  QuestionElementType,
} from 'context/AssignmentContextProvider/types';
import { isAssignmentQuestion } from 'facultyComponents/assignmentEditor/helpers/questionElements';
import styled from '@emotion/styled';

interface IPreviewTabProps {
  id: string;
  testId: string;
}

interface IPreviewPanelProps {
  index: number;
  questionElement: IQuestionElement;
  questionNumber: number[] | null;
}

interface IPreviewQuestionSetPanelProps {
  index: number;
  questionSet: IQuestion[];
  questionElementType: QuestionElementType;
}

const QuestionSetNavigation = styled(Flex)`
  background: ${({ theme }) => theme.colors.lightGrey};
  border: 0.125rem solid ${({ theme }) => theme.colors.greyAccent};
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  margin-bottom: 1.5rem;
`;

const StyledSpan = styled.span`
  padding: 0.5rem;
`;

const assignmentQuestionContentPass = '/wx/control?control=assignment/editor/content&rm=question_content';

const getAssignmentQuestionContentPass = (qid: number, questionNumber: number | null): string => {
  return `${window.location.origin}${assignmentQuestionContentPass}&qid=${qid}&position=${questionNumber}`;
};

const getIframeSrc = (questionElement: IQuestionElement, questionNumber: number[] | null): string | null => {
  if (isAssignmentQuestion(questionElement)) {
    if (!questionNumber) {
      return null;
    }
    const qid = questionElement.question.id;
    return getAssignmentQuestionContentPass(qid, questionNumber[0]);
  }
  return null;
};

export const PreviewTab = (props: IPreviewTabProps): JSX.Element => {
  const { id, testId } = props;
  const { t } = useTranslation();

  return (
    <Tab icon={<ListAltIcon />} id={id} testId={testId}>
      {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.PREVIEW')}
    </Tab>
  );
};

export const PreviewPanel = (props: IPreviewPanelProps): JSX.Element | null => {
  const { index, questionElement, questionNumber } = props;
  switch (questionElement.elementType) {
    case QuestionElementType.QUESTION_POOL:
      const poolQuestionSet = (questionElement as IQuestionPool).questions.map(
        assignmentQuestion => assignmentQuestion.question
      );
      return (
        <PreviewPanelForQuestionSet
          index={index}
          questionSet={poolQuestionSet}
          questionElementType={QuestionElementType.QUESTION_POOL}
        />
      );
    case QuestionElementType.MASTERY_SET:
      const masterySetQuestionSet = (questionElement as IMasterySet).questions.map(
        assignmentQuestion => assignmentQuestion.question
      );
      return (
        <PreviewPanelForQuestionSet
          index={index}
          questionSet={masterySetQuestionSet}
          questionElementType={QuestionElementType.MASTERY_SET}
        />
      );
    case QuestionElementType.QUESTION:
      return (
        <PreviewPanelForQuestion index={index} questionElement={questionElement} questionNumber={questionNumber} />
      );
    default:
      return null;
  }
};

const PreviewPanelForQuestion = (props: IPreviewPanelProps): JSX.Element | null => {
  const { index, questionElement, questionNumber } = props;
  const { t } = useTranslation();
  const iframeSrc = getIframeSrc(questionElement, questionNumber);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleQuestionContentLoad = () => {
    // Currently, we're using version4's question_content.pl to display question previews.
    // This template suppresses onLoad dom events, and stores them for later execution, which is triggered by external javascript.
    // For some content, graphpad/numberline, needs those events to trigger in order to render assets.
    // This handler calls question_content.pl's fireReadyEvents method to execute the stored onLoad events.

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    iframeRef.current?.contentWindow?.fireReadyEvents();
  };

  return (
    <>
      {iframeSrc ? (
        <IFrameLoader
          id={`question_${index}_preview_panel`}
          initialHeight={defaultTabHeightRem}
          resizeToFit={true}
          src={iframeSrc}
          testId={`question_${index}_preview_panel`}
          title={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_IFRAME_TITLE', { questionPosition: index + 1 })}
          onLoadHandler={handleQuestionContentLoad}
          ref={iframeRef}
        />
      ) : null}
    </>
  );
};

const getTitleForQuestionSetQuestion = (questionName: string, qid: number): string => {
  return `${questionName} (${qid})`;
};

const PreviewPanelForQuestionSet = (props: IPreviewQuestionSetPanelProps): JSX.Element | null => {
  const { index, questionSet, questionElementType } = props;
  const { t } = useTranslation();
  const firstQuestionId = questionSet[0].id;
  const iframeSrcInitValue = getAssignmentQuestionContentPass(firstQuestionId, null);
  const titleForQuestionSetQuestionInitValue = getTitleForQuestionSetQuestion(questionSet[0].name, firstQuestionId);
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [questionSetIframeSrc, setQuestionSetIframeSrc] = useState<string>(iframeSrcInitValue);
  const [titleForQuestionSetQuestion, setTitleForQuestionSetQuestion] = useState<string>(
    titleForQuestionSetQuestionInitValue
  );

  const iframeRef = createRef<HTMLIFrameElement>();

  const lastElementIndex = questionSet.length - 1;

  const showPreviousQuestionSetQuestion = () => {
    const previousQuestionIndex = questionIndex === 0 ? lastElementIndex : questionIndex - 1;
    setTitleAndIframeSrc(questionSet[previousQuestionIndex]);
    setQuestionIndex(previousQuestionIndex);
  };

  const showNextQuestionSetQuestion = () => {
    const nextQuestionIndex = questionIndex === lastElementIndex ? 0 : questionIndex + 1;
    setTitleAndIframeSrc(questionSet[nextQuestionIndex]);
    setQuestionIndex(nextQuestionIndex);
  };

  const setTitleAndIframeSrc = (question: IQuestion) => {
    setTitleForQuestionSetQuestion(getTitleForQuestionSetQuestion(question.name, question.id));
    setQuestionSetIframeSrc(getAssignmentQuestionContentPass(question.id, null));
  };

  const handleQuestionContentLoad = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    iframeRef.current?.contentWindow?.fireReadyEvents();
  };

  const previousQuestionString =
    questionElementType === QuestionElementType.QUESTION_POOL
      ? 'ASSIGNMENT_EDITOR.QUESTIONS_LIST.PREVIOUS_POOL_QUESTION'
      : 'ASSIGNMENT_EDITOR.QUESTIONS_LIST.PREVIOUS_MASTERY_SET_QUESTION';

  const nextQuestionString =
    questionElementType === QuestionElementType.QUESTION_POOL
      ? 'ASSIGNMENT_EDITOR.QUESTIONS_LIST.NEXT_POOL_QUESTION'
      : 'ASSIGNMENT_EDITOR.QUESTIONS_LIST.NEXT_MASTERY_SET_QUESTION';

  const iframeTitleString =
    questionElementType === QuestionElementType.QUESTION_POOL
      ? 'ASSIGNMENT_EDITOR.QUESTIONS_LIST.POOL_IFRAME_TITLE'
      : 'ASSIGNMENT_EDITOR.QUESTIONS_LIST.MASTERY_SET_IFRAME_TITLE';
  return (
    <>
      <QuestionSetNavigation
        behavior={FlexBehavior.container}
        alignItems={FlexAlignItems.center}
        spacing={1}
        testId={`questionSetNavigation_${index}`}
      >
        <IconButton
          icon={<ArrowBackIcon />}
          color={ButtonColor.secondary}
          testId="previousQuestion"
          shape={ButtonShape.fill}
          aria-label={t(previousQuestionString) || 'translate me'}
          onClick={showPreviousQuestionSetQuestion}
          size={ButtonSize.small}
        />
        <StyledSpan>
          {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_SET_NAVIGATION_TITLE', {
            questionPosition: questionIndex + 1,
            questionSetLength: questionSet.length,
          })}
        </StyledSpan>
        <IconButton
          icon={<ArrowForwardIcon />}
          color={ButtonColor.secondary}
          testId="nextQuestion"
          shape={ButtonShape.fill}
          aria-label={t(nextQuestionString) || 'translate me'}
          onClick={showNextQuestionSetQuestion}
          size={ButtonSize.small}
        />
        <StyledSpan>{titleForQuestionSetQuestion}</StyledSpan>
      </QuestionSetNavigation>
      <IFrameLoader
        id={`question_${index}_preview_panel`}
        initialHeight={defaultTabHeightRem}
        resizeToFit={true}
        src={questionSetIframeSrc}
        testId={`question_${index}_preview_panel`}
        title={t(iframeTitleString, {
          questionPosition: questionIndex + 1,
        })}
        onLoadHandler={handleQuestionContentLoad}
        ref={iframeRef}
      />
    </>
  );
};

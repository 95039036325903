import { checkIsDecimalOnly, checkIsWholeNumber, checkMinMax } from 'utils/validation/validationHelpers';
import { IMasterySet, IQuestionPool } from 'context/AssignmentContextProvider/types';
import { t } from 'i18next';

export const validateField = ({
  value,
  min,
  max,
  minMessage = '',
  maxMessage = '',
  message = '',
  withDecimal = false,
}: {
  value: string;
  min: number;
  max: number;
  minMessage?: string;
  maxMessage?: string;
  message?: string;
  withDecimal?: boolean;
}): { value: boolean; message: string } => {
  if (value.trim().length === 0) {
    return { value: true, message: minMessage };
  }
  const minMaxError = checkMinMax(+value, min, max, minMessage, maxMessage);
  const notWholeNumberError = checkIsWholeNumber(String(value), message);
  const onlyDecimalError = checkIsDecimalOnly(value, message);
  if (minMaxError.value) {
    return minMaxError;
  }
  if (notWholeNumberError.value && !withDecimal) {
    return notWholeNumberError;
  }
  if (onlyDecimalError.value && withDecimal) {
    return onlyDecimalError;
  }
  return { value: false, message: '' };
};

export const validatePointsInput = (
  pointsString: string | null,
  min = 0,
  minimumIsValid = true
): { error: boolean; message: string } => {
  const points = pointsString || pointsString == `0` ? parseFloat(pointsString) : null;
  let message = '';
  let error = false;
  if (!points && points !== 0) {
    error = true;
    message = t('ASSIGNMENT_EDITOR.POINT_ERRORS_MESSAGES.INVALID_CHARACTER');
  } else if (minimumIsValid && points < min) {
    error = true;
    message = t('ASSIGNMENT_EDITOR.POINT_ERRORS_MESSAGES.STRICTLY_LESS_THAN_MIN', { minimum: min });
  } else if (!minimumIsValid && points <= min) {
    error = true;
    message = t('ASSIGNMENT_EDITOR.POINT_ERRORS_MESSAGES.LESS_THAN_OR_EQUAL_TO_MIN', { minimum: min });
  } else if (pointsString?.toString().match(/\d*\.\d{3,}/)) {
    error = true;
    message = t('ASSIGNMENT_EDITOR.POINT_ERRORS_MESSAGES.MORE_THAN_TWO_DECIMALS');
  } else if (pointsString?.toString().match(/\d[0-9.]{6,}/)) {
    error = true;
    message = t('ASSIGNMENT_EDITOR.POINT_ERRORS_MESSAGES.TOO_MANY_CHARACTERS');
  }

  return { error, message };
};

// TODO: consolidate these getters into a single function with a common interface
export const getMasteryPoints = (mastery: IMasterySet): number | null => {
  const points = mastery.gradingSettings.pointsValue;
  return points || points === 0 ? points : null;
};

export const getPoolPoints = (pool: IQuestionPool): number | null => {
  const points = pool.gradingSettings.pointsValue;
  return points || points === 0 ? points : null;
};

export const SUBMISSION_COUNT = {
  MIN: 1,
  MAX: 100,
};

export const MIN_AFTER_SPECIFIED_SUBMISSION = 2;

import React from 'react';

class DefaultErrorBoundary extends React.Component<unknown, { hasError: boolean; error: Error | null | undefined }> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: unknown): { hasError: boolean; error: unknown } {
    return { hasError: true, error: error };
  }

  render(): React.ReactElement {
    return this.state.hasError ? (
      <div data-testid="errorBoundaryWrapper">something has gone wrong!</div>
    ) : (
      (this.props.children as React.ReactElement)
    );
  }
}

export default DefaultErrorBoundary;

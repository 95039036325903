import { Dispatch } from 'react';
import { FetcherType } from './typings';
import { ActionType, getErrorAction, getLoadedAction } from './actionCreators';

type WAResponse = {
  result: any;
};

const fetchData = (
  fetcher: FetcherType,
  apiName: string,
  inputData: any,
  setter: Dispatch<ActionType>,
  contextKey = ''
): void => {
  if (!fetcher) {
    return;
  }
  // key is unique value to store fetched result in context
  // when we have input params, we get key by combining apiName and inputParams
  // hence contextKey is prioritized over apiName
  const key = contextKey ? contextKey : apiName;
  fetcher(apiName, inputData)
    .then(async (response: Response) => {
      if (response.ok) {
        const resultJson = await response.json();
        const waResponse = <WAResponse>resultJson;

        setter(getLoadedAction(key, waResponse.result));
      } else {
        const errorMessage: string = await response.text();
        setter(getErrorAction(key, errorMessage));
      }
    })
    .catch((err: Error) => {
      setter(getErrorAction(key, err.message));
    });
};

export { fetchData };

import { EnumTooltipPosition, Tooltip } from 'react-magma-dom';
import React from 'react';
import { ListQuestions } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/ListQuestions';

interface IProps {
  questionNames: string[];
}

export const ToolTip = ({ questionNames }: IProps): JSX.Element => {
  return (
    <>
      <div style={{ width: 'fit-content' }}>
        {questionNames.length > 1 && (
          <Tooltip
            testId="questions_tool_tip_id"
            position={EnumTooltipPosition.right}
            content={ListQuestions(questionNames)}
            style={{ width: 'fit-content' }}
          >
            <div
              role={'button'}
              tabIndex={0}
              style={{ textDecorationColor: 'blue', color: 'blue', textDecorationLine: 'underline', cursor: 'pointer' }}
            >
              {questionNames.length} Questions
            </div>
          </Tooltip>
        )}
        {questionNames.length === 1 && <div>{questionNames[0]}</div>}
      </div>
    </>
  );
};

import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import FacultyOmniBar from '../../components/faculty/facultyHeader/FacultyOmniBar';
import { StudentFooter as FacultyFooter } from 'wa-ui-components';
import {
  AssignmentContextProvider,
  initialState,
  reducer,
} from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { Edit as AssignmentEditPage } from './Edit';
import MasqueradeHeader from '../../components/masqueradeHeader/MasqueradeHeader';
import { AssignmentEditorWorkflow } from 'context/AssignmentContextProvider/types';
import { useFetchedDataContext } from 'context/data/FetchedData';
import { API_NAME_CONSTANTS } from '../../service/registry';
import { ACTIONS, ActionType, getLoadingAction } from 'context/data/actionCreators';
import { LoaderComponent } from 'wa-ui-components';
import QualtricsInformation from 'sharedComponents/qualtricsInformation/QualtricsInformation';

const FacultyMain = styled.main`
  box-sizing: border-box;
  min-height: calc(100vh - 8.75rem);
`;

export const Root = (): ReactElement => {
  const { getter } = useFetchedDataContext();

  const apiDataState: ActionType | null = getter(API_NAME_CONSTANTS.USER);
  const { data: userData, status: userStatus } = apiDataState
    ? apiDataState
    : getLoadingAction(API_NAME_CONSTANTS.USER);

  if (userStatus === ACTIONS.LOADING) {
    return <LoaderComponent />;
  }

  return (
    <BrowserRouter>
      <QualtricsInformation userSsoGuid={userData.ssoGuid} />
      {userData.masqueraded && (
        <MasqueradeHeader fullName={userData.fullName} userName={userData.userName} schoolName={userData.schoolName} />
      )}
      <FacultyOmniBar userData={userData} userStatus={userStatus} />
      <FacultyMain>
        <div id="mainContent">
          <Switch>
            <Route
              exact
              path="/app/assignment/create"
              render={() => (
                <AssignmentContextProvider
                  initialState={{
                    ...initialState,
                    userId: userData.id,
                    env: userData.userPreferences.WA_UI_ENV,
                    workflow: AssignmentEditorWorkflow.CREATE,
                  }}
                  reducer={reducer}
                >
                  <AssignmentEditPage />
                </AssignmentContextProvider>
              )}
            />
            <Route
              path="/app/assignment/:assignmentId/edit"
              render={() => (
                <AssignmentContextProvider
                  initialState={{
                    ...initialState,
                    userId: userData.id,
                    env: userData.userPreferences.WA_UI_ENV,
                  }}
                  reducer={reducer}
                >
                  <AssignmentEditPage />
                </AssignmentContextProvider>
              )}
            />
            <Route
              path="/app/assignment/:assignmentId/duplicate"
              render={() => (
                <AssignmentContextProvider
                  initialState={{
                    ...initialState,
                    userId: userData.id,
                    env: userData.userPreferences.WA_UI_ENV,
                    workflow: AssignmentEditorWorkflow.DUPLICATE,
                  }}
                  reducer={reducer}
                >
                  <AssignmentEditPage />
                </AssignmentContextProvider>
              )}
            />
          </Switch>
        </div>
      </FacultyMain>

      <FacultyFooter disableLink="0" />
    </BrowserRouter>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import {
  Alert,
  AlertVariant,
  ButtonColor,
  ButtonIconPosition,
  Flex,
  FlexAlignItems,
  FlexBehavior,
  FlexJustify,
  FlexProps,
  IconButton,
  LoadingIndicator,
  Toast,
} from 'react-magma-dom';
import { AddIcon, ExpandMoreIcon, InfoIcon } from 'react-magma-icons';

import { AssignmentEditorAccordion } from 'facultyComponents/assignmentEditor/helpers/Accordion/AssignmentEditorAccordion';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { QuestionBrowserWindow } from './QuestionBrowser/QuestionBrowserWindow';
import { QuestionListItem } from './QuestionListItem/QuestionListItem';

import {
  setErrors,
  setLearningToolsAndFeedbackSettings,
  setQuestionRandomizationSettings,
  setQuestionsData,
  setSubmissionAndWorkSettings,
} from 'context/AssignmentContextProvider/actions';
import { getQuestionsDataUpdates } from '../apiHelpers';

import {
  compareQuestionElementQuestionBrowserElement,
  containsMasterySet,
  getQuestionBrowserStringRepresentation,
  getQuestionElementStringRepresentation,
  setQuestionsDefaults,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';
import {
  IMasterySet,
  IQuestionBrowserElement,
  IQuestionBrowserMasterySet,
  IQuestionElement,
  IQuestionPool,
  QuestionElementType,
  Randomization,
  Submission,
} from 'context/AssignmentContextProvider/types';
import {
  TotalPoints,
  TotalTimeToAnswer,
} from 'facultyComponents/assignmentEditor/assignmentEditorQuestionList/QuestionElements';

import { getFieldValue } from 'utils/utils';
import { QuestionPointsContextProvider } from 'context/QuestionPointsContextProvider/QuestionPointsContextProvider';
import { useQuestionBrowserContext } from 'context/QuestionBrowserContextProvider/QuestionBrowserContextProvider';
import { getTemplateFeedbackOnRandomizationAfterEverySubmission } from 'facultyComponents/assignmentEditor/helpers/FeedbackTabItem/feedbackItemHelper';
import { ButtonHelperTextWrapper } from 'sharedComponents/ButtonHelperTextWrapper/ButtonHelperTextWrapper';
import {
  makeConditionalPointsFormula,
  PresetDefaults,
} from 'facultyComponents/assignmentEditor/helpers/PointsAdjustment/ConditionalPointsSettings';

const addQuestionsBtnNewPadding = '1.25rem 1.25rem 0rem 0.625rem';

const StyledButtonGroup = styled.div<{ hasQuestions?: boolean; isModificationLimited?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${({ theme, hasQuestions, isModificationLimited }) =>
    hasQuestions && !isModificationLimited ? theme.colors.darkBlue : theme.colors.white};
  padding: ${({ hasQuestions, isModificationLimited }) =>
    hasQuestions || isModificationLimited ? '' : addQuestionsBtnNewPadding};
`;

const addQuestionButtonStyle = {
  margin: '0.25rem 0.75rem',
};

const QuestionElementManager = styled.div`
  display: contents;
`;
const StyledFlex = styled(Flex)`
  display: flex;
  justify-content: right;
  z-index: 1;
`;

const QuestionListHead = styled(Flex)`
  background: ${({ theme }) => theme.colors.lightGrey};
  border-bottom: 0.125rem solid ${({ theme }) => theme.colors.greyAccent};
  margin: 0;
  padding: 0rem 1rem 0.375rem 1rem;
  width: 100%;
`;
const ListHeader = styled.div`
  font-weight: bold;
`;

const ParentheticalHeaderText = styled.span`
  display: block;
  font-weight: normal;
`;

const InlineParentheticalHeaderText = styled.span`
  display: inline-block;
  font-weight: normal;
`;

const QuestionList = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Loader = styled(LoadingIndicator)`
  margin-top: 4rem;
`;

const EmptyQuestionAlert = styled.div`
  width: fit-content;
  block-size: fit-content;
  margin-top: 0.625rem;
`;

const HideExpandAll = styled.div`
  display: none;
`;

export enum QuestionListColumns {
  question = 'question',
  time = 'time',
  points = 'points',
  controls = 'controls',
}

// React-magma's Flex component supports
// 5 screen size breakpoints (xs, sm, md, lg, xl) and
// 12 units for the number of columns an item should take up (1-12).
// Here we define the values we'll use for these properties
// to ensure proper alignment in this component and its children.
export const FlexItemProps: { [key in QuestionListColumns]: FlexProps } = {
  [QuestionListColumns.question]: { behavior: FlexBehavior.item, xs: 5 },
  [QuestionListColumns.time]: { behavior: FlexBehavior.item, xs: 2 },
  [QuestionListColumns.points]: { behavior: FlexBehavior.item, xs: 2 },
  [QuestionListColumns.controls]: { behavior: FlexBehavior.item, xs: 3, justify: FlexJustify.flexEnd },
};

export const QuestionColumn = (props: Partial<FlexProps>): JSX.Element => (
  <Flex {...props} {...FlexItemProps[QuestionListColumns.question]}>
    {props.children}
  </Flex>
);

export const TimeColumn = (props: Partial<FlexProps>): JSX.Element => (
  <Flex {...props} {...FlexItemProps[QuestionListColumns.time]}>
    {props.children}
  </Flex>
);

export const PointsColumn = (props: Partial<FlexProps>): JSX.Element => (
  <Flex {...props} {...FlexItemProps[QuestionListColumns.points]}>
    {props.children}
  </Flex>
);

export const ControlsColumn = (props: Partial<FlexProps>): JSX.Element => (
  <StyledFlex {...props} {...FlexItemProps[QuestionListColumns.controls]}>
    {props.children}
  </StyledFlex>
);

export const AssignmentEditorQuestionList = (): JSX.Element => {
  const {
    state: {
      userId,
      questionsData,
      assignmentEditorContext: {
        assignmentInfo: { id, isModificationLimited },
      },
      errors,
      isQuestionsLoading,
      isSettingsLoading,
      assignmentSettings: {
        currentAssignmentTemplate: {
          settings: {
            submissionAndWorkSettings,
            questionRandomizationSettings,
            templateFeedback,
            conditionalPointsFormula,
          },
        },
      },
    },
    dispatch,
  } = useAssignmentContext();
  const { showQuestionBrowser, questionBrowserWindowRef, updateShowQuestionBrowser, openOrFocusQuestionBrowser } =
    useQuestionBrowserContext();
  const { t } = useTranslation();
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [showToast, updateShowToast] = useState(false);
  const [messageForAlert, setMessageForAlert] = useState({ message: '', variant: '' });
  const [showRandomizationToast, updateShowRandomizationToast] = useState(false);
  const [randomizationMessageForAlert, setRandomizationMessageForAlert] = useState({ message: '', variant: '' });

  let questionCounter = 0;
  let poolCounter = 0;
  let masterySetCounter = 0;
  const questionElementData: number[][] = [];
  const hasQuestions = questionsData.length > 0;

  const [totalPointsUpdatedFlag, setTotalPointsUpdatedFlag] = useState(false);

  const flagTotalPointsUpdated = (): void => {
    setTotalPointsUpdatedFlag(!totalPointsUpdatedFlag);
  };

  const onRemoveQuestion = (index: number) => {
    const currentQuestions = [...questionsData];
    currentQuestions.splice(index, 1);
    dispatch(setQuestionsData(currentQuestions));
  };

  questionsData.forEach(questionElement => {
    questionCounter++;
    switch (questionElement.elementType) {
      case QuestionElementType.QUESTION:
        questionElementData.push([questionCounter]);
        break;
      case QuestionElementType.QUESTION_POOL:
        const lastQuestionInPool = questionCounter + (questionElement as IQuestionPool).includedNumberOfQuestions - 1;
        questionElementData.push([questionCounter, lastQuestionInPool]);
        questionCounter = lastQuestionInPool;
        (questionElement as IQuestionPool).positionAmongSets = ++poolCounter;
        break;
      case QuestionElementType.MASTERY_SET:
        questionElementData.push([questionCounter]);
        (questionElement as IMasterySet).positionAmongSets = ++masterySetCounter;
        break;
      default:
        questionElementData.push([questionCounter]);
    }
  });

  const questionButtonRef = useRef<HTMLButtonElement>(null);
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    !hasQuestions && questionButtonRef.current?.focus();
    dispatch(
      setErrors({
        field: 'questions.empty',
        value: hasQuestions ? undefined : t('ASSIGNMENT_EDITOR.BAR.SAVE_MESSAGE_ALERT.QUESTIONS_REQUIRED'),
      })
    );
  }, [questionsData]);

  useEffect(() => {
    updateShowQuestionBrowser(false);
  }, [questionsData]);

  const applyQuestionBrowserElementProperties = (
    questionElement: IQuestionElement,
    questionBrowserElement: IQuestionBrowserElement
  ): IQuestionElement => {
    const updatedQuestion = { ...questionElement };
    if (updatedQuestion.elementType === QuestionElementType.MASTERY_SET) {
      (updatedQuestion as IMasterySet).subsetSize = (questionBrowserElement as IQuestionBrowserMasterySet).subsetSize;
      (updatedQuestion as IMasterySet).masteryThreshold = (
        questionBrowserElement as IQuestionBrowserMasterySet
      ).masteryThreshold;
    }
    return updatedQuestion;
  };

  const mapToExistingQuestions = (
    contents: IQuestionBrowserElement[]
  ): [IQuestionElement[], Map<number, IQuestionBrowserElement>] => {
    const newQuestionData = new Array<IQuestionElement>(contents.length);
    const questionDataNeededMap = new Map<number, IQuestionBrowserElement>();
    contents.forEach((value, index) => {
      questionDataNeededMap.set(index, value);
    });
    // Search the current Question List for matches
    questionsData.forEach(question => {
      const questionIndex = contents.findIndex(questionBrowserElement => {
        return compareQuestionElementQuestionBrowserElement(question, questionBrowserElement);
      });
      if (questionIndex >= 0) {
        // If a match is found, update its properties to match those from the question browser
        // then store it, and delete corresponding entry in neededMap so we don't try retrieve it in request
        const updatedQuestion = applyQuestionBrowserElementProperties(question, contents[questionIndex]);
        newQuestionData[questionIndex] = updatedQuestion;
        questionDataNeededMap.delete(questionIndex);
      }
    });

    return [newQuestionData, questionDataNeededMap];
  };

  const fetchQuestionData = async (
    questionDataNeededMap: Map<number, IQuestionBrowserElement>
  ): Promise<[IQuestionElement[], Map<string, number>]> => {
    const questionDataToFetchMap = new Map<string, number>();
    questionDataNeededMap.forEach((value, key) => {
      questionDataToFetchMap.set(getQuestionBrowserStringRepresentation(value), key);
    });
    const questionDataToFetchString = Array.from(questionDataToFetchMap.keys()).join(',');
    const returnedQuestionData = (await getQuestionsDataUpdates(userId, questionDataToFetchString)).data.result;
    return [returnedQuestionData, questionDataToFetchMap];
  };

  const insertNewQuestionData = (
    returnedQuestionData: IQuestionElement[],
    newQuestionData: IQuestionElement[],
    questionDataToFetchMap: Map<string, number>
  ): void => {
    // This method assumes the data retrieved matches the data requested - I.E. all questions were retrieved in the exact order requested
    // We should consider adding some error handling here
    returnedQuestionData.forEach(questionElement => {
      const newQuestionDataIndex = questionDataToFetchMap.get(getQuestionElementStringRepresentation(questionElement))!;
      newQuestionData[newQuestionDataIndex] = questionElement;
    });
    const hasMastery = containsMasterySet(returnedQuestionData);

    if (hasMastery && submissionAndWorkSettings.submission !== Submission.QUESTION) {
      setMessageForAlert({
        message: t('ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ANSWER_SETTING.TOAST_FOR_MASTERY'),
        variant: AlertVariant.success,
      });
      updateShowToast(true);

      dispatch(
        setSubmissionAndWorkSettings({
          ...submissionAndWorkSettings,
          submission: Submission.QUESTION,
        })
      );
    }

    if (
      hasMastery &&
      (questionRandomizationSettings.randomization !== Randomization.AFTER_EVRY_SUBM ||
        questionRandomizationSettings.randomizationSubmissionsCount != 1)
    ) {
      dispatch(
        setLearningToolsAndFeedbackSettings(getTemplateFeedbackOnRandomizationAfterEverySubmission(templateFeedback))
      );
      dispatch(
        setQuestionRandomizationSettings({
          ...questionRandomizationSettings,
          randomization: Randomization.AFTER_EVRY_SUBM,
          randomizationSubmissionsCount: 1,
        })
      );
      setRandomizationMessageForAlert({
        message: t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.RANDOMIZATION_MASTERY_TOAST'),
        variant: AlertVariant.success,
      });
      updateShowRandomizationToast(true);
    }
  };

  const updateQuestionsContent = async (contents: IQuestionBrowserElement[]): Promise<void> => {
    setLoadingIndicator(true);
    const [newQuestionData, questionDataNeededMap] = mapToExistingQuestions(contents);
    if (questionDataNeededMap.size) {
      const [returnedQuestionData, questionDataToFetchMap] = await fetchQuestionData(questionDataNeededMap);
      insertNewQuestionData(
        setQuestionsDefaults(
          returnedQuestionData,
          conditionalPointsFormula === makeConditionalPointsFormula(PresetDefaults.SET_AT_QUESTION_LEVEL)
            ? ''
            : conditionalPointsFormula
        ),
        newQuestionData,
        questionDataToFetchMap
      );
    }
    setLoadingIndicator(false);
    dispatch(setQuestionsData(newQuestionData));
  };

  return (
    <QuestionElementManager>
      {showQuestionBrowser ? (
        <QuestionBrowserWindow
          ref={questionBrowserWindowRef}
          setShowQuestionBrowserFalse={() => updateShowQuestionBrowser(false)}
          updateQuestionContent={updateQuestionsContent}
          contents={questionsData}
          assignmentId={id}
          userPass={''}
        />
      ) : null}
      <AssignmentEditorAccordion
        padContent={false}
        testId="questions"
        btnText={t('QUESTIONS')}
        accordionType={'questionsOpen'}
      >
        {isQuestionsLoading || isSettingsLoading || loadingIndicator ? (
          <Loader />
        ) : (
          <>
            <StyledButtonGroup hasQuestions={hasQuestions} isModificationLimited={isModificationLimited}>
              <ButtonHelperTextWrapper
                icon={<InfoIcon />}
                helperMessage={
                  isModificationLimited ? t('ASSIGNMENT_EDITOR.ADD_QUESTIONS.DISABLED_HELPER_MESSAGE') : ''
                }
                containerStyle={addQuestionButtonStyle}
                testId="addQuestionsButtonContainer"
              >
                <IconButton
                  icon={<AddIcon />}
                  color={hasQuestions ? ButtonColor.secondary : ButtonColor.primary}
                  testId="addQuestionsButton"
                  onClick={() => {
                    openOrFocusQuestionBrowser();
                  }}
                  aria-label={t('ASSIGNMENT_EDITOR.ADD_QUESTIONS.ARIA-LABEL') || 'translate me'}
                  disabled={isModificationLimited}
                  ref={questionButtonRef}
                >
                  {t('ASSIGNMENT_EDITOR.ADD_QUESTIONS.BUTTON') || 'translate me!'}
                </IconButton>
              </ButtonHelperTextWrapper>
              {hasQuestions ? (
                <HideExpandAll>
                  <IconButton
                    icon={<ExpandMoreIcon />}
                    color={ButtonColor.secondary}
                    iconPosition={ButtonIconPosition.right}
                    testId="expandAllButton"
                  >
                    {t('EXPAND_ALL') || 'translate me!'}
                  </IconButton>
                </HideExpandAll>
              ) : null}
            </StyledButtonGroup>
            {hasQuestions ? (
              <>
                <QuestionListHead behavior={FlexBehavior.container} alignItems={FlexAlignItems.center} spacing={1}>
                  <QuestionColumn testId={'columnHeaderQuestions'}>
                    <ListHeader>
                      {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.COLUMN.QUESTIONS', { count: 0 }) + ' '}
                      <InlineParentheticalHeaderText>({questionCounter})</InlineParentheticalHeaderText>
                    </ListHeader>
                  </QuestionColumn>
                  <TimeColumn testId={'columnHeaderTime'}>
                    <ListHeader>
                      {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.COLUMN.TIME') + ' '}
                      <ParentheticalHeaderText>
                        <TotalTimeToAnswer questionsData={questionsData} />
                      </ParentheticalHeaderText>
                    </ListHeader>
                  </TimeColumn>
                  <PointsColumn testId={'columnHeaderPoints'}>
                    <ListHeader>
                      <TotalPoints flagTotalPointsUpdated={flagTotalPointsUpdated} questionsData={questionsData} />
                    </ListHeader>
                  </PointsColumn>
                </QuestionListHead>
                {loadingIndicator ? (
                  <Loader />
                ) : (
                  <QuestionList>
                    {questionsData.map((questionElement, index) => (
                      <QuestionPointsContextProvider
                        questionIndex={index}
                        questionElement={questionElement}
                        totalPointsUpdatedFlag={totalPointsUpdatedFlag}
                        key={`question_element_${index}_points`}
                      >
                        <QuestionListItem
                          index={index}
                          key={`question_element_${index}`}
                          questionElement={questionElement}
                          questionNumber={questionElementData[index]}
                          isLast={index + 1 === questionsData.length}
                          onClickRemove={() => onRemoveQuestion(index)}
                        />
                      </QuestionPointsContextProvider>
                    ))}
                  </QuestionList>
                )}
              </>
            ) : null}
            {getFieldValue(errors, 'questions.empty') ? (
              <EmptyQuestionAlert>
                <Alert variant={AlertVariant.danger} role="alert" testId={'emptyQuestionAlert'}>
                  <Trans t={t}>ASSIGNMENT_EDITOR.BAR.SAVE_MESSAGE_ALERT.QUESTIONS_REQUIRED</Trans>
                </Alert>
              </EmptyQuestionAlert>
            ) : null}
            {showToast ? (
              <Toast
                role="alert"
                onDismiss={() => updateShowToast(false)}
                variant={AlertVariant.info}
                testId={'warningAlert'}
              >
                {messageForAlert.message}
              </Toast>
            ) : null}
            {showRandomizationToast ? (
              <Toast
                role="alert"
                onDismiss={() => updateShowRandomizationToast(false)}
                variant={AlertVariant.info}
                testId={'masteryRandomizationAlertToast'}
              >
                {randomizationMessageForAlert.message}
              </Toast>
            ) : null}
          </>
        )}
      </AssignmentEditorAccordion>
    </QuestionElementManager>
  );
};

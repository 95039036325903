import React, { Context, createContext, ReactElement } from 'react';
import { PropsType, FetchContextType } from './../typings';

// Step 1: Create Context
const FetchContext: Context<FetchContextType> = createContext({} as FetchContextType);

const FetchProvider = (props: PropsType): ReactElement => {
  const { children, fetcher } = props;

  // Step 2: Provide values to Context, which can be accessed by its children
  const context: FetchContextType = {
    fetcher,
  };

  // Step 3: Return the Subtree where the created context is valid
  return <FetchContext.Provider value={context}>{children}</FetchContext.Provider>;
};

export { FetchProvider, FetchContext };

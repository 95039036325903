import React from 'react';

import { useTranslation } from 'react-i18next';

import { Tab } from 'react-magma-dom';
import { HelpCenterIcon } from 'react-magma-icons';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import {
  IQuestionElement,
  IQuestionFeedbackSettings,
  Randomization,
  Submission,
} from 'context/AssignmentContextProvider/types';
import { setQuestionElementFeedbackSettings } from 'context/AssignmentContextProvider/actions';
import { QuestionSubmissionOverride } from 'facultyComponents/assignmentEditor/assignmentEditorQuestionList/QuestionElements/HelpHints/QuestionSubmissionOverride';
import { CustomHint } from 'facultyComponents/assignmentEditor/assignmentEditorQuestionList/QuestionElements/HelpHints/CustomHint';
import {
  isAssignmentQuestion,
  isMasterySet,
  isQuestionPool,
  questionSubmissionsAllowed,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { SUBMISSION_COUNT } from 'facultyComponents/assignmentEditor/helpers/commonHelpers';

interface IHelpHintsTabProps {
  id: string;
  testId: string;
}

interface IHelpHintsPanelProps {
  index: number;
  questionElement: IQuestionElement;
}

export const HelpHintsTab = (props: IHelpHintsTabProps): JSX.Element => {
  const { id, testId } = props;
  const { t } = useTranslation();

  return (
    <Tab icon={<HelpCenterIcon />} id={id} testId={testId}>
      {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.HELP_HINTS')}
    </Tab>
  );
};

export const HelpHintsPanel = (props: IHelpHintsPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { index, questionElement } = props;
  const {
    state: {
      assignmentSettings: {
        currentAssignmentTemplate: {
          settings: {
            questionRandomizationSettings: { randomization },
            templateFeedback: { showHints, showPracticeAnotherVersion },
            submissionAndWorkSettings: { allowedSubmissionsCount, submission },
          },
        },
      },
    },
    dispatch,
  } = useAssignmentContext();

  //get help and pav from settings
  const isHelpAlways = showHints.showBeforeDueDate && showHints.specifiedNumberOfSubmissions <= 1;
  const isPavAlways =
    showPracticeAnotherVersion.showBeforeDueDate && showPracticeAnotherVersion.specifiedNumberOfSubmissions <= 1;

  const isPAVSupported = isAssignmentQuestion(questionElement) || isQuestionPool(questionElement);

  const isToggleChecked = (questionElement: IQuestionElement, settings: keyof IQuestionFeedbackSettings): boolean => {
    if (isAssignmentQuestion(questionElement) || isMasterySet(questionElement)) {
      return questionElement.feedbackSettings[settings] != null && questionElement.feedbackSettings[settings] !== 0;
    }
    if (isQuestionPool(questionElement)) {
      return (
        questionElement.questions[0].feedbackSettings[settings] != null &&
        questionElement.questions[0].feedbackSettings[settings] !== 0
      );
    }
    return false;
  };

  const getSubmissionValue = (
    questionElement: IQuestionElement,
    settings:
      | 'showHintsBeforeDueDateAfterNumberOfSubmissions'
      | 'showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions'
  ): number => {
    const allowedMin = 2;
    if (isAssignmentQuestion(questionElement) || isMasterySet(questionElement)) {
      return questionElement.feedbackSettings[settings] || allowedMin;
    }
    if (isQuestionPool(questionElement)) {
      return questionElement.questions[0].feedbackSettings[settings] || allowedMin;
    }
    return allowedMin;
  };

  const filterNullValues = (allowedSubmissions: number | null): allowedSubmissions is number => {
    return allowedSubmissions !== null;
  };

  const isOneSubmissionAllowed = (questionElement: IQuestionElement): boolean => {
    if (isQuestionPool(questionElement)) {
      return (
        (questionElement.gradingSettings.allowedSubmissionsCount !== null &&
          questionElement.gradingSettings.allowedSubmissionsCount <= 1) ||
        (questionElement.gradingSettings.allowedSubmissionsCount === null && allowedSubmissionsCount === 1)
      );
    }
    if (isAssignmentQuestion(questionElement)) {
      const allowedSubmissionsForQuestion = questionElement.boxes
        .map(box => box.gradingSettings.allowedSubmissionsCount)
        .filter(filterNullValues);
      if (allowedSubmissionsForQuestion.length) {
        return allowedSubmissionsForQuestion.some(el => el === SUBMISSION_COUNT.MIN);
      }
    }
    return false;
  };

  const getMaxSubmissionsCount = (questionElement: IQuestionElement): number => {
    if (submission === Submission.ASSIGNMENT) {
      return allowedSubmissionsCount;
    }
    if (isOneSubmissionAllowed(questionElement)) {
      return 1;
    }
    const maxSubmissionCount = questionSubmissionsAllowed({ questionElement, allowedSubmissionsCount });
    if (maxSubmissionCount > SUBMISSION_COUNT.MAX) {
      return SUBMISSION_COUNT.MAX;
    }
    return maxSubmissionCount < SUBMISSION_COUNT.MIN ? SUBMISSION_COUNT.MIN : maxSubmissionCount;
  };

  return (
    <>
      <QuestionSubmissionOverride
        id={'help'}
        labelName={t('ASSIGNMENT_EDITOR.FEEDBACK.HELP_CONTENT')}
        labelHelper={t('ASSIGNMENT_EDITOR.FEEDBACK.HELP_CONTENT_HELPER')}
        index={index}
        isToggleChecked={isToggleChecked(questionElement, 'showHintsBeforeDueDateAfterNumberOfSubmissions')}
        isAESettingsSetToAlways={isHelpAlways}
        submissionsValue={getSubmissionValue(questionElement, 'showHintsBeforeDueDateAfterNumberOfSubmissions')}
        allowedSubmissionsCount={getMaxSubmissionsCount(questionElement)}
        dispatchFunction={(checkedToggle: boolean, submissions: number) => {
          dispatch(
            setQuestionElementFeedbackSettings({
              elementIndex: index,
              feedbackSettings: { showHintsBeforeDueDateAfterNumberOfSubmissions: checkedToggle ? submissions : null },
            })
          );
        }}
      />
      {isPAVSupported ? (
        <QuestionSubmissionOverride
          id={'pav'}
          labelName={t('ASSIGNMENT_EDITOR.FEEDBACK.PAV')}
          labelHelper={t('ASSIGNMENT_EDITOR.FEEDBACK.PAV_HELPER')}
          index={index}
          isToggleChecked={isToggleChecked(
            questionElement,
            'showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions'
          )}
          isAESettingsSetToAlways={isPavAlways || randomization === Randomization.AFTER_EVRY_SUBM}
          submissionsValue={getSubmissionValue(
            questionElement,
            'showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions'
          )}
          allowedSubmissionsCount={getMaxSubmissionsCount(questionElement)}
          dispatchFunction={(checkedToggle: boolean, submissions: number) => {
            dispatch(
              setQuestionElementFeedbackSettings({
                elementIndex: index,
                feedbackSettings: {
                  showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions: checkedToggle ? submissions : null,
                },
              })
            );
          }}
        />
      ) : null}
      {isAssignmentQuestion(questionElement) ? (
        <CustomHint index={index} feedbackSettings={questionElement.feedbackSettings} />
      ) : null}
    </>
  );
};

import React, { useState } from 'react';
import { AlertVariant, Button, ButtonColor, ButtonVariant } from 'react-magma-dom';
import { syncCxpItem } from 'facultyComponents/cxpQuestionManager/apiHelpers';
import { ICxpRequest, IFlattenedCxpResultQuestion } from 'context/CxpQuestionContextProvider/types';
import { mapToCxpItem } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/cxpQuestionUtil';

interface IProps {
  getQuestions: () => void;
  questions: IFlattenedCxpResultQuestion[];
  setBlockFlag: React.Dispatch<React.SetStateAction<boolean>>;
  errorHandling: (message: string) => void;
  setMessageToast: React.Dispatch<
    React.SetStateAction<{
      messageToast: string;
      typeToast: AlertVariant;
      dismissToast: boolean;
      showToast: boolean;
      disableAutoDismiss: boolean;
    }>
  >;
}

const CxpSynchronizer = ({
  questions,
  setBlockFlag,
  setMessageToast,
  getQuestions,
  errorHandling,
}: IProps): JSX.Element => {
  const [loader, setLoader] = useState<boolean>(false);

  function toastShower(message: string, isDismissible: boolean, type: AlertVariant, disableAutoDismiss: boolean) {
    setLoader(false);
    setBlockFlag(false);
    setMessageToast({
      messageToast: message,
      typeToast: type,
      dismissToast: isDismissible,
      showToast: true,
      disableAutoDismiss: disableAutoDismiss,
    });
  }

  async function handleSync() {
    try {
      setBlockFlag(true);
      setLoader(true);
      const mappedCxpItems: ICxpRequest[] = questions.map(item => mapToCxpItem(item));
      const response = await syncCxpItem(mappedCxpItems);
      getQuestions();

      if (!response.data.result.successful) {
        response.data.result.cxpItemProperties.fieldCount === 0
          ? toastShower(
              `CXP Metadata update failed for ${response.data.result.cxpItemProperties.productCode} with id ${response.data.result.cxpItemProperties.cxpId}. The field count for this CXP item is zero.`,
              false,
              AlertVariant.danger,
              true
            )
          : toastShower(
              `CXP Metadata update failed for ${response.data.result.cxpItemProperties.productCode} with id ${response.data.result.cxpItemProperties.cxpId}.`,
              false,
              AlertVariant.danger,
              true
            );
      } else {
        toastShower('CXP Metadata successfully updated.', false, AlertVariant.success, false);
      }
    } catch (error) {
      errorHandling(
        `Synchronization request failed, try again. If this persists create a support ticket and escalate to engineering.`
      );
    }
  }

  return (
    <div style={{ marginLeft: '1em' }}>
      <Button
        variant={ButtonVariant.solid}
        color={ButtonColor.success}
        onClick={handleSync}
        testId="syncCxpQuestionsButton"
        isLoading={loader}
      >
        Sync CXP metadata
      </Button>
    </div>
  );
};

export default CxpSynchronizer;

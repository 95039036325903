// The following dependency is subject to the attached license
import { read, utils, writeFile } from 'xlsx';
// Copyright (C) 2012-present   SheetJS LLC

// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at

//     http://www.apache.org/licenses/LICENSE-2.0

// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

const readXlsx = (buffer: ArrayBuffer) => {
  return read(buffer);
};

const createWorkbook = () => {
  return utils.book_new();
};

const convertJsonToWorksheet = (json: any) => {
  return utils.json_to_sheet(json);
};

const convertWorksheetToJson = (worksheet: any) => {
  return utils.sheet_to_json(worksheet);
};

const addSheetToWorkbook = (workbook: any, worksheet: any, sheetName: string) => {
  return utils.book_append_sheet(workbook, worksheet, sheetName);
};

const createAndDownloadXlsx = (workbook: any, filename: string) => {
  return writeFile(workbook, filename);
};

const convertFileToJson = async (newFile: File) => {
  const buffer = await newFile.arrayBuffer();
  const workbook = readXlsx(buffer);
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  return convertWorksheetToJson(sheet);
};

export {
  readXlsx,
  createWorkbook,
  convertJsonToWorksheet,
  convertWorksheetToJson,
  addSheetToWorkbook,
  createAndDownloadXlsx,
  convertFileToJson,
};

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { validatePointsInput } from '../../commonHelpers';
import { setQuestionElementPointsAdjustment } from 'context/AssignmentContextProvider/actions';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { InputAutocorrect, StandardErrorFormat } from 'sharedComponents/InputAutocorrect/InputAutocorrect';

import { IPointsAdjustments } from '../PointsAdjustment';
import { IConditionalPointSettings, makeConditionalPointsFormula, PresetDefaults } from '../ConditionalPointsSettings';
import {
  InputsWrapper,
  InputWithLeftLabel,
  InputWithPercent,
  MagmaInputContainerStyle,
  MagmaInputMessageStyle,
} from '../InputContainers';

const validateExtraCredit = (value: string): StandardErrorFormat => validatePointsInput(value, 0, false);

export const ExtraCredit = (props: IPointsAdjustments & IConditionalPointSettings): JSX.Element => {
  const { questionElementIndex, amount } = props;
  const { dispatch } = useAssignmentContext();

  const { t } = useTranslation();

  const [extraCreditNum, setExtraCreditNum] = useState<number>(
    amount || PresetDefaults.ASSIGN_EXTRA_CREDIT.amount || 1
  );

  useEffect(() => {
    if (amount) {
      setExtraCreditNum(amount);
    }
  }, [amount]);

  const updateDependencies = (point: number) => {
    if (questionElementIndex !== undefined) {
      const settings = { ...PresetDefaults.ASSIGN_EXTRA_CREDIT };
      settings.amount = point;
      dispatch(
        setQuestionElementPointsAdjustment({
          elementIndex: questionElementIndex,
          conditionalPointsFormula: makeConditionalPointsFormula(settings),
        })
      );
    }
    setExtraCreditNum(point);
  };

  return (
    <InputsWrapper>
      <InputWithLeftLabel data-testid={`extraCredit${questionElementIndex}`}>
        <label data-testid="extraCreditNumberInputLabel" htmlFor={`extraCreditNumber${questionElementIndex}`}>
          {t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.CORRECT_ANSWER')}
        </label>
        <InputWithPercent>
          <InputAutocorrect
            id={`extraCreditNumber${questionElementIndex}`}
            testId={`extraCreditNumber${questionElementIndex}`}
            value={extraCreditNum.toString()}
            validator={validateExtraCredit}
            validationError={{ error: false, message: '' }}
            onValidChange={(value: string) => updateDependencies(parseFloat(value))}
            containerStyle={MagmaInputContainerStyle}
            messageStyle={MagmaInputMessageStyle}
          />
        </InputWithPercent>
      </InputWithLeftLabel>
    </InputsWrapper>
  );
};

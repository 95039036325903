import React from 'react';
import { Tab } from 'react-magma-dom';
import { useTranslation } from 'react-i18next';
import { SettingsIcon } from 'react-magma-icons';

interface IMasterySetSettingTabProps {
  id: string;
  testId: string;
}
export const MasterySetSettingsTab = (props: IMasterySetSettingTabProps): JSX.Element => {
  const { id, testId } = props;
  const { t } = useTranslation();

  return (
    <Tab id={id} icon={<SettingsIcon />} testId={testId}>
      {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.MASTERY_SET_SETTINGS')}
    </Tab>
  );
};

import React from 'react';
import { Button, Modal, ButtonVariant, ButtonColor, LoadingIndicator } from 'react-magma-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { trashAssignment } from '../apiHelpers';
const ModalStyle = styled(Modal)`
  max-width: 22.5rem !important;
`;
const ScheduledDescription = styled.p`
  margin-top: 0;
  margin-bottom: 2em;
`;
interface IProps {
  show?: boolean;
  close: () => void;
  assignmentId: number;
  userId: number | null;
  isScheduled: boolean;
  isLoading?: boolean;
}
export const TrashModal: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { show, close, isLoading, isScheduled, ...rest } = props;
  return (
    <>
      <ModalStyle
        header={t(isScheduled ? 'ASSIGNMENT_EDITOR.BAR.SCHEDULED_HEADER' : 'ASSIGNMENT_EDITOR.BAR.UNSCHEDULED_HEADER')}
        onClose={close}
        isOpen={show}
      >
        {isLoading ? (
          <LoadingIndicator testId="trash_modal_loader" />
        ) : (
          <TrashModalContent isScheduled={isScheduled} close={close} {...rest} />
        )}
      </ModalStyle>
    </>
  );
};

const TrashModalContent = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { close, assignmentId, userId, isScheduled } = props;
  const handleTrashAssignment = async () => {
    await trashAssignment(assignmentId, userId);
    window.location.href = window.location.origin + '/v4cgi/faculty.pl?action=assignments/my';
  };
  return (
    <>
      {isScheduled ? (
        <ScheduledDescription>{t('ASSIGNMENT_EDITOR.BAR.SCHEDULED_DESCRIPTION')}</ScheduledDescription>
      ) : (
        ''
      )}
      <Button isFullWidth variant={ButtonVariant.solid} testId="trashButton" onClick={handleTrashAssignment}>
        {t('ASSIGNMENT_EDITOR.BAR.TRASH')}
      </Button>
      <Button
        isFullWidth
        color={ButtonColor.secondary}
        variant={ButtonVariant.solid}
        onClick={close}
        testId="cancelButton"
      >
        {t('ASSIGNMENT_EDITOR.BAR.CANCEL')}
      </Button>
    </>
  );
};

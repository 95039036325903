type ActionType = {
  apiName: string;
  status: string;
  data: any;
};

const ACTIONS = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

const getContextMissingAction = (): ActionType => {
  return {
    status: ACTIONS.ERROR,
    apiName: '',
    data: 'context missing',
  };
};

const getFetcherMissingAction = (): ActionType => {
  return {
    status: ACTIONS.ERROR,
    apiName: '',
    data: 'prop:fetcher missing',
  };
};

const getAPINameNotProvidedAction = (): ActionType => {
  return {
    status: ACTIONS.ERROR,
    apiName: '',
    data: 'API name not provided',
  };
};

const getLoadingAction = (apiName = ''): ActionType => {
  if (!apiName) {
    return getAPINameNotProvidedAction();
  }
  return {
    status: ACTIONS.LOADING,
    apiName,
    data: null,
  };
};

const getLoadedAction = (apiName: string, data: any): ActionType => {
  return {
    status: ACTIONS.LOADED,
    apiName,
    data: data,
  };
};

const getErrorAction = (apiName: string, error: string): ActionType => {
  return {
    status: ACTIONS.ERROR,
    apiName,
    data: error,
  };
};

export {
  getContextMissingAction,
  getFetcherMissingAction,
  getLoadingAction,
  getLoadedAction,
  getErrorAction,
  ActionType,
  ACTIONS,
};

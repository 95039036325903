import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './src/assets/locales/en-US.json';
import translationES from './src/assets/locales/es-MX.json';

const resources = {
  'en-US': {
    translation: translationEN,
  },
  'es-MX': {
    translation: translationES,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en-US',
    supportedLngs: ['en-US', 'es-MX'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import { RefObject } from 'react';
import {
  ACTIONTYPES,
  IAssignmentEditorContext,
  IAssignmentTemplate,
  ISetAssignmentEditorContext,
  ISetAssignmentTemplate,
  ISetInitialAssignmentTemplate,
  ISetAssignmentTemplatesData,
  ISetAssignmentCode,
  ISetAssignmentInstructions,
  ISetAssignmentName,
  ISetIsError,
  IDynamicInfoItem,
  ISetAssignmentDynamicInfo,
  ISetAssignmentEditorWorkflow,
  ISetVersionHistory,
  ISetIsLoading,
  ITemplateListItem,
  ISetRefWithError,
  IRemoveRefWithError,
  IQuestionElement,
  IQuestionElementPoints,
  ISetQuestionElementSubmissionSettings,
  ISetQuestionElementShowMyWorkSettings,
  ISetAssignmentAndQuestionsShowMyWorkSettings,
  ISetQuestionsData,
  INumericalToleranceSettings,
  ISetNumericalToleranceSettings,
  ISignificantFiguresSettings,
  ISetSignificantFiguresSettings,
  ISetLearningToolsAndFeedbackSettings,
  ISetSubmissionAndWorkSettings,
  ISetQuestionElementPoints,
  ISetAllQuestionPoints,
  ISubmissionAndWorkSettings,
  ILearningToolsAndFeedbackSettings,
  ISetQuestionRandomizationSettings,
  IQuestionRandomizationSettings,
  ISetDisplayAndSyntaxErrorsSettings,
  IDisplayAndSyntaxErrorsSettings,
  IAssignmentSettings,
  ISetAssignmentSettings,
  ISetIsQuestionRandomizedSettings,
  ISetDisplayAllQuestionsSettings,
  ISetResetInitialState,
  ISetFieldError,
  IAccordionState,
  ISetAccordionState,
  ShowMyWorkMode,
  AssignmentEditorWorkflow,
  IVersionHistory,
  ISetAssignmentTemplatePointsAdjustment,
  ISetQuestionElementFeedbackSettings,
  ISetQuestionFeedbackSettings,
  ISetQuestionElementPointsAdjustment,
  ISetAdjustAllQuestionConditionalPoints,
  ISetQuestionElementNumericalTolerance,
  IQuestionNumericalToleranceSettings,
  QuestionOptionalFeedbackSettings,
  ISetDuplicateAssignmentEditorContext,
  ISetPrivacySetting,
  ISetIsQuestionsLoading,
  ISetIsSettingsLoading,
  ISetMasterySetSettings,
  ISetIsCustomTemplate,
  IConditionalPointsGradingSettings,
} from './types';

const setAssignmentTemplatesData = (payload: { templateList: ITemplateListItem[] }): ISetAssignmentTemplatesData => ({
  type: ACTIONTYPES.SET_ASSIGNMENTS_TEMPLATES_DATA,
  payload,
});
const setAssignmentTemplate = (payload: IAssignmentTemplate): ISetAssignmentTemplate => ({
  type: ACTIONTYPES.SET_ASSIGNMENTS_TEMPLATE,
  payload,
});
const setInitialAssignmentTemplate = (payload: IAssignmentTemplate): ISetInitialAssignmentTemplate => ({
  type: ACTIONTYPES.SET_INITIAL_ASSIGNMENTS_TEMPLATE,
  payload,
});
const setAssignmentEditorContext = (payload: IAssignmentEditorContext): ISetAssignmentEditorContext => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_EDITOR_CONTEXT,
  payload,
});
const setAssignmentName = (payload: string): ISetAssignmentName => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_NAME,
  payload,
});
const setAssignmentInstructions = (payload: string): ISetAssignmentInstructions => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_INSTRUCTIONS,
  payload,
});
const setAssignmentCode = (payload: string): ISetAssignmentCode => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_CODE,
  payload,
});
const setAssignmentDynamicInfo = (payload: {
  groups: IDynamicInfoItem[];
  categories: IDynamicInfoItem[];
}): ISetAssignmentDynamicInfo => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_DYNAMIC_INFO,
  payload,
});
const setIsLoading = (payload: boolean): ISetIsLoading => ({
  type: ACTIONTYPES.SET_IS_LOADING,
  payload,
});
const setIsQuestionsLoading = (payload: boolean): ISetIsQuestionsLoading => ({
  type: ACTIONTYPES.SET_IS_QUESTIONS_LOADING,
  payload,
});
const setIsSettingsLoading = (payload: boolean): ISetIsSettingsLoading => ({
  type: ACTIONTYPES.SET_IS_SETTINGS_LOADING,
  payload,
});
const setResetInitialState = (payload: boolean): ISetResetInitialState => ({
  type: ACTIONTYPES.SET_RESET_INITIAL_STATE,
  payload,
});
const setAssignmentEditorWorkflow = (payload: AssignmentEditorWorkflow): ISetAssignmentEditorWorkflow => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_EDITOR_WORKFLOW,
  payload,
});
const setVersionHistory = (payload: IVersionHistory[]): ISetVersionHistory => ({
  type: ACTIONTYPES.SET_VERSION_HISTORY,
  payload,
});
const setIsError = (payload: boolean): ISetIsError => ({
  type: ACTIONTYPES.SET_IS_ERROR,
  payload,
});
const setIsCustomTemplate = (payload: boolean): ISetIsCustomTemplate => ({
  type: ACTIONTYPES.SET_IS_CUSTOM_TEMPLATE,
  payload,
});
/**
 * @deprecated The method should not be used, to use global error handling check this solution:
 * https://stash.cengage.com/projects/WA/repos/wa-react-app/pull-requests/100/overview
 */
const setRefWithError = (payload: RefObject<HTMLElement>): ISetRefWithError => ({
  type: ACTIONTYPES.SET_REF_WITH_ERROR,
  payload,
});
/**
 * @deprecated The method should not be used, to use global error handling check this solution:
 * https://stash.cengage.com/projects/WA/repos/wa-react-app/pull-requests/100/overview
 */
const removeRefWithError = (payload: RefObject<HTMLElement>): IRemoveRefWithError => ({
  type: ACTIONTYPES.REMOVE_REF_WITH_ERROR,
  payload,
});
const setQuestionsData = (payload: IQuestionElement[]): ISetQuestionsData => ({
  type: ACTIONTYPES.SET_QUESTIONS_DATA,
  payload,
});
const setQuestionElementSubmissionSettings = (payload: {
  boxIndex?: number;
  elementIndex: number;
  allowedSubmissionsCount: number;
}): ISetQuestionElementSubmissionSettings => ({
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_SUBMISSION_SETTINGS,
  payload,
});
const setQuestionElementShowMyWorkSettings = (payload: {
  elementIndex: number;
  showMyWorkMode: ShowMyWorkMode;
  showMyWorkPointValue: number;
}): ISetQuestionElementShowMyWorkSettings => ({
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_SHOW_MY_WORK_SETTINGS,
  payload,
});
const setAssignmentAndQuestionsShowMyWorkSettings = (
  payload: ShowMyWorkMode
): ISetAssignmentAndQuestionsShowMyWorkSettings => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_AND_QUESTIONS_SHOW_MY_WORK_SETTINGS,
  payload,
});

const setQuestionElementPointsAdjustment = (payload: {
  elementIndex: number;
  conditionalPointsFormula: string;
  conditionalPointsFormulaExplanation?: string;
  isAdvanced?: boolean;
}): ISetQuestionElementPointsAdjustment => ({
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_POINTS_ADJUSTMENT,
  payload,
});

const setAssignmentTemplatePointsAdjustment = (payload: {
  conditionalPointsFormula: string;
  existingTemplate?: boolean;
}): ISetAssignmentTemplatePointsAdjustment => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_TEMPLATE_POINTS_ADJUSTMENT,
  payload,
});

const setAdjustAllQuestionConditionalPoints = (
  payload: IConditionalPointsGradingSettings
): ISetAdjustAllQuestionConditionalPoints => ({
  type: ACTIONTYPES.SET_ADJUST_ALL_QUESTION_CONDITIONAL_POINTS,
  payload,
});

const setNumericalToleranceSettings = (payload: INumericalToleranceSettings): ISetNumericalToleranceSettings => ({
  type: ACTIONTYPES.SET_NUMERICAL_TOLERANCE_SETTINGS,
  payload,
});

const setQuestionElementNumericalTolerance = (payload: {
  elementIndex: number;
  numericalToleranceSettings: IQuestionNumericalToleranceSettings;
}): ISetQuestionElementNumericalTolerance => ({
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_NUMERICAL_TOLERANCE,
  payload,
});

const setSignificantFiguresSettings = (payload: ISignificantFiguresSettings): ISetSignificantFiguresSettings => ({
  type: ACTIONTYPES.SET_SIGNIFICANT_FIGURES_SETTINGS,
  payload,
});
const setLearningToolsAndFeedbackSettings = (
  payload: ILearningToolsAndFeedbackSettings
): ISetLearningToolsAndFeedbackSettings => ({
  type: ACTIONTYPES.SET_LEARNING_TOOLS_AND_FEEDBACK_SETTINGS,
  payload,
});
const setQuestionElementPoints = (payload: { elementIndex: number; points: number[] }): ISetQuestionElementPoints => ({
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_POINTS,
  payload,
});
const setAllQuestionPoints = (payload: IQuestionElementPoints[]): ISetAllQuestionPoints => ({
  type: ACTIONTYPES.SET_ALL_QUESTION_POINTS,
  payload,
});
const setSubmissionAndWorkSettings = (payload: ISubmissionAndWorkSettings): ISetSubmissionAndWorkSettings => ({
  type: ACTIONTYPES.SET_SUBMISSION_AND_WORK_SETTINGS,
  payload,
});
const setQuestionRandomizationSettings = (
  payload: IQuestionRandomizationSettings
): ISetQuestionRandomizationSettings => ({
  type: ACTIONTYPES.SET_QUESTION_RANDOMIZATION_SETTINGS,
  payload,
});
const setDisplayAndSyntaxErrorsSettings = (
  payload: IDisplayAndSyntaxErrorsSettings
): ISetDisplayAndSyntaxErrorsSettings => ({
  type: ACTIONTYPES.SET_DISPLAY_AND_SYNTAX_ERRORS_SETTINGS,
  payload,
});
const setAssignmentSettings = (payload: IAssignmentSettings): ISetAssignmentSettings => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_SETTINGS,
  payload,
});
const setIsQuestionRandomizedSettings = (payload: boolean): ISetIsQuestionRandomizedSettings => ({
  type: ACTIONTYPES.SET_IS_QUESTION_RANDOMIZED_SETTINGS,
  payload,
});
const setDisplayAllQuestionsSettings = (payload: boolean): ISetDisplayAllQuestionsSettings => ({
  type: ACTIONTYPES.SET_DISPLAY_All_QUESTIONS_SETTINGS,
  payload,
});
const setErrors = (payload: { field: string; value?: string }): ISetFieldError => ({
  type: ACTIONTYPES.SET_FIELD_ERROR,
  payload,
});
const setAccordionState = (payload: IAccordionState): ISetAccordionState => ({
  type: ACTIONTYPES.SET_ACCORDION_STATE,
  payload,
});
const setQuestionElementFeedbackSettings = (payload: {
  elementIndex: number;
  feedbackSettings: QuestionOptionalFeedbackSettings;
}): ISetQuestionElementFeedbackSettings => ({
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_FEEDBACK_SETTINGS,
  payload,
});
const setQuestionFeedbackSettings = (payload: QuestionOptionalFeedbackSettings): ISetQuestionFeedbackSettings => ({
  type: ACTIONTYPES.SET_QUESTION_FEEDBACK_SETTINGS,
  payload,
});
const setDuplicateAssignmentEditorContext = (
  payload: IAssignmentEditorContext
): ISetDuplicateAssignmentEditorContext => ({
  type: ACTIONTYPES.SET_DUPLICATE_ASSIGNMENT_EDITOR_CONTEXT,
  payload,
});
const setPrivacySetting = (payload: { privacySetting: string; groupId: number }): ISetPrivacySetting => ({
  type: ACTIONTYPES.SET_ASSIGNMENT_PRIVACY_SETTING,
  payload,
});
const setMasterySetSettings = (payload: {
  index: number;
  threshold: number;
  subsetSize: number;
  resetId?: boolean;
}): ISetMasterySetSettings => ({
  type: ACTIONTYPES.SET_MASTERY_SET_SETTINGS,
  payload,
});
export {
  setAssignmentTemplatesData,
  setAssignmentTemplate,
  setInitialAssignmentTemplate,
  setAssignmentEditorContext,
  setAssignmentName,
  setAssignmentInstructions,
  setAssignmentCode,
  setAssignmentEditorWorkflow,
  setVersionHistory,
  setIsLoading,
  setIsSettingsLoading,
  setIsQuestionsLoading,
  setIsError,
  setAssignmentDynamicInfo,
  setRefWithError,
  removeRefWithError,
  setQuestionsData,
  setQuestionElementSubmissionSettings,
  setQuestionElementShowMyWorkSettings,
  setAssignmentAndQuestionsShowMyWorkSettings,
  setNumericalToleranceSettings,
  setSignificantFiguresSettings,
  setLearningToolsAndFeedbackSettings,
  setQuestionElementPoints,
  setAllQuestionPoints,
  setSubmissionAndWorkSettings,
  setQuestionRandomizationSettings,
  setDisplayAndSyntaxErrorsSettings,
  setAssignmentSettings,
  setIsQuestionRandomizedSettings,
  setDisplayAllQuestionsSettings,
  setResetInitialState,
  setErrors,
  setAccordionState,
  setQuestionElementFeedbackSettings,
  setQuestionFeedbackSettings,
  setQuestionElementPointsAdjustment,
  setDuplicateAssignmentEditorContext,
  setPrivacySetting,
  setAdjustAllQuestionConditionalPoints,
  setQuestionElementNumericalTolerance,
  setAssignmentTemplatePointsAdjustment,
  setMasterySetSettings,
  setIsCustomTemplate,
};

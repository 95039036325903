/* istanbul ignore file */
interface IClassInfo {
  sectionId: number;
  courseId: number;
  sectionName: string;
  courseName: string;
  year: number;
  termName: string;
}

interface ILinks {
  href: string;
  name: string;
}

interface IUserData {
  admin: string;
  staff: string;
  firstName: string;
  fullName: string;
  id: number;
  uid: string;
  masqueraded: string;
  userPreferences: {
    WA_UI_ENV: string;
  };
  authorizations: {
    restricted_admin: string;
    course_creation_enabled: boolean;
    cxp_tool: boolean;
    axscode: boolean;
  };
  faculty: boolean;
  activeClassContext: string;
}
const baseUrl = '/v4cgi/faculty.pl';
// Method for the open popup window
const openPopUp = (comp: string, title: string, attr: string): void => {
  comp = comp.includes('/v4cgi/') || comp.includes('/manual/') ? comp : '/v4cgi/' + comp;
  const newWindow = window.open(comp, title, attr) as Window;
  newWindow.focus();
};

// Method for the navigation
//todo: when a course is loaded in wa-react-app
// store course related info in context.

// formData type must be object with a string type in values
// example { action: string; UserPass: string; course: string }
const navigate = (action: string, formData: any): void => {
  let queryParams = `action=${action}`;
  const params = Object.keys(formData);

  for (let i = 0; i < params.length; i++) {
    const param: string | undefined = params[i];

    const value = formData[param];
    queryParams = `${queryParams}&${param}=${value}`;
  }
  window.location.assign(`${baseUrl}?${queryParams}`);
};

//todo: when a course is loaded in wa-react-app
// store course related info in context.
// create end point in wa-ui-service, which returns all course related info (course, coursePeriod, classDate ...)
export const setWAClassDate = (action: string): void => {
  const data: { course?: string; coursePeriod?: string; classDate?: string } = {};

  if (document.getElementById('course')) {
    data.course = (document.getElementById('course') as HTMLInputElement).value;
    if (document.getElementById('coursePeriod')) {
      data.coursePeriod = (document.getElementById('coursePeriod') as HTMLInputElement).value;
      data.classDate = (document.getElementById('classDate') as HTMLInputElement).value;
    }
  }
  navigate(action, data);
};
// Method for the quick search
const onQuickSearch = (searchValue: string, activeDropDownAction: string): void => {
  if (!searchValue) {
    return;
  }
  const queryParams = `quick=1&qID=${searchValue}&qAction=${activeDropDownAction}&mybox=${searchValue}&qFormat=short&format2=qShort`;
  window.location.assign(`${baseUrl}?${queryParams}`);
};

const createLinkForCourse = (courseSection: string): string => {
  if (courseSection == '-') return '';
  let url = `/v4cgi/faculty.pl?action=course/overview`;
  if (courseSection) {
    url = `${url}&course=${courseSection}`;
  }
  return url;
};

// Method for the rendering correct classes names
const getClassesLinks = (classesDataForType: IClassInfo[], linkName: string): { name: string; links: ILinks[] } => {
  return {
    name: linkName,
    links: !classesDataForType
      ? []
      : classesDataForType.map(
          (classInfo: {
            courseId: number;
            sectionId: number;
            sectionName: string;
            courseName: string;
            year: number;
            termName: string;
          }) => {
            const href = createLinkForCourse(`${classInfo.courseId},${classInfo.sectionId}`);
            return {
              href,
              name: `${classInfo.courseName}, ${
                classInfo.sectionName ? 'section ' + classInfo.sectionName + ', ' : ''
              } ${classInfo.termName}, ${classInfo.year}`,
            };
          }
        ),
  };
};

const getDropDownQuestionsLinks = (userData: IUserData): ILinks[] => {
  const dropDownQuestionsLinks: ILinks[] = [
    { name: 'CREATE_QUESTION', href: `${baseUrl}?action=questions/new` },
    { name: 'MY_QUESTIONS', href: `${baseUrl}?action=questions/my` },
  ];
  if (userData.admin || userData.staff) {
    dropDownQuestionsLinks.push({ name: 'RECENTLY_MODIFIED', href: `${baseUrl}?action=questions/recent` });
  }
  dropDownQuestionsLinks.push({ name: 'SEARCH_QUESTIONS', href: `${baseUrl}?action=questions/search` });
  if (userData.userPreferences.WA_UI_ENV === 'editorial') {
    dropDownQuestionsLinks.push({ name: 'PROOFING_PAGE', href: '/v4cgi/proofing/index.tpl' });
  }
  if (userData.admin) {
    dropDownQuestionsLinks.push({ name: 'ASSET_TOOL', href: '/web/Admin/QuestionAssets/serve' });
  }
  if (!userData.masqueraded && userData.authorizations.cxp_tool) {
    dropDownQuestionsLinks.push({ name: 'CXP_TOOL', href: '/app/questions/cxp/editor' });
  }
  return dropDownQuestionsLinks;
};

const getDropDownAssignmentLinks = (userData: IUserData): ILinks[] => {
  const dropDownAssignmentLinks: ILinks[] = [
    { name: 'CREATE_ASSIGNMENT', href: '/app/assignment/create' },
    { name: 'MY_ASSIGNMENTS', href: `${baseUrl}?action=assignments/my` },
  ];
  if (userData.admin || userData.staff) {
    dropDownAssignmentLinks.push({ name: 'RECENTLY_MODIFIED', href: `${baseUrl}?action=assignments/recent` });
  }
  dropDownAssignmentLinks.push(
    { name: 'SETTINGS_TEMPLATES', href: `${baseUrl}?action=/control/assignment/template/manager&rm=index` },
    { name: 'SEARCH_ASSIGNMENTS', href: `${baseUrl}?action=assignments/search` },
    { name: 'FOLDERS', href: `${baseUrl}?action=folders/home` },
    {
      name: 'MY_EDITOR_GROUPS',
      href: "javascript: openPopUp('util/groups.tpl','Groups','width=670,height=510,scrollbars=yes,resizable=yes');",
    }
  );
  return dropDownAssignmentLinks;
};

const getDropDownAdminLinks = (userData: IUserData): ILinks[] => {
  const dropDownAdminLinks: ILinks[] = [{ name: 'PEOPLE', href: `${baseUrl}?action=admin/people/index` }];
  if (!userData.masqueraded) {
    if (userData.admin) {
      dropDownAdminLinks.push(
        { name: 'TEXTBOOKS', href: `${baseUrl}?action=admin/textbooks/index` },
        { name: 'PUBLISHERS', href: `${baseUrl}?action=admin/publishers` },
        { name: 'SCHOOLS', href: `${baseUrl}?action=admin/schools/index` },
        { name: 'IMPORT', href: `${baseUrl}?action=admin/import/questions` },
        { name: 'EXPORT', href: `${baseUrl}?action=admin/export` },
        { name: 'REPORTS', href: `${baseUrl}?action=admin/reports/index` }, //config
        { name: 'MEMOS', href: `${baseUrl}?action=memo/list` },
        { name: 'PRICING', href: `${baseUrl}?action=admin/pricing` }
      );
    }
    dropDownAdminLinks.push({ name: 'ACCESS_CODE_SEARCH', href: '/web/Admin/Registration/search' });
    if (userData.admin && userData.authorizations.axscode) {
      dropDownAdminLinks.push({ name: 'ACCESS_CODE_CREATION', href: '/web/Admin/Registration/serve' });
    }
  }
  return dropDownAdminLinks;
};

export {
  getClassesLinks,
  onQuickSearch,
  navigate,
  openPopUp,
  createLinkForCourse,
  getDropDownQuestionsLinks,
  getDropDownAssignmentLinks,
  getDropDownAdminLinks,
  IUserData,
};

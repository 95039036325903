import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { AlertVariant, Input, InputType, magma, Select, Toast, Toggle } from 'react-magma-dom';
import {
  getLabelElement,
  getLabelText,
  getSelectItems,
  setCorrectTemplateFeedbackOnInputChange,
  setCorrectTemplateFeedbackOnSelectChange,
  setCorrectTemplateFeedbackOnToggleChange,
  setDefaultSelectItem,
  setDefaultToggleCheck,
} from './feedbackItemHelper';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { IScores, ITableItem, Randomization } from 'context/AssignmentContextProvider/types';
import { FEEDBACK_ITEM } from '../types';
import { validateField } from '../commonHelpers';
import {
  removeRefWithError,
  setLearningToolsAndFeedbackSettings,
  setRefWithError,
} from 'context/AssignmentContextProvider/actions';
import { useTranslation } from 'react-i18next';

const FeedbackItemLayout = styled.div`
  display: flex;
`;
const width = '25rem';
const margin = '-0.75rem 0.75rem';
const toggleContainerStyle = { alignItems: 'center' };
const selectContainerStyle = { width, margin };
const inputContainerStyle = { margin, width: '7rem' };
const toggleLabelStyle = { width };
const ToolTipStyle = styled.div`
  margin-left: 0.75rem;
  margin-top: 0.5rem;
`;
const HelpTextForDueDateLimitation = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${magma.colors.neutral03};
`;

const FeedbackItem: React.FC<{ labelTextKey: FEEDBACK_ITEM }> = ({ labelTextKey }) => {
  const { t } = useTranslation();
  const {
    state: {
      assignmentSettings: { currentAssignmentTemplate },
    },
    dispatch,
  } = useAssignmentContext();

  const {
    settings: {
      questionRandomizationSettings: { randomization },
      templateFeedback: {
        showKey,
        showHints,
        showPracticeAnotherVersion,
        showResponses,
        showMark,
        showAssignmentScore,
        showQuestionScore,
        showQuestionPartScore,
        showGraderComments,
      },
      submissionAndWorkSettings: { submission, allowedSubmissionsCount },
    },
  } = currentAssignmentTemplate!;

  const submInputRef = useRef<HTMLInputElement>(null);

  const [checkedToggle, updateCheckedToggle] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<{ label: string; value: string } | null>(null);
  const [submissionInputValue, setSubmissionInputValue] = useState(2);
  const [submissionInputValueError, setSubmissionInputValueError] = useState({
    value: false,
    message: '',
  });
  const [showToast, updateShowToast] = useState(false);
  const [toastMessage, updateToastMessage] = useState('');

  const feedbackItemData: {
    [key: string]: IScores | ITableItem;
  } = {
    [FEEDBACK_ITEM.answerKey]: showKey,
    [FEEDBACK_ITEM.helpContent]: showHints,
    [FEEDBACK_ITEM.practiceAnotherVersion]: showPracticeAnotherVersion,
    [FEEDBACK_ITEM.studentsPreviousResp]: showResponses,
    [FEEDBACK_ITEM.answerFeedback]: showMark,
    [FEEDBACK_ITEM.studentScore]: {
      showAssignmentScore,
      showQuestionScore,
      showQuestionPartScore,
      showGraderComments,
    } as IScores,
  };

  useEffect(() => {
    setDefaultToggleCheck(feedbackItemData[labelTextKey], labelTextKey, updateCheckedToggle);
    setDefaultSelectItem(
      labelTextKey,
      feedbackItemData[labelTextKey],
      submission,
      setSelectedItem,
      setSubmissionInputValue
    );
  }, [currentAssignmentTemplate.settings.templateFeedback]);

  const handleSelectedItemChange = (changes: any) => {
    if (currentAssignmentTemplate) {
      setSelectedItem(changes.selectedItem);
      dispatch(
        setLearningToolsAndFeedbackSettings(
          //DROPDOWN
          setCorrectTemplateFeedbackOnSelectChange(
            labelTextKey,
            changes.selectedItem.value,
            currentAssignmentTemplate,
            submissionInputValue
          )
        )
      );
      if (changes.selectedItem.value !== '2' && submInputRef.current) {
        dispatch(removeRefWithError(submInputRef));
      }
    }
  };

  const handleToggle = () => {
    updateCheckedToggle(!checkedToggle);
    if (currentAssignmentTemplate) {
      dispatch(
        setLearningToolsAndFeedbackSettings(
          //TOGGLE
          setCorrectTemplateFeedbackOnToggleChange(
            checkedToggle,
            labelTextKey,
            currentAssignmentTemplate,
            updateShowToast,
            updateToastMessage
          )
        )
      );
    }
  };
  const handleSubmissionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateField({
      value: e.target.value,
      min: 2,
      max: allowedSubmissionsCount,
    });
    dispatch(setRefWithError(submInputRef));
    setSubmissionInputValueError(error);
    if (e.target.value && !error.value && currentAssignmentTemplate) {
      dispatch(removeRefWithError(submInputRef));
      dispatch(
        setLearningToolsAndFeedbackSettings(
          setCorrectTemplateFeedbackOnInputChange(labelTextKey, currentAssignmentTemplate, e.target.value)
        )
      );
      setSubmissionInputValue(+e.target.value);
    }
  };
  const showOnlyAfterDueDate = (labelTextKey: FEEDBACK_ITEM, randomization: Randomization): boolean => {
    return (
      randomization === Randomization.AFTER_EVRY_SUBM &&
      (labelTextKey === FEEDBACK_ITEM.answerKey || labelTextKey === FEEDBACK_ITEM.practiceAnotherVersion)
    );
  };
  return (
    <>
      <FeedbackItemLayout data-testid="feedbackItemLayout">
        <Toggle
          id={labelTextKey + '_feedbackToggle'}
          testId={labelTextKey + '_feedbackToggle'}
          checked={checkedToggle}
          onChange={handleToggle}
          labelStyle={toggleLabelStyle}
          containerStyle={toggleContainerStyle}
          labelText={getLabelElement(labelTextKey, submission)}
        />
        {checkedToggle && !showOnlyAfterDueDate(labelTextKey, randomization) ? (
          <>
            <Select
              id={labelTextKey + '_selectLabelId'}
              labelId={labelTextKey + '_selectLabelId'}
              testId={labelTextKey + '_feedbackSelect'}
              containerStyle={selectContainerStyle}
              items={getSelectItems(labelTextKey, submission, allowedSubmissionsCount > 1)}
              selectedItem={selectedItem}
              onSelectedItemChange={handleSelectedItemChange}
              isLabelVisuallyHidden
              labelText={getLabelText(labelTextKey)}
            />
            {selectedItem?.value === '2' ? (
              <Input
                ref={submInputRef}
                testId={labelTextKey + '_feedbackSubmitInput'}
                value={submissionInputValue}
                onChange={handleSubmissionInputChange}
                labelText={t('ASSIGNMENT_EDITOR.FEEDBACK.ENTER_NUMBER_OF_SUBMISSIONS')}
                type={InputType.number}
                helperMessage={`2-${allowedSubmissionsCount}`}
                errorMessage={submissionInputValueError.value && `2-${allowedSubmissionsCount}`}
                min="0"
                containerStyle={inputContainerStyle}
                isLabelVisuallyHidden
              />
            ) : null}
          </>
        ) : null}
        {checkedToggle && showOnlyAfterDueDate(labelTextKey, randomization) ? (
          <ToolTipStyle>
            <div data-testid="afterDueFeedbackDiv">{t('ASSIGNMENT_EDITOR.FEEDBACK.SELECT.AFTER_DUE')}</div>
            <HelpTextForDueDateLimitation>
              {t('ASSIGNMENT_EDITOR.FEEDBACK.AFTER_DUE_HELPER_TEXT')}
            </HelpTextForDueDateLimitation>
          </ToolTipStyle>
        ) : null}
      </FeedbackItemLayout>
      {showToast ? (
        <Toast
          role="alert"
          onDismiss={() => updateShowToast(false)}
          variant={AlertVariant.info}
          testId={'warning_alert'}
        >
          {toastMessage}
        </Toast>
      ) : null}
    </>
  );
};

export default FeedbackItem;

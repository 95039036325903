import React, { ReactElement, useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import DefaultErrorBoundary from './components/ErrorBoundary/DefaultErrorBoundary';

import waUtils from './utils/wa';
import { Theme } from 'wa-ui-components';
import { openPopUp, setWAClassDate, navigate } from 'utils/facultyHeaderHelpers/facultyHeaderMethods';
import { useTranslation } from 'react-i18next';

import i18n from '../i18n';
import { I18nextProvider } from 'react-i18next';
import { useUserAPI } from './service/dataHooks';
import { ACTIONS } from 'context/data';
import { LoaderComponent } from 'wa-ui-components';
import KmliProvider from 'sharedComponents/Kmli/KmliProvider';

declare global {
  interface Window {
    waUtil: typeof waUtils;
    submitAction: typeof navigate;
    openPopUp: typeof openPopUp;
    setWAClassDate: typeof setWAClassDate;
  }
}

const defineGlobalMethods = () => {
  if (!window.waUtil) {
    window.waUtil = waUtils;
  }
  if (!window.submitAction) {
    window.submitAction = navigate;
  }
  if (!window.openPopUp) {
    window.openPopUp = openPopUp;
  }
  if (!window.setWAClassDate) {
    window.setWAClassDate = setWAClassDate;
  }
};

interface IAppProvider {
  children: ReactElement;
}

const AppProvider = ({ children }: IAppProvider): ReactElement => {
  defineGlobalMethods();
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  const { status: userStatus } = useUserAPI();

  useEffect(() => {
    changeLanguage(localStorage.getItem('language') || undefined);
  }, []);

  if (userStatus === ACTIONS.LOADING) {
    return <LoaderComponent />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <KmliProvider>
        <DefaultErrorBoundary>
          <ThemeProvider theme={Theme}>{children}</ThemeProvider>
        </DefaultErrorBoundary>
      </KmliProvider>
    </I18nextProvider>
  );
};
export default AppProvider;

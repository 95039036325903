import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ButtonVariant, IconButton } from 'react-magma-dom';
import { ReplyIcon } from 'react-magma-icons';

interface IProps {
  onClick: () => void;
}

const ButtonWrapper = styled.div`
  display: inline-block;
  margin-left: auto;
  margin-bottom: -0.25rem;
`;

export const AssignmentEditorToggle = (props: IProps): JSX.Element => {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <ButtonWrapper>
      <IconButton
        id="revert_new_ae"
        testId="revert_new_ae"
        variant={ButtonVariant.link}
        icon={<ReplyIcon aria-hidden="true" />}
        onClick={onClick}
        data-analytics="revert-new-assignment-editor"
      >
        {t('ASSIGNMENT_EDITOR.REVERT_EDITOR') || 'translate_me'}
      </IconButton>
    </ButtonWrapper>
  );
};

/* istanbul ignore file */
//todo: add test code, once we have our first end point, until then ignore
import { getRequestFor, GetRequestForReturnType } from './registry';
import fetcher from './fetcher';

/**
 * This function is injected as prop in DataFetch provider
 * 1. prepares request object and make Http request to fetch data
 * 2. If API name is not found will reject by throwing an error
 *
 * @param apiName: string
 * @param inputData: any
 */
async function fetchDataFor(apiName: string, inputData: any = null): Promise<Response> {
  const requestObject: GetRequestForReturnType = getRequestFor(apiName, inputData);
  if (!requestObject) {
    return Promise.reject(new Error('API not found'));
  }
  const [url, header] = requestObject;
  if (inputData) {
    //todo: add inputData to header body if any
  }
  return fetcher(url, header);
}

export { fetchDataFor };

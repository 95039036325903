import { ActionType } from 'context/data/actionCreators';
import { API_NAME_CONSTANTS } from '../../../service/registry';
import { useFetchedDataContext } from 'context/data/FetchedData';

export enum Privilege {
  REACT_FACULTY_UI = 'react_faculty_ui',
  REACT_ASSIGNMENT_EDITOR = 'react_assignment_editor',
  CXP_TOOL = 'cxp_tool',
}

export const useAuthorizationStatus = (privileges: Privilege[]): boolean => {
  const { getter } = useFetchedDataContext();
  const apiDataState: ActionType | null = getter(API_NAME_CONSTANTS.USER);

  const { authorizations } = (apiDataState && apiDataState.data) || {};

  return privileges.every(privilege => authorizations[privilege] !== undefined && authorizations[privilege]);
};

import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import React, { useEffect, useRef, useState } from 'react';
import { Randomization, Submission } from 'context/AssignmentContextProvider/types';
import {
  removeRefWithError,
  setLearningToolsAndFeedbackSettings,
  setQuestionRandomizationSettings,
  setRefWithError,
  setSubmissionAndWorkSettings,
} from 'context/AssignmentContextProvider/actions';
import { Alert, AlertVariant, Checkbox, FormGroup, Input, InputType, Toast, Toggle } from 'react-magma-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { validateField } from 'facultyComponents/assignmentEditor/helpers/commonHelpers';
import { containsMasterySet } from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { getTemplateFeedbackOnRandomizationAfterEverySubmission } from 'facultyComponents/assignmentEditor/helpers/FeedbackTabItem/feedbackItemHelper';

const Label = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: 600;
  font-size: 0.875rem;
`;
const HelperText = styled.div`
  color: #707070;
  font-size: 0.875rem;
`;
const SubmissionControlLayout = styled.div`
  display: flex;
  align-items: center;
`;
const SubmissionsInput = styled.div`
  padding: 0 0 0 3.5rem;
`;

const RandomizationAlertContainer = styled.div`
  width: 26rem;
`;

const inputContainerStyle = { margin: '-0.75rem 0.75rem', width: '5.625rem', high: '2.5rem' };
const formGroupLabelStyle = { fontSize: '1rem' };
const checkBoxContainerStyle = { margin: '1rem' };

const QuestionRandomizationGroup = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    state: {
      assignmentEditorContext: {
        assignmentInfo: { isModificationLimited },
      },
      assignmentSettings: {
        currentAssignmentTemplate: {
          id,
          settings: { questionRandomizationSettings, submissionAndWorkSettings, templateFeedback },
        },
        isCustomTemplate,
      },
      questionsData,
    },
    dispatch,
  } = useAssignmentContext();

  const { allowedSubmissionsCount, submission } = submissionAndWorkSettings;

  const submInputRef = useRef<HTMLInputElement>(null);
  const [submissionInputValueError, setSubmissionInputValueError] = useState({
    value: false,
    message: '',
  });

  const [checkedToggle, updateCheckedToggle] = useState<boolean>(false);
  const [checkedCheckBox, updateCheckedCheckBox] = useState<boolean>(false);
  const [submissionInputValue, setSubmissionInputValue] = useState<number>(1);
  const [showToast, updateShowToast] = useState(false);
  const [toastMessage, updateToastMessage] = useState('');
  const [hasMastery, setHasMastery] = useState(false);
  const [warningMessage, setWarningMessage] = useState<string>(() =>
    isModificationLimited ? t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.RANDOMIZATION_LOCKED') : ''
  );

  const isRandomizationModificationLimited = hasMastery || isModificationLimited;

  useEffect(() => {
    const questionsDataContainsMasterySet = containsMasterySet(questionsData);
    setHasMastery(questionsDataContainsMasterySet);
    if (questionsDataContainsMasterySet && !isModificationLimited) {
      setWarningMessage(t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.RANDOMIZATION_MASTERY'));
    }
  }, [questionsData]);

  useEffect(() => {
    updateCheckedToggle(questionRandomizationSettings.randomization !== Randomization.SAME_VALUES);
    updateCheckedCheckBox(questionRandomizationSettings.randomization === Randomization.AFTER_EVRY_SUBM);
    const { showAnswerKey, showSolution } = questionRandomizationSettings;
    // showSolution for question randomization settings is deprecated and may be removed in the future.
    // Going forward, showAnswerKey will determine whether to show solutions. In cases where an
    // assignment with custom settings where showAnswer and showSolution question randomization settings
    // do not match, we set them equal so that later saves will adjust the data to match our new
    // expectations. If the settings discrepancy exists in a saved assignment template, we will not make
    // any changes so that the template is preserved. Such discrepancies should instead be corrected in
    // the saved template data at rest, rather than attempting to correct here.
    if ((isCustomTemplate || id === 0) && showAnswerKey !== showSolution) {
      dispatch(
        setQuestionRandomizationSettings({
          ...questionRandomizationSettings,
          showAnswerKey: showAnswerKey,
          showSolution: showAnswerKey,
        })
      );
    }
    setSubmissionInputValue(questionRandomizationSettings.randomizationSubmissionsCount);
  }, [questionRandomizationSettings]);

  const handleToggle = (): void => {
    updateCheckedToggle(!checkedToggle);
    updateCheckedCheckBox(false);
    if (checkedToggle) {
      dispatch(
        setQuestionRandomizationSettings({
          ...questionRandomizationSettings,
          randomization: Randomization.SAME_VALUES,
        })
      );
    } else {
      dispatch(
        setQuestionRandomizationSettings({
          ...questionRandomizationSettings,
          randomization: Randomization.DIFFERENT_VALUES,
        })
      );
    }
  };
  const handleCheckBox = (): void => {
    updateCheckedCheckBox(!checkedCheckBox);
    if (!checkedCheckBox) {
      dispatch(
        setLearningToolsAndFeedbackSettings(getTemplateFeedbackOnRandomizationAfterEverySubmission(templateFeedback))
      );
      if (submission === Submission.QUESTION_PART) {
        updateToastMessage(t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.RANDOMIZATION_TOAST'));
        updateShowToast(true);
        dispatch(
          setSubmissionAndWorkSettings({
            ...submissionAndWorkSettings,
            submission: Submission.QUESTION,
          })
        );
      }
      dispatch(
        setQuestionRandomizationSettings({
          ...questionRandomizationSettings,
          randomization: Randomization.AFTER_EVRY_SUBM,
        })
      );
    } else {
      dispatch(
        setQuestionRandomizationSettings({
          ...questionRandomizationSettings,
          randomization: Randomization.DIFFERENT_VALUES,
        })
      );
    }
  };
  const handleShowAnswerCheckBox = (): void => {
    // for question randomization settings, showSolution is driven by showAnswerKey setting
    dispatch(
      setQuestionRandomizationSettings({
        ...questionRandomizationSettings,
        showAnswerKey: !questionRandomizationSettings.showAnswerKey,
        showSolution: !questionRandomizationSettings.showAnswerKey,
      })
    );
  };
  const handleSubmissionInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const error = validateField({
      value: e.target.value,
      min: 1,
      max: allowedSubmissionsCount - 1,
    });
    dispatch(setRefWithError(submInputRef));
    setSubmissionInputValueError(error);
    if (e.target.value && !error.value) {
      dispatch(removeRefWithError(submInputRef));
      dispatch(
        setQuestionRandomizationSettings({
          ...questionRandomizationSettings,
          randomizationSubmissionsCount: +e.target.value,
        })
      );
      setSubmissionInputValue(+e.target.value);
    }
  };

  return (
    <>
      <FormGroup
        labelStyle={formGroupLabelStyle}
        labelText={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.RANDOMIZATION')}
        testId="randomizationGroup"
      >
        {isRandomizationModificationLimited ? (
          <RandomizationAlertContainer>
            <Alert testId={'randomizationAlert'}>{warningMessage}</Alert>
          </RandomizationAlertContainer>
        ) : null}
        <Toggle
          checked={checkedToggle}
          onChange={handleToggle}
          defaultChecked={checkedToggle}
          labelText={
            <div data-testid="toggleLabel">
              <Label>{t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.RANDOMIZE_QUESTION_VALUES')}</Label>
              <HelperText>
                {checkedToggle
                  ? t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.UNIQUE_VERSION_ASSIGNMENT')
                  : t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.SAME_VERSION_ASSIGNMENT')}
              </HelperText>
            </div>
          }
          testId="randomizeQuestionsToggle"
          disabled={isRandomizationModificationLimited}
        />
        {allowedSubmissionsCount > 1 ? (
          <>
            <SubmissionControlLayout>
              {checkedToggle && (
                <Checkbox
                  checked={checkedCheckBox}
                  onChange={handleCheckBox}
                  id="specifiedSubmissions"
                  labelText={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.AFTER_SPECIFIED_SUBMISSIONS')}
                  testId="specifiedSubmissionsCheckBox"
                  containerStyle={checkBoxContainerStyle}
                  disabled={isRandomizationModificationLimited}
                />
              )}
            </SubmissionControlLayout>
            {checkedCheckBox && (
              <SubmissionsInput>
                <Input
                  type={InputType.number}
                  ref={submInputRef}
                  value={submissionInputValue}
                  onChange={handleSubmissionInputChange}
                  labelText={t('SUBMISSIONS')}
                  helperMessage={`1-${allowedSubmissionsCount - 1}`}
                  containerStyle={inputContainerStyle}
                  min={0}
                  errorMessage={submissionInputValueError.value && `1-${allowedSubmissionsCount - 1}`}
                  testId="submissionsInput"
                  disabled={isRandomizationModificationLimited}
                />
              </SubmissionsInput>
            )}
            <>
              {checkedToggle && checkedCheckBox && (
                <Checkbox
                  checked={questionRandomizationSettings.showAnswerKey}
                  onChange={handleShowAnswerCheckBox}
                  id="showAnswerKey"
                  labelText={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.SHOW_ANSWER_KEY_BEFORE_RANDOMIZING')}
                  testId="showAnswerKeyCheckBox"
                  containerStyle={checkBoxContainerStyle}
                  disabled={isModificationLimited}
                />
              )}
            </>
          </>
        ) : null}
      </FormGroup>
      {showToast ? (
        <Toast
          role="alert"
          onDismiss={() => updateShowToast(false)}
          variant={AlertVariant.info}
          testId={'warningAlert'}
        >
          {toastMessage}
        </Toast>
      ) : null}
    </>
  );
};

export default QuestionRandomizationGroup;

import React, { CSSProperties, ReactElement } from 'react';
import { ButtonVariant, EnumTooltipPosition, IconButton, Tooltip } from 'react-magma-dom';

import { HelpIcon } from 'react-magma-icons';

interface IProps {
  content: string;
  labeledBy: string;
  style?: CSSProperties;
}

export const HelpTooltip = (props: IProps): ReactElement => {
  const { content, labeledBy, style } = props;

  return (
    <Tooltip position={EnumTooltipPosition.right} content={content}>
      <IconButton
        aria-label="helpTooltip"
        aria-labelledby={labeledBy}
        icon={<HelpIcon color="black" />}
        variant={ButtonVariant.link}
        style={style || undefined}
        testId="helpTooltip"
      />
    </Tooltip>
  );
};

import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { TabsContainer, Tabs, TabsIconPosition, TabPanelsContainer, TabPanel } from 'react-magma-dom';

import { IQuestionElement, QuestionElementType, IMasterySet } from 'context/AssignmentContextProvider/types';

import { isMasterySet } from 'facultyComponents/assignmentEditor/helpers/questionElements';

import { PreviewPanel, PreviewTab } from '../Preview/PreviewTabPanel';
import { SubmissionsTab, SubmissionTabPanel } from '../Submissions/SubmissionTabPanel';
import { PointsPanel, PointsTab } from '../Points/PointsTabPanel';
import { PointsAdjustmentPanel, PointsAdjustmentTab } from '../PointsAdjustment/PointsAdjustmentTabPanel';
import { HelpHintsPanel, HelpHintsTab } from '../HelpHints/HelpHintsTabPanel';
import { MasterySetSettingPanel } from '../MasterySetSettings/MasterySetSettings';
import { MasterySetSettingsTab } from '../MasterySetSettings/MasterySetSettingsTabPanel';

interface IProps {
  activeIndex: number;
  id: string;
  index: number;
  questionElement: IQuestionElement;
  questionNumber: number[] | null;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

export enum DrawerTabs {
  questionPreview = 'questionPreview',
  submissions = 'submissions',
  points = 'points',
  pointsAdjustments = 'pointsAdjustments',
  helpHints = 'helpHints',
  masterySetSettings = 'masterySetSettings',
}

export const getDrawerTabsForElement = (key: QuestionElementType): Record<string, number> =>
  ({
    [QuestionElementType.QUESTION]: {
      [DrawerTabs.questionPreview]: 0,
      [DrawerTabs.submissions]: 1,
      [DrawerTabs.points]: 2,
      [DrawerTabs.pointsAdjustments]: 3,
      [DrawerTabs.helpHints]: 4,
    },
    [QuestionElementType.QUESTION_POOL]: {
      [DrawerTabs.questionPreview]: 0,
      [DrawerTabs.submissions]: 1,
      [DrawerTabs.pointsAdjustments]: 2,
      [DrawerTabs.helpHints]: 3,
    },
    [QuestionElementType.MASTERY_SET]: {
      [DrawerTabs.questionPreview]: 0,
      [DrawerTabs.submissions]: 1,
      [DrawerTabs.masterySetSettings]: 2,
      [DrawerTabs.helpHints]: 3,
    },
  }[key]);

const getDrawerTab = (key: DrawerTabs, position: number, questionIndex: number, isMastery: boolean): JSX.Element =>
  ({
    [DrawerTabs.questionPreview]: (
      <PreviewTab
        id={`question_${questionIndex}_drawer_tab_${position}`}
        testId={`question_${questionIndex}_preview`}
      />
    ),
    [DrawerTabs.submissions]: (
      <SubmissionsTab
        id={`question_${questionIndex}_drawer_tab_${position}`}
        testId={`question_${questionIndex}_submission_settings`}
        isMastery={isMastery}
      />
    ),
    [DrawerTabs.points]: (
      <PointsTab
        id={`question_${questionIndex}_drawer_tab_${position}`}
        testId={`question_${questionIndex}_point_settings`}
      />
    ),
    [DrawerTabs.pointsAdjustments]: (
      <PointsAdjustmentTab
        id={`question_${questionIndex}_drawer_tab_${position}`}
        testId={`question_${questionIndex}_points_adjustment`}
      />
    ),
    [DrawerTabs.helpHints]: (
      <HelpHintsTab
        id={`question_${questionIndex}_drawer_tab_${position}`}
        testId={`question_${questionIndex}_help_hints`}
      />
    ),
    masterySetSettings: (
      <MasterySetSettingsTab
        id={`question_${questionIndex}_drawer_tab_${position}`}
        testId={`question_${questionIndex}_mastery_set_settings`}
      />
    ),
  }[key]);

const getDrawerPanel = (
  key: DrawerTabs,
  index: number,
  questionElement: IQuestionElement,
  questionNumber: number[] | null
): JSX.Element =>
  ({
    [DrawerTabs.questionPreview]: (
      <PreviewPanel index={index} questionElement={questionElement} questionNumber={questionNumber} />
    ),
    [DrawerTabs.submissions]: <SubmissionTabPanel index={index} />,
    [DrawerTabs.points]: <PointsPanel />,
    [DrawerTabs.pointsAdjustments]: <PointsAdjustmentPanel index={index} questionElement={questionElement} />,
    [DrawerTabs.helpHints]: <HelpHintsPanel index={index} questionElement={questionElement} />,
    [DrawerTabs.masterySetSettings]: (
      <MasterySetSettingPanel index={index} masterySet={questionElement as IMasterySet} />
    ),
  }[key]);

// Adjust as needed to ensure settings tabs render uniformly without scrolling.
// This will also be the initial height of the preview tab, which should expand
// to fit the iframed content without affecting the size of the other tabs.
export const defaultTabHeightRem = 37.5;

const StyledTabsContainer = styled(TabsContainer)`
  width: 100%;
`;

export const StyledTabPanel = styled(TabPanel)`
  min-height: ${defaultTabHeightRem}rem;
`;

export const Drawer = (props: IProps): JSX.Element | null => {
  const { activeIndex, id, index, questionElement, questionNumber, setActiveTabIndex } = props;
  const { t } = useTranslation();

  const DrawerTabIndex = getDrawerTabsForElement(questionElement.elementType);

  const panels: Array<JSX.Element> = Object.keys(DrawerTabIndex).map(key => {
    return getDrawerPanel(key as DrawerTabs, index, questionElement, questionNumber);
  });

  const tabs: Array<JSX.Element> = Object.keys(DrawerTabIndex).map(key => {
    return getDrawerTab(key as DrawerTabs, DrawerTabIndex[key], index, isMasterySet(questionElement));
  });

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <StyledTabsContainer id={id} activeIndex={activeIndex}>
      <Tabs
        aria-label={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.TABS_ARIA_LABEL')}
        iconPosition={TabsIconPosition.left}
        onChange={handleTabChange}
      >
        {tabs.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Tabs>
      <TabPanelsContainer>
        {panels.map((item, index) => (
          <StyledTabPanel key={index}>{item}</StyledTabPanel>
        ))}
      </TabPanelsContainer>
    </StyledTabsContainer>
  );
};

import React from 'react';

import { useTranslation, TFunction } from 'react-i18next';

import { VisuallyHidden } from 'react-magma-dom';

import {
  IAssignmentQuestion,
  IMasterySet,
  IQuestionElement,
  IQuestionPool,
  IQuestionSet,
  IUsageData,
  QuestionElementType,
} from 'context/AssignmentContextProvider/types';

interface IQuestionSetTimeToAnswerRange {
  upperCardinality?: number;
  lowerCardinality: number;
}

interface ITimeToAnswerProps {
  questionElement: IQuestionElement;
  elementIdentifier: string;
  singularQuestion: boolean;
}

interface ITotalTimeToAnswerProps {
  questionsData: IQuestionElement[];
}

interface ITimeToAnswerRange {
  min: number | null;
  max: number | null;
}

interface IAlternateText {
  ariaText: string;
  screenText: string;
}

export const getQuestionTimeToAnswer = <T extends IUsageData>(usageData: T): number | null => {
  if (!usageData.hasEstimate) {
    return null;
  }
  const timeToAnswerString = usageData.timeToAnswerInMinutes;
  const timeToAnswerInt = parseInt(timeToAnswerString);
  return isFinite(timeToAnswerInt) && timeToAnswerInt !== 0 ? timeToAnswerInt : null;
};

const getOrderedQuestionSetAnswerTimes = (questionSet: IQuestionSet, descending = false): number[] => {
  return questionSet.questions
    .map(question => getQuestionTimeToAnswer(question.usageData))
    .filter((timeToAnswer): timeToAnswer is number => !!timeToAnswer)
    .sort((a, b) => (descending ? b - a : a - b));
};

const getTimeToAnswerRange = (answerTimes: number[], minSetSize: number, maxSetSize: number): ITimeToAnswerRange => {
  if (answerTimes.length < 2 || maxSetSize == 0 || maxSetSize > answerTimes.length || minSetSize > maxSetSize) {
    return {
      min: null,
      max: null,
    };
  }
  return {
    min: answerTimes.slice(0, minSetSize).reduce((total, time) => total + time, 0),
    max: answerTimes.slice(-maxSetSize).reduce((total, time) => total + time, 0),
  };
};

export const getQuestionSetTimeToAnswerRange = <T extends IQuestionSet>(
  element: T,
  minSetSize: number,
  maxSetSize: number = minSetSize
): ITimeToAnswerRange => {
  const answerTimes = getOrderedQuestionSetAnswerTimes(element);

  return getTimeToAnswerRange(answerTimes, minSetSize, maxSetSize);
};

const getTimeToAnswer = (questionElement: IQuestionElement, t: TFunction<'translation', undefined>): string | null => {
  switch (questionElement.elementType) {
    case QuestionElementType.QUESTION_POOL:
      return getTimeToAnswerForQuestionSet(
        questionElement as IQuestionPool,
        {
          lowerCardinality: (questionElement as IQuestionPool).includedNumberOfQuestions,
        },
        t
      );
    case QuestionElementType.MASTERY_SET:
      return getTimeToAnswerForQuestionSet(
        questionElement as IMasterySet,
        {
          lowerCardinality: (questionElement as IMasterySet).masteryThreshold,
          upperCardinality: (questionElement as IMasterySet).subsetSize,
        },
        t
      );
    case QuestionElementType.QUESTION:
      const tta = getQuestionTimeToAnswer((questionElement as IAssignmentQuestion).usageData);
      return tta !== null ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.TIME.SINGULAR', { value: tta }) : null;
    default:
      return null;
  }
};

const getTimeToAnswerForQuestionSet = <T extends IQuestionSet>(
  questionElement: T,
  range: IQuestionSetTimeToAnswerRange,
  t: TFunction<'translation', undefined>
): string | null => {
  const { min, max } = {
    ...getQuestionSetTimeToAnswerRange(
      questionElement,
      range.lowerCardinality,
      range.upperCardinality ? range.upperCardinality : range.lowerCardinality
    ),
  };
  if (min !== null && max !== null) {
    return min === max
      ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.TIME.SINGULAR', { value: max })
      : t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.TIME.RANGE', { min: min, max: max });
  }
  return null;
};

export const getTotalTimeToAnswer = (
  questionElements: IQuestionElement[],
  t: TFunction<'translation', undefined>
): string | null => {
  const total = questionElements
    .map(questionElement => {
      switch (questionElement.elementType) {
        case QuestionElementType.QUESTION_POOL:
          return getQuestionSetTimeToAnswerRange(
            questionElement as IQuestionPool,
            (questionElement as IQuestionPool).includedNumberOfQuestions
          ).max;
        case QuestionElementType.MASTERY_SET:
          return getQuestionSetTimeToAnswerRange(
            questionElement as IMasterySet,
            (questionElement as IMasterySet).masteryThreshold,
            (questionElement as IMasterySet).subsetSize
          ).max;
        case QuestionElementType.QUESTION:
          return getQuestionTimeToAnswer((questionElement as IAssignmentQuestion).usageData);
        default:
          return null;
      }
    })
    .filter((timeToAnswer): timeToAnswer is number => !!timeToAnswer)
    .reduce((totalTTA, nonNullTTA) => totalTTA + nonNullTTA, 0);
  return total > 0 ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.TIME.SINGULAR', { value: total }) : null;
};

const makeEstimatedTimeText = (
  estimatedTime: string | null,
  t: TFunction<'translation', undefined>
): IAlternateText => {
  return {
    ariaText: estimatedTime !== null ? estimatedTime : t('NOT_AVAILABLE'),
    screenText: estimatedTime !== null ? estimatedTime : '-',
  };
};

export const TimeToAnswer = (props: ITimeToAnswerProps): JSX.Element => {
  const { questionElement, elementIdentifier, singularQuestion } = props;
  const { t } = useTranslation();

  const elementTimeToAnswer = getTimeToAnswer(questionElement, t);
  const { ariaText, screenText } = makeEstimatedTimeText(elementTimeToAnswer, t);

  return (
    <>
      <VisuallyHidden>
        {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_TIME', {
          count: singularQuestion ? 1 : 0,
          elementIdentifier,
          estimatedTime: ariaText,
        })}
      </VisuallyHidden>
      <span aria-hidden="true">{screenText}</span>
    </>
  );
};

export const TotalTimeToAnswer = (props: ITotalTimeToAnswerProps): JSX.Element => {
  const { questionsData } = props;
  const { t } = useTranslation();

  const total = getTotalTimeToAnswer(questionsData, t);
  const { ariaText, screenText } = makeEstimatedTimeText(total, t);

  return total === null ? (
    <>
      <VisuallyHidden>{ariaText}</VisuallyHidden>
      <span aria-hidden="true">{screenText}</span>
    </>
  ) : (
    <>({t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.TIME.TOTAL', { value: screenText })})</>
  );
};

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { AlertVariant, Banner, Toggle } from 'react-magma-dom';

import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import {
  setAdjustAllQuestionConditionalPoints,
  setQuestionElementShowMyWorkSettings,
} from 'context/AssignmentContextProvider/actions';

import { isAssignmentQuestion } from 'facultyComponents/assignmentEditor/helpers/questionElements';
import {
  IAssignmentQuestion,
  IConditionalPointsGradingSettings,
  IQuestionElement,
  IQuestionPool,
  QuestionElementType,
  ShowMyWorkMode,
  Submission,
} from 'context/AssignmentContextProvider/types';
import { validatePointsInput } from 'facultyComponents/assignmentEditor/helpers/commonHelpers';
import { InputAutocorrect, StandardErrorFormat } from 'sharedComponents/InputAutocorrect/InputAutocorrect';
import { PointsAdjustment } from 'facultyComponents/assignmentEditor/helpers/PointsAdjustment/PointsAdjustment';
import { MagmaInputMessageStyle } from 'facultyComponents/assignmentEditor/helpers/PointsAdjustment/InputContainers';
import { NumericToleranceOverride } from 'facultyComponents/assignmentEditor/helpers/PointsAdjustment/NumericToleranceOverride/NumericToleranceOverride';
import { AdjustAllQuestionsButton } from 'facultyComponents/assignmentEditor/helpers/PointsAdjustment/AdjustAllQuestions/AdjustAllQuestionsButton';

interface IShowMyWorkProps {
  questionElementIndex: number;
}

interface IPointsAdjustmentsProps {
  questionElementIndex: number;
  questionElement: IQuestionElement;
}

const ShowMyWorkLayout = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ShowMyWorkToggleContainer = styled.div`
  font-weight: 600;
`;

const showMyWorkToggleWithMessage = {
  minHeight: '6.625rem',
};

const showMyWorkToggleWithoutMessage = {
  minHeight: '5.625rem',
  paddingTop: '2rem',
};

const ShowMyWorkPointsContainer = styled.div`
  margin: 0 1.5rem;
  width: 6rem;
`;

const AlignAdjustAllQuestionsButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ShowMyWorkWasDisabled = styled(Banner)<{ variant: AlertVariant }>`
  width: 31.25rem;
  white-space: pre-line;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
`;

const isShowWorkRequired = (questionElement: IQuestionElement): boolean =>
  isAssignmentQuestion(questionElement) && questionElement.showMyWorkMode === ShowMyWorkMode.REQUIRED;

const ShowMyWorkSettings = (props: IShowMyWorkProps): JSX.Element | null => {
  const { questionElementIndex } = props;
  const {
    state: {
      assignmentSettings: {
        currentAssignmentTemplate: {
          settings: {
            submissionAndWorkSettings: { submission },
          },
        },
      },
      questionsData,
    },
    dispatch,
  } = useAssignmentContext();
  const { t } = useTranslation();

  const questionElement = questionsData[questionElementIndex];

  const [showMyWorkRequired, setShowMyWorkRequired] = useState<boolean>(isShowWorkRequired(questionElement));
  const [showMyWorkPoints, setShowMyWorkPoints] = useState<number>(questionElement.showMyWorkPointValue || 1);

  const updateDependencies = (value: number) => {
    setShowMyWorkPoints(value);
    if (
      showMyWorkRequired !== isShowWorkRequired(questionElement) ||
      (questionElement.showMyWorkPointValue !== 0 && value !== questionElement.showMyWorkPointValue)
    ) {
      dispatch(
        setQuestionElementShowMyWorkSettings({
          elementIndex: questionElementIndex,
          showMyWorkMode: showMyWorkRequired ? ShowMyWorkMode.REQUIRED : ShowMyWorkMode.DEFAULT,
          showMyWorkPointValue: value,
        })
      );
    }
  };

  useEffect(() => {
    updateDependencies(showMyWorkPoints);
  }, [showMyWorkRequired]);

  useEffect(() => {
    setShowMyWorkRequired(isShowWorkRequired(questionElement));
  }, [questionElement.showMyWorkMode]);

  const handleShowMyWorkRequiredToggle = () => {
    setShowMyWorkRequired(!showMyWorkRequired);
  };

  const validatePoints = (value: string): StandardErrorFormat => validatePointsInput(value);

  // SMW only available for assignment questions (not pools et al)
  return isAssignmentQuestion(questionElement) ? (
    <ShowMyWorkLayout>
      <ShowMyWorkToggleContainer
        style={submission === Submission.ASSIGNMENT ? showMyWorkToggleWithMessage : showMyWorkToggleWithoutMessage}
      >
        {submission === Submission.ASSIGNMENT ? (
          <ShowMyWorkWasDisabled variant={AlertVariant.info} testId="showMyWorkIsDisabledMessage">
            {t('ASSIGNMENT_EDITOR.SCORING.SHOW_MY_WORK_IS_DISABLED_MESSAGE')}
          </ShowMyWorkWasDisabled>
        ) : null}
        <Toggle
          checked={showMyWorkRequired}
          labelText={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.POINTS_ADJUST_SETTINGS.SHOW_WORK_REQUIRED')}
          onChange={handleShowMyWorkRequiredToggle}
          testId={`showMyWorkRequired${questionElementIndex}`}
          disabled={submission === Submission.ASSIGNMENT}
        />
      </ShowMyWorkToggleContainer>
      {showMyWorkRequired ? (
        <ShowMyWorkPointsContainer>
          <InputAutocorrect
            testId={`showMyWorkPoints${questionElementIndex}`}
            labelText={t('POINTS')}
            value={showMyWorkPoints.toString()}
            validator={validatePoints}
            validationError={{ error: false, message: '' }}
            onValidChange={(value: string) => updateDependencies(parseFloat(value))}
            messageStyle={MagmaInputMessageStyle}
          />
        </ShowMyWorkPointsContainer>
      ) : null}
    </ShowMyWorkLayout>
  ) : null;
};

export const PointsAdjustmentSettings = (props: IPointsAdjustmentsProps): JSX.Element => {
  const { questionElementIndex, questionElement } = props;

  const { state, dispatch } = useAssignmentContext();
  const {
    assignmentSettings: { currentAssignmentTemplate },
  } = state;

  const getConditionalPointsFormula = (): string => {
    switch (questionElement.elementType) {
      case QuestionElementType.QUESTION:
        return (questionElement as IAssignmentQuestion).boxes[0].gradingSettings.conditionalPointsFormula;
      case QuestionElementType.QUESTION_POOL:
        return (questionElement as IQuestionPool).gradingSettings.conditionalPointsFormula;
      default:
        return currentAssignmentTemplate.settings.conditionalPointsFormula;
    }
  };

  const getConditionalPointsFormulaExplanation = (): string | undefined => {
    switch (questionElement.elementType) {
      case QuestionElementType.QUESTION:
        return (questionElement as IAssignmentQuestion).boxes[0].gradingSettings.conditionalPointsFormulaExplanation;
      case QuestionElementType.QUESTION_POOL:
        return (questionElement as IQuestionPool).gradingSettings.conditionalPointsFormulaExplanation;
      default:
        return undefined;
    }
  };

  const getIsAdvanced = (): boolean => {
    switch (questionElement.elementType) {
      case QuestionElementType.QUESTION:
        return (questionElement as IAssignmentQuestion).boxes[0].gradingSettings.isAdvanced || false;
      case QuestionElementType.QUESTION_POOL:
        return (questionElement as IQuestionPool).gradingSettings.isAdvanced || false;
      default:
        return false;
    }
  };

  const conditionalPointsGradingSettings: IConditionalPointsGradingSettings = {
    conditionalPointsFormula: getConditionalPointsFormula(),
    conditionalPointsFormulaExplanation: getConditionalPointsFormulaExplanation(),
    isAdvanced: getIsAdvanced(),
  };

  return (
    <>
      <ShowMyWorkSettings questionElementIndex={questionElementIndex} />
      <AlignAdjustAllQuestionsButton>
        <PointsAdjustment
          questionElementIndex={questionElementIndex}
          testId={`question_${questionElementIndex}_points_adjustment`}
        />
        <AdjustAllQuestionsButton
          adjustAllQuestionConditionalPoints={() =>
            dispatch(setAdjustAllQuestionConditionalPoints(conditionalPointsGradingSettings))
          }
          questionElementIndex={questionElementIndex}
        />
      </AlignAdjustAllQuestionsButton>
      <NumericToleranceOverride questionElementIndex={questionElementIndex} />
    </>
  );
};

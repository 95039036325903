/* istanbul ignore file */
import axios from 'axios';
import {
  ICxpImportRequest,
  ICxpResult,
  SearchRequest,
  ICxpRefreshResult,
  ICxpRequest,
  ICxpSearchGroupQuestion,
  ITextbookShortName,
  ICxpTextbookQuestion,
} from 'context/CxpQuestionContextProvider/types';

const instance = axios.create({
  baseURL: window.location.origin,
});

const importCxpQuestions = (request: ICxpImportRequest): Promise<{ data: { result: ICxpResult; status: number } }> => {
  return instance.post(`wa-ui-services/question/cxp/import`, request);
};

const searchCxpItem = (
  request: SearchRequest
): Promise<{
  data: { result: ICxpSearchGroupQuestion[]; status: number };
}> => {
  return instance.post(`wa-ui-services/question/cxp/search`, request);
};

const syncCxpItem = (request: ICxpRequest[]): Promise<{ data: { result: ICxpRefreshResult; status: number } }> => {
  return instance.post(`wa-ui-services/question/cxpItems/refresh`, request);
};

const updateCxpItemOneSubmission = (request: number): Promise<{ status: number }> => {
  return instance.post(`wa-ui-services/question/cxpItems/refresh/submission/${request}`);
};

const searchDistinctTextbookNames = (): Promise<{ data: { result: ITextbookShortName[]; status: number } }> => {
  return instance.get(`wa-ui-services/question/textbook/names/cxp`);
};

const searchQuestionsByTextbookId = (
  id: number
): Promise<{ data: { result: ICxpTextbookQuestion[]; status: number } }> => {
  return instance.get(`wa-ui-services/question/cxp/textbook/${id}`);
};

export {
  importCxpQuestions,
  searchCxpItem,
  syncCxpItem,
  updateCxpItemOneSubmission,
  searchDistinctTextbookNames,
  searchQuestionsByTextbookId,
};

import React from 'react';

import { useTranslation } from 'react-i18next';

import { Tab } from 'react-magma-dom';
import { GradeIcon } from 'react-magma-icons';
import { QuestionPartPoints } from '../Points/Points';
import { useQuestionPointsContext } from 'context/QuestionPointsContextProvider/QuestionPointsContextProvider';

interface IPointsTabProps {
  id: string;
  testId: string;
}

export const PointsTab = (props: IPointsTabProps): JSX.Element => {
  const { id, testId } = props;
  const { t } = useTranslation();

  return (
    <Tab icon={<GradeIcon />} id={id} testId={testId}>
      {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.POINT_SETTINGS')}
    </Tab>
  );
};

export const PointsPanel = (): JSX.Element => {
  const { questionIndex, questionPartLevelPoints } = useQuestionPointsContext();

  return (
    <>
      {questionPartLevelPoints.map((points, index) => (
        <QuestionPartPoints questionIndex={questionIndex} boxIndex={index} boxPoints={points} key={index} />
      ))}
    </>
  );
};

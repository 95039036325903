import React, { useEffect, useState } from 'react';
import { Input, InputProps, InputType } from 'react-magma-dom';

interface IValidedNumericInputProps {
  testId: string;
  value: number;
  forceRevertFlag?: boolean;
  updateDependencies: (updatedValue: number) => void;
  validationMethod: (value: any) => { error: boolean; message: string };
}

const displayPoints = (points: number | null): string => {
  if (!points) {
    if (points === 0) {
      return '0';
    }
    return '';
  }
  return `${points}`;
};

export const ValidatedNumericInput = (props: InputProps & IValidedNumericInputProps): JSX.Element => {
  const {
    testId,
    value,
    updateDependencies,
    validationMethod: validationFunction,
    forceRevertFlag = false,
    ...rest
  } = props;

  const [points, setPoints] = useState(value);
  const [updated, setUpdated] = useState(false);

  const [validationError, setValidationError] = useState({
    error: false,
    message: '',
  });

  useEffect(() => {
    setPoints(value);
  }, [value]);

  useEffect(() => {
    setPoints(value);
  }, [forceRevertFlag]);

  const updateOrRevert = (value: string) => {
    if (!updated) {
      return;
    }
    if (validationError.error) {
      setPoints(props.value);
      setValidationError({ error: false, message: '' });
    } else {
      const updatedPoints = parseFloat(value);
      setPoints(updatedPoints);
      updateDependencies(updatedPoints as number);
    }
    setUpdated(false);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    updateOrRevert(e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.focus();
    e.preventDefault();
    const point = parseFloat(e.target.value);
    setPoints(point);
    setUpdated(true);
    setValidationError(validationFunction(e.target.value));
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateOrRevert(e.currentTarget.value);
    }
  };

  return (
    <Input
      {...rest}
      testId={testId}
      type={InputType.number}
      maxLength={4}
      value={displayPoints(points)}
      onChange={handleChange}
      onKeyPress={handleEnter}
      onBlur={handleBlur}
      errorMessage={validationError.error ? validationError.message : null}
    />
  );
};

import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

import { SelectComponent } from '../helpers/ScoringTabSelect/ScoringTabSelect';
import ToggleComponent from '../helpers/Toggle/Toggle';
import { AssignmentEditorAccordion } from '../helpers/Accordion/AssignmentEditorAccordion';
import AdvancedScoringTabSettings from '../helpers/AdvancedSettings/AdvansedScoringTabAdvancedSettings';
import { SELECT_LABEL } from '../helpers/types';
import { useTranslation } from 'react-i18next';
import { PointsAdjustment } from '../helpers/PointsAdjustment/PointsAdjustment';

const ScoringTabLayout = styled.div`
  display: flex;
`;
const LeftPan = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: 0 1.5rem;
`;
const RightPan = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(65% - 2rem);
  margin: 0 1rem;
`;

const ScoringTab = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <ScoringTabLayout>
      <LeftPan>
        <SelectComponent isNumbOfSub label={SELECT_LABEL.NumOfSubm} />
        <SelectComponent label={SELECT_LABEL.SubmitAnsw} />
        <SelectComponent label={SELECT_LABEL.ScoreUsing} />
        <SelectComponent label={SELECT_LABEL.gradeBook} />
      </LeftPan>
      <RightPan>
        <PointsAdjustment questionElementIndex={undefined} testId="assignmentPointsAdjustment" />
        <ToggleComponent labelTxt={t('ASSIGNMENT_EDITOR.SCORING.STUDENTS_SHOW_WORK')} />
        <AssignmentEditorAccordion
          isAdvacedSettings
          btnText={t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.TITLE')}
          testId="advancedSettings"
        >
          <AdvancedScoringTabSettings />
        </AssignmentEditorAccordion>
      </RightPan>
    </ScoringTabLayout>
  );
};

export default ScoringTab;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import i18next from '../../../../../../i18n';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Button, ButtonVariant, Select, Textarea } from 'react-magma-dom';

import {
  setAdjustAllQuestionConditionalPoints,
  setAssignmentTemplatePointsAdjustment,
  setQuestionElementPointsAdjustment,
} from 'context/AssignmentContextProvider/actions';
import {
  isAssignmentQuestion,
  isQuestionPool,
  getQuestionElementGradingSettings,
  hasCxpQuestion,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { SELECT_LABEL } from '../types';

import {
  IConditionalPointSettings,
  PresetDefaults,
  getPresetFromSettings,
  getConditionalPointSettingsFromFormula,
  getQuestionElementConditionalPointsDataFromState,
  makeConditionalPointsFormula,
  isAdvancedPointsAdjustment,
  PresetConditionalPoints,
  DEFAULT_GRADING_FORMULA,
} from './ConditionalPointsSettings';
import { SubmissionBonusPenalty } from './SubmissionBonusPenalty/SubmissionBonusPenalty';
import { ExtraCredit } from './ExtraCredit/ExtraCredit';
import { AwardCreditToEveryone } from './AwardCreditToEveryone/AwardCreditToEveryone';
import { LaunchIcon } from 'react-magma-icons';
import {
  IAssignmentQuestion,
  IQuestionElement,
  IQuestionPool,
  QuestionElementType,
} from 'context/AssignmentContextProvider/types';

export interface IPointsAdjustments {
  questionElementIndex?: number;
  testId?: string;
}

interface IPointsAdjustmentsWithCallback extends IPointsAdjustments {
  setAdvancedMode: Dispatch<SetStateAction<boolean>>;
}

interface IPointsAdjustmentsAdvanced {
  questionElementIndex: number;
  setAdvancedMode: Dispatch<SetStateAction<boolean>>;
}

const FORMULA_FOR_SET_AT_QUESTION_LEVEL = makeConditionalPointsFormula(PresetDefaults.SET_AT_QUESTION_LEVEL);

const SelectWrapper = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

const AdvancedWrapper = styled.div`
  width: 34rem;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

const ExplanationTextareaWrapper = styled.div`
  padding-top: 0.875rem;
  textarea {
    height: 4.375rem;
  }
`;

const FormulaTextareaWrapper = styled.div`
  margin-top: 0.25rem;
  textarea {
    height: 3.5rem;
  }
`;

const ButtonWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlue};
  flex-direction: column;
  position: absolute;
  float: right;
  text-decoration: none;
  left: 11rem;
  margin: 0.4rem 0 0 0;
  z-index: 2;
  button {
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

const LinkWrapper = styled.a`
  margin: 0.5rem 1rem 1rem 0;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration: none;
  align-items: center;
  display: flex;
  width: 8rem;
  color: rgb(0, 98, 152);
`;

const ButtonLabel = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  width: 8rem;
  padding-top: 1.2rem;
`;

const selectStyles = { marginTop: '0.25rem' };

const selectLabelStyle: React.CSSProperties = { margin: '1rem 0 1rem 0', display: 'flex', fontWeight: 600 };

export const PointsAdjustmentType = {
  NONE: {
    label: i18next.t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.OPTIONS.NONE'),
    value: '',
  },
  SET_AT_QUESTION_LEVEL: {
    label: i18next.t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.OPTIONS.SET_AT_QUESTION_LEVEL'),
    value: '0',
  },
  SUBMISSION_PENALTY: {
    label: i18next.t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.OPTIONS.DEDUCT'),
    value: '1',
  },
  EARLY_SUBMISSION_BONUS: {
    label: i18next.t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.OPTIONS.ADD'),
    value: '2',
  },
  ASSIGN_EXTRA_CREDIT: {
    label: i18next.t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.OPTIONS.ASSIGN'),
    value: '3',
  },
  AWARD_EVERYONE_CREDIT: {
    label: i18next.t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.OPTIONS.AWARD'),
    value: '4',
  },
};
const getAdjustmentTypeFromSettings = (settings: IConditionalPointSettings): keyof typeof PointsAdjustmentType =>
  settings ? getPresetFromSettings(settings) : 'NONE';

const getPointsAdjustmentTypeFromOptionValue = (value: string): keyof typeof PointsAdjustmentType => {
  const found = Object.keys(PointsAdjustmentType).find(key => {
    const type = PointsAdjustmentType[key as keyof typeof PointsAdjustmentType];
    return type.value === value;
  });
  return found ? (found as keyof typeof PointsAdjustmentType) : ('NONE' as keyof typeof PointsAdjustmentType);
};

const AdjustmentComponent = (props: IPointsAdjustments & IConditionalPointSettings): JSX.Element => {
  const { questionElementIndex, testId, ...rest } = props;
  const adjustmentType = getAdjustmentTypeFromSettings(rest);

  switch (adjustmentType) {
    case 'SUBMISSION_PENALTY':
      return (
        <SubmissionBonusPenalty
          key={`submission_penalty_${questionElementIndex}`}
          isDeduct={true}
          questionElementIndex={questionElementIndex}
          testId={testId}
          {...rest}
        />
      );
    case 'EARLY_SUBMISSION_BONUS':
      return (
        <SubmissionBonusPenalty
          key={`early_submission_bonus_${questionElementIndex}`}
          isDeduct={false}
          questionElementIndex={questionElementIndex}
          testId={testId}
          {...rest}
        />
      );
    case 'ASSIGN_EXTRA_CREDIT':
      return (
        <ExtraCredit
          key={`extra_credit_${questionElementIndex}`}
          questionElementIndex={questionElementIndex}
          testId={testId}
          {...rest}
        />
      );
    case 'AWARD_EVERYONE_CREDIT':
      return (
        <AwardCreditToEveryone
          key={`award_everyone_credit${questionElementIndex}`}
          questionElementIndex={questionElementIndex}
          testId={testId}
          {...rest}
        />
      );
    default:
      return <></>;
  }
};

export const PointsAdjustment = (props: IPointsAdjustments): JSX.Element => {
  const { questionElementIndex, testId } = props;
  const { state } = useAssignmentContext();
  const { questionsData } = state;
  const questionElement = questionElementIndex !== undefined ? questionsData[questionElementIndex] : undefined;
  const questionElementGradingSetting = questionElement
    ? getQuestionElementGradingSettings(questionElement)
    : undefined;

  const [isAdvancedMode, setAdvancedMode] = useState<boolean>(isAdvancedPointsAdjustment(questionElement) || false);

  useEffect(() => {
    setAdvancedMode(isAdvancedPointsAdjustment(questionElement));
  }, [questionElementGradingSetting?.isAdvanced]);

  if (isAdvancedMode && questionElementIndex !== undefined) {
    return (
      <AdvancedModePointsAdjustments questionElementIndex={questionElementIndex} setAdvancedMode={setAdvancedMode} />
    );
  }
  return (
    <StandardModePointsAdjustments
      questionElementIndex={questionElementIndex}
      testId={testId}
      setAdvancedMode={setAdvancedMode}
    />
  );
};

const AdvancedModePointsAdjustments = (props: IPointsAdjustmentsAdvanced): JSX.Element => {
  const { questionElementIndex, setAdvancedMode } = props;
  const { state, dispatch } = useAssignmentContext();
  const { t } = useTranslation();
  const { questionsData } = state;
  const questionElement = questionsData[questionElementIndex];
  const questionGradingSettings = getQuestionElementGradingSettings(questionElement);
  const pointsFormulaInitialValue = questionGradingSettings?.conditionalPointsFormula;
  const pointsFormulaExplanationInitialValue = questionGradingSettings?.conditionalPointsFormulaExplanation || '';

  const [adjustmentFormula, setAdjustmentFormula] = useState(pointsFormulaInitialValue);
  const [adjustmentFormulaExplanation, setAdjustmentFormulaExplanation] = useState(
    pointsFormulaExplanationInitialValue
  );

  useEffect(() => {
    setAdjustmentFormula(pointsFormulaInitialValue);
  }, [pointsFormulaInitialValue]);

  useEffect(() => {
    setAdjustmentFormulaExplanation(pointsFormulaExplanationInitialValue);
  }, [pointsFormulaExplanationInitialValue]);

  const switchToStandardMode = () => {
    if (questionElementIndex !== undefined) {
      dispatch(
        setQuestionElementPointsAdjustment({
          elementIndex: questionElementIndex,
          conditionalPointsFormula: DEFAULT_GRADING_FORMULA,
          conditionalPointsFormulaExplanation: '',
          isAdvanced: false,
        })
      );
    }
    setAdvancedMode(false);
  };

  const handleAdjustmentFormulaBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setAdjustmentFormula(value);
    if (questionElementIndex !== undefined) {
      const formulaExplanation = value && value.trim() ? adjustmentFormulaExplanation : '';
      dispatch(
        setQuestionElementPointsAdjustment({
          elementIndex: questionElementIndex,
          conditionalPointsFormula: value,
          conditionalPointsFormulaExplanation: formulaExplanation,
          isAdvanced: value && value.trim() ? true : false,
        })
      );
    }
  };

  const handleAdjustmentFormulaExplanationBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setAdjustmentFormulaExplanation(value);
    if (adjustmentFormula && adjustmentFormula.trim() && questionElementIndex !== undefined) {
      dispatch(
        setQuestionElementPointsAdjustment({
          elementIndex: questionElementIndex,
          conditionalPointsFormula: adjustmentFormula,
          conditionalPointsFormulaExplanation: value,
        })
      );
    }
  };

  return (
    <AdvancedWrapper data-testid="pointsAdjustmentSelectWrapper">
      <ButtonLabel>{t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.POINTS_ADJUSTMENT')}</ButtonLabel>
      <ButtonWrapper>
        <Button
          testId={`question_${questionElementIndex}_switch_to_standard_mode`}
          aria-label={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.POINTS_ADJUSTMENT')}
          variant={ButtonVariant.link}
          onClick={switchToStandardMode}
        >
          {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.POINTS_ADJUST_SETTINGS.SWITCH_TO_STANDARD')}
        </Button>
      </ButtonWrapper>
      <FormulaTextareaWrapper>
        <Textarea
          testId={`question_${questionElementIndex}_formula`}
          value={adjustmentFormula}
          labelText={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.POINTS_ADJUST_SETTINGS.ADJUSTMENTS_FORMULA')}
          labelStyle={selectLabelStyle}
          onBlur={handleAdjustmentFormulaBlur}
        />
      </FormulaTextareaWrapper>
      <LinkWrapper href="https://help.cengage.com/webassign/instructor_guide/?t=adv-cond-pts" target="_blank">
        {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.POINTS_ADJUST_SETTINGS.FORMULA_HELP') || 'translate me!'}
        <LaunchIcon />
      </LinkWrapper>
      <ExplanationTextareaWrapper>
        <Textarea
          testId={`question_${questionElementIndex}_formula_explanation`}
          value={adjustmentFormulaExplanation}
          labelText={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.POINTS_ADJUST_SETTINGS.EXPLANATION')}
          labelStyle={selectLabelStyle}
          onBlur={handleAdjustmentFormulaExplanationBlur}
          helperMessage={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.POINTS_ADJUST_SETTINGS.EXPLANATION_HELPER')}
        />
      </ExplanationTextareaWrapper>
    </AdvancedWrapper>
  );
};

const StandardModePointsAdjustments = (props: IPointsAdjustmentsWithCallback): JSX.Element => {
  const { questionElementIndex, testId, setAdvancedMode } = props;
  const { state, dispatch } = useAssignmentContext();
  const { t } = useTranslation();

  const {
    questionsData,
    assignmentSettings: { currentAssignmentTemplate, isCustomTemplate },
  } = state;

  const equalsCurrentTemplateConditionalPoints = (questionElement: IQuestionElement): boolean => {
    switch (questionElement.elementType) {
      case QuestionElementType.QUESTION:
        return (
          (questionElement as IAssignmentQuestion).boxes[0].gradingSettings.conditionalPointsFormula !==
          currentAssignmentTemplate.settings.conditionalPointsFormula
        );
      case QuestionElementType.QUESTION_POOL:
        return (
          (questionElement as IQuestionPool).gradingSettings.conditionalPointsFormula !==
          currentAssignmentTemplate.settings.conditionalPointsFormula
        );
      default:
        return false;
    }
  };

  const hasNoneConditionalPoints = (questionElement: IQuestionElement): boolean => {
    switch (questionElement.elementType) {
      case QuestionElementType.QUESTION:
        return (
          (questionElement as IAssignmentQuestion).boxes[0].gradingSettings.conditionalPointsFormula ===
            DEFAULT_GRADING_FORMULA ||
          !(questionElement as IAssignmentQuestion).boxes[0].gradingSettings.conditionalPointsFormula
        );
      case QuestionElementType.QUESTION_POOL:
        return (
          (questionElement as IQuestionPool).gradingSettings.conditionalPointsFormula === DEFAULT_GRADING_FORMULA ||
          !(questionElement as IQuestionPool).gradingSettings.conditionalPointsFormula
        );
      default:
        return true;
    }
  };

  let conditionalPointsFormulaString = currentAssignmentTemplate.settings.conditionalPointsFormula;

  if (questionElementIndex !== undefined) {
    const questionElement = state.questionsData[questionElementIndex];
    conditionalPointsFormulaString = isAssignmentQuestion(questionElement)
      ? questionElement.boxes[0].gradingSettings.conditionalPointsFormula
      : isQuestionPool(questionElement)
      ? questionElement.gradingSettings.conditionalPointsFormula
      : conditionalPointsFormulaString;
  }

  const assignmentSettings = getConditionalPointSettingsFromFormula(
    currentAssignmentTemplate.settings.conditionalPointsFormula
  ) || { ...PresetDefaults.NONE };

  const initialState =
    questionElementIndex !== undefined
      ? getQuestionElementConditionalPointsDataFromState(state, questionElementIndex).settings
      : assignmentSettings;

  const [conditionalPointSettings, setConditionalPointSettings] = useState(initialState);
  const wereConditionalPointsUpdatedForAnyQuestion = (): boolean => {
    return questionsData
      ? questionsData.some(question => equalsCurrentTemplateConditionalPoints(question)) &&
          !(
            questionsData.every(question => hasNoneConditionalPoints(question)) &&
            (currentAssignmentTemplate.settings.conditionalPointsFormula === DEFAULT_GRADING_FORMULA ||
              currentAssignmentTemplate.settings.conditionalPointsFormula === FORMULA_FOR_SET_AT_QUESTION_LEVEL)
          )
      : false;
  };

  const [wereConditionalPointsUpdated, setWereConditionalPointsUpdated] = useState<boolean>(
    wereConditionalPointsUpdatedForAnyQuestion()
  );

  const pointAdjOptions = [
    PointsAdjustmentType.NONE,
    PointsAdjustmentType.SUBMISSION_PENALTY,
    PointsAdjustmentType.EARLY_SUBMISSION_BONUS,
  ];

  if (questionElementIndex !== undefined) {
    pointAdjOptions.push(PointsAdjustmentType.ASSIGN_EXTRA_CREDIT, PointsAdjustmentType.AWARD_EVERYONE_CREDIT);
  }

  if (questionElementIndex === undefined && wereConditionalPointsUpdated) {
    pointAdjOptions.push(PointsAdjustmentType.SET_AT_QUESTION_LEVEL);
  }

  useEffect(() => {
    setWereConditionalPointsUpdated(wereConditionalPointsUpdatedForAnyQuestion());
    if (
      questionElementIndex === undefined &&
      currentAssignmentTemplate.settings.conditionalPointsFormula === FORMULA_FOR_SET_AT_QUESTION_LEVEL &&
      !pointAdjOptions.includes(PointsAdjustmentType.SET_AT_QUESTION_LEVEL)
    ) {
      pointAdjOptions.push(PointsAdjustmentType.SET_AT_QUESTION_LEVEL);
    }
  }, [questionsData]);

  useEffect(() => {
    if (wereConditionalPointsUpdated && questionElementIndex === undefined) {
      dispatch(
        setAssignmentTemplatePointsAdjustment({
          conditionalPointsFormula: FORMULA_FOR_SET_AT_QUESTION_LEVEL,
          existingTemplate:
            (DEFAULT_GRADING_FORMULA.includes(makeConditionalPointsFormula(initialState)) ||
              FORMULA_FOR_SET_AT_QUESTION_LEVEL.includes(makeConditionalPointsFormula(initialState))) &&
            !isCustomTemplate,
        })
      );
      setConditionalPointSettings(PresetDefaults.SET_AT_QUESTION_LEVEL);
    }
  }, [wereConditionalPointsUpdated]);

  useEffect(() => {
    if (questionElementIndex === undefined) {
      if (conditionalPointSettings.mode !== PresetConditionalPoints.SET_AT_QUESTION_LEVEL.mode) {
        const conditionalPointsFormula = makeConditionalPointsFormula(conditionalPointSettings);
        if (conditionalPointsFormula !== makeConditionalPointsFormula(initialState)) {
          dispatch(setAssignmentTemplatePointsAdjustment({ conditionalPointsFormula }));
          dispatch(setAdjustAllQuestionConditionalPoints({ conditionalPointsFormula }));
          setWereConditionalPointsUpdated(false);
        }
      }
      return;
    }

    dispatch(
      setQuestionElementPointsAdjustment({
        elementIndex: questionElementIndex,
        conditionalPointsFormula: makeConditionalPointsFormula(conditionalPointSettings),
      })
    );
    setWereConditionalPointsUpdated(wereConditionalPointsUpdatedForAnyQuestion());

    if (
      assignmentSettings.mode === PresetConditionalPoints.SET_AT_QUESTION_LEVEL.mode &&
      conditionalPointSettings.mode === PresetConditionalPoints.NONE.mode &&
      !wereConditionalPointsUpdated
    ) {
      dispatch(
        setAssignmentTemplatePointsAdjustment({
          conditionalPointsFormula: DEFAULT_GRADING_FORMULA,
          existingTemplate:
            DEFAULT_GRADING_FORMULA.includes(makeConditionalPointsFormula(initialState)) && !isCustomTemplate,
        })
      );
    }
  }, [conditionalPointSettings]);

  useEffect(() => {
    const newConditionalPointSettings = getConditionalPointSettingsFromFormula(
      conditionalPointsFormulaString,
      false,
      currentAssignmentTemplate.settings.conditionalPointsFormula
    );
    if (newConditionalPointSettings) {
      setConditionalPointSettings(newConditionalPointSettings);
    }
  }, [conditionalPointsFormulaString]);

  const handleSelectItemChange = (changes: any) => {
    const newConditionalPoints = {
      ...PresetDefaults[getPointsAdjustmentTypeFromOptionValue(changes.selectedItem.value)],
    };

    setConditionalPointSettings(newConditionalPoints);
  };

  const shouldShowAdvancedMode = () => {
    if (questionElementIndex !== undefined) {
      const questionElement = questionsData[questionElementIndex];
      return !hasCxpQuestion(questionElement);
    }
    return false;
  };

  const switchToAdvancedMode = () => {
    if (questionElementIndex !== undefined) {
      dispatch(
        setQuestionElementPointsAdjustment({
          elementIndex: questionElementIndex,
          conditionalPointsFormula: '',
          conditionalPointsFormulaExplanation: '',
        })
      );
    }
    setAdvancedMode(true);
  };

  return (
    <SelectWrapper data-testid="pointsAdjustmentSelectWrapper">
      {shouldShowAdvancedMode() ? (
        <ButtonWrapper>
          <Button
            testId={`question_${questionElementIndex}_switch_to_advanced_mode`}
            variant={ButtonVariant.link}
            onClick={switchToAdvancedMode}
            aria-label={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.POINTS_ADJUSTMENT')}
          >
            {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.POINTS_ADJUST_SETTINGS.SWITCH_TO_ADVANCED')}
          </Button>
        </ButtonWrapper>
      ) : null}
      <Select
        containerStyle={selectStyles}
        labelStyle={selectLabelStyle}
        items={pointAdjOptions}
        labelText={t(SELECT_LABEL.pointsAdjust)}
        testId={`${testId}_selector`}
        selectedItem={
          wereConditionalPointsUpdated && questionElementIndex === undefined
            ? PointsAdjustmentType['SET_AT_QUESTION_LEVEL']
            : PointsAdjustmentType[getAdjustmentTypeFromSettings(conditionalPointSettings)]
        }
        helperMessage={
          questionElementIndex === undefined
            ? t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.HELPER_MESSAGE_AT_ASSIGNMENT_LEVEL')
            : t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.HELPER_MESSAGE_AT_QUESTION_LEVEL')
        }
        onSelectedItemChange={handleSelectItemChange}
      />

      <AdjustmentComponent
        testId={`${testId}_adjustments`}
        questionElementIndex={questionElementIndex}
        {...conditionalPointSettings}
      />
    </SelectWrapper>
  );
};

import React, { useEffect, useState } from 'react';

import { Textarea } from 'react-magma-dom';
import { useTranslation } from 'react-i18next';
import { setErrors, setQuestionElementFeedbackSettings } from 'context/AssignmentContextProvider/actions';
import { IQuestionFeedbackSettings } from 'context/AssignmentContextProvider/types';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { getFieldValue } from 'utils/utils';

interface ICustomHintProps {
  index: number;
  feedbackSettings: IQuestionFeedbackSettings;
}

const hintStyle = { width: '60%' };

//works only for questions, will be updated to work with pools in upcoming tickets
export const CustomHint = (props: ICustomHintProps): JSX.Element => {
  const { index, feedbackSettings } = props;
  const { t } = useTranslation();
  const {
    state: { errors },
    dispatch,
  } = useAssignmentContext();

  const [customHint, setCustomHint] = useState(feedbackSettings.customHintText || '');
  const [errorCustomHint, setErrorCustomHint] = useState('');

  useEffect(() => {
    const hintError = getFieldValue(errors, 'questions.question_' + index);
    if (hintError) {
      setErrorCustomHint(hintError);
    }
  }, [errors]);

  const handleCustomHintChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { value } = e.target;
    setCustomHint(value);
    if (value.length > 65536) {
      setErrorCustomHint('ASSIGNMENT_EDITOR.INFORMATION.ERRORS.INSTRUCTIONS.LENGTH');
      return;
    }
    setErrorCustomHint('');
  };

  const handleCustomHintBlur = () => {
    dispatch(
      setErrors({
        field: 'questions.question_' + index,
        value: errorCustomHint ? errorCustomHint : undefined,
      })
    );
    const isEmpty = !customHint || /^\s*$/.test(customHint);
    dispatch(
      setQuestionElementFeedbackSettings({
        elementIndex: index,
        feedbackSettings: {
          ...feedbackSettings,
          customHintText: isEmpty ? null : customHint,
          showCustomHintBeforeDueDateAfterNumberOfSubmissions: isEmpty ? null : 1,
        },
      })
    );
  };

  return (
    <Textarea
      testId={'customHint_' + index}
      value={customHint}
      labelText={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.HELP_HINTS_SETTINGS.CUSTOM_HINT')}
      placeholder={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.HELP_HINTS_SETTINGS.CUSTOM_HINT_PLACEHOLDER')}
      helperMessage={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.HELP_HINTS_SETTINGS.CUSTOM_HINT_HELPER')}
      containerStyle={hintStyle}
      onBlur={handleCustomHintBlur}
      onChange={handleCustomHintChange}
      errorMessage={errorCustomHint ? t(errorCustomHint) : null}
    />
  );
};

import React, { createContext, useContext, useRef, useState } from 'react';

interface IQuestionBrowserContext {
  showQuestionBrowser: boolean;
  questionBrowserWindowRef: React.RefObject<CanFocusWindow> | null;
  updateShowQuestionBrowser: (shouldShowBrowser: boolean) => void;
  openOrFocusQuestionBrowser: () => void;
}

interface IQuestionBrowserContextProviderProps {
  children: React.ReactNode;
}

export interface CanFocusWindow {
  focusBrowser(): void;
}

export const QuestionBrowserContext = createContext<IQuestionBrowserContext>({
  showQuestionBrowser: false,
  questionBrowserWindowRef: null,
  updateShowQuestionBrowser: () => null,
  openOrFocusQuestionBrowser: () => null,
});

export const useQuestionBrowserContext = (): IQuestionBrowserContext => useContext(QuestionBrowserContext);

export const QuestionBrowserContextProvider = ({ children }: IQuestionBrowserContextProviderProps): JSX.Element => {
  const [showQuestionBrowser, setShowQuestionBrowser] = useState<boolean>(false);
  const updateShowQuestionBrowser = (shouldShowBrowser: boolean): void => {
    setShowQuestionBrowser(shouldShowBrowser);
  };

  const questionBrowserWindowRef = useRef<CanFocusWindow>(null);

  const openOrFocusQuestionBrowser = (): void => {
    if (showQuestionBrowser) {
      questionBrowserWindowRef.current?.focusBrowser();
    } else {
      setShowQuestionBrowser(true);
    }
  };
  return (
    <QuestionBrowserContext.Provider
      value={{
        showQuestionBrowser,
        questionBrowserWindowRef,
        updateShowQuestionBrowser,
        openOrFocusQuestionBrowser,
      }}
    >
      {children}
    </QuestionBrowserContext.Provider>
  );
};

import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonColor,
  ButtonTextTransform,
  ButtonVariant,
  EnumTooltipPosition,
  IconButton,
  Tooltip,
  VisuallyHidden,
} from 'react-magma-dom';
import {
  AutoAwesomeIcon,
  DeleteIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  GradeIcon,
  HelpCenterIcon,
  LibraryAddCheckIcon,
  SettingsIcon,
} from 'react-magma-icons';

import { QuestionElementType } from 'context/AssignmentContextProvider/types';

interface IControlsProps {
  index: number;
  drawerId: string;
  elementIdentifier: string;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  singularQuestion: boolean;
  submissionSettings?: boolean;
  pointSettings?: boolean;
  pointsAdjustment?: boolean;
  helpHints?: boolean;
  onClickRemove: () => void;
  elementType: QuestionElementType;
  elementTabs: Record<string, number>;
}

interface IQuestionNameButtonProps {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  singularQuestion: boolean;
  elementIdentifier: string;
  drawerId: string;
  elementTabs: Record<string, number>;
}

interface IQuestionElementSettingsButtonProps {
  index: number;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  expanded: boolean;
  elementTabs: Record<string, number>;
}

export const Controls = (props: IControlsProps): JSX.Element => {
  const {
    index,
    drawerId,
    elementIdentifier,
    expanded,
    setExpanded,
    setActiveTabIndex,
    singularQuestion,
    submissionSettings,
    pointSettings,
    pointsAdjustment,
    helpHints,
    onClickRemove,
    elementTabs,
  } = props;
  const { t } = useTranslation();

  const expandTabPanel = (panelIndex: number, toggle?: boolean) => {
    setActiveTabIndex(panelIndex);
    setExpanded(toggle ? !expanded : true);
  };

  const PREVIEW_DEFAULT_INDEX = 0;

  return (
    <>
      {submissionSettings && elementTabs.submissions ? (
        <Tooltip content={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.CONTROL_TOOLTIPS.SUBMISSION_SETTINGS')}>
          <IconButton
            icon={<LibraryAddCheckIcon />}
            color={ButtonColor.secondary}
            aria-label={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.SUBMISSION_SETTINGS') || 'translate me'}
            variant={ButtonVariant.link}
            onClick={() => expandTabPanel(elementTabs.submissions)}
            testId={`question${index}SubmissionsOverride`}
          />
        </Tooltip>
      ) : null}
      {pointSettings && elementTabs.points ? (
        <Tooltip content={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.CONTROL_TOOLTIPS.POINT_SETTINGS')}>
          <IconButton
            icon={<GradeIcon />}
            color={ButtonColor.secondary}
            aria-label={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.POINT_SETTINGS') || 'translate me'}
            variant={ButtonVariant.link}
            onClick={() => expandTabPanel(elementTabs.points)}
          />
        </Tooltip>
      ) : null}
      {pointsAdjustment && elementTabs.pointsAdjustments ? (
        <Tooltip content={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.CONTROL_TOOLTIPS.POINTS_ADJUSTMENT')}>
          <IconButton
            icon={<AutoAwesomeIcon />}
            color={ButtonColor.secondary}
            aria-label={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.POINTS_ADJUSTMENT') || 'translate me'}
            variant={ButtonVariant.link}
            onClick={() => expandTabPanel(elementTabs.pointsAdjustments)}
            testId={`question${index}PointsAdjustment`}
          />
        </Tooltip>
      ) : null}
      {helpHints && elementTabs.helpHints ? (
        <Tooltip content={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.CONTROL_TOOLTIPS.HELP_HINTS')}>
          <IconButton
            icon={<HelpCenterIcon />}
            color={ButtonColor.secondary}
            aria-label={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.HELP_HINTS') || 'translate me'}
            variant={ButtonVariant.link}
            onClick={() => expandTabPanel(elementTabs.helpHints)}
            testId={`question${index}helpHintsOverride`}
          />
        </Tooltip>
      ) : null}
      <IconButton
        onClick={onClickRemove}
        icon={<DeleteIcon />}
        color={ButtonColor.secondary}
        testId={`question_${index}_remove`}
        aria-label={
          t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_CONTROLS.REMOVE', {
            count: singularQuestion ? 1 : 0,
            elementIdentifier,
          }) || 'translate me'
        }
        variant={ButtonVariant.link}
      />
      <IconButton
        aria-controls={drawerId}
        aria-expanded={expanded}
        icon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        color={ButtonColor.secondary}
        testId={`question_${index}_expand`}
        aria-label={
          t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_CONTROLS.EXPAND', {
            count: singularQuestion ? 1 : 0,
            elementIdentifier,
            context: expanded ? 'collapse' : 'expand',
          }) || 'translate me'
        }
        variant={ButtonVariant.link}
        onClick={() =>
          expandTabPanel(elementTabs.questionPreview ? elementTabs.questionPreview : PREVIEW_DEFAULT_INDEX, true)
        }
      />
    </>
  );
};

export const QuestionNameControls = (props: IControlsProps): JSX.Element => {
  const {
    index,
    drawerId,
    elementIdentifier,
    expanded,
    setExpanded,
    setActiveTabIndex,
    elementType,
    singularQuestion,
    elementTabs,
  } = props;

  return (
    <>
      <QuestionNameButton
        expanded={expanded}
        setExpanded={setExpanded}
        index={index}
        elementIdentifier={elementIdentifier}
        singularQuestion={singularQuestion}
        drawerId={drawerId}
        setActiveTabIndex={setActiveTabIndex}
        elementTabs={elementTabs}
      />
      {elementType == QuestionElementType.MASTERY_SET ? (
        <>
          <QuestionElementSettingsButton
            index={index}
            setExpanded={setExpanded}
            setActiveTabIndex={setActiveTabIndex}
            elementTabs={elementTabs}
            expanded={expanded}
          />
        </>
      ) : null}
    </>
  );
};

const QuestionNameButton = (props: IQuestionNameButtonProps): JSX.Element => {
  const {
    expanded,
    setExpanded,
    setActiveTabIndex,
    index,
    singularQuestion,
    elementIdentifier,
    drawerId,
    elementTabs,
  } = props;

  const { t } = useTranslation();

  const handleClick = () => {
    setExpanded(!expanded);
    setActiveTabIndex(elementTabs.questionPreview);
  };
  return (
    <Button
      aria-expanded={expanded}
      aria-controls={drawerId}
      color={ButtonColor.secondary}
      onClick={handleClick}
      testId={`question_${index}_name_button`}
      textTransform={ButtonTextTransform.none}
      variant={ButtonVariant.link}
    >
      <VisuallyHidden>
        {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.COLUMN.QUESTIONS', { count: singularQuestion ? 1 : 0 })}{' '}
      </VisuallyHidden>
      {elementIdentifier}
    </Button>
  );
};

const QuestionElementSettingsButton = (props: IQuestionElementSettingsButtonProps): JSX.Element => {
  const { index, setExpanded, setActiveTabIndex, elementTabs, expanded } = props;
  const { t } = useTranslation();
  const expandTabPanel = (panelIndex: number): void => {
    setActiveTabIndex(panelIndex);
    setExpanded(true);
  };

  return (
    <Tooltip
      content={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.CONTROL_TOOLTIPS.MASTERY_SET_SETTINGS')}
      position={EnumTooltipPosition.top}
    >
      <IconButton
        icon={<SettingsIcon />}
        testId={`question_${index}_question_element_settings`}
        aria-label={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.MASTERY_SET_SETTINGS') || 'translate_me'}
        aria-expanded={expanded}
        onClick={() => expandTabPanel(elementTabs.masterySetSettings)}
        variant={ButtonVariant.link}
        color={ButtonColor.secondary}
      />
    </Tooltip>
  );
};

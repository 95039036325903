import styled from '@emotion/styled';

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-direction: space-between;
`;

export const InputWithLeftLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 1.5rem;
  label {
    width: 25rem;
    font-weight: 400;
    margin-left: 1.5rem;
  }
`;

export const InputWithPercent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 8.125rem;
  div {
    margin-right: 0.2rem;
  }
`;

export const Percent = styled.span`
  height: 3rem;
`;

export const MagmaInputContainerStyle = { width: '6.25rem' };

export const MagmaInputMessageStyle = { width: '25rem' };

export const VisuallyHiddenSpan = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  position: absolute;
  overflow: hidden;
  top: auto;
  white-space: nowrap;
  width: 1px;
`;

import React, { useEffect, useState } from 'react';
import { Input, InputProps, InputType } from 'react-magma-dom';

export interface StandardErrorFormat {
  error: boolean;
  message: string;
}

interface InputAutocorrectProps {
  value: string;
  validationError: StandardErrorFormat; // error state in StandardErrorFormat
  validator: (value: string) => StandardErrorFormat;
  autocorrect?: () => string; // how to replace a user's invalid entry with a valid one
  onValidChange?: (value: string) => void; // additional processing for valid entries (ex: dispatching other state changes)
  dependency?: any;
}

export const InputAutocorrect = (props: InputProps & InputAutocorrectProps): JSX.Element => {
  const {
    autocorrect,
    onValidChange = () => {
      return;
    },
    validator,
    dependency,
    ...rest
  } = props;
  const { onChange, onKeyPress, onBlur } = rest;

  const [value, setValue] = useState(props.value);
  const [validationError, setValidationError] = useState(props.validationError);

  const [valueChanged, setValueChanged] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setValidationError(validator(value));
  }, [value, dependency]);

  const updateOrRevert = () => {
    if (!valueChanged) {
      return;
    }
    if (validationError.error) {
      if (autocorrect) {
        const corrected = autocorrect();
        setValue(corrected);
        setValidationError({ error: false, message: '' });
        onValidChange(corrected);
      } else {
        setValue(props.value);
        setValidationError({ error: false, message: '' });
        onValidChange(props.value);
      }
    } else {
      onValidChange(value);
    }
    setValueChanged(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.focus();
    e.preventDefault();
    if (e.target.value !== value) {
      setValue(e.target.value);
      setValueChanged(true);
    }
    if (onChange) {
      onChange(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    updateOrRevert();
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateOrRevert();
      if (onKeyPress) {
        onKeyPress(e);
      }
    }
  };

  return (
    <Input
      {...rest}
      type={InputType.number}
      maxLength={4}
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      onBlur={handleBlur}
      errorMessage={validationError.error ? validationError.message : null}
    />
  );
};

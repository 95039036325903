/* istanbul ignore file */
import { Dispatch, RefObject } from 'react';

interface IAssignmentContextProviderProps {
  reducer: (state: IAssignmentState, action: IActions) => IAssignmentState;
  initialState: IAssignmentState;
  children: React.ReactNode;
}

interface IAssignmentContextProps {
  dispatch: Dispatch<IActions>;
  state: IAssignmentState;
}

type AEErrors<Values> = {
  [K in keyof Values]?: Values[K] extends Record<string, string> ? AEErrors<Values[K]> : string;
};

interface IAccordionState {
  infoOpen: boolean;
  settingsOpen: boolean;
  questionsOpen: boolean;
}

enum AssignmentEditorWorkflow {
  CREATE = 'create',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
}

interface IAssignmentState {
  resetInitialState: boolean;
  accordionState: IAccordionState;
  workflow: AssignmentEditorWorkflow;
  isLoading: boolean;
  isSettingsLoading: boolean;
  isQuestionsLoading: boolean;
  isError: boolean;
  userId: number | null;
  isScheduled: boolean;
  errors: AEErrors<any>;
  assignmentEditorContext: IAssignmentEditorContext;
  env: string;
  assignmentSettings: {
    templateList: ITemplateListItem[];
    selectedTemplateListItem: ITemplateListItem | null;
    currentAssignmentTemplate: IAssignmentTemplate;
    assignmentDynamicInfo: {
      groups: IDynamicInfoItem[];
      categories: IDynamicInfoItem[];
    };
    isCustomTemplate: boolean;
  };
  elementsWithErrors: RefObject<HTMLElement>[];
  questionsData: IQuestionElement[];
}
enum SubmissionType {
  ASSIGNMENT = 'n', //Assignment: Entire assignment must be submitted
  QUESTION = 'q', //Questions: Entire questions must be submitted
  QUESTION_PART = 'y', //Question Parts: New or changed question parts can be submitted individually
}
enum ScoringAnswers { //duplicate to check
  LAST = 'last',
  ASSIGNMENT = 'assignment',
  QUESTION = 'question',
  QUESTION_PART = 'part',
}

enum SaltStatus {
  FULLY_INTEGRATED = 'on',
  LIMITED_INTEGRATED = 'lite',
  NO_INTEGRATION = 'off',
}

enum QuestionNameDisplay {
  FOR_ALL_QUESTIONS = 'y',
  FOR_TEXTBOOK_QUESTIONS = 't',
  NEVER = 'n',
}
enum Randomization {
  SAME_VALUES = 'A', // Use the same values for all students
  DIFFERENT_VALUES = 'P', // Use different values for each student
  AFTER_EVRY_SUBM = 'D', // Require new randomization after every * submissions
  QUESTIONS = 'Q', // Question
  GROUP = 'G', // Group
  O = 'O', // ?
}
const RandomizationValues: { [key: string]: (submissions: number | undefined) => string } = {
  [Randomization.SAME_VALUES]: () => 'Use the same values for all students',
  [Randomization.DIFFERENT_VALUES]: () => 'Use different values for each student',
  [Randomization.AFTER_EVRY_SUBM]: submissions => `Require new randomization after every ${submissions} submissions`,
  [Randomization.QUESTIONS]: () => 'Question',
  [Randomization.GROUP]: () => 'Group',
  [Randomization.O]: () => 'Wrong value',
};
enum SHOW_MY_WORK {
  DISABLED = 'disabled',
  OPTIONAL = 'optional',
  REQUIRED = 'required',
}

enum FormatTips {
  SHOW_TIPS = 'y', // Show answer format tips
  NOT_SHOW_TIPS = 'n', // Do not show answer format tips
  USE_COURSE_SETTING = 'c', // Use course setting
}
const AnswerFormatTips: { [key: string]: string } = {
  [FormatTips.SHOW_TIPS]: 'Show answer format tips',
  [FormatTips.NOT_SHOW_TIPS]: 'Do not show answer format tips',
  [FormatTips.USE_COURSE_SETTING]: 'Use course setting',
};
const Permission: { [key: string]: string } = {
  PRIVATE: 'x', // Private: Only I can edit or schedule the assignment
  PROTECTED: 'r', // Protected: Only I can edit; instructors I give the assignment ID can schedule
  GROUP: 'g', //group ID: number, // Group: Instructors in group can edit or schedule; instructors with the assignment ID can schedule
  /*COPYRIGHT = 'c' - deprecated, // Copyright: Only I can edit; any instructor can schedule (contains copyrighted material)*/
  PUBLIC: 'p', // Public: Only I can edit; any instructor can schedule
};
enum ShowNames {
  ALL = 'y', // For all questions
  NEVER = 'n', // Never
  TEXTBOOK_QUESTIONS = 't', // For textbook questions only (default)
}
enum NullOrOne {
  NULL = '0',
  ONE = '1',
}
enum BoxMode {
  NUMERICAL = 'N',
  MULTIPLE_CHOICE = 'C',
  MULTIPLE_SELECT = 'S',
  FILL_IN_THE_BLANK = 'B',
  ESSAY = 'E',
  SYMBOLIC = 'Q',
  ALGEBRAIC = 'A',
  NUMBER_LINE = 'R',
  FILE_UPLOAD = 'F',
  JAVA = 'J',
  POLL = 'P',
  IMAGE_MAP = 'I',
  GRAPHING = 'G',
}
enum Submission {
  ASSIGNMENT = 'n',
  QUESTION = 'q',
  QUESTION_PART = 'y',
}
enum ShowMyWorkMode {
  DISABLED = 'disabled', // toggle should be disabled and banner should not be showing
  OPTIONAL = 'optional',
  REQUIRED = 'required',
  DEFAULT = 'default',
  WAS_DISABLED = 'was_disabled', // temporary state that we have before saving. Toggle should be disabled and banner should be showing
}
enum TrashableStatus {
  CAN_TRASH = 1, //assignment is trashable
  DEPLOYED_EXTERNALLY = 2, //assignment has been deployed to a section that the user does not have modify access for
  NOT_YOURS = 3, //user is not the author of the assignment
  IN_TRASH = 4, //item is already in the trash bin
}

enum QuestionElementType {
  QUESTION = 'question',
  QUESTION_POOL = 'pool',
  MASTERY_SET = 'mastery',
}

interface ITableItem {
  showAfterDueDate: boolean; //after
  showBeforeDueDate: boolean; //before
  specifiedNumberOfSubmissions: number; //condition
}
interface ISubmissionAndWorkSettings {
  allowedSubmissionsCount: number; //submissions_allowed
  submission: Submission; //submission_type
  scoringAnswers: ScoringAnswers; //grade_which
  showMyWorkMode: ShowMyWorkMode; //show_my_work_mode
  showMyWorkPointValue: number; //show_my_work_value
}
interface IQuestionRandomizationSettings {
  randomization: Randomization; //randomization
  randomizationSubmissionsCount: number; //det_submissions
  showAnswerKey: boolean; //det_key
  showSolution: boolean; //det_solution
}
interface ILearningToolsAndFeedbackSettings {
  showHints: ITableItem; //rich
  showPracticeAnotherVersion: ITableItem; //pav
  showKey: ITableItem; //key
  showSolution: ITableItem; //solution
  showMark: ITableItem; //mark
  showResponses: ITableItem; //responses
  showAssignmentScore: ITableItem; //a_scores
  showQuestionScore: ITableItem; //q_scores
  showQuestionPartScore: ITableItem; //qp_scores
  showGraderComments: ITableItem; //comments
}
interface INumericalToleranceSettings {
  toleranceOverridden: boolean; //override_tolerance
  numericalTolerance: number; //tolerance
  isToleranceInPercent: boolean; //percent_tolerance
  partialCreditForUnitsPercentage: number; //units_credit
}
interface ISignificantFiguresSettings {
  significantFiguresOverridden: boolean; //override_sigfigs
  significantFiguresCheckEnabled: boolean; //sigfigs_on
  significantFiguresHelpIconEnabled: boolean; //sigfigs_help
  isSignificantFiguresHelpIconClickable: boolean; //sigfigs_icon
  significantFiguresTolerance: number; //sigfigs_tolerance
  significantFiguresPartialCreditPercentage: number; //sigfigs_credit
}
interface IDisplayAndSyntaxErrorsSettings {
  syntaxErrorsOverridden: boolean; //override_syntax
  symbolicFormattingHelpIconEnabled: boolean; //symbolic_icon
  answerFormatTips: FormatTips; //format_tips
  questionNameDisplay: ShowNames; //show_names
  isIncorrectSubmissionsWithSyntaxErrorsForNumericalQuestions: boolean; //numerical_syntax
  isIncorrectSubmissionsWithSyntaxErrorsForSymbolicQuestions: boolean; //symbolic_syntax
  isIncorrectSubmissionsWithSyntaxErrorsForAlgebraicQuestions: boolean; //algebraic_syntax
}
interface IAssignmentSettings {
  isPublic: boolean; //public
  authorId: number; //user
  categoryId: number; //category
  categoryName: string; //category_name
  submissionAndWorkSettings: ISubmissionAndWorkSettings;
  questionRandomizationSettings: IQuestionRandomizationSettings;
  displayAllQuestions: boolean; //display_all
  isQuestionOrderRandomized: boolean; //random_order
  assignmentPermissions: string | ''; //permission
  groupId: number; //group
  groupName: string | null; //group_name
  saveIsSubmit: NullOrOne | null; //save_is_submit
  templateFeedback: ILearningToolsAndFeedbackSettings;
  conditionalPointsFormula: string; //point_adjustments
  numericalToleranceSettings: INumericalToleranceSettings;
  significantFiguresSettings: ISignificantFiguresSettings;
  displayAndSyntaxErrorsSettings: IDisplayAndSyntaxErrorsSettings;
  saltStatus: SaltStatus | string; //salt_display
  isAdvanced: boolean;
}
interface IAssignmentTemplate {
  id?: number;
  name: string;
  description: string;
  authorFullName: string;
  settings: IAssignmentSettings;
}
interface IAssignmentEditorContext {
  assignmentInfo: IAssignmentInfo;
  canSaveAssignment: boolean;
  trashableStatus: number;
  canAccessQwsTestingTools: boolean;
  instructorId: number;
  defaultTemplateId: number;
}
interface IVersionHistory {
  date: string;
  user: string;
}
interface IAssignmentInfo {
  id: number;
  name: string;
  code: string;
  instructions: string;
  authorId: number;
  authorName: string;
  authorEmail: string;
  templateId: number;
  categoryId: number;
  isQuestionCached: boolean;
  isModificationLimited: boolean;
  versionHistory: IVersionHistory[];
}

interface IDynamicInfoItem {
  id: string;
  name: string;
}

interface ITemplateListItem {
  id: number;
  name: string;
  description: string;
}

interface IResultSaveAssignmentEditor {
  isRequestSuccessful: boolean;
  message: string;
  messageStatus: string;
  assignmentId: number;
  versionHistory: IVersionHistory[];
}
interface ITrashAssignment {
  assignmentId: number;
  instructorId: number;
}
interface IResultScheduleAssignment {
  isScheduled: boolean;
}
interface IAssignmentTemplateActionResult {
  isRequestSuccessful: boolean;
  templateId: number;
  templateName: string;
}
interface ITranslationItem {
  label: string;
  value: string;
}
//QUESTIONS
interface IQuestionElement {
  elementType: QuestionElementType;
  showMyWorkMode: string;
  showMyWorkPointValue: number;
  type: string;
}

interface IAssignmentQuestion extends IQuestionElement, IFeedbackConfigurable {
  boxes: IBox[];
  isQuestionPartSubmissionRequired: boolean;
  entireAssignmentSubmissionNotAllowed: boolean;
  onlyOneSubmission: boolean;
  numericalToleranceSettings: IQuestionNumericalToleranceSettings;
  question: IQuestion;
  isCxpQuestion: boolean;
  usageData: IUsageData;
}
interface IQuestionSet {
  questions: IAssignmentQuestion[];
  positionAmongSets: number;
}
interface IFeedbackConfigurable {
  feedbackSettings: IQuestionFeedbackSettings;
}
//POOL
interface IQuestionPool extends IQuestionElement, IQuestionSet {
  gradingSettings: IGradingSettings;
  includedNumberOfQuestions: number;
  numberOfQuestions: number;
}
interface IMasterySet extends IQuestionElement, IQuestionSet, IFeedbackConfigurable {
  gradingSettings: IGradingSettings;
  id: number | null;
  hasSubsets?: boolean;
  masteryThreshold: number;
  numberOfQuestions: number;
  subsetSize: number;
}
interface IBox {
  mode: BoxMode;
  name: string;
  gradingSettings: IGradingSettings;
}
interface IConditionalPointsGradingSettings {
  conditionalPointsFormula: string;
  conditionalPointsFormulaExplanation?: string;
  isAdvanced?: boolean;
}
interface IGradingSettings extends IConditionalPointsGradingSettings {
  allowedSubmissionsCount: number | null;
  pointsValue: number;
}
interface IQuestionFeedbackSettings {
  customHintText: string | null;
  showCustomHintBeforeDueDateAfterNumberOfSubmissions: number | null;
  showHintsBeforeDueDateAfterNumberOfSubmissions: number | null;
  showKeyBeforeDueDateAfterNumberOfSubmissions: number | null;
  showMarkBeforeDueDateAfterNumberOfSubmissions: number | null;
  showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions: number | null;
  showResponsesBeforeDueDateAfterNumberOfSubmissions: number | null;
  showSolutionBeforeDueDateAfterNumberOfSubmissions: number | null;
}
type QuestionOptionalFeedbackSettings = {
  [property in keyof IQuestionFeedbackSettings]?: IQuestionFeedbackSettings[property];
};
interface IQuestionNumericalToleranceSettings {
  isToleranceInPercent: boolean; //percent_tolerance
  numericalTolerance: number | null; //tolerance
}
interface IQuestion {
  id: number;
  name: string;
}
interface IUsageData {
  answerMedianTimeInSeconds: number;
  estimationStatus: string;
  hasEstimate: boolean;
  studentCount: number;
  timeToAnswerInMinutes: string;
}
interface IScores {
  showAssignmentScore: ITableItem; //a_scores
  showQuestionScore: ITableItem; //q_scores
  showQuestionPartScore: ITableItem; //qp_scores
  showGraderComments: ITableItem; //comments
}
//QUESTION FROM QUESTION BROWSER
interface IQuestionBrowserElement {
  elementType: QuestionElementType;
}
interface IQuestionBrowserQuestion extends IQuestionBrowserElement {
  id: number;
}
interface IQuestionBrowserQuestionPool extends IQuestionBrowserElement {
  numberOfQuestions: number;
  includedNumberOfQuestions: number;
  questions: IQuestionBrowserQuestion[];
}
interface IQuestionBrowserMasterySet extends IQuestionBrowserElement {
  id: number | null;
  masteryThreshold: number;
  numberOfQuestions: number;
  questions: IQuestionBrowserQuestion[];
  subsetSize: number;
}
//Points
interface IQuestionElementPoints {
  questionPartLevelPoints: number[];
}
// ACTIONS
// ===========================
type IActions =
  | ISetAssignmentTemplatesData
  | ISetAssignmentTemplate
  | ISetAssignmentEditorContext
  | ISetAssignmentName
  | ISetAssignmentInstructions
  | ISetAssignmentCode
  | ISetAssignmentEditorWorkflow
  | ISetVersionHistory
  | ISetIsLoading
  | ISetIsSettingsLoading
  | ISetIsQuestionsLoading
  | ISetIsError
  | ISetAssignmentDynamicInfo
  | ISetInitialAssignmentTemplate
  | ISetRefWithError
  | IRemoveRefWithError
  | ISetQuestionsData
  | ISetQuestionElementSubmissionSettings
  | ISetQuestionElementShowMyWorkSettings
  | ISetAssignmentAndQuestionsShowMyWorkSettings
  | ISetQuestionElementPoints
  | ISetAllQuestionPoints
  | ISetNumericalToleranceSettings
  | ISetSignificantFiguresSettings
  | ISetLearningToolsAndFeedbackSettings
  | ISetSubmissionAndWorkSettings
  | ISetQuestionRandomizationSettings
  | ISetDisplayAndSyntaxErrorsSettings
  | ISetIsQuestionRandomizedSettings
  | ISetDisplayAllQuestionsSettings
  | ISetAssignmentSettings
  | ISetFieldError
  | ISetResetInitialState
  | ISetAccordionState
  | ISetQuestionElementFeedbackSettings
  | ISetQuestionFeedbackSettings
  | ISetDuplicateAssignmentEditorContext
  | ISetAdjustAllQuestionConditionalPoints
  | ISetPrivacySetting
  | ISetQuestionElementNumericalTolerance
  | ISetQuestionElementPointsAdjustment
  | ISetAssignmentTemplatePointsAdjustment
  | ISetMasterySetSettings
  | ISetIsCustomTemplate;
// ===========================

enum ACTIONTYPES {
  SET_ASSIGNMENT_EDITOR_CONTEXT = 'SET_ASSIGNMENT_EDITOR_CONTEXT',
  SET_ASSIGNMENT_DYNAMIC_INFO = 'SET_ASSIGNMENT_DYNAMIC_INFO',
  SET_ASSIGNMENTS_TEMPLATES_DATA = 'SET_ASSIGNMENTS_TEMPLATES_DATA',
  SET_ASSIGNMENTS_TEMPLATE = 'SET_ASSIGNMENTS_TEMPLATE',
  SET_INITIAL_ASSIGNMENTS_TEMPLATE = 'SET_INITIAL_ASSIGNMENTS_TEMPLATE',
  SET_ASSIGNMENT_NAME = 'SET_ASSIGNMENT_NAME',
  SET_ASSIGNMENT_INSTRUCTIONS = 'SET_ASSIGNMENT_INSTRUCTIONS',
  SET_ASSIGNMENT_CODE = 'SET_ASSIGNMENT_CODE',
  SET_ASSIGNMENT_EDITOR_WORKFLOW = 'SET_ASSIGNMENT_EDITOR_WORKFLOW',
  SET_VERSION_HISTORY = 'SET_VERSION_HISTORY',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_SETTINGS_LOADING = 'SET_IS_SETTINGS_LOADING',
  SET_IS_QUESTIONS_LOADING = 'SET_IS_QUESTIONS_LOADING',
  SET_IS_ERROR = 'SET_IS_ERROR',
  SET_REF_WITH_ERROR = 'SET_REF_WITH_ERROR',
  REMOVE_REF_WITH_ERROR = 'REMOVE_REF_WITH_ERROR',
  SET_IS_CUSTOM_TEMPLATE = 'SET_IS_CUSTOM_TEMPLATE',
  SET_QUESTIONS_DATA = 'SET_QUESTIONS_DATA',
  SET_QUESTION_ELEMENT_SUBMISSION_SETTINGS = 'SET_QUESTION_ELEMENT_SUBMISSION_SETTINGS',
  SET_QUESTION_ELEMENT_SHOW_MY_WORK_SETTINGS = 'SET_QUESTION_ELEMENT_SHOW_MY_WORK_SETTINGS',
  SET_ASSIGNMENT_AND_QUESTIONS_SHOW_MY_WORK_SETTINGS = 'SET_ASSIGNMENT_AND_QUESTIONS_SHOW_MY_WORK_SETTINGS',
  SET_NUMERICAL_TOLERANCE_SETTINGS = 'SET_NUMERICAL_TOLERANCE_SETTINGS',
  SET_SIGNIFICANT_FIGURES_SETTINGS = 'SET_SIGNIFICANT_FIGURES_SETTINGS',
  SET_LEARNING_TOOLS_AND_FEEDBACK_SETTINGS = 'SET_LEARNING_TOOLS_AND_FEEDBACK_SETTINGS',
  SET_QUESTION_ELEMENT_POINTS = 'SET_QUESTION_ELEMENT_POINTS',
  SET_ALL_QUESTION_POINTS = 'SET_ALL_QUESTION_POINTS',
  SET_SUBMISSION_AND_WORK_SETTINGS = 'SET_SUBMISSION_AND_WORK_SETTINGS',
  SET_QUESTION_RANDOMIZATION_SETTINGS = 'SET_QUESTION_RANDOMIZATION_SETTINGS',
  SET_DISPLAY_AND_SYNTAX_ERRORS_SETTINGS = 'SET_DISPLAY_AND_SYNTAX_ERRORS_SETTINGS',
  SET_ASSIGNMENT_SETTINGS = 'SET_ASSIGNMENT_SETTINGS',
  SET_IS_QUESTION_RANDOMIZED_SETTINGS = 'SET_IS_QUESTION_RANDOMIZED_SETTINGS',
  SET_DISPLAY_All_QUESTIONS_SETTINGS = 'SET_DISPLAY_All_QUESTIONS_SETTINGS',
  SET_RESET_INITIAL_STATE = 'SET_RESET_INITIAL_STATE',
  SET_FIELD_ERROR = 'SET_FIELD_ERROR',
  SET_ACCORDION_STATE = 'SET_ACCORDION_STATE',
  SET_QUESTION_ELEMENT_FEEDBACK_SETTINGS = 'SET_QUESTION_ELEMENT_FEEDBACK_SETTINGS',
  SET_QUESTION_FEEDBACK_SETTINGS = 'SET_QUESTION_FEEDBACK_SETTINGS',
  SET_QUESTION_ELEMENT_POINTS_ADJUSTMENT = 'SET_QUESTION_ELEMENT_POINTS_ADJUSTMENT',
  SET_ASSIGNMENT_PRIVACY_SETTING = 'SET_ASSIGNMENT_PRIVACY_SETTING',
  SET_DUPLICATE_ASSIGNMENT_EDITOR_CONTEXT = 'SET_DUPLICATE_ASSIGNMENT_EDITOR_CONTEXT',
  SET_ADJUST_ALL_QUESTION_CONDITIONAL_POINTS = 'SET_ADJUST_ALL_QUESTION_CONDITIONAL_POINTS',
  SET_QUESTION_ELEMENT_NUMERICAL_TOLERANCE = 'SET_QUESTION_ELEMENT_NUMERICAL_TOLERANCE',
  SET_ASSIGNMENT_TEMPLATE_POINTS_ADJUSTMENT = 'SET_ASSIGNMENT_TEMPLATE_POINTS_ADJUSTMENT',
  SET_MASTERY_SET_SETTINGS = 'SET_MASTERY_SET_SETTINGS',
}

interface ISetAssignmentTemplatesData {
  type: ACTIONTYPES.SET_ASSIGNMENTS_TEMPLATES_DATA;
  payload: {
    templateList: ITemplateListItem[];
  };
}
interface ISetAssignmentTemplate {
  type: ACTIONTYPES.SET_ASSIGNMENTS_TEMPLATE;
  payload: IAssignmentTemplate;
}
interface ISetInitialAssignmentTemplate {
  type: ACTIONTYPES.SET_INITIAL_ASSIGNMENTS_TEMPLATE;
  payload: IAssignmentTemplate;
}
interface ISetAssignmentEditorContext {
  type: ACTIONTYPES.SET_ASSIGNMENT_EDITOR_CONTEXT;
  payload: IAssignmentEditorContext;
}
interface ISetAssignmentDynamicInfo {
  type: ACTIONTYPES.SET_ASSIGNMENT_DYNAMIC_INFO;
  payload: { groups: IDynamicInfoItem[]; categories: IDynamicInfoItem[] };
}
interface ISetAssignmentName {
  type: ACTIONTYPES.SET_ASSIGNMENT_NAME;
  payload: string;
}
interface ISetAssignmentInstructions {
  type: ACTIONTYPES.SET_ASSIGNMENT_INSTRUCTIONS;
  payload: string;
}
interface ISetAssignmentCode {
  type: ACTIONTYPES.SET_ASSIGNMENT_CODE;
  payload: string;
}
interface ISetAssignmentEditorWorkflow {
  type: ACTIONTYPES.SET_ASSIGNMENT_EDITOR_WORKFLOW;
  payload: AssignmentEditorWorkflow;
}
interface ISetVersionHistory {
  type: ACTIONTYPES.SET_VERSION_HISTORY;
  payload: IVersionHistory[];
}
interface ISetIsLoading {
  type: ACTIONTYPES.SET_IS_LOADING;
  payload: boolean;
}
interface ISetIsSettingsLoading {
  type: ACTIONTYPES.SET_IS_SETTINGS_LOADING;
  payload: boolean;
}
interface ISetIsQuestionsLoading {
  type: ACTIONTYPES.SET_IS_QUESTIONS_LOADING;
  payload: boolean;
}
interface ISetResetInitialState {
  type: ACTIONTYPES.SET_RESET_INITIAL_STATE;
  payload: boolean;
}
interface ISetIsError {
  type: ACTIONTYPES.SET_IS_ERROR;
  payload: boolean;
}
interface ISetIsCustomTemplate {
  type: ACTIONTYPES.SET_IS_CUSTOM_TEMPLATE;
  payload: boolean;
}
interface ISetRefWithError {
  type: ACTIONTYPES.SET_REF_WITH_ERROR;
  payload: RefObject<HTMLElement>;
}
interface IRemoveRefWithError {
  type: ACTIONTYPES.REMOVE_REF_WITH_ERROR;
  payload: RefObject<HTMLElement>;
}
interface ISetQuestionsData {
  type: ACTIONTYPES.SET_QUESTIONS_DATA;
  payload: IQuestionElement[];
}

interface ISetQuestionElementSubmissionSettings {
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_SUBMISSION_SETTINGS;
  payload: {
    boxIndex?: number;
    elementIndex: number;
    allowedSubmissionsCount: number;
  };
}
interface ISetQuestionElementShowMyWorkSettings {
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_SHOW_MY_WORK_SETTINGS;
  payload: {
    elementIndex: number;
    showMyWorkMode: ShowMyWorkMode;
    showMyWorkPointValue: number;
  };
}
interface ISetAssignmentAndQuestionsShowMyWorkSettings {
  type: ACTIONTYPES.SET_ASSIGNMENT_AND_QUESTIONS_SHOW_MY_WORK_SETTINGS;
  payload: ShowMyWorkMode;
}
interface ISetQuestionElementPointsAdjustment {
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_POINTS_ADJUSTMENT;
  payload: {
    elementIndex: number;
    conditionalPointsFormula: string;
    conditionalPointsFormulaExplanation?: string;
    isAdvanced?: boolean;
  };
}

interface ISetAssignmentTemplatePointsAdjustment {
  type: ACTIONTYPES.SET_ASSIGNMENT_TEMPLATE_POINTS_ADJUSTMENT;
  payload: {
    conditionalPointsFormula: string;
    existingTemplate?: boolean;
  };
}

interface ISetAdjustAllQuestionConditionalPoints {
  type: ACTIONTYPES.SET_ADJUST_ALL_QUESTION_CONDITIONAL_POINTS;
  payload: IConditionalPointsGradingSettings;
}

interface ISetNumericalToleranceSettings {
  type: ACTIONTYPES.SET_NUMERICAL_TOLERANCE_SETTINGS;
  payload: INumericalToleranceSettings;
}
interface ISetSignificantFiguresSettings {
  type: ACTIONTYPES.SET_SIGNIFICANT_FIGURES_SETTINGS;
  payload: ISignificantFiguresSettings;
}
interface ISetLearningToolsAndFeedbackSettings {
  type: ACTIONTYPES.SET_LEARNING_TOOLS_AND_FEEDBACK_SETTINGS;
  payload: ILearningToolsAndFeedbackSettings;
}
interface ISetSubmissionAndWorkSettings {
  type: ACTIONTYPES.SET_SUBMISSION_AND_WORK_SETTINGS;
  payload: ISubmissionAndWorkSettings;
}
interface ISetAllQuestionPoints {
  type: ACTIONTYPES.SET_ALL_QUESTION_POINTS;
  payload: IQuestionElementPoints[];
}
interface ISetQuestionElementPoints {
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_POINTS;
  payload: {
    elementIndex: number;
    points: number[];
  };
}
interface ISetQuestionRandomizationSettings {
  type: ACTIONTYPES.SET_QUESTION_RANDOMIZATION_SETTINGS;
  payload: IQuestionRandomizationSettings;
}
interface ISetDisplayAndSyntaxErrorsSettings {
  type: ACTIONTYPES.SET_DISPLAY_AND_SYNTAX_ERRORS_SETTINGS;
  payload: IDisplayAndSyntaxErrorsSettings;
}
interface ISetAssignmentSettings {
  type: ACTIONTYPES.SET_ASSIGNMENT_SETTINGS;
  payload: IAssignmentSettings;
}
interface ISetIsQuestionRandomizedSettings {
  type: ACTIONTYPES.SET_IS_QUESTION_RANDOMIZED_SETTINGS;
  payload: boolean;
}
interface ISetDisplayAllQuestionsSettings {
  type: ACTIONTYPES.SET_DISPLAY_All_QUESTIONS_SETTINGS;
  payload: boolean;
}
interface ISetFieldError {
  type: ACTIONTYPES.SET_FIELD_ERROR;
  payload: { field: string; value?: string };
}
interface ISetAccordionState {
  type: ACTIONTYPES.SET_ACCORDION_STATE;
  payload: IAccordionState;
}
interface ISetQuestionElementFeedbackSettings {
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_FEEDBACK_SETTINGS;
  payload: {
    elementIndex: number;
    feedbackSettings: QuestionOptionalFeedbackSettings;
  };
}
interface ISetQuestionFeedbackSettings {
  type: ACTIONTYPES.SET_QUESTION_FEEDBACK_SETTINGS;
  payload: QuestionOptionalFeedbackSettings;
}

interface ISetQuestionElementNumericalTolerance {
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_NUMERICAL_TOLERANCE;
  payload: {
    elementIndex: number;
    numericalToleranceSettings: IQuestionNumericalToleranceSettings;
  };
}
interface ISetQuestionElementNumericalTolerance {
  type: ACTIONTYPES.SET_QUESTION_ELEMENT_NUMERICAL_TOLERANCE;
  payload: {
    elementIndex: number;
    numericalToleranceSettings: IQuestionNumericalToleranceSettings;
  };
}
interface ISetDuplicateAssignmentEditorContext {
  type: ACTIONTYPES.SET_DUPLICATE_ASSIGNMENT_EDITOR_CONTEXT;
  payload: IAssignmentEditorContext;
}
interface ISetPrivacySetting {
  type: ACTIONTYPES.SET_ASSIGNMENT_PRIVACY_SETTING;
  payload: {
    privacySetting: string;
    groupId: number;
  };
}
interface ISetMasterySetSettings {
  type: ACTIONTYPES.SET_MASTERY_SET_SETTINGS;
  payload: {
    index: number;
    threshold: number;
    subsetSize: number;
    resetId?: boolean;
  };
}
export {
  ACTIONTYPES,
  AEErrors,
  AnswerFormatTips,
  QuestionElementType,
  AssignmentEditorWorkflow,
  BoxMode,
  FormatTips,
  IAccordionState,
  IActions,
  IAssignmentState,
  IQuestionElement,
  IAssignmentContextProps,
  IAssignmentContextProviderProps,
  IAssignmentEditorContext,
  IAssignmentInfo,
  IVersionHistory,
  IAssignmentQuestion,
  IAssignmentSettings,
  IAssignmentTemplate,
  IAssignmentTemplateActionResult,
  IBox,
  IConditionalPointsGradingSettings,
  IDisplayAndSyntaxErrorsSettings,
  IDynamicInfoItem,
  IFeedbackConfigurable,
  IGradingSettings,
  ILearningToolsAndFeedbackSettings,
  IMasterySet,
  INumericalToleranceSettings,
  IQuestion,
  IQuestionBrowserElement,
  IQuestionBrowserMasterySet,
  IQuestionBrowserQuestion,
  IQuestionBrowserQuestionPool,
  IQuestionElementPoints,
  IQuestionFeedbackSettings,
  IQuestionNumericalToleranceSettings,
  IQuestionPool,
  IQuestionRandomizationSettings,
  IQuestionSet,
  IRemoveRefWithError,
  IResultSaveAssignmentEditor,
  IResultScheduleAssignment,
  IScores,
  ISetAccordionState,
  ISetAdjustAllQuestionConditionalPoints,
  ISetAllQuestionPoints,
  ISetAssignmentAndQuestionsShowMyWorkSettings,
  ISetAssignmentDynamicInfo,
  ISetAssignmentEditorContext,
  ISetAssignmentTemplate,
  ISetAssignmentTemplatesData,
  ISetAssignmentCode,
  ISetAssignmentInstructions,
  ISetAssignmentName,
  ISetAssignmentSettings,
  ISetAssignmentTemplatePointsAdjustment,
  ISetAssignmentEditorWorkflow,
  ISetVersionHistory,
  ISetDisplayAllQuestionsSettings,
  ISetDisplayAndSyntaxErrorsSettings,
  ISetDuplicateAssignmentEditorContext,
  ISetFieldError,
  ISetInitialAssignmentTemplate,
  ISetIsCustomTemplate,
  ISetIsError,
  ISetIsLoading,
  ISetIsQuestionRandomizedSettings,
  ISetIsQuestionsLoading,
  ISetIsSettingsLoading,
  ISetLearningToolsAndFeedbackSettings,
  ISetMasterySetSettings,
  ISetNumericalToleranceSettings,
  ISetPrivacySetting,
  ISetQuestionsData,
  ISetQuestionElementFeedbackSettings,
  ISetQuestionElementNumericalTolerance,
  ISetQuestionElementPointsAdjustment,
  ISetQuestionElementPoints,
  ISetQuestionElementShowMyWorkSettings,
  ISetQuestionElementSubmissionSettings,
  ISetQuestionFeedbackSettings,
  ISetQuestionRandomizationSettings,
  ISetRefWithError,
  ISetResetInitialState,
  ISetSignificantFiguresSettings,
  ISetSubmissionAndWorkSettings,
  ISignificantFiguresSettings,
  ISubmissionAndWorkSettings,
  ITableItem,
  ITemplateListItem,
  ITranslationItem,
  ITrashAssignment,
  IUsageData,
  Permission,
  QuestionNameDisplay,
  QuestionOptionalFeedbackSettings,
  Randomization,
  RandomizationValues,
  SHOW_MY_WORK,
  SaltStatus,
  ScoringAnswers,
  ShowMyWorkMode,
  ShowNames,
  Submission,
  SubmissionType,
  TrashableStatus,
};

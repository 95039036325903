import React, { useState } from 'react';
import styled from '@emotion/styled';
import { magma } from 'react-magma-dom';

interface IContainerStyle {
  margin?: string;
}

interface IButtonHelperTextWrapperProps {
  helperMessage?: string;
  icon?: JSX.Element;
  children: React.ReactNode;
  testId?: string;
  containerStyle?: IContainerStyle;
}

const HelperMessageContainer = styled.div`
  display: flex;
  align-self: start;
  align-items: left;
`;

const Container = styled.div<{ containerStyle?: IContainerStyle }>`
  margin: ${({ containerStyle }) => (containerStyle?.margin ? containerStyle.margin : '')};
`;
const IconDisplay = styled.span`
  display: inline-block;
  margin-top: 0.025rem;
  margin-right: 0.1rem;
`;
const MessageDisplay = styled.span<{ hasIcon: boolean }>`
  font-size: 0.875rem;
  color: ${magma.colors.neutral03};
  margin-top: ${({ hasIcon }) => (hasIcon ? '' : '-0.1rem')};
`;

export const ButtonHelperTextWrapper = (props: IButtonHelperTextWrapperProps): JSX.Element => {
  const { helperMessage, icon, children, containerStyle, testId = 'button_container' } = props;
  const [hasIcon] = useState<boolean>(typeof icon !== 'undefined');
  return (
    <Container containerStyle={containerStyle} data-testid={testId}>
      {children}
      {helperMessage ? (
        <HelperMessageContainer>
          {hasIcon ? <IconDisplay>{icon}</IconDisplay> : null}
          <MessageDisplay hasIcon={hasIcon}>{helperMessage}</MessageDisplay>
        </HelperMessageContainer>
      ) : null}
    </Container>
  );
};

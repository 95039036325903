import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { AlertVariant, Button, ButtonColor, Toast } from 'react-magma-dom';
import { useTranslation } from 'react-i18next';
import { ButtonHelperTextWrapper } from 'sharedComponents/ButtonHelperTextWrapper/ButtonHelperTextWrapper';
import {
  IAssignmentQuestion,
  IQuestionElement,
  IQuestionPool,
  QuestionElementType,
} from 'context/AssignmentContextProvider/types';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';

const ButtonAndMessageDisplay = styled.div`
  display: flex;
  flex-direction: column;
`;

const marginButtonForAdvancedMode = {
  margin: '6.75rem 0 0 1.5rem',
};

const marginButtonForStandardMode = {
  margin: '3.75rem 0 0 1.5rem',
};

interface IAdjustAllQuestions {
  adjustAllQuestionConditionalPoints: () => void;
  questionElementIndex: number;
}

export const AdjustAllQuestionsButton = (props: IAdjustAllQuestions): JSX.Element => {
  const { t } = useTranslation();
  const { adjustAllQuestionConditionalPoints, questionElementIndex } = props;
  const {
    state: { questionsData },
  } = useAssignmentContext();
  const getIsAdvanced = (questionElement: IQuestionElement): boolean => {
    switch (questionElement.elementType) {
      case QuestionElementType.QUESTION:
        return (
          (questionElement as IAssignmentQuestion).boxes[0].gradingSettings.conditionalPointsFormula === '' ||
          (questionElement as IAssignmentQuestion).boxes[0].gradingSettings.isAdvanced ||
          false
        );
      case QuestionElementType.QUESTION_POOL:
        return (
          (questionElement as IQuestionPool).gradingSettings.conditionalPointsFormula === '' ||
          (questionElement as IQuestionPool).gradingSettings.isAdvanced ||
          false
        );
      default:
        return false;
    }
  };

  const [showToast, updateShowToast] = useState(false);
  const [toastMessageAndVariant, setToastMessageAndVariant] = useState({ message: '', variant: '' });
  const [isAdvanced, setIsAdvanced] = useState(getIsAdvanced(questionsData[questionElementIndex]));

  useEffect(() => {
    setIsAdvanced(getIsAdvanced(questionsData[questionElementIndex]));
  }, [questionsData]);
  const handleClick = () => {
    adjustAllQuestionConditionalPoints();
    setToastMessageAndVariant({
      message: t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.APPLY_TO_ALL_QUESTIONS'),
      variant: AlertVariant.success,
    });
    updateShowToast(true);
  };
  return (
    <>
      <ButtonAndMessageDisplay style={isAdvanced ? marginButtonForAdvancedMode : marginButtonForStandardMode}>
        <ButtonHelperTextWrapper
          helperMessage={t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.APPLY_ADJUST_ALL_QUESTIONS_HELPER_MESSAGE')}
        >
          <Button
            testId={`adjustAllButton_${questionElementIndex}`}
            onClick={handleClick}
            color={ButtonColor.secondary}
            aria-label={t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.APPLY_ADJUST_ALL_QUESTIONS_ARIA_LABEL')}
          >
            {t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.ADJUST_ALL_QUESTIONS')}
          </Button>
        </ButtonHelperTextWrapper>
      </ButtonAndMessageDisplay>
      {showToast ? (
        <Toast
          role="alert"
          onDismiss={() => updateShowToast(false)}
          variant={toastMessageAndVariant.variant as AlertVariant}
          testId={'successAlert'}
        >
          {toastMessageAndVariant.message}
        </Toast>
      ) : null}
    </>
  );
};

import React from 'react';

const HeaderStyle: React.CSSProperties = {
  color: '#003865',
  fontFamily: "'Open Sans', Helvetica, sans-serif",
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '28px',
  lineHeight: '40px',
  marginLeft: '30px',
};

const HorizontalLineStyle: React.CSSProperties = {
  borderBottom: '0.06rem solid #8F8F8F',
};

const CxpToolHeader = (): JSX.Element => {
  return (
    <>
      <div style={HorizontalLineStyle}>
        <h2 id="cxpToolHeaderId" style={HeaderStyle}>
          CXP items management tool
        </h2>
      </div>
    </>
  );
};

export default CxpToolHeader;

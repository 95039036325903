import React from 'react';

import styled from '@emotion/styled';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AlertVariant, Banner, Button, Card, CardBody, CardHeading } from 'react-magma-dom';

import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { untrashAssignment } from 'facultyComponents/assignmentEditor/apiHelpers';

const RestoreButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AssignmentEditorTrashedAssignment = (): JSX.Element => {
  const {
    state: {
      userId,
      assignmentEditorContext: {
        assignmentInfo: { authorId, id, name },
      },
    },
  } = useAssignmentContext();
  const { t } = useTranslation();
  const history = useHistory();

  const userCanRestore = (): boolean => userId === authorId;

  const handleRestoreAssignment = async () => {
    await untrashAssignment(id, userId);
    // Refreshing the page is not ideal, however we rely on external data at
    // such a high level and to such a degree the added cost of reloading is
    // better than the complexity and risk of defects associated with more
    // targeted state changes at this time.
    history.go(0);
  };

  return userCanRestore() ? (
    <Card testId="trashedAssignmentUnavailableAuthor">
      <CardBody>
        <CardHeading>{t('ASSIGNMENT_EDITOR.TRASHED_ASSIGNMENT.HEADING', { name })}</CardHeading>
        <p>{t('ASSIGNMENT_EDITOR.TRASHED_ASSIGNMENT.UNAVAILABLE_MESSAGE_AUTHOR')}</p>
        <p>
          <Trans t={t}>ASSIGNMENT_EDITOR.TRASHED_ASSIGNMENT.HOW_TO_RESTORE_MESSAGE</Trans>
        </p>
        <RestoreButtonContainer>
          <Button testId="untrashAssignmentButton" onClick={handleRestoreAssignment}>
            {t('ASSIGNMENT_EDITOR.TRASHED_ASSIGNMENT.RESTORE_BUTTON')}
          </Button>
        </RestoreButtonContainer>
      </CardBody>
    </Card>
  ) : (
    <Banner variant={AlertVariant.danger} testId="trashedAssignmentUnavailableOthers">
      {t('ASSIGNMENT_EDITOR.TRASHED_ASSIGNMENT.UNAVAILABLE_MESSAGE_OTHERS', { name })}
    </Banner>
  );
};

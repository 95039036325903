/* istanbul ignore file */
//todo: add test code, once we have our first end point, until then ignore
type FetcherType = (input: RequestInfo, init: RequestInit) => Promise<Response>;

/**
 * Wrapper for http request initiator,
 * 1. Gives us an advantage to replace native fetch with third party library
 * 2. Without changing everywhere in our codebase
 *
 * @param input: RequestInfo
 * @param init: RequestInit
 */
const fetcher: FetcherType = async (input: RequestInfo, init: RequestInit): Promise<Response> => {
  return fetch(input, init);
};

export { fetcher as default, FetcherType };

/* istanbul ignore file */
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Root as AssignmentRoot } from './pages/assignments/Root';
import { Root as CxpQuestionRoot } from './pages/questions/cxp/Root';
import { Root as ExtensionsRoot } from './pages/extensions/Root';
import UnknownPage from './pages/unknown/UnknownRoot';

import AppProvider from './AppProvider';
import { AuthorizedElement } from 'sharedComponents/authorization/AuthoizedElement';
import DataFetchProvider from './context/data';
import { fetchDataFor } from './service';
import { Privilege } from 'sharedComponents/authorization/AuthorizationStatus';

const App = (): ReactElement => {
  return (
    <DataFetchProvider fetcher={fetchDataFor}>
      <AppProvider>
        <Switch>
          <Route path="/assignment">
            <AuthorizedElement privilege={Privilege.REACT_ASSIGNMENT_EDITOR}>
              <AssignmentRoot />
            </AuthorizedElement>
          </Route>
          <Route path="/questions/cxp">
            <AuthorizedElement privilege={Privilege.CXP_TOOL}>
              <CxpQuestionRoot />
            </AuthorizedElement>
          </Route>
          <Route path="/extensions">
            <ExtensionsRoot />
          </Route>
          <Route path="*">
            <UnknownPage />
          </Route>
        </Switch>
      </AppProvider>
    </DataFetchProvider>
  );
};
export default App;

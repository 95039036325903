import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import {
  Banner,
  ButtonSize,
  ButtonVariant,
  EnumTooltipPosition,
  Hyperlink,
  IconButton,
  Tooltip,
} from 'react-magma-dom';
import { CrossIcon } from 'react-magma-icons';

interface IProps {
  userName: string;
  fullName: string;
  schoolName: string;
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const BannerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StopMasqueradeButton = styled.div`
  display: flex;
  align-items: center;
  div {
    button {
      &:not(:disabled):hover {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.darkGrey};
      }
    }
  }
`;

const MasqueradedUserInfo = styled.span`
  fontweight: 'bold';
`;

const MasqueradeHeader = (props: IProps): ReactElement => {
  const { userName, fullName, schoolName } = props;

  return (
    <Banner testId="masqueradeHeader">
      <Wrapper>
        <BannerInfo data-testid="masqueradeInfo">
          <MasqueradedUserInfo>Masquerading as {userName}</MasqueradedUserInfo>
          <span>
            {fullName} - {schoolName}
          </span>
        </BannerInfo>
        <StopMasqueradeButton>
          <Tooltip isInverse position={EnumTooltipPosition.left} content="Stop Masquerading">
            <Hyperlink to={'/v4cgi/faculty.pl?action=admin/home&subaction=unmasq'}>
              <IconButton
                isInverse
                aria-label="Stop Masquerading"
                icon={<CrossIcon />}
                variant={ButtonVariant.outline}
                size={ButtonSize.small}
              />
            </Hyperlink>
          </Tooltip>
        </StopMasqueradeButton>
      </Wrapper>
    </Banner>
  );
};

export default MasqueradeHeader;

import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

let AppRoot = App;
const basename = '/app';

if (process.env.NODE_ENV === 'development') {
  // Enable hot module reload for dev builds
  const { hot } = require('react-hot-loader');
  AppRoot = hot(module)(App);

  // react-axe logs accessibility issues to the console. It adds weight and uses resources, Only use in dev mode!!
  // This block is removed with dead code elimination in production builds
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(
  <BrowserRouter basename={basename}>
    <AppRoot />
  </BrowserRouter>,
  document.getElementById('app')
);

/* istanbul ignore file */
import axios from 'axios';

const instance = axios.create({
  baseURL: window.location.origin,
});

interface IUserFeatureSetting {
  userId: number;
  featureName: string;
  enabled: boolean;
}

interface IWalkMeConfig {
  walkmeUrl?: string;
  isWalkmeEnabled: boolean;
}

const getWalkMeConfig = (): Promise<{
  data: { result: IWalkMeConfig; status: number };
}> => {
  return instance.get(`wa-ui-services/walkme/config`);
};

const getUnreadMessagesCounter = (
  userId: number | null
): Promise<{ data: { result: { unreadMessagesCount: string } }; status: number }> => {
  return instance.get(`wa-ui-services/private-messages/user/${userId}/unread-messages-count`);
};

const setRestrictedFeatures = (
  userFeatureSetting: IUserFeatureSetting
): Promise<{
  data: { result: { [userId: number]: { string: boolean } }; status: number };
}> => {
  return instance.put(`wa-ui-services/restricted-features/user-settings`, userFeatureSetting);
};

export { getUnreadMessagesCounter, getWalkMeConfig, IUserFeatureSetting, IWalkMeConfig, setRestrictedFeatures };

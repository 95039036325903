import { IAssignmentState, IQuestionElement } from 'context/AssignmentContextProvider/types';
import {
  isAssignmentQuestion,
  isQuestionPool,
  getQuestionElementGradingSettings,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';

// Example currentAssignmentTemplate string format: preset:deduct_credit_subs:5:6:true
// "preset" -> some global variable,
// "deduct_credit_subs" -> adjustment mode,
// "5" -> amount to add or subtract from the score,
// "6" -> time (or number of events) threshold for triggering the adjustment,
// ":true" - optional indication that the amount should be deducted (default is to add)

export const PresetConditionalPoints = {
  NONE: {
    preset: true,
    mode: 'none',
    deduct: false,
    assignmentLevelCompatible: true,
  },
  SUBMISSION_PENALTY: {
    preset: true,
    mode: 'deduct_credit_subs',
    deduct: true,
    assignmentLevelCompatible: true,
  },
  EARLY_SUBMISSION_BONUS: {
    preset: true,
    mode: 'assign_credit_subs',
    deduct: false,
    assignmentLevelCompatible: true,
  },
  ASSIGN_EXTRA_CREDIT: {
    preset: true,
    mode: 'assign_credit_extra',
    deduct: false,
    assignmentLevelCompatible: false,
  },
  AWARD_EVERYONE_CREDIT: {
    preset: true,
    mode: 'assign_credit_incorrect',
    deduct: false,
    assignmentLevelCompatible: false,
  },
  SET_AT_QUESTION_LEVEL: {
    preset: true,
    mode: 'set_at_question_level',
    deduct: false,
    assignmentLevelCompatible: true,
  },
};

export interface IPresetConditionalPointSettings {
  preset?: boolean;
  mode: string | undefined;
  deduct: boolean | undefined;
  assignmentLevelCompatible?: boolean;
}

export const getPresetFromSettings = (
  settings: IPresetConditionalPointSettings
): keyof typeof PresetConditionalPoints => {
  const found = Object.keys(PresetConditionalPoints).find(key => {
    const type = PresetConditionalPoints[key as keyof typeof PresetConditionalPoints];
    const deduct = settings.deduct ? settings.deduct : false;
    if (settings.assignmentLevelCompatible) {
      return (
        type.mode === settings.mode &&
        type.deduct === deduct &&
        type.assignmentLevelCompatible === settings.assignmentLevelCompatible
      );
    }
    return type.mode === settings.mode && type.deduct === deduct;
  });
  return found ? (found as keyof typeof PresetConditionalPoints) : ('NONE' as keyof typeof PresetConditionalPoints);
};

export interface IConditionalPointSettings extends IPresetConditionalPointSettings {
  amount?: number | undefined;
  time?: number | undefined;
}

export interface IPresetDefaults {
  [type: string]: IConditionalPointSettings;
}

export const PresetDefaults: IPresetDefaults = {
  NONE: {
    ...PresetConditionalPoints.NONE,
    amount: 0,
  },
  SUBMISSION_PENALTY: {
    ...PresetConditionalPoints.SUBMISSION_PENALTY,
    amount: 1,
    time: 1,
  },
  EARLY_SUBMISSION_BONUS: {
    ...PresetConditionalPoints.EARLY_SUBMISSION_BONUS,
    amount: 1,
    time: 1,
  },
  ASSIGN_EXTRA_CREDIT: {
    ...PresetConditionalPoints.ASSIGN_EXTRA_CREDIT,
    amount: 1,
  },
  AWARD_EVERYONE_CREDIT: {
    ...PresetConditionalPoints.AWARD_EVERYONE_CREDIT,
    amount: 100,
  },
  SET_AT_QUESTION_LEVEL: {
    ...PresetConditionalPoints.SET_AT_QUESTION_LEVEL,
    amount: 0,
  },
};

export const getPresetDefaultsFromFormula = (
  formula: string,
  isAssignmentFormula = false,
  assignmentLevelFormula?: string
): IConditionalPointSettings | undefined => {
  if (!formula || formula.trim() === '') {
    if (
      !assignmentLevelFormula ||
      getConditionalPointSettingsFromFormula(assignmentLevelFormula)?.mode ===
        PresetConditionalPoints.SET_AT_QUESTION_LEVEL.mode
    ) {
      return { ...PresetDefaults.NONE };
    }
    formula = assignmentLevelFormula;
  }
  const [preset, mode, , , deduct] = formula.split(':');
  if (preset !== 'preset') {
    return undefined;
  }
  const presetType = getPresetFromSettings({
    mode,
    deduct: deduct === 'true',
    assignmentLevelCompatible: isAssignmentFormula,
  });
  return { ...PresetDefaults[presetType] };
};

export const getConditionalPointSettingsFromFormula = (
  formula: string,
  isAssignmentFormula = false,
  assignmentLevelFormula?: string
): IConditionalPointSettings | undefined => {
  const settings = getPresetDefaultsFromFormula(formula, isAssignmentFormula, assignmentLevelFormula);
  if (settings === undefined || settings.mode === undefined || settings.mode === 'none') {
    return settings;
  }
  const [, , amount, time] = formula.split(':');
  if (settings.amount !== undefined && amount !== undefined) {
    settings.amount = parseFloat(amount);
  }
  if (settings.time !== undefined && time !== undefined) {
    settings.time = parseFloat(time);
  }
  return settings;
};

export const makeConditionalPointsFormula = (settings: IConditionalPointSettings): string => {
  if (!settings || !settings.mode) {
    return '';
  }
  let formula = settings.preset ? `preset:${settings.mode}` : settings.mode;
  if (settings.amount || settings.amount === 0) {
    formula += `:${settings.amount}`;
  }
  if (settings.time) {
    formula += `:${settings.time}`;
  }
  if (settings.deduct) {
    formula += `:true`;
  }
  return formula;
};

export const DEFAULT_GRADING_FORMULA = makeConditionalPointsFormula(PresetDefaults.NONE);

export interface IQuestionElementConditionalPointsData {
  formula: string;
  isMixed: boolean;
  matchesAssignment: boolean;
  settings: IConditionalPointSettings;
  isAdvanced?: boolean;
}

export const getQuestionElementConditionalPointsDataFromState = (
  state: IAssignmentState,
  questionElementIndex: number
): IQuestionElementConditionalPointsData => {
  const {
    assignmentSettings: { currentAssignmentTemplate },
    questionsData,
  } = state;

  const data: IQuestionElementConditionalPointsData = {
    formula: '',
    isMixed: false,
    matchesAssignment: true,
    settings: { ...PresetDefaults.NONE },
    isAdvanced: false,
  };

  const assignmentConditionalPointsFormula = currentAssignmentTemplate.settings.conditionalPointsFormula;
  const questionElement = questionsData[questionElementIndex];
  if (!questionElement) {
    const assignmentSettings = getConditionalPointSettingsFromFormula(
      assignmentConditionalPointsFormula,
      false,
      assignmentConditionalPointsFormula
    );
    if (assignmentSettings) {
      data.settings = assignmentSettings;
    }
    return data;
  }

  if (isAssignmentQuestion(questionElement)) {
    data.formula = questionElement.boxes[0].gradingSettings.conditionalPointsFormula;
    data.isAdvanced = questionElement.boxes[0].gradingSettings.isAdvanced || false;
    data.isMixed = questionElement.boxes.some(
      box =>
        box.gradingSettings.conditionalPointsFormula !==
        questionElement.boxes[0].gradingSettings.conditionalPointsFormula
    );
  } else if (isQuestionPool(questionElement)) {
    data.formula = questionElement.gradingSettings.conditionalPointsFormula;
    data.isAdvanced = questionElement.gradingSettings.isAdvanced || false;
  }
  data.matchesAssignment = data.formula.trim() === assignmentConditionalPointsFormula.trim();
  data.settings = getConditionalPointSettingsFromFormula(
    data.formula,
    false,
    currentAssignmentTemplate.settings.conditionalPointsFormula
  ) || { ...PresetDefaults.NONE };
  return data;
};

export const isAdvancedPointsAdjustment = (questionElement: IQuestionElement | undefined): boolean => {
  if (!questionElement) {
    return false;
  }
  const gradingSettings = getQuestionElementGradingSettings(questionElement);
  return gradingSettings?.isAdvanced === true;
};

export const mapToSupportedPresetFormula = (
  conditionalPointsFormula: string,
  isAssignmentConditionalPoints = false
): string => {
  const conditionalPoints = getConditionalPointSettingsFromFormula(
    conditionalPointsFormula,
    isAssignmentConditionalPoints
  );
  if (conditionalPoints) {
    return makeConditionalPointsFormula(conditionalPoints);
  }
  return makeConditionalPointsFormula({ ...PresetDefaults.NONE });
};

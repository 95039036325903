import React, { useEffect, useState } from 'react';
import { Button, ButtonColor, Input, magma, Modal, ModalSize } from 'react-magma-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ITemplateListItem } from 'context/AssignmentContextProvider/types';
import { StandardErrorFormat } from 'sharedComponents/InputAutocorrect/InputAutocorrect';

const StyledModal = styled(Modal)`
  width: 43.5rem;
  height: 23.75rem;
`;

const ModalNeutralText = styled.div`
  color: ${magma.colors.neutral};
  padding: 0 0 1rem 0;
`;

const ButtonGroup = styled.div`
  justify-content: flex-end;
  display: flex;
`;

const TemplateNameInput: React.CSSProperties = {
  width: '100%',
  padding: '0 0 15.325% 0',
};

interface ICreateTemplateModalProps {
  show: boolean;
  close: () => void;
  templates: ITemplateListItem[];
  save: (templateName: string) => Promise<void>;
}

export const CreateTemplateModal = (props: ICreateTemplateModalProps): JSX.Element => {
  const { show, close, templates, save } = props;
  const { t } = useTranslation();

  const [newTemplateName, setNewTemplateName] = useState<string>('');
  const [validationError, setValidationError] = useState<StandardErrorFormat>({
    error: false,
    message: '',
  });
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setNewTemplateName('');
      setValidationError({ error: false, message: '' });
    }
  }, [show]);

  const saveModal = async (): Promise<void> => {
    const inputError = validate(newTemplateName);
    if (inputError.error) {
      // Move focus to template name input?
      setValidationError(inputError);
      return;
    }
    setIsSaveButtonLoading(true);
    await save(newTemplateName);
    setIsSaveButtonLoading(false);
  };

  const validate = (templateName: string): StandardErrorFormat => {
    const templateNameList = templates.map(template => template.name);
    if (templateName.trim().length === 0) {
      return {
        error: true,
        message: t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.ERROR.EMPTY_NAME'),
      };
    }
    if (templateName.length > 50) {
      return {
        error: true,
        message: t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.ERROR.MAX_CHARACTER_COUNT_EXCEEDED', { value: 50 }),
      };
    }
    if (templateNameList.some(existingTemplate => existingTemplate === templateName)) {
      return {
        error: true,
        message: t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.ERROR.DUPLICATE_NAME'),
      };
    }
    return validationError;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (validationError.error) {
      setValidationError({
        error: false,
        message: '',
      });
    }
    setNewTemplateName(e.currentTarget.value);
  };

  return (
    <StyledModal
      testId="createTemplateModal"
      header={t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.SAVE_TEMPLATE_HEADER')}
      isOpen={show}
      onClose={close}
      size={ModalSize.large}
    >
      <ModalNeutralText>{t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.SAVE_TEMPLATE_DESCRIPTION')}</ModalNeutralText>
      <Input
        testId={`createTemplateNameInput`}
        labelText={t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.TEMPLATE_NAME')}
        placeholder={t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.LENGTH', { value: 50 })}
        helperMessage={t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.TEMPLATE_NAME_HELPER')}
        containerStyle={TemplateNameInput}
        value={newTemplateName}
        errorMessage={validationError.error ? validationError.message : ''}
        onChange={handleChange}
      />
      <ButtonGroup>
        <Button color={ButtonColor.secondary} onClick={close} testId="closeCreateTemplateModalButton">
          {t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.CANCEL')}
        </Button>
        <Button
          color={ButtonColor.primary}
          onClick={saveModal}
          testId="saveTemplateModalButton"
          isLoading={isSaveButtonLoading}
        >
          {t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.SAVE_TEMPLATE')}
        </Button>
      </ButtonGroup>
    </StyledModal>
  );
};

import React from 'react';
import { Button, ButtonColor, ButtonVariant } from 'react-magma-dom';

interface ButtonProps {
  handleReset: () => void;
  handleUploadClick: () => void;
  isBlock: boolean;
}
const ButtonRow = (props: ButtonProps): JSX.Element => {
  return (
    <>
      <div
        className="row"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          paddingRight: '100px',
        }}
      >
        <Button
          color={ButtonColor.secondary}
          variant={ButtonVariant.solid}
          onClick={props.handleReset}
          testId="clearCxpQuestionsButton"
        >
          Clear
        </Button>
        <Button
          variant={ButtonVariant.solid}
          onClick={props.handleUploadClick}
          testId="searchCxpQuestionsButton"
          disabled={props.isBlock}
        >
          Search
        </Button>
      </div>
    </>
  );
};

export default ButtonRow;

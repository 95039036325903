/* istanbul ignore file */

enum HEADERS {
  WA_QUESTION = 'WA Question',
  WA_QUESTION_ID = 'WA Question ID',
  CXP_ITEM_ID = 'WA CXP ID',
  CXP_ITEM_CXP_ID = 'CXP Item ID',
  GEYSER_REPO = 'Geyser Repo',
  FIELD_COUNT = 'Field Count',
  HANDLER_TYPE = 'Handler',
  IS_ALGORITHMIC = 'Algorithmic',
  LIMIT_ONE_SUBMISSION = 'One Submission',
  LAST_SYNCED = 'Last Updated',
}

enum IMPORT_HEADERS {
  IMPORT_WA_QUESTION = 'WA QUESTION',
  IMPORT_CXP_ITEM_ID = 'CXP ITEM ID',
  IMPORT_GEYSER_REPO = 'GEYSER REPO',
  IMPORT_AUTHOR_NAME = 'AUTHOR USERNAME',
  IMPORT_ONE_SUBMISSION = 'ONE SUBMISSION',
}
interface ICxpImportRequestData {
  [IMPORT_HEADERS.IMPORT_WA_QUESTION]: string;
  [IMPORT_HEADERS.IMPORT_CXP_ITEM_ID]: number;
  [IMPORT_HEADERS.IMPORT_GEYSER_REPO]: string;
  [IMPORT_HEADERS.IMPORT_AUTHOR_NAME]: string;
  [IMPORT_HEADERS.IMPORT_ONE_SUBMISSION]: string | null;
}

interface IFlattenedCxpImportResultQuestion {
  [HEADERS.WA_QUESTION_ID]: number;
  [HEADERS.CXP_ITEM_ID]: number;
  [HEADERS.GEYSER_REPO]: string;
  [HEADERS.CXP_ITEM_CXP_ID]: string;
  [HEADERS.WA_QUESTION]: string;
  [HEADERS.HANDLER_TYPE]: string;
  [HEADERS.FIELD_COUNT]: number;
  [HEADERS.IS_ALGORITHMIC]: string | boolean;
  [HEADERS.LIMIT_ONE_SUBMISSION]: string | boolean;
}

interface IFlattenedTextbookResultQuestion {
  [HEADERS.WA_QUESTION_ID]: string;
  [HEADERS.CXP_ITEM_ID]: string;
  [HEADERS.GEYSER_REPO]: string;
  [HEADERS.CXP_ITEM_CXP_ID]: string;
  [HEADERS.WA_QUESTION]: string;
  [HEADERS.HANDLER_TYPE]: string;
  [HEADERS.FIELD_COUNT]: string;
  [HEADERS.IS_ALGORITHMIC]: string;
  [HEADERS.LIMIT_ONE_SUBMISSION]: string;
}

interface IFlattenedCxpResultQuestion {
  id: number;
  [HEADERS.CXP_ITEM_ID]: JSX.Element;
  [HEADERS.GEYSER_REPO]: string;
  [HEADERS.CXP_ITEM_CXP_ID]: string;
  [HEADERS.WA_QUESTION]: JSX.Element;
  [HEADERS.HANDLER_TYPE]: string;
  [HEADERS.FIELD_COUNT]: number;
  [HEADERS.IS_ALGORITHMIC]: string | boolean;
  [HEADERS.LIMIT_ONE_SUBMISSION]: string | boolean;
  [HEADERS.LAST_SYNCED]: JSX.Element;
}

interface ICxpRequest {
  id: number;
  cxpId: string;
  handlerType: string;
  productCode: string;
  isAlgorithmic: boolean;
  boxCount: number;
  enforceOneSubmission: string;
}

interface ICxpItem {
  id: number;
  cxpId: string;
  productCode: string;
  boxCount: number;
  handlerType: string;
  isAlgorithmic: boolean;
  enforceOneSubmission: boolean;
  lastSynced: number;
}

interface ICxpImportRequestQuestion {
  questionName: string;
  textbookCode: string;
  textbookChapter: string;
  cxpId: number;
  productCode: string;
  enforceOneSubmission: boolean;
}

interface ICxpImportRequest {
  authorUserName: string;
  questions: ICxpImportRequestQuestion[];
}
interface SearchRequest {
  productCode: string;
  cxpId: string;
  questionName: string;
}

interface ICxpResultQuestion {
  questionName: string;
  questionId: number;
  cxpItem: ICxpItem;
}

interface ICxpSearchGroupQuestion {
  questionNames: string[];
  cxpItem: ICxpItem;
}

interface ICxpTextbookQuestion {
  questionId: number;
  questionName: string;
  cxpItem: ICxpItem;
}

interface ICxpResult {
  isSuccess: boolean;
  errors: string[];
  importedQuestions: ICxpResultQuestion[];
}

interface ICxpRefreshResult {
  successful: boolean;
  cxpItemProperties: IFailedAttributes;
}

interface ITextbookShortName {
  id: number;
  shortName: string;
  code: string;
}

interface IFailedAttributes {
  cxpId: string;
  productCode: string;
  fieldCount: number;
}

export {
  HEADERS,
  IMPORT_HEADERS,
  ICxpImportRequestData,
  IFlattenedCxpImportResultQuestion,
  ICxpItem,
  ICxpImportRequestQuestion,
  ICxpImportRequest,
  ICxpResult,
  SearchRequest,
  ICxpResultQuestion,
  IFlattenedCxpResultQuestion,
  ICxpRefreshResult,
  ICxpRequest,
  ICxpSearchGroupQuestion,
  ITextbookShortName,
  ICxpTextbookQuestion,
  IFlattenedTextbookResultQuestion,
};

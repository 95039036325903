/* istanbul ignore file */
import styled from '@emotion/styled';
import isEqual from 'react-fast-compare';
import React, { useEffect, useRef } from 'react';
import { Loader } from 'wa-ui-components';
import {
  createDuplicateAssignmentEditorContext,
  useAssignmentContext,
} from 'context/AssignmentContextProvider/AssignmentContextProvider';
import {
  setAccordionState,
  setAssignmentDynamicInfo,
  setAssignmentEditorContext,
  setAssignmentTemplatesData,
  setInitialAssignmentTemplate,
  setIsCustomTemplate,
  setIsError,
  setIsLoading,
  setIsQuestionsLoading,
  setIsSettingsLoading,
  setQuestionFeedbackSettings,
  setQuestionsData,
  setResetInitialState,
} from 'context/AssignmentContextProvider/actions';
import {
  getAssignmentDynamicInfo,
  getAssignmentEditorContext,
  getAssignmentTemplate,
  getAssignmentTemplates,
  getQuestionsData,
} from 'facultyComponents/assignmentEditor/apiHelpers';
import { useParams } from 'react-router';
import { AssignmentEditorToggle } from 'facultyComponents/assignmentEditor/assignmentEditorToggle/AssignmentEditorToggle';
import { AssignmentEditorInfo } from 'facultyComponents/assignmentEditor/assignmentEditorInfo/AssignmentEditorInfo';
import { AssignmentEditorBar } from 'facultyComponents/assignmentEditor/assignmentEditorBar/AssignmentEditorBar';
import AssignmentEditorSettings from 'facultyComponents/assignmentEditor/assignmentEditorSettings/AssignmentEditorSettings';
import { AssignmentEditorQuestionList } from 'facultyComponents/assignmentEditor/assignmentEditorQuestionList/AssignmentEditorQuestionList';
import cloneDeep from 'lodash/cloneDeep';
import {
  AssignmentEditorWorkflow,
  IAccordionState,
  IAssignmentEditorContext,
  IAssignmentTemplate,
  IDynamicInfoItem,
  IQuestionElement,
  Permission,
  Randomization,
  TrashableStatus,
} from 'context/AssignmentContextProvider/types';
import { setQuestionsDefaults } from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { useUserAPI } from '../../service/dataHooks';
import { setRestrictedFeatures } from '../../apiHelpers';
import { navigate } from '../../utils/facultyHeaderHelpers/facultyHeaderMethods';
import { AssignmentEditorTrashedAssignment } from 'facultyComponents/assignmentEditor/assignmentEditorTrashedAssignment/AssignmentEditorTrashedAssignment';
import { Privilege } from 'sharedComponents/authorization/AuthorizationStatus';
import { QuestionBrowserContextProvider } from 'context/QuestionBrowserContextProvider/QuestionBrowserContextProvider';
import { useTranslation } from 'react-i18next';
import { useKmliModalState } from 'sharedComponents/Kmli/KmliProvider';
import { BackToClassLink } from 'facultyComponents/backToClass/BackToClassLink';

const AssignmentEditorLayout = styled.div<{ inTrash?: boolean }>`
  display: flex;
  align-items: ${({ inTrash }) => (inTrash ? 'flex-start' : 'flex-end')};
  flex-direction: column;
  padding: 1rem;
`;

const EditorTopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  width: 100%;
`;

enum AID {
  DEV = 'dev',
  INT = 'integration',
  STAGE = 'staging',
}
interface IaId {
  [key: string]: number;
}
const assignmentIdMock: IaId = {
  [AID.DEV]: 7544811,
  [AID.INT]: 5880312,
  [AID.STAGE]: 11815453,
};

export const Edit: React.FC = () => {
  const { t } = useTranslation();
  const { assignmentId }: { assignmentId: string } = useParams();
  const { data: userData } = useUserAPI();
  const {
    state: {
      isLoading,
      isSettingsLoading,
      isQuestionsLoading,
      env,
      userId,
      assignmentSettings: { currentAssignmentTemplate },
      assignmentEditorContext,
      questionsData,
      workflow,
      resetInitialState,
    },
    dispatch,
  } = useAssignmentContext();

  const initialData = useRef({
    assignmentEditorContext: {},
    assignmentSettings: {},
    questionsData: {},
  });

  const isTrashedContext = (context: IAssignmentEditorContext): boolean =>
    context.trashableStatus === TrashableStatus.IN_TRASH;

  // GET ALL NECESSARY DATA FOR ASSIGNMENT
  const getAndSetAssignmentData = async (workflow: AssignmentEditorWorkflow) => {
    const getTemplateWithCorrectPermission = (template: IAssignmentTemplate): IAssignmentTemplate => {
      if (
        template.settings.assignmentPermissions &&
        Object.values(Permission).includes(template.settings.assignmentPermissions)
      ) {
        return template;
      }
      // override privacy setting if it is not public/protected/private or group(in this case the value equals groupId)
      return {
        ...template,
        settings: {
          ...template.settings,
          assignmentPermissions: Permission.PROTECTED,
        },
      };
    };
    const getAndSetTemplatesInfo = async (
      activeTemplateId: number,
      defaultTemplateId: number
    ): Promise<IAssignmentTemplate> => {
      const promiseResults = await Promise.all([
        getAssignmentTemplates(userId, +assignmentId),
        getAssignmentTemplate({
          userId,
          assignmentId: assignmentId ? +assignmentId : 0,
          defaultTemplateId,
          templateId: activeTemplateId,
        }),
      ]);

      const templates = promiseResults[0].data.result;

      const template = getTemplateWithCorrectPermission(promiseResults[1].data.result);
      if (template.settings.authorId != userId && !template.settings.isPublic) {
        const authorFullNameWithLimitation =
          template.authorFullName.length > 50 ? template.authorFullName.substr(0, 50) : template.authorFullName;
        const templateName = t('ASSIGNMENT_EDITOR.SETTINGS.TEMPLATE_NAME_NOT_OWNED', {
          name: authorFullNameWithLimitation,
        });

        template.name = templateName;

        const notOwnedTemplate = templates.find(i => i.id === template.id);
        if (notOwnedTemplate) {
          notOwnedTemplate.name = templateName;
        }
      }

      dispatch(
        setAssignmentTemplatesData({
          templateList: templates,
        })
      );

      dispatch(setInitialAssignmentTemplate(template));
      dispatch(setIsSettingsLoading(false));
      return template;
    };

    const getDynamicInfo = async (
      assignmentId: number | undefined
    ): Promise<{ categories: IDynamicInfoItem[]; groups: IDynamicInfoItem[] }> => {
      // GET GROUPS AND CATEGORIES
      const dynamicInfo = await getAssignmentDynamicInfo(assignmentId, userId);
      const { groups, categories } = dynamicInfo.data.result;
      return { groups, categories };
    };

    const getQuestionsDataPromise = async (assignmentId: number): Promise<IQuestionElement[]> => {
      const questions = await getQuestionsData(assignmentId, userId);
      return questions.data.result;
    };

    try {
      if (workflow === AssignmentEditorWorkflow.CREATE) {
        dispatch(setIsSettingsLoading(true));
        // temporary put 0 as defaultTemplateId. During getting templates default template will be put first in the list
        const defaultTemplateId = 0;
        await Promise.all([
          getAndSetTemplatesInfo(defaultTemplateId, defaultTemplateId),
          getDynamicInfo(undefined),
        ]).then(values => dispatch(setAssignmentDynamicInfo(values[1])));
        dispatch(setIsSettingsLoading(false));
      } else {
        dispatch(setIsLoading(true));
        // get context so we can tell if the assignment is trashed
        const {
          data: { result: sourceAssignmentEditorContext },
        } = await getAssignmentEditorContext(assignmentId ? +assignmentId : assignmentIdMock[env], userId);

        if (isTrashedContext(sourceAssignmentEditorContext)) {
          dispatch(setAssignmentEditorContext(sourceAssignmentEditorContext));
          dispatch(setIsLoading(false));
          dispatch(setIsSettingsLoading(false));
          dispatch(setIsQuestionsLoading(false));
          return;
        }

        // expanding all accordions here, as the Settings accordion's Scoring tab requiers the updated context to be applied before render
        // else the initialState's settings are shown
        const expandAllAccordionState: IAccordionState = {
          infoOpen: true,
          settingsOpen: true,
          questionsOpen: true,
        };
        dispatch(setAccordionState(expandAllAccordionState));

        const assignmentEditorContextData =
          workflow === AssignmentEditorWorkflow.DUPLICATE
            ? createDuplicateAssignmentEditorContext(sourceAssignmentEditorContext, userId)
            : sourceAssignmentEditorContext;

        dispatch(setAssignmentEditorContext(assignmentEditorContextData));

        dispatch(setIsLoading(false));
        dispatch(setIsSettingsLoading(true));
        dispatch(setIsQuestionsLoading(true));
        const activeTemplateId = sourceAssignmentEditorContext.assignmentInfo.templateId;
        // TODO: Consider whether there is a difference between the sourceContext templateId and the duplicate context templateId
        await Promise.all([
          getAndSetTemplatesInfo(activeTemplateId, assignmentEditorContextData.defaultTemplateId),
          getQuestionsDataPromise(sourceAssignmentEditorContext.assignmentInfo.id),
          getDynamicInfo(sourceAssignmentEditorContext.assignmentInfo.id),
        ]).then(values => {
          dispatch(setQuestionsData(setQuestionsDefaults(values[1])));
          dispatch(setIsQuestionsLoading(false));
          dispatch(setAssignmentDynamicInfo(values[2]));
        });
        if (workflow === AssignmentEditorWorkflow.DUPLICATE && !sourceAssignmentEditorContext.canSaveAssignment) {
          dispatch(setIsCustomTemplate(true));
        }
      }
      dispatch(setResetInitialState(true));
    } catch (e) {
      dispatch(setIsError(true));
    }
  };

  useEffect(() => {
    getAndSetAssignmentData(workflow);
  }, []);

  const hasDataChanged = React.useMemo(() => {
    if (resetInitialState) {
      initialData.current = {
        assignmentEditorContext: cloneDeep(assignmentEditorContext),
        assignmentSettings: cloneDeep(currentAssignmentTemplate),
        questionsData: cloneDeep(questionsData),
      };
      dispatch(setResetInitialState(false));
    }
    const info = !isEqual(initialData.current.assignmentEditorContext, assignmentEditorContext);
    const settings = !isEqual(initialData.current.assignmentSettings, currentAssignmentTemplate);
    const questions = !isEqual(initialData.current.questionsData, questionsData);

    if (!isLoading && !isSettingsLoading && !isQuestionsLoading) {
      return info || settings || questions;
    } else {
      return false;
    }
  }, [
    initialData.current,
    questionsData,
    currentAssignmentTemplate,
    assignmentEditorContext,
    resetInitialState,
    isLoading,
    isSettingsLoading,
    isQuestionsLoading,
  ]);

  const { isKmliModalOpen } = useKmliModalState();

  useEffect(() => {
    window.onbeforeunload = hasDataChanged && !isKmliModalOpen ? () => 'placeholder' : null;
    return () => {
      window.onbeforeunload = null;
    };
  }, [hasDataChanged, isKmliModalOpen]);

  const initialTemplateFeedback = useRef({
    showHints: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 },
    showPracticeAnotherVersion: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 },
  });
  useEffect(() => {
    const settingsHelpChanged = !isEqual(
      initialTemplateFeedback.current.showHints,
      currentAssignmentTemplate.settings.templateFeedback.showHints
    );
    const settingsPavChanged = !isEqual(
      initialTemplateFeedback.current.showPracticeAnotherVersion,
      currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion
    );
    if (settingsHelpChanged) {
      dispatch(
        setQuestionFeedbackSettings({
          showHintsBeforeDueDateAfterNumberOfSubmissions: null,
        })
      );
      initialTemplateFeedback.current.showHints = currentAssignmentTemplate.settings.templateFeedback.showHints;
    }
    if (
      settingsPavChanged ||
      currentAssignmentTemplate.settings.questionRandomizationSettings.randomization === Randomization.AFTER_EVRY_SUBM
    ) {
      dispatch(
        setQuestionFeedbackSettings({
          showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions: null,
        })
      );
      initialTemplateFeedback.current.showPracticeAnotherVersion =
        currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion;
    }
  }, [
    currentAssignmentTemplate.settings.templateFeedback,
    currentAssignmentTemplate.settings.questionRandomizationSettings.randomization,
  ]);

  const toggleAE = async () => {
    await setRestrictedFeatures({
      userId: userData.id,
      featureName: Privilege.REACT_ASSIGNMENT_EDITOR,
      enabled: false,
    });
    if (workflow === AssignmentEditorWorkflow.EDIT) {
      navigate('assignments/edit', { aid: assignmentEditorContext.assignmentInfo.id });
      return;
    }
    navigate('assignments/new', {});
  };

  return (
    <Loader loading={isLoading}>
      <>
        <EditorTopBlock>
          <BackToClassLink classContext={userData.activeClassContext} />
          <AssignmentEditorToggle onClick={toggleAE} />
        </EditorTopBlock>
        <AssignmentEditorBar />
        <AssignmentEditorLayout inTrash={isTrashedContext(assignmentEditorContext)}>
          {isTrashedContext(assignmentEditorContext) ? (
            <AssignmentEditorTrashedAssignment />
          ) : (
            <>
              <AssignmentEditorInfo />
              <AssignmentEditorSettings />
              <QuestionBrowserContextProvider>
                <AssignmentEditorQuestionList />
              </QuestionBrowserContextProvider>
            </>
          )}
        </AssignmentEditorLayout>
      </>
    </Loader>
  );
};

import React, { Context, createContext, ReactElement, useContext, useReducer } from 'react';
import reducer from './reducer';
import { PropsType, FetchedDataContextType } from './../typings';
import { ActionType } from '../actionCreators';

// Step 1: Create Context
const FetchedDataContext: Context<FetchedDataContextType> = createContext({} as FetchedDataContextType);
export const useFetchedDataContext = (): FetchedDataContextType => useContext(FetchedDataContext);

const FetchedDataProvider = (props: PropsType): ReactElement => {
  const { children, fetcher } = props;

  // Step 2: Initialize store, where we will be saving the fetched Data
  // Step 3: Create getter and setter for the store, update to store will trigger re-render
  const [state, dispatch] = useReducer(reducer, {});

  // two types of key possible
  // 1. apiName
  // 2. apiName + inputParams
  function getter(key: string): ActionType | null {
    const dataState: ActionType = state[key] as ActionType;

    return dataState ? { ...dataState } : null;
  }

  // Step 4: Provide values to Context, which can be accessed by its children
  const context: FetchedDataContextType = {
    fetcher,
    getter,
    dispatch,
  };

  return <FetchedDataContext.Provider value={context}>{children}</FetchedDataContext.Provider>;
};

export { FetchedDataProvider, FetchedDataContext };

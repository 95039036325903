import React from 'react';
import {
  Card,
  Flex,
  FlexAlignContent,
  FlexBehavior,
  FlexJustify,
  FlexWrap,
  Heading,
  magma,
  Paragraph,
  Spacer,
  TypographyContextVariant,
  TypographyVisualStyle,
} from 'react-magma-dom';
import { AccessTimeIcon } from 'react-magma-icons';

type AssignmentInfo = {
  id: string;
  name: string;
};

type HeaderProps = {
  assignment: AssignmentInfo;
};

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { assignment } = props;

  return (
    <>
      <Heading level={1} noMargins visualStyle={TypographyVisualStyle.headingLarge} css={undefined}>
        Extensions / Accommodations
      </Heading>
      <Spacer size={magma.spaceScale.spacing01} />
      <Heading
        level={2}
        noMargins
        contextVariant={TypographyContextVariant.expressive}
        visualStyle={TypographyVisualStyle.headingMedium}
        css={undefined}
      >
        {assignment.name} ({assignment.id})
      </Heading>
      <Spacer size={magma.spaceScale.spacing03} />
      <Flex
        behavior={FlexBehavior.container}
        alignContent={FlexAlignContent.center}
        justify={FlexJustify.flexStart}
        spacing={0.5}
        wrap={FlexWrap.nowrap}
      >
        <Flex behavior={FlexBehavior.item}>
          <AccessTimeIcon size={24} color={magma.colors.primary} />
        </Flex>
        <Flex behavior={FlexBehavior.item}>
          <Paragraph
            noMargins
            contextVariant={TypographyContextVariant.expressive}
            visualStyle={TypographyVisualStyle.bodyMedium}
          >
            60 minute Timed Assignment, Conditional Release
          </Paragraph>
        </Flex>
      </Flex>
      <Spacer size={magma.spaceScale.spacing03} />
      <Card></Card>
    </>
  );
};

import React from 'react';
import { Input } from 'react-magma-dom';
import styled from '@emotion/styled';

interface IProps {
  hasError?: boolean;
  fieldValue: string;
  setField: React.Dispatch<React.SetStateAction<string>>;
  handleOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    setField: React.Dispatch<React.SetStateAction<string>>
  ) => void;
  child: string;
  testId: string;
}

const InputRowContainer = styled.div`
  height: 80px;
`;

const InputRow = (props: IProps): JSX.Element => {
  return (
    <>
      <InputRowContainer>
        <Input
          labelText={props.child}
          errorMessage={props.hasError ? 'Geyser Repo name is required when searching by CXP Item ID.' : null}
          onChange={e => props.handleOnChange(e, props.setField)}
          containerStyle={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end ' }}
          labelStyle={{ flexBasis: '30%', textAlign: 'right', marginRight: '20px' }}
          inputStyle={{ width: '400px' }}
          messageStyle={{ marginLeft: '225px' }}
          testId={props.testId}
          value={props.fieldValue}
        />
      </InputRowContainer>
    </>
  );
};

export default InputRow;

/*
    We don't want to load wa-assets/wa/View/static/wa.js onto the new SPA,
    but need some functionality from that file hence replicating it here.
    Blame MooTools!
 */

const waUtils = {
  openEbook: (serverName: string, waAlias: string, ebookUrl: string, ebookId: string, secFlag: string): boolean => {
    let windowUrl = ebookUrl;
    let windowOptions = '';

    if (secFlag === 'on') {
      // original - jQuery; get from the cookie instead ->
      // var userPass = $('UserPass').value;

      const cookiesArray = document.cookie.split('; ');
      const userPassCookie = cookiesArray.find(row => row.startsWith('UserPass='));
      const userPass = userPassCookie ? userPassCookie.split('=')[1] : '';

      windowUrl = `https://${serverName}/${waAlias}/extra/ebookwrapper/index.tpl?`;
      windowUrl += `ebook_url=${ebookUrl}`;
      windowUrl += `&UserPass=${userPass}`;
      windowOptions =
        'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbar=no,resizable,copyhistory=yes,width=960,height=720,left=25,top=25,screenX=25,screenY=25';
    }

    // TODO: send analytics data here (see waAnalytics.recordActivityAnalytics & wa-assets/wa/View/static/wa.js:264)

    _openWindow(windowUrl, ebookId, windowOptions);
    return false;
  },

  openMindtapReader: (isbn: string, extra: string): boolean => {
    // Did this not work because more than 2 arguments were passed into the function?
    // Refer to the comment at wa-assets/wa/View/static/wa.js -> WA.Util.openMindtapReader
    let windowUrl = '/web/User/Cengage/mtr?isbn=' + isbn;

    if (extra !== '') {
      windowUrl += '&' + extra;
    }

    const windowOptions = '';

    _openWindow(windowUrl, 'Mindtap Reader', windowOptions);
    return false;
  },
};

const _openWindow = (comp: string, name: string, attr: string): void => {
  // if a name is being passed in
  if (typeof name !== 'undefined' && name !== null) {
    // replace spaces with _ and all other illegal characters with the empty string
    name = String(name)
      .replace(/\s/g, '_')
      .replace(/[^a-z0-9_]/gi, '');
  }

  const newWindow = window.open(comp, name, attr);
  newWindow?.focus();
};

export default waUtils;

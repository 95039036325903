import React from 'react';

export const ListQuestions = (questions: string[]): JSX.Element[] => {
  return questions.map(element => (
    <>
      <div key={element}>{element}</div>
      <br />
    </>
  ));
};

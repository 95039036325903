/* istanbul ignore file */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useContext, useReducer } from 'react';
import {
  ACTIONTYPES,
  AssignmentEditorWorkflow,
  FormatTips,
  IActions,
  IAssignmentState,
  IAssignmentContextProps,
  IAssignmentContextProviderProps,
  IAssignmentEditorContext,
  ISetAdjustAllQuestionConditionalPoints,
  ISetAllQuestionPoints,
  ISetAssignmentAndQuestionsShowMyWorkSettings,
  ISetMasterySetSettings,
  ISetQuestionElementFeedbackSettings,
  ISetQuestionElementNumericalTolerance,
  ISetQuestionElementPointsAdjustment,
  ISetQuestionElementPoints,
  ISetQuestionElementShowMyWorkSettings,
  ISetQuestionElementSubmissionSettings,
  Permission,
  Randomization,
  ScoringAnswers,
  ShowMyWorkMode,
  ShowNames,
  Submission,
} from './types';
import {
  isAssignmentQuestion,
  isMasterySet,
  isQuestionPool,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { addFieldToObj } from 'utils/utils';
import { assignmentSettingsDefaults } from 'facultyComponents/assignmentEditor/helpers/DataCorrections/Templates';

export const initialState: IAssignmentState = {
  resetInitialState: false,
  accordionState: {
    infoOpen: false,
    settingsOpen: false,
    questionsOpen: false,
  },
  workflow: AssignmentEditorWorkflow.EDIT,
  isLoading: false,
  isSettingsLoading: false,
  isQuestionsLoading: false,
  isError: false,
  userId: null,
  isScheduled: false,
  errors: {},
  assignmentEditorContext: {
    assignmentInfo: {
      id: 0,
      name: '',
      code: '',
      instructions: '',
      authorId: 0,
      authorName: '',
      authorEmail: '',
      templateId: 0,
      categoryId: 0,
      isQuestionCached: false,
      isModificationLimited: false,
      versionHistory: [],
    },
    canSaveAssignment: true,
    trashableStatus: 0,
    canAccessQwsTestingTools: false,
    instructorId: 0,
    defaultTemplateId: 0,
  },
  env: '',
  assignmentSettings: {
    templateList: [],
    selectedTemplateListItem: null,
    currentAssignmentTemplate: {
      id: 0,
      name: '',
      description: '',
      authorFullName: '',
      settings: {
        isPublic: false, //public
        authorId: 0, //user
        categoryId: 0, //category
        categoryName: '', //category_name
        submissionAndWorkSettings: {
          allowedSubmissionsCount: 0, //submissions_allowed
          submission: Submission.ASSIGNMENT, //submission_type
          scoringAnswers: ScoringAnswers.ASSIGNMENT, //grade_which
          showMyWorkMode: ShowMyWorkMode.DEFAULT, //show_my_work_mode
          showMyWorkPointValue: 0, //show_my_work_value
        },
        questionRandomizationSettings: {
          randomizationSubmissionsCount: 0, //det_submissions,
          showSolution: false, //det_solution
          showAnswerKey: false, // det_key
          randomization: Randomization.SAME_VALUES, //randomization
        },
        displayAllQuestions: false, //display_all
        isQuestionOrderRandomized: false, //random_order
        assignmentPermissions: Permission.PROTECTED, //permission
        groupId: 0, //group
        groupName: null, //group_name
        saveIsSubmit: null, //save_is_submit  NullOrOne; =? to check
        templateFeedback: {
          showHints: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 }, //rich
          showPracticeAnotherVersion: {
            showAfterDueDate: false,
            showBeforeDueDate: false,
            specifiedNumberOfSubmissions: 0,
          }, //pav
          showKey: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 }, //key
          showSolution: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 }, //solution
          showMark: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 }, //mark
          showResponses: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 }, //responses
          showAssignmentScore: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 }, //a_scores
          showQuestionScore: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 }, //q_scores
          showQuestionPartScore: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 }, //qp_scores
          showGraderComments: { showAfterDueDate: false, showBeforeDueDate: false, specifiedNumberOfSubmissions: 0 }, //comments
        },
        conditionalPointsFormula: 'preset:none:0', //point_adjustments
        isAdvanced: false,
        numericalToleranceSettings: {
          toleranceOverridden: false, //override_tolerance
          numericalTolerance: 0, //tolerance
          isToleranceInPercent: false, //percent_tolerance
          partialCreditForUnitsPercentage: 0, //units_credit
        },
        significantFiguresSettings: {
          significantFiguresOverridden: false, //override_sigfigs
          significantFiguresCheckEnabled: false, //sigfigs_on
          significantFiguresHelpIconEnabled: false, //sigfigs_help
          isSignificantFiguresHelpIconClickable: false, //sigfigs_icon
          significantFiguresTolerance: 0, //sigfigs_tolerance
          significantFiguresPartialCreditPercentage: 0, //sigfigs_credit
        },
        displayAndSyntaxErrorsSettings: {
          syntaxErrorsOverridden: true, //override_syntax
          symbolicFormattingHelpIconEnabled: false, //symbolic_icon
          answerFormatTips: FormatTips.USE_COURSE_SETTING, //format_tips
          questionNameDisplay: ShowNames.TEXTBOOK_QUESTIONS, //show_names
          isIncorrectSubmissionsWithSyntaxErrorsForNumericalQuestions: false, //numerical_syntax
          isIncorrectSubmissionsWithSyntaxErrorsForSymbolicQuestions: false, //symbolic_syntax
          isIncorrectSubmissionsWithSyntaxErrorsForAlgebraicQuestions: false, //algebraic_syntax
        },
        saltStatus: '', //salt_display
      },
    },
    assignmentDynamicInfo: {
      groups: [],
      categories: [],
    },
    isCustomTemplate: false,
  },
  elementsWithErrors: [],
  questionsData: [],
};

export const createDuplicateAssignmentEditorContext = (
  sourceContext: IAssignmentEditorContext,
  userId: number | null
): IAssignmentEditorContext => {
  return {
    ...sourceContext,
    assignmentInfo: {
      ...initialState.assignmentEditorContext.assignmentInfo,
      instructions: sourceContext.assignmentInfo.instructions,
      name: sourceContext.assignmentInfo.name,
      authorId: userId ? userId : 0,
      templateId: sourceContext.assignmentInfo.templateId,
      categoryId: sourceContext.assignmentInfo.categoryId,
    },
    canSaveAssignment: true,
    instructorId: 0,
    trashableStatus: 0,
    defaultTemplateId: sourceContext.defaultTemplateId,
  } as IAssignmentEditorContext;
};

// REDUCER

export const reducer = (state: IAssignmentState, action: IActions): IAssignmentState => {
  const {
    questionsData,
    assignmentSettings: {
      currentAssignmentTemplate: {
        settings: { submissionAndWorkSettings },
      },
    },
  } = state;
  // Shared functions
  const updateQuestionElementSubmissions = (settings: Pick<ISetQuestionElementSubmissionSettings, 'payload'>) => {
    const { boxIndex, elementIndex, allowedSubmissionsCount } = settings.payload;
    const { questionsData } = state;
    const questionElement = questionsData[elementIndex];

    if (isQuestionPool(questionElement) || isMasterySet(questionElement)) {
      questionElement.gradingSettings = {
        ...questionElement.gradingSettings,
        allowedSubmissionsCount,
      };
      questionsData[elementIndex] = questionElement;
    } else if (isAssignmentQuestion(questionElement) && boxIndex !== undefined) {
      questionElement.boxes[boxIndex].gradingSettings = {
        ...questionElement.boxes[boxIndex].gradingSettings,
        allowedSubmissionsCount,
      };
    } else if (isAssignmentQuestion(questionElement)) {
      questionElement.boxes.forEach(box => {
        box.gradingSettings = { ...box.gradingSettings, allowedSubmissionsCount };
      });
    }
    questionsData[elementIndex] = questionElement;
    return questionsData;
  };

  const updateQuestionElementShowMyWork = (settings: Pick<ISetQuestionElementShowMyWorkSettings, 'payload'>) => {
    const { elementIndex, showMyWorkMode, showMyWorkPointValue } = settings.payload;
    const { questionsData } = state;
    const questionElement = questionsData[elementIndex];
    questionElement.showMyWorkMode = showMyWorkMode;
    questionElement.showMyWorkPointValue = showMyWorkPointValue;
    questionsData[elementIndex] = questionElement;
    return questionsData;
  };
  const updateQuestionElementPointsAdjustment = (settings: Pick<ISetQuestionElementPointsAdjustment, 'payload'>) => {
    const applyConditionalPointsSettings = ({
      formula,
      explanation,
      isAdvanced,
    }: {
      formula: string;
      explanation: string | undefined;
      isAdvanced: boolean | undefined;
    }) => {
      return {
        conditionalPointsFormula: formula,
        conditionalPointsFormulaExplanation: formula.trim() && explanation ? explanation : '',
        ...(isAdvanced !== undefined ? { isAdvanced } : {}),
      };
    };

    const { conditionalPointsFormula, conditionalPointsFormulaExplanation, elementIndex, isAdvanced } =
      settings.payload;
    const { questionsData } = state;
    const questionElement = questionsData[elementIndex];

    if (isAssignmentQuestion(questionElement)) {
      questionElement.boxes.map(box => {
        box.gradingSettings = {
          ...box.gradingSettings,
          ...applyConditionalPointsSettings({
            formula: conditionalPointsFormula,
            explanation: conditionalPointsFormulaExplanation,
            isAdvanced,
          }),
        };
        return box;
      });
    } else if (isQuestionPool(questionElement)) {
      questionElement.gradingSettings = {
        ...questionElement.gradingSettings,
        ...applyConditionalPointsSettings({
          formula: conditionalPointsFormula,
          explanation: conditionalPointsFormulaExplanation,
          isAdvanced,
        }),
      };
    }
    questionsData[elementIndex] = questionElement;

    return questionsData;
  };

  const updateQuestionElementPoints = (settings: Pick<ISetQuestionElementPoints, 'payload'>) => {
    const { elementIndex, points } = settings.payload;
    const { questionsData } = state;
    const questionElement = questionsData[elementIndex];
    if (isQuestionPool(questionElement) || isMasterySet(questionElement)) {
      questionElement.gradingSettings.pointsValue = points[0];
    } else if (isAssignmentQuestion(questionElement) && Array.isArray(points)) {
      points.forEach((value, index) => {
        questionElement.boxes[index].gradingSettings.pointsValue = value;
      });
    }
    questionsData[elementIndex] = questionElement;
    return questionsData;
  };

  const updateQuestionElementNumericalTolerance = (
    settings: Pick<ISetQuestionElementNumericalTolerance, 'payload'>
  ) => {
    const { numericalToleranceSettings, elementIndex } = settings.payload;
    const { questionsData } = state;
    const questionElement = { ...questionsData[elementIndex] };

    if (isQuestionPool(questionElement)) {
      questionElement.questions.forEach(question => (question.numericalToleranceSettings = numericalToleranceSettings));
    } else if (isAssignmentQuestion(questionElement)) {
      questionElement.numericalToleranceSettings = numericalToleranceSettings;
    }
    questionsData[elementIndex] = questionElement;
    return questionsData;
  };

  const updateAllQuestionPoints = (settings: Pick<ISetAllQuestionPoints, 'payload'>) => {
    const questionPoints = settings.payload;
    const { questionsData } = state;
    questionPoints.forEach((questionPoints, index) => {
      const questionElement = questionsData[index];
      if (isQuestionPool(questionElement) && questionPoints.questionPartLevelPoints.length == 1) {
        questionElement.gradingSettings.pointsValue = questionPoints.questionPartLevelPoints[0];
      } else if (isAssignmentQuestion(questionElement)) {
        questionPoints.questionPartLevelPoints.forEach((point, index) => {
          questionElement.boxes[index].gradingSettings.pointsValue = point;
        });
      }
    });
    return questionsData;
  };

  const updateAdjustAllQuestionConditionalPoints = (
    settings: Pick<ISetAdjustAllQuestionConditionalPoints, 'payload'>
  ) => {
    const { conditionalPointsFormula, conditionalPointsFormulaExplanation, isAdvanced } = settings.payload;
    if (conditionalPointsFormula) {
      return questionsData.map(questionElement => {
        if (isAssignmentQuestion(questionElement)) {
          // Do not apply advanced settings to cxp questions
          if (isAdvanced && questionElement.isCxpQuestion) {
            return questionElement;
          }
          questionElement.boxes.forEach(box => {
            box.gradingSettings = {
              ...box.gradingSettings,
              conditionalPointsFormula: conditionalPointsFormula,
              isAdvanced: isAdvanced || false,
              conditionalPointsFormulaExplanation: conditionalPointsFormulaExplanation,
            };
          });
        }
        if (isQuestionPool(questionElement)) {
          // Do not apply advanced settings if a question pool contains cxp questions
          const hasCxpQuestion = questionElement.questions.some(question => question.isCxpQuestion);
          if (isAdvanced && hasCxpQuestion) {
            return questionElement;
          }
          questionElement.gradingSettings = {
            ...questionElement.gradingSettings,
            conditionalPointsFormula: conditionalPointsFormula,
            isAdvanced: isAdvanced || false,
            conditionalPointsFormulaExplanation: conditionalPointsFormulaExplanation,
          };
        }
        return questionElement;
      });
    }
    return questionsData;
  };

  const updateShowMyWorkSettings = (settings: Pick<ISetAssignmentAndQuestionsShowMyWorkSettings, 'payload'>) => {
    return questionsData.map((questionElement, index) => {
      if (isAssignmentQuestion(questionElement)) {
        const shouldBeSetToDisabled =
          (questionElement.showMyWorkMode === ShowMyWorkMode.REQUIRED ||
            questionElement.showMyWorkMode === ShowMyWorkMode.OPTIONAL) &&
          submissionAndWorkSettings.submission === Submission.ASSIGNMENT;
        updateQuestionElementShowMyWork({
          payload: {
            elementIndex: index,
            showMyWorkMode: shouldBeSetToDisabled ? ShowMyWorkMode.WAS_DISABLED : settings.payload,
            showMyWorkPointValue: 0,
          },
        });
      }
      return questionElement;
    });
  };

  const updateQuestionElementFeedbackSettings = (settings: Pick<ISetQuestionElementFeedbackSettings, 'payload'>) => {
    const { elementIndex, feedbackSettings } = settings.payload;
    const { questionsData } = state;
    const questionElement = questionsData[elementIndex];
    if (isAssignmentQuestion(questionElement) || isMasterySet(questionElement)) {
      questionElement.feedbackSettings = { ...questionElement.feedbackSettings, ...feedbackSettings };
    }
    if (isQuestionPool(questionElement)) {
      questionElement.questions.forEach(question => {
        question.feedbackSettings = { ...question.feedbackSettings, ...feedbackSettings };
      });
    }
    questionsData[elementIndex] = questionElement;
    return questionsData;
  };
  const updateMasterySetSettings = (settings: Pick<ISetMasterySetSettings, 'payload'>) => {
    const { index, threshold, subsetSize, resetId } = settings.payload;
    const { questionsData } = state;
    const questionElement = questionsData[index];
    if (isMasterySet(questionElement)) {
      questionElement.masteryThreshold = threshold;
      questionElement.subsetSize = subsetSize;
      if (resetId) {
        questionElement.id = null;
      }
      questionsData[index] = questionElement;
    }
    return questionElement;
  };
  // REDUCTION
  switch (action.type) {
    case ACTIONTYPES.SET_ASSIGNMENTS_TEMPLATES_DATA:
      const { templateList } = action.payload;
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          templateList,
        },
      };
    case ACTIONTYPES.SET_ASSIGNMENT_DYNAMIC_INFO:
      const { groups, categories } = action.payload;
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          assignmentDynamicInfo: {
            groups,
            categories,
          },
        },
      };
    case ACTIONTYPES.SET_ASSIGNMENTS_TEMPLATE:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          currentAssignmentTemplate: action.payload,
          isCustomTemplate: true,
        },
      };
    case ACTIONTYPES.SET_INITIAL_ASSIGNMENTS_TEMPLATE:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          currentAssignmentTemplate: assignmentSettingsDefaults(action.payload),
          isCustomTemplate: !action.payload.id,
        },
      };
    case ACTIONTYPES.SET_ASSIGNMENT_EDITOR_CONTEXT:
      return {
        ...state,
        assignmentEditorContext: action.payload,
      };
    case ACTIONTYPES.SET_ASSIGNMENT_NAME:
      return {
        ...state,
        assignmentEditorContext: {
          ...state.assignmentEditorContext,
          assignmentInfo: {
            ...state.assignmentEditorContext.assignmentInfo,
            name: action.payload,
          },
        },
      };
    case ACTIONTYPES.SET_ASSIGNMENT_INSTRUCTIONS:
      return {
        ...state,
        assignmentEditorContext: {
          ...state.assignmentEditorContext,
          assignmentInfo: {
            ...state.assignmentEditorContext.assignmentInfo,
            instructions: action.payload,
          },
        },
      };
    case ACTIONTYPES.SET_ASSIGNMENT_CODE:
      return {
        ...state,
        assignmentEditorContext: {
          ...state.assignmentEditorContext,
          assignmentInfo: {
            ...state.assignmentEditorContext.assignmentInfo,
            code: action.payload,
          },
        },
      };
    case ACTIONTYPES.SET_ASSIGNMENT_EDITOR_WORKFLOW:
      return {
        ...state,
        workflow: action.payload,
      };
    case ACTIONTYPES.SET_VERSION_HISTORY:
      return {
        ...state,
        assignmentEditorContext: {
          ...state.assignmentEditorContext,
          assignmentInfo: {
            ...state.assignmentEditorContext.assignmentInfo,
            versionHistory: action.payload,
          },
        },
      };
    case ACTIONTYPES.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ACTIONTYPES.SET_IS_QUESTIONS_LOADING:
      return {
        ...state,
        isQuestionsLoading: action.payload,
      };
    case ACTIONTYPES.SET_IS_SETTINGS_LOADING:
      return {
        ...state,
        isSettingsLoading: action.payload,
      };
    case ACTIONTYPES.SET_RESET_INITIAL_STATE:
      return {
        ...state,
        resetInitialState: action.payload,
      };
    case ACTIONTYPES.SET_REF_WITH_ERROR:
      return {
        ...state,
        elementsWithErrors: state.elementsWithErrors.includes(action.payload)
          ? [...state.elementsWithErrors]
          : [...state.elementsWithErrors, action.payload],
      };
    case ACTIONTYPES.REMOVE_REF_WITH_ERROR:
      return {
        ...state,
        elementsWithErrors: [...state.elementsWithErrors].filter(
          element => element.current?.id !== action.payload.current!.id
        ),
      };
    case ACTIONTYPES.SET_QUESTIONS_DATA:
      return {
        ...state,
        questionsData: action.payload,
      };

    case ACTIONTYPES.SET_NUMERICAL_TOLERANCE_SETTINGS:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: true,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              numericalToleranceSettings: action.payload,
            },
          },
        },
      };
    case ACTIONTYPES.SET_SIGNIFICANT_FIGURES_SETTINGS:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: true,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              significantFiguresSettings: action.payload,
            },
          },
        },
      };
    case ACTIONTYPES.SET_LEARNING_TOOLS_AND_FEEDBACK_SETTINGS:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: true,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              ...{ templateFeedback: action.payload },
            },
          },
        },
      };
    case ACTIONTYPES.SET_SUBMISSION_AND_WORK_SETTINGS:
      const { allowedSubmissionsCount, submission } = action.payload;
      if (submission === Submission.ASSIGNMENT) {
        questionsData.forEach((_questionElement, index) => {
          updateQuestionElementSubmissions({
            payload: {
              elementIndex: index,
              allowedSubmissionsCount,
            },
          });
        });
      }
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: true,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              submissionAndWorkSettings: action.payload,
            },
          },
        },
        questionsData,
      };
    case ACTIONTYPES.SET_ASSIGNMENT_TEMPLATE_POINTS_ADJUSTMENT:
      const { conditionalPointsFormula, existingTemplate } = action.payload;
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              conditionalPointsFormula: conditionalPointsFormula,
            },
          },
          isCustomTemplate: !existingTemplate,
        },
      };
    case ACTIONTYPES.SET_QUESTION_ELEMENT_SUBMISSION_SETTINGS:
      return {
        ...state,
        questionsData: [...updateQuestionElementSubmissions(action)],
      };
    case ACTIONTYPES.SET_QUESTION_ELEMENT_SHOW_MY_WORK_SETTINGS:
      return {
        ...state,
        questionsData: [...updateQuestionElementShowMyWork(action)],
      };
    case ACTIONTYPES.SET_ASSIGNMENT_AND_QUESTIONS_SHOW_MY_WORK_SETTINGS:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              submissionAndWorkSettings: {
                ...state.assignmentSettings.currentAssignmentTemplate.settings.submissionAndWorkSettings,
                showMyWorkMode: action.payload,
                showMyWorkPointValue: 0,
              },
            },
          },
        },
        questionsData: [...updateShowMyWorkSettings(action)],
      };
    case ACTIONTYPES.SET_QUESTION_ELEMENT_POINTS_ADJUSTMENT:
      return {
        ...state,
        questionsData: [...updateQuestionElementPointsAdjustment(action)],
      };
    case ACTIONTYPES.SET_QUESTION_ELEMENT_POINTS:
      return {
        ...state,
        questionsData: [...updateQuestionElementPoints(action)],
      };
    case ACTIONTYPES.SET_QUESTION_ELEMENT_NUMERICAL_TOLERANCE:
      return {
        ...state,
        questionsData: [...updateQuestionElementNumericalTolerance(action)],
      };
    case ACTIONTYPES.SET_ALL_QUESTION_POINTS:
      return {
        ...state,
        questionsData: [...updateAllQuestionPoints(action)],
      };
    case ACTIONTYPES.SET_ADJUST_ALL_QUESTION_CONDITIONAL_POINTS:
      return {
        ...state,
        questionsData: [...updateAdjustAllQuestionConditionalPoints(action)],
      };
    case ACTIONTYPES.SET_QUESTION_RANDOMIZATION_SETTINGS:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: true,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              questionRandomizationSettings: action.payload,
            },
          },
        },
      };
    case ACTIONTYPES.SET_DISPLAY_AND_SYNTAX_ERRORS_SETTINGS:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: true,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              displayAndSyntaxErrorsSettings: action.payload,
            },
          },
        },
      };
    case ACTIONTYPES.SET_ASSIGNMENT_SETTINGS:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: action.payload,
          },
        },
      };
    case ACTIONTYPES.SET_DISPLAY_All_QUESTIONS_SETTINGS:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: true,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              displayAllQuestions: action.payload,
            },
          },
        },
      };
    case ACTIONTYPES.SET_IS_QUESTION_RANDOMIZED_SETTINGS:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: true,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              isQuestionOrderRandomized: action.payload,
            },
          },
        },
      };
    case ACTIONTYPES.SET_FIELD_ERROR:
      return {
        ...state,
        errors: addFieldToObj(state.errors, action.payload.field, action.payload.value),
      };
    case ACTIONTYPES.SET_ACCORDION_STATE:
      return {
        ...state,
        accordionState: action.payload,
      };
    case ACTIONTYPES.SET_QUESTION_ELEMENT_FEEDBACK_SETTINGS:
      return {
        ...state,
        questionsData: [...updateQuestionElementFeedbackSettings(action)],
      };
    case ACTIONTYPES.SET_QUESTION_FEEDBACK_SETTINGS:
      questionsData.forEach((_questionElement, index) => {
        updateQuestionElementFeedbackSettings({
          payload: {
            elementIndex: index,
            feedbackSettings: action.payload,
          },
        });
      });
      return {
        ...state,
        questionsData,
      };
    case ACTIONTYPES.SET_MASTERY_SET_SETTINGS:
      questionsData[action.payload.index] = updateMasterySetSettings(action);
      return {
        ...state,
      };
    case ACTIONTYPES.SET_DUPLICATE_ASSIGNMENT_EDITOR_CONTEXT:
      return {
        ...state,
        workflow: AssignmentEditorWorkflow.DUPLICATE,
        assignmentEditorContext: createDuplicateAssignmentEditorContext(action.payload, state.userId),
      };
    case ACTIONTYPES.SET_ASSIGNMENT_PRIVACY_SETTING:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: true,
          currentAssignmentTemplate: {
            ...state.assignmentSettings.currentAssignmentTemplate,
            settings: {
              ...state.assignmentSettings.currentAssignmentTemplate.settings,
              assignmentPermissions: action.payload.privacySetting,
              groupId: action.payload.groupId,
            },
          },
        },
      };
    case ACTIONTYPES.SET_IS_CUSTOM_TEMPLATE:
      return {
        ...state,
        assignmentSettings: {
          ...state.assignmentSettings,
          isCustomTemplate: action.payload,
        },
      };
    default:
      return { ...state };
  }
};

export const AssignmentContext = createContext<IAssignmentContextProps>({
  state: initialState,
  dispatch: () => null,
});

export const useAssignmentContext = () => useContext(AssignmentContext);

export const AssignmentContextProvider = ({ reducer, initialState, children }: IAssignmentContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AssignmentContext.Provider value={{ state, dispatch }}>{children}</AssignmentContext.Provider>;
};

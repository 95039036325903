import React, { Dispatch, SetStateAction } from 'react';
import { FEEDBACK_ITEM } from '../types';
import styled from '@emotion/styled';
import { CheckIcon, ClearIcon } from 'react-magma-icons';
import i18next from 'i18next';
import {
  IAssignmentTemplate,
  ILearningToolsAndFeedbackSettings,
  IScores,
  ITableItem,
  Submission,
} from 'context/AssignmentContextProvider/types';

const Label = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: 600;
  font-size: 0.875rem;
`;
const HelperText = styled.div<{ isHidden?: boolean }>`
  color: #707070;
  font-size: 0.875rem;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

export const FEEDBACK_SELECT_ITEMS = [
  { label: i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.SELECT.ALWAYS_SHOW'), value: '0' },
  { label: i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.SELECT.AFTER_DUE'), value: '1' },
  { label: i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.SELECT.AFTER_SPECIFIED'), value: '2' },
];

const getLabelTextElement = (label: string, helper: string | JSX.Element, isHelperHidden: boolean): JSX.Element => {
  return (
    <div data-testid="selectLabel">
      <Label>{label}</Label>
      <HelperText isHidden={isHelperHidden}>{helper}</HelperText>
    </div>
  );
};

export const getLabelElement = (key: FEEDBACK_ITEM, submission: Submission): JSX.Element =>
  ({
    [FEEDBACK_ITEM.answerKey]: getLabelTextElement(
      i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.ANSWER_KEY'),
      i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.ANSWER_KEY_HELPER'),
      false
    ),
    [FEEDBACK_ITEM.helpContent]: getLabelTextElement(
      i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.HELP_CONTENT'),
      i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.HELP_CONTENT_HELPER'),
      false
    ),
    [FEEDBACK_ITEM.practiceAnotherVersion]: getLabelTextElement(
      i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.PAV'),
      i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.PAV_HELPER'),
      false
    ),
    [FEEDBACK_ITEM.studentsPreviousResp]: getLabelTextElement(
      i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.STUDENTS_PREVIOUS_ANSWERS'),
      i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.STUDENTS_PREVIOUS_ANSWERS_HELPER'),
      true
    ),
    [FEEDBACK_ITEM.answerFeedback]: getLabelTextElement(
      i18next.t(i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.ANSWER_FEEDBACK')),
      <>
        {i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.INDICATORS')} (
        <CheckIcon color="green" aria-label={i18next.t('CORRECT_CHECKMARK')} />/
        <ClearIcon color="red" aria-label={i18next.t('INCORRECT_CHECKMARK')} />)
        {submission === Submission.QUESTION_PART && i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.AND_SCORE')}
      </>,
      false
    ),
    [FEEDBACK_ITEM.studentScore]: getLabelTextElement(
      i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.STUDENT_SCORE'),
      'Score',
      true
    ),
  }[key]);

export const getLabelText = (feedbackItem: number): string => {
  switch (feedbackItem) {
    case FEEDBACK_ITEM.answerKey:
      return i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.CHOOSE_SETTINGS_FOR_ANSWER_KEY');
    case FEEDBACK_ITEM.helpContent:
      return i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.CHOOSE_SETTINGS_FOR_HELP_CONTENT');
    case FEEDBACK_ITEM.practiceAnotherVersion:
      return i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.CHOOSE_SETTINGS_FOR_PAV');
    case FEEDBACK_ITEM.studentsPreviousResp:
      return i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.CHOOSE_SETTINGS_FOR_STUDENTS_PREVIOUS_ANSWERS');
    case FEEDBACK_ITEM.answerFeedback:
      return i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.CHOOSE_SETTINGS_FOR_ANSWER_FEEDBACK');
    case FEEDBACK_ITEM.studentScore:
      return i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.CHOOSE_SETTINGS_FOR_STUDENT_SCORE');
    default:
      return i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.CHOOSE_SETTINGS');
  }
};

const shouldUseAllSelectItems = (feedbackItem: FEEDBACK_ITEM, submissionStrategy: Submission): boolean => {
  return (
    feedbackItem === FEEDBACK_ITEM.answerKey ||
    feedbackItem === FEEDBACK_ITEM.helpContent ||
    feedbackItem === FEEDBACK_ITEM.practiceAnotherVersion ||
    (feedbackItem === FEEDBACK_ITEM.answerFeedback && submissionStrategy !== Submission.QUESTION_PART)
  );
};

export const getSelectItems = (
  feedbackItem: FEEDBACK_ITEM,
  submissionStrategy: Submission,
  multipleSubmissionsAllowed: boolean
): { label: string; value: string }[] => {
  if (shouldUseAllSelectItems(feedbackItem, submissionStrategy) && multipleSubmissionsAllowed) {
    return FEEDBACK_SELECT_ITEMS;
  }
  return FEEDBACK_SELECT_ITEMS.slice(0, -1);
};

export const setDefaultToggleCheck = (
  feedbackItemData: ITableItem | IScores,
  labelTextKey: FEEDBACK_ITEM,
  updateCheckedToggle: Dispatch<SetStateAction<boolean>>
): void => {
  const { showBeforeDueDate, showAfterDueDate, specifiedNumberOfSubmissions }: ITableItem =
    feedbackItemData as ITableItem;
  switch (labelTextKey) {
    case FEEDBACK_ITEM.answerKey:
      updateCheckedToggle(showBeforeDueDate || showAfterDueDate);
      break;
    case FEEDBACK_ITEM.helpContent:
      const isCheckedHelpContent = showBeforeDueDate || showAfterDueDate;
      updateCheckedToggle(isCheckedHelpContent);
      break;
    case FEEDBACK_ITEM.practiceAnotherVersion:
      const isCheckedPracticeAnotherVersion = showBeforeDueDate || showAfterDueDate;
      updateCheckedToggle(isCheckedPracticeAnotherVersion);
      break;
    case FEEDBACK_ITEM.studentsPreviousResp:
      const isCheckedStudentsPreviousResp =
        !(!showBeforeDueDate && !showAfterDueDate) || (!specifiedNumberOfSubmissions && showAfterDueDate);
      updateCheckedToggle(isCheckedStudentsPreviousResp);
      break;
    case FEEDBACK_ITEM.answerFeedback:
      const isCheckedAnswerFeedback = showAfterDueDate || showBeforeDueDate;
      updateCheckedToggle(isCheckedAnswerFeedback);
      break;
    case FEEDBACK_ITEM.studentScore:
      const templateFeedback = feedbackItemData as IScores;
      updateCheckedToggle(isScoreSettingsOn(templateFeedback));
      break;
    default:
      updateCheckedToggle(false);
  }
};

//UPDATE SELECTOR STATE ON COMPONENT LOAD//
export const setDefaultSelectItem = (
  labelTextKey: FEEDBACK_ITEM,
  feedbackItemData: ITableItem | IScores,
  submissionStrategy: Submission,
  setSelectedItem: Dispatch<SetStateAction<{ label: string; value: string } | null>>,
  setSubmissionInputValue: Dispatch<SetStateAction<number>>
): void => {
  if (shouldUseAllSelectItems(labelTextKey, submissionStrategy)) {
    const { showBeforeDueDate, showAfterDueDate, specifiedNumberOfSubmissions }: ITableItem =
      feedbackItemData as ITableItem;
    const alwaysShowItemSelected = showBeforeDueDate && specifiedNumberOfSubmissions <= 1;
    const showOnlyAfterDueDateItemSelected = !showBeforeDueDate && showAfterDueDate;
    const showAfterSpecSubmItemSelected = specifiedNumberOfSubmissions > 1 && showBeforeDueDate;
    if (alwaysShowItemSelected) {
      setSelectedItem(FEEDBACK_SELECT_ITEMS[0]);
    }
    if (showOnlyAfterDueDateItemSelected) {
      setSelectedItem(FEEDBACK_SELECT_ITEMS[1]);
    }
    if (showAfterSpecSubmItemSelected) {
      setSelectedItem(FEEDBACK_SELECT_ITEMS[2]);
      setSubmissionInputValue(specifiedNumberOfSubmissions);
    }
  } else if (labelTextKey === FEEDBACK_ITEM.studentsPreviousResp) {
    const { showBeforeDueDate, showAfterDueDate }: ITableItem = feedbackItemData as ITableItem;
    const alwaysShowItemSelected = (showBeforeDueDate && showAfterDueDate) || (showBeforeDueDate && !showAfterDueDate);
    const showOnlyAfterDueDateItemSelected = !showBeforeDueDate && showAfterDueDate;
    if (alwaysShowItemSelected) {
      setSelectedItem(FEEDBACK_SELECT_ITEMS[0]);
    }
    if (showOnlyAfterDueDateItemSelected) {
      setSelectedItem(FEEDBACK_SELECT_ITEMS[1]);
    }
  } else if (labelTextKey === FEEDBACK_ITEM.answerFeedback) {
    // on this code branch when labeltext is answerFeedback AND submission is QUESTION_PART
    const { showBeforeDueDate, showAfterDueDate }: ITableItem = feedbackItemData as ITableItem;
    if (showBeforeDueDate && showAfterDueDate) {
      setSelectedItem(FEEDBACK_SELECT_ITEMS[0]);
    }
    if (!showBeforeDueDate && showAfterDueDate) {
      setSelectedItem(FEEDBACK_SELECT_ITEMS[1]);
    }
  } else if (labelTextKey === FEEDBACK_ITEM.studentScore) {
    const templateFeedback = feedbackItemData as IScores;
    if (isScoresSetToAlways(templateFeedback)) {
      setSelectedItem(FEEDBACK_SELECT_ITEMS[0]);
    } else {
      setSelectedItem(FEEDBACK_SELECT_ITEMS[1]);
    }
  }
};

const isScoreSettingsOn = (scores: IScores): boolean => {
  const { showAssignmentScore, showQuestionScore, showQuestionPartScore, showGraderComments } = scores;
  return (
    showQuestionScore.showAfterDueDate ||
    showQuestionScore.showBeforeDueDate ||
    showAssignmentScore.showAfterDueDate ||
    showAssignmentScore.showBeforeDueDate ||
    showQuestionPartScore.showAfterDueDate ||
    showQuestionPartScore.showBeforeDueDate ||
    showGraderComments.showAfterDueDate ||
    showGraderComments.showBeforeDueDate
  );
};
const isScoresSetToAlways = (scores: IScores): boolean => {
  const { showAssignmentScore, showGraderComments } = scores;
  return (
    showAssignmentScore.showAfterDueDate &&
    showAssignmentScore.showBeforeDueDate &&
    showGraderComments.showAfterDueDate &&
    showGraderComments.showBeforeDueDate
  );
};
const isMarksOn = (currentAssignmentTemplate: IAssignmentTemplate): boolean => {
  const { showBeforeDueDate, showAfterDueDate } = currentAssignmentTemplate.settings.templateFeedback.showMark;
  return showBeforeDueDate || showAfterDueDate;
};
const isSetToAfterSpecifiedSubmission = (feedbackSetting: ITableItem): boolean => {
  const { showBeforeDueDate, showAfterDueDate, specifiedNumberOfSubmissions } = feedbackSetting;
  return showBeforeDueDate && showAfterDueDate && specifiedNumberOfSubmissions > 1;
};

export const getTemplateFeedbackForOneSubmissionAllowed = (
  currentAssignmentTemplate: IAssignmentTemplate
): ILearningToolsAndFeedbackSettings => {
  const feedbackMarkAndScores = isSetToAfterSpecifiedSubmission(
    currentAssignmentTemplate.settings.templateFeedback.showMark
  )
    ? getTemplateFeedbackWith(currentAssignmentTemplate).marksAndScoresAlways()
    : currentAssignmentTemplate.settings.templateFeedback;

  const updateItem = (item: ITableItem): ITableItem => {
    return {
      showBeforeDueDate: isSetToAfterSpecifiedSubmission(item) || item.showBeforeDueDate,
      showAfterDueDate: isSetToAfterSpecifiedSubmission(item) || item.showAfterDueDate,
      specifiedNumberOfSubmissions: 1,
    };
  };

  return {
    ...feedbackMarkAndScores,
    showKey: updateItem(currentAssignmentTemplate.settings.templateFeedback.showKey),
    showSolution: updateItem(currentAssignmentTemplate.settings.templateFeedback.showSolution),
    showHints: updateItem(currentAssignmentTemplate.settings.templateFeedback.showHints),
    showPracticeAnotherVersion: updateItem(
      currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion
    ),
  };
};

export const setTemplateFeedbackOnSubmissionChange = (
  newSubmission: Submission,
  template: IAssignmentTemplate
): ILearningToolsAndFeedbackSettings => {
  const { showMark } = template.settings.templateFeedback;
  if (isSetToAfterSpecifiedSubmission(showMark)) {
    if (newSubmission === Submission.QUESTION_PART) {
      return getTemplateFeedbackWith(template).marksAndScoresAlways();
    }
    return getTemplateFeedbackWith(template).marksAfterSpecifiedSubmission(
      showMark.specifiedNumberOfSubmissions,
      newSubmission
    );
  }
  return template.settings.templateFeedback;
};

export const getTemplateFeedbackOnRandomizationAfterEverySubmission = (
  templateFeedback: ILearningToolsAndFeedbackSettings
): ILearningToolsAndFeedbackSettings => {
  return {
    ...templateFeedback,
    showKey: {
      ...templateFeedback.showKey,
      showBeforeDueDate: false,
      showAfterDueDate: true,
    },
    showSolution: {
      ...templateFeedback.showSolution,
      showBeforeDueDate: false,
      showAfterDueDate: false,
    },
    showPracticeAnotherVersion: {
      ...templateFeedback.showPracticeAnotherVersion,
      showBeforeDueDate: false,
      showAfterDueDate: true,
    },
  };
};

///DROPDOWN SELECT
export const setCorrectTemplateFeedbackOnSelectChange = (
  labelTextKey: FEEDBACK_ITEM,
  selectedItemIndex: string,
  currentAssignmentTemplate: IAssignmentTemplate,
  submissionInputValue: number
): ILearningToolsAndFeedbackSettings => {
  //ALWAYS
  if (selectedItemIndex === '0') {
    if (labelTextKey === FEEDBACK_ITEM.answerKey) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showKey: {
          specifiedNumberOfSubmissions: 0,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
        showSolution: {
          specifiedNumberOfSubmissions: 0,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.helpContent) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showHints: {
          specifiedNumberOfSubmissions: 0,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.practiceAnotherVersion) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showPracticeAnotherVersion: {
          specifiedNumberOfSubmissions: 0,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.studentsPreviousResp) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showResponses: {
          ...currentAssignmentTemplate.settings.templateFeedback.showResponses,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.answerFeedback) {
      return getTemplateFeedbackWith(currentAssignmentTemplate).marksAndScoresAlways();
    }
    if (labelTextKey === FEEDBACK_ITEM.studentScore) {
      return getTemplateFeedbackWith(currentAssignmentTemplate).scoresAlways();
    }
  }
  //AFTER DUE
  if (selectedItemIndex === '1') {
    if (labelTextKey === FEEDBACK_ITEM.answerKey) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showKey: {
          ...currentAssignmentTemplate.settings.templateFeedback.showKey,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
        showSolution: {
          ...currentAssignmentTemplate.settings.templateFeedback.showSolution,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.helpContent) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showHints: {
          ...currentAssignmentTemplate.settings.templateFeedback.showHints,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.practiceAnotherVersion) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showPracticeAnotherVersion: {
          ...currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.studentsPreviousResp) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showResponses: {
          ...currentAssignmentTemplate.settings.templateFeedback.showResponses,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.answerFeedback) {
      if (currentAssignmentTemplate.settings.submissionAndWorkSettings.submission === Submission.QUESTION_PART) {
        return getTemplateFeedbackWith(currentAssignmentTemplate).marksAndScoresAfterDue();
      }
      return getTemplateFeedbackWith(currentAssignmentTemplate).marksAfterDue();
    }
    if (labelTextKey === FEEDBACK_ITEM.studentScore) {
      return getTemplateFeedbackWith(currentAssignmentTemplate).scoresAfterDue();
    }
  }
  //AFTER SPECIFIED SUBMISSIONS
  if (selectedItemIndex === '2') {
    if (labelTextKey === FEEDBACK_ITEM.answerKey) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showKey: {
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: +submissionInputValue,
        },
        showSolution: {
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: +submissionInputValue,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.helpContent) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showHints: {
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: +submissionInputValue,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.practiceAnotherVersion) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showPracticeAnotherVersion: {
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: +submissionInputValue,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.answerFeedback) {
      return getTemplateFeedbackWith(currentAssignmentTemplate).marksAfterSpecifiedSubmission(+submissionInputValue);
    }
  }
  return currentAssignmentTemplate.settings.templateFeedback;
};
//TOGGLE
export const setCorrectTemplateFeedbackOnToggleChange = (
  checkedToggle: boolean,
  labelTextKey: FEEDBACK_ITEM,
  currentAssignmentTemplate: IAssignmentTemplate,
  updateShowToast: Dispatch<SetStateAction<boolean>>,
  updateToastMessage: Dispatch<SetStateAction<string>>
): ILearningToolsAndFeedbackSettings => {
  //OFF
  if (checkedToggle) {
    if (labelTextKey === FEEDBACK_ITEM.answerKey) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showKey: {
          ...currentAssignmentTemplate.settings.templateFeedback.showKey,
          showBeforeDueDate: false,
          showAfterDueDate: false,
        },
        showSolution: {
          ...currentAssignmentTemplate.settings.templateFeedback.showSolution,
          showBeforeDueDate: false,
          showAfterDueDate: false,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.helpContent) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showHints: {
          ...currentAssignmentTemplate.settings.templateFeedback.showHints,
          showBeforeDueDate: false,
          showAfterDueDate: false,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.practiceAnotherVersion) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showPracticeAnotherVersion: {
          ...currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion,
          showBeforeDueDate: false,
          showAfterDueDate: false,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.studentsPreviousResp) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showResponses: {
          ...currentAssignmentTemplate.settings.templateFeedback.showResponses,
          showBeforeDueDate: false,
          showAfterDueDate: false,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.answerFeedback) {
      if (currentAssignmentTemplate.settings.submissionAndWorkSettings.submission === Submission.QUESTION_PART) {
        return getTemplateFeedbackWith(currentAssignmentTemplate).marksAndScoresOff();
      }
      return getTemplateFeedbackWith(currentAssignmentTemplate).marksOff();
    }
    if (labelTextKey === FEEDBACK_ITEM.studentScore) {
      //showMark and scores ALL OFF
      if (isMarksOn(currentAssignmentTemplate)) {
        updateShowToast(true);
        updateToastMessage(i18next.t('ASSIGNMENT_EDITOR.FEEDBACK.TOAST'));
      }
      return getTemplateFeedbackWith(currentAssignmentTemplate).marksAndScoresOff();
    }
  }

  //ON
  if (!checkedToggle) {
    if (labelTextKey === FEEDBACK_ITEM.answerKey) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showKey: {
          ...currentAssignmentTemplate.settings.templateFeedback.showKey,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
        showSolution: {
          ...currentAssignmentTemplate.settings.templateFeedback.showSolution,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.helpContent) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showHints: {
          ...currentAssignmentTemplate.settings.templateFeedback.showHints,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.practiceAnotherVersion) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showPracticeAnotherVersion: {
          ...currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.studentsPreviousResp) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showResponses: {
          ...currentAssignmentTemplate.settings.templateFeedback.showResponses,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    }
    if (labelTextKey === FEEDBACK_ITEM.answerFeedback) {
      return getTemplateFeedbackWith(currentAssignmentTemplate).marksAndScoresAlways();
    }
    if (labelTextKey === FEEDBACK_ITEM.studentScore) {
      return getTemplateFeedbackWith(currentAssignmentTemplate).scoresAlways();
    }
  }
  return currentAssignmentTemplate.settings.templateFeedback;
};
export const setCorrectTemplateFeedbackOnInputChange = (
  labelTextKey: FEEDBACK_ITEM,
  currentAssignmentTemplate: IAssignmentTemplate,
  inputValue: string
): ILearningToolsAndFeedbackSettings => {
  if (labelTextKey === FEEDBACK_ITEM.answerKey) {
    return {
      ...currentAssignmentTemplate.settings.templateFeedback,
      showKey: {
        ...currentAssignmentTemplate.settings.templateFeedback.showKey,
        specifiedNumberOfSubmissions: +inputValue,
      },
      showSolution: {
        ...currentAssignmentTemplate.settings.templateFeedback.showSolution,
        specifiedNumberOfSubmissions: +inputValue,
      },
    };
  }
  if (labelTextKey === FEEDBACK_ITEM.helpContent) {
    return {
      ...currentAssignmentTemplate.settings.templateFeedback,
      showHints: {
        ...currentAssignmentTemplate.settings.templateFeedback.showHints,
        specifiedNumberOfSubmissions: +inputValue,
      },
    };
  }
  if (labelTextKey === FEEDBACK_ITEM.practiceAnotherVersion) {
    return {
      ...currentAssignmentTemplate.settings.templateFeedback,
      showPracticeAnotherVersion: {
        ...currentAssignmentTemplate.settings.templateFeedback.showPracticeAnotherVersion,
        specifiedNumberOfSubmissions: +inputValue,
      },
    };
  }
  if (labelTextKey === FEEDBACK_ITEM.answerFeedback) {
    const { submission } = currentAssignmentTemplate.settings.submissionAndWorkSettings;
    if (submission === Submission.QUESTION) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showMark: {
          ...currentAssignmentTemplate.settings.templateFeedback.showMark,
          specifiedNumberOfSubmissions: +inputValue,
        },
      };
    }
    if (submission === Submission.ASSIGNMENT) {
      return {
        ...currentAssignmentTemplate.settings.templateFeedback,
        showMark: {
          ...currentAssignmentTemplate.settings.templateFeedback.showMark,
          specifiedNumberOfSubmissions: +inputValue,
        },
      };
    }
  }
  return currentAssignmentTemplate.settings.templateFeedback;
};

const getTemplateFeedbackWith = (currentAssignmentTemplate: IAssignmentTemplate) => {
  const {
    settings: {
      templateFeedback,
      submissionAndWorkSettings: { submission },
    },
  } = currentAssignmentTemplate;

  const isShowMarksOn = (): boolean => {
    const { showBeforeDueDate, showAfterDueDate } = templateFeedback.showMark;
    return showBeforeDueDate || showAfterDueDate;
  };

  const isShowMarksAlways = (): boolean => {
    const { showBeforeDueDate, showAfterDueDate, specifiedNumberOfSubmissions } = templateFeedback.showMark;
    return showBeforeDueDate && showAfterDueDate && specifiedNumberOfSubmissions < 1;
  };

  return {
    marksAndScoresOff(): ILearningToolsAndFeedbackSettings {
      return {
        ...templateFeedback,
        showMark: {
          ...templateFeedback.showMark,
          showBeforeDueDate: false,
          showAfterDueDate: false,
          specifiedNumberOfSubmissions: 0,
        },
        showAssignmentScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: false,
          showAfterDueDate: false,
        },
        showQuestionScore: {
          ...templateFeedback.showQuestionScore,
          showBeforeDueDate: false,
          showAfterDueDate: false,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionPartScore: {
          ...templateFeedback.showQuestionPartScore,
          showBeforeDueDate: false,
          showAfterDueDate: false,
          specifiedNumberOfSubmissions: 0,
        },
        showGraderComments: {
          ...templateFeedback.showGraderComments,
          showBeforeDueDate: false,
          showAfterDueDate: false,
        },
      };
    },
    marksAndScoresAlways(): ILearningToolsAndFeedbackSettings {
      return {
        ...templateFeedback,
        showMark: {
          ...templateFeedback.showMark,
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showAssignmentScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionScore: {
          ...templateFeedback.showQuestionScore,
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionPartScore: {
          ...templateFeedback.showQuestionPartScore,
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showGraderComments: {
          ...templateFeedback.showGraderComments,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    },
    marksAndScoresAfterDue(): ILearningToolsAndFeedbackSettings {
      return {
        ...templateFeedback,
        showMark: {
          ...templateFeedback.showMark,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
        showAssignmentScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
        showQuestionScore: {
          ...templateFeedback.showQuestionScore,
          showBeforeDueDate: false,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionPartScore: {
          ...templateFeedback.showQuestionPartScore,
          showBeforeDueDate: false,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showGraderComments: {
          ...templateFeedback.showGraderComments,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
      };
    },
    marksOff(): ILearningToolsAndFeedbackSettings {
      return {
        ...templateFeedback,
        showMark: {
          ...templateFeedback.showMark,
          showBeforeDueDate: false,
          showAfterDueDate: false,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionPartScore: {
          ...templateFeedback.showQuestionPartScore,
          showBeforeDueDate: false,
          showAfterDueDate: false,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionScore: {
          ...templateFeedback.showQuestionScore,
          showBeforeDueDate:
            submission === Submission.ASSIGNMENT ? false : templateFeedback.showQuestionScore.showBeforeDueDate,
          showAfterDueDate:
            submission === Submission.ASSIGNMENT ? false : templateFeedback.showQuestionScore.showAfterDueDate,
          specifiedNumberOfSubmissions: 0,
        },
      };
    },
    marksAfterDue(): ILearningToolsAndFeedbackSettings {
      return {
        ...templateFeedback,
        showMark: {
          ...templateFeedback.showMark,
          showBeforeDueDate: false,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionPartScore: {
          ...templateFeedback.showQuestionPartScore,
          showBeforeDueDate: false,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionScore: {
          ...templateFeedback.showQuestionScore,
          showBeforeDueDate:
            submission === Submission.ASSIGNMENT ? false : templateFeedback.showQuestionScore.showBeforeDueDate,
          showAfterDueDate: submission === Submission.ASSIGNMENT || templateFeedback.showQuestionScore.showAfterDueDate,
          specifiedNumberOfSubmissions: 0,
        },
      };
    },
    marksAfterSpecifiedSubmission(
      submissions: number,
      selectedSubmission: Submission = submission
    ): ILearningToolsAndFeedbackSettings {
      return {
        ...templateFeedback,
        showMark: {
          ...templateFeedback.showMark,
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: +submissions,
        },
        showAssignmentScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: true,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionScore: {
          ...templateFeedback.showQuestionScore,
          showBeforeDueDate: selectedSubmission === Submission.QUESTION,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionPartScore: {
          ...templateFeedback.showQuestionPartScore,
          showBeforeDueDate: false,
          showAfterDueDate: true,
          specifiedNumberOfSubmissions: 0,
        },
        showGraderComments: {
          ...templateFeedback.showGraderComments,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    },
    scoresAlways(): ILearningToolsAndFeedbackSettings {
      return {
        ...templateFeedback,
        showAssignmentScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
        showQuestionScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: submission !== Submission.ASSIGNMENT || isShowMarksAlways(),
          showAfterDueDate: submission !== Submission.ASSIGNMENT || isShowMarksOn(),
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionPartScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: isShowMarksAlways(),
          showAfterDueDate: isShowMarksOn(),
          specifiedNumberOfSubmissions: 0,
        },
        showGraderComments: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: true,
          showAfterDueDate: true,
        },
      };
    },
    scoresAfterDue(): ILearningToolsAndFeedbackSettings {
      return {
        ...templateFeedback,
        showMark: {
          ...templateFeedback.showMark,
          showBeforeDueDate: false,
          showAfterDueDate: isShowMarksOn(),
          specifiedNumberOfSubmissions: 0,
        },
        showAssignmentScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
        showQuestionScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: false,
          showAfterDueDate: submission !== Submission.ASSIGNMENT || isShowMarksOn(),
          specifiedNumberOfSubmissions: 0,
        },
        showQuestionPartScore: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: false,
          showAfterDueDate: isShowMarksOn(),
          specifiedNumberOfSubmissions: 0,
        },
        showGraderComments: {
          ...templateFeedback.showAssignmentScore,
          showBeforeDueDate: false,
          showAfterDueDate: true,
        },
      };
    },
  };
};

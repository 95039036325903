enum DirectorSettings {
  IS_LOGOUT_NOTIFICATION_ENABLED = 'is_logout_notification_enabled',
  //Add other director settings names here
}

interface DirectorSetting<T> {
  name: string;
  type: string;
  value: T;
}

export { DirectorSettings, DirectorSetting };

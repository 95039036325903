import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Input, InputType, Select } from 'react-magma-dom';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import {
  IAssignmentSettings,
  QuestionElementType,
  SaltStatus,
  ScoringAnswers,
  Submission,
} from 'context/AssignmentContextProvider/types';
import {
  getSelectedItem,
  mapDynamicInfoItems,
  mapScoreUsingItems,
  saltOptions,
  setChangedItem,
  submissionsAllowedOptions,
  submissionsAnswerOptions,
} from './selectHelper';
import {
  removeRefWithError,
  setAssignmentTemplate,
  setDisplayAllQuestionsSettings,
  setRefWithError,
} from 'context/AssignmentContextProvider/actions';
import { ISelectItem, SELECT_LABEL, SUBMISSIONS_OTHER } from '../types';
import { validateField } from '../commonHelpers';
import { useTranslation } from 'react-i18next';
import { getFieldValue, translateSelectOptions } from 'utils/utils';
import { SUBMISSION_COUNT } from 'facultyComponents/assignmentEditor/helpers/commonHelpers';

const ScoringAnswersText: { [key: string]: string } = {
  [ScoringAnswers.LAST]: 'ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.LAST_LABEL',
  [ScoringAnswers.QUESTION_PART]: 'ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.QUESTION_PART_LABEL',
  [ScoringAnswers.QUESTION]: 'ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.QUESTION_LABEL',
  [ScoringAnswers.ASSIGNMENT]: 'ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.ASSIGNMENT_LABEL',
};
const SaltSettingsText: { [key: string]: string } = {
  [SaltStatus.FULLY_INTEGRATED]: `ASSIGNMENT_EDITOR.SCORING.SALT_SETTING.INTEGRATED_LABEL`,
  [SaltStatus.LIMITED_INTEGRATED]: `ASSIGNMENT_EDITOR.SCORING.SALT_SETTING.INTEGRATED_LABEL`,
  [SaltStatus.NO_INTEGRATION]: `ASSIGNMENT_EDITOR.SCORING.SALT_SETTING.NOT_INTEGRATED_LABEL`,
};
interface IProps {
  items?: ISelectItem[];
  isNumbOfSub?: boolean;
  label: string;
}

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
`;
const SelectLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const numberOfSubmissionsStyle = { paddingLeft: '1rem', paddingTop: '0.438rem', width: '10rem' };

export const SelectComponent: React.FC<IProps> = (props): ReactElement => {
  const {
    state: {
      errors,
      assignmentSettings: {
        currentAssignmentTemplate,
        assignmentDynamicInfo: { categories },
      },
      questionsData,
    },
    dispatch,
  } = useAssignmentContext();
  const { t } = useTranslation();

  const numbOfSubInputRef = useRef<HTMLInputElement>(null);

  const { isNumbOfSub, label } = props;

  const {
    submissionAndWorkSettings: { allowedSubmissionsCount, submission, scoringAnswers },
    saltStatus,
    categoryId,
  } = currentAssignmentTemplate?.settings as IAssignmentSettings;

  // We are getting a scpecify format of this string example: preset:deduct_credit_subs:5:6:true
  // Where: preset is some global variable,
  // deduct_credit_subs - mode,
  // 5 - amount,
  // 6 - time,
  // true - is deduct option (add based option should be without true)
  const SelectItems: { [key: string]: ISelectItem[] } = {
    [SELECT_LABEL.NumOfSubm]: translateSelectOptions(submissionsAllowedOptions),
    [SELECT_LABEL.SubmitAnsw]: translateSelectOptions(submissionsAnswerOptions),
    [SELECT_LABEL.ScoreUsing]: translateSelectOptions(mapScoreUsingItems(submission)),
    [SELECT_LABEL.gradeBook]: mapDynamicInfoItems(categories),
    [SELECT_LABEL.saltStat]: translateSelectOptions(saltOptions),
  };
  const SelectItemsSelector: { [key: string]: string } = {
    [SELECT_LABEL.NumOfSubm]: String(allowedSubmissionsCount),
    [SELECT_LABEL.SubmitAnsw]: submission,
    [SELECT_LABEL.ScoreUsing]: scoringAnswers,
    [SELECT_LABEL.gradeBook]: String(categoryId),
    [SELECT_LABEL.saltStat]: String(saltStatus),
  };
  const SelectItemIdentifier: { [key: string]: string } = {
    [SELECT_LABEL.NumOfSubm]: 'number_of_submissions',
    [SELECT_LABEL.SubmitAnsw]: 'submission_strategy',
    [SELECT_LABEL.ScoreUsing]: 'scoring',
    [SELECT_LABEL.gradeBook]: 'grade_book',
    [SELECT_LABEL.saltStat]: 'salt_status',
  };

  const otherSubmErrorValue =
    allowedSubmissionsCount < SUBMISSION_COUNT.MIN || allowedSubmissionsCount > SUBMISSION_COUNT.MAX;

  const [selectedItem, updateSelectedItem] = useState<ISelectItem | null>(
    getSelectedItem(label, SelectItems[label], SelectItemsSelector[label], scoringAnswers)
  );

  const [otherSubm, setOtherSubm] = useState<number | null>(allowedSubmissionsCount);
  const [otherSubmError, setOtherSubmError] = useState({
    value: otherSubmErrorValue,
    message: '',
  });
  const [isOtherNumOfSubm, setIsOtherNumOfSubm] = useState(
    getSelectedItem(label, SelectItems[label], SelectItemsSelector[label], scoringAnswers).value === SUBMISSIONS_OTHER
  );
  const selectStyles = {
    width: label === SELECT_LABEL.NumOfSubm ? '60%' : label === SELECT_LABEL.saltStat ? '40%' : '100%',
    marginTop: isOtherNumOfSubm ? '-1.4rem' : '0',
  };
  const [selectError, setSelectError] = useState('');

  const hasMastery = questionsData.some(element => element.elementType === QuestionElementType.MASTERY_SET);

  useEffect(() => {
    const submitAnswerByError = getFieldValue(errors, 'settings.submitAnswerBy');
    submitAnswerByError ? setSelectError(submitAnswerByError) : setSelectError('');
    if (SELECT_LABEL.ScoreUsing === label || SELECT_LABEL.SubmitAnsw === label || SELECT_LABEL.gradeBook) {
      updateSelectedItem(getSelectedItem(label, SelectItems[label], SelectItemsSelector[label], scoringAnswers));
    }
  }, [scoringAnswers, submission, allowedSubmissionsCount, errors, categories]);

  const handleSelectItemChange = (changes: any) => {
    setIsOtherNumOfSubm(false);
    if (changes.selectedItem.value === SUBMISSIONS_OTHER) {
      setOtherSubmError({ value: false, message: '' });
      setIsOtherNumOfSubm(true);
      setOtherSubm(allowedSubmissionsCount);
    }
    if (currentAssignmentTemplate) {
      const changedItems = setChangedItem(
        allowedSubmissionsCount,
        label,
        changes.selectedItem,
        scoringAnswers,
        currentAssignmentTemplate,
        hasMastery
      );
      dispatch(
        setAssignmentTemplate({
          ...currentAssignmentTemplate,
          settings: {
            ...currentAssignmentTemplate.settings,
            ...changedItems.assignment,
            submissionAndWorkSettings: {
              ...currentAssignmentTemplate.settings.submissionAndWorkSettings,
              ...changedItems.submissionAndWorkSettings,
            },
            templateFeedback: {
              ...currentAssignmentTemplate.settings.templateFeedback,
              ...changedItems.templateFeedback,
            },
            questionRandomizationSettings: {
              ...currentAssignmentTemplate.settings.questionRandomizationSettings,
              ...changedItems.questionRandomizationSettings,
            },
          },
        })
      );
      if (label === SELECT_LABEL.SubmitAnsw) {
        if (changes.selectedItem.value === Submission.ASSIGNMENT) {
          dispatch(setDisplayAllQuestionsSettings(true));
        }
      }
      updateSelectedItem(changes.selectedItem);
    }
  };
  // If we choose 'Other' option from number of subm. items, input for custom value will appear
  const handleNumberOfSumbInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateField({
      value: event.target.value,
      min: SUBMISSION_COUNT.MIN,
      max: SUBMISSION_COUNT.MAX,
      message: t('ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ALLOWED_SETTING.ONLY_WHOLE_NUMBER'),
    });
    setOtherSubmError(error);
    dispatch(setRefWithError(numbOfSubInputRef));

    if (currentAssignmentTemplate && event.target.value && !error.value) {
      dispatch(removeRefWithError(numbOfSubInputRef));
      setOtherSubm(+event.target.value);

      const changedItems = setChangedItem(
        +event.target.value,
        label,
        { value: SUBMISSIONS_OTHER, label: '' },
        scoringAnswers,
        currentAssignmentTemplate,
        hasMastery
      );
      dispatch(
        setAssignmentTemplate({
          ...currentAssignmentTemplate,
          settings: {
            ...currentAssignmentTemplate.settings,
            submissionAndWorkSettings: {
              ...currentAssignmentTemplate.settings.submissionAndWorkSettings,
              ...changedItems.submissionAndWorkSettings,
            },
            templateFeedback: {
              ...currentAssignmentTemplate.settings.templateFeedback,
              ...changedItems.templateFeedback,
            },
            questionRandomizationSettings: {
              ...currentAssignmentTemplate.settings.questionRandomizationSettings,
              ...changedItems.questionRandomizationSettings,
            },
          },
        })
      );
    }
  };

  return (
    <SelectWrapper data-testid="selectWrapper">
      <SelectLayout>
        <Select
          testId={`items_select_${SelectItemIdentifier[label]}`}
          labelText={t(label)}
          items={SelectItems[label]}
          containerStyle={selectStyles}
          helperMessage={
            (label === SELECT_LABEL.ScoreUsing && t(ScoringAnswersText[scoringAnswers])) ||
            (label === SELECT_LABEL.saltStat && t(SaltSettingsText[saltStatus]))
          }
          selectedItem={selectedItem}
          onSelectedItemChange={handleSelectItemChange}
          errorMessage={label === SELECT_LABEL.SubmitAnsw && selectError ? selectError : null}
        />
        {isNumbOfSub && isOtherNumOfSubm && (
          <Input
            ref={numbOfSubInputRef}
            testId="numbOfSubInput"
            aria-label={
              t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.NUMBER_OF_SUBMISSION_INPUT_ARIA_LABEL') || 'translate me'
            }
            labelText={t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.NUMBER_OF_SUBMISSION_INPUT_LABEL')}
            type={InputType.number}
            helperMessage={`${SUBMISSION_COUNT.MIN}-${SUBMISSION_COUNT.MAX}`}
            min="0"
            containerStyle={numberOfSubmissionsStyle}
            isLabelVisuallyHidden={true}
            onChange={handleNumberOfSumbInputChange}
            errorMessage={
              otherSubmError.value
                ? otherSubmError.message
                  ? otherSubmError.message
                  : `${SUBMISSION_COUNT.MIN}-${SUBMISSION_COUNT.MAX}`
                : null
            }
            value={otherSubm ? otherSubm : ''}
          />
        )}
      </SelectLayout>
    </SelectWrapper>
  );
};

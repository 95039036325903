import React, { ReactElement, useEffect, useState } from 'react';
import { AlertVariant, Banner, Toggle } from 'react-magma-dom';
import { setSubmissionAndWorkSettings } from 'context/AssignmentContextProvider/actions';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { IAssignmentTemplate, ShowMyWorkMode, Submission } from 'context/AssignmentContextProvider/types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const ShowMyWorkWasDisabled = styled(Banner)<{ variant: AlertVariant }>`
  width: 31.25rem;
  white-space: pre-line;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
`;

interface IProps {
  labelTxt: string;
}

const showMyWorkOptions: { [key: string]: boolean } = {
  [ShowMyWorkMode.DISABLED]: false,
  [ShowMyWorkMode.WAS_DISABLED]: false,
  [ShowMyWorkMode.OPTIONAL]: true,
  [ShowMyWorkMode.REQUIRED]: true,
};

const ToggleComponent: React.FC<IProps> = (props): ReactElement => {
  const {
    state: {
      assignmentSettings: { currentAssignmentTemplate },
    },
    dispatch,
  } = useAssignmentContext();
  const { t } = useTranslation();

  const {
    settings: {
      submissionAndWorkSettings: { showMyWorkMode, submission },
    },
  } = currentAssignmentTemplate as IAssignmentTemplate;

  const { labelTxt } = props;

  const [isAllow, setIsAllow] = useState(showMyWorkOptions[showMyWorkMode]);

  useEffect(() => {
    if (showMyWorkMode === ShowMyWorkMode.REQUIRED && currentAssignmentTemplate) {
      dispatch(
        setSubmissionAndWorkSettings({
          ...currentAssignmentTemplate.settings.submissionAndWorkSettings,
          ...{ showMyWorkMode: ShowMyWorkMode.OPTIONAL },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (submission === Submission.ASSIGNMENT) {
      setIsAllow(false);
    }
  }, [submission]);

  const handleUpdateChecked = () => {
    if (isAllow && currentAssignmentTemplate) {
      setIsAllow(!isAllow);
      dispatch(
        setSubmissionAndWorkSettings({
          ...currentAssignmentTemplate.settings.submissionAndWorkSettings,
          ...{ showMyWorkMode: ShowMyWorkMode.DISABLED },
        })
      );
    }
    if (!isAllow && currentAssignmentTemplate) {
      setIsAllow(!isAllow);
      dispatch(
        setSubmissionAndWorkSettings({
          ...currentAssignmentTemplate.settings.submissionAndWorkSettings,
          ...{ showMyWorkMode: ShowMyWorkMode.OPTIONAL },
        })
      );
    }
  };

  return (
    <>
      {submission === Submission.ASSIGNMENT ? (
        <ShowMyWorkWasDisabled variant={AlertVariant.info} testId="showMyWorkIsDisabledMessage">
          {t('ASSIGNMENT_EDITOR.SCORING.SHOW_MY_WORK_IS_DISABLED_MESSAGE')}
        </ShowMyWorkWasDisabled>
      ) : null}
      <Toggle
        testId="toggleBtn"
        checked={isAllow}
        labelText={labelTxt}
        onChange={handleUpdateChecked}
        disabled={submission === Submission.ASSIGNMENT}
      />
    </>
  );
};

export default ToggleComponent;

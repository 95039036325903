import React from 'react';
import { Button, ButtonColor, ButtonVariant, magma, Modal } from 'react-magma-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { IVersionHistory } from 'context/AssignmentContextProvider/types';

const ModalStyle = styled(Modal)`
  width: 37.5rem;
  height: 32.625rem;
`;
const HistoryContent = styled.div`
  color: ${magma.colors.neutral03};
  font-weight: 400;
  font-size: 1rem;
  padding-bottom: 1rem;
  max-height: 22rem; /* Adjust this value as needed */
  overflow-y: auto; /* Enables vertical scrolling */
`;
const AssignmentInfo = styled.div`
  padding-bottom: 0.5rem;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 2px solid ${({ theme }) => theme.colors.lightGrey};
`;
const TableRow = styled.tr`
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
`;
const TableHeaderCell = styled.th`
  font-weight: bold;
  text-align: left;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
`;
const TableCell = styled.td`
  padding: 0.5rem; /* Padding for every cell */
`;
const ButtonsLayout = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;
const CloseButton = styled(Button)`
  margin-left: 1rem;
`;

interface IProps {
  show: boolean;
  close: () => void;
  name: string;
  id: number;
  versionHistory: IVersionHistory[];
}

export const VersionHistoryModal = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { show, close, name, id, versionHistory } = props;

  return (
    <ModalStyle
      onClose={close}
      isOpen={show}
      header={t('ASSIGNMENT_EDITOR.VERSION_HISTORY.TITLE')}
      testId="versionHistoryModal"
    >
      <HistoryContent>
        <AssignmentInfo>
          {t('ASSIGNMENT_EDITOR.VERSION_HISTORY.ASSIGNMENT')}: {name}: {id}
        </AssignmentInfo>
        <Table>
          <thead>
            <TableRow>
              <TableHeaderCell>{t('ASSIGNMENT_EDITOR.VERSION_HISTORY.SAVED_ON')}</TableHeaderCell>
              <TableHeaderCell>{t('ASSIGNMENT_EDITOR.VERSION_HISTORY.SAVED_BY')}</TableHeaderCell>
            </TableRow>
          </thead>
          <tbody>
            {versionHistory
              ? versionHistory.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>{entry.date}</TableCell>
                    <TableCell>{entry.user}</TableCell>
                  </TableRow>
                ))
              : null}
          </tbody>
        </Table>
      </HistoryContent>
      <ButtonsLayout>
        <CloseButton color={ButtonColor.primary} variant={ButtonVariant.solid} onClick={close} testId="closeButton">
          {t('ASSIGNMENT_EDITOR.BAR.CLOSE')}
        </CloseButton>
      </ButtonsLayout>
    </ModalStyle>
  );
};

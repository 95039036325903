import React, { useContext, createContext, useState } from 'react';
import { LogoutNotification } from 'wa-ui-components';
import { useDirectorAPI } from '../../../service/dataHooks';
import { DirectorSettings, DirectorSetting } from 'context/sharedTypes';
import { ACTIONS } from 'context/data';

interface KmliContextValue {
  isKmliModalOpen: boolean;
}

const KmliContext = createContext<KmliContextValue>({
  isKmliModalOpen: false,
});

interface KmliProviderProps {
  children: React.ReactNode;
  forceShow?: boolean;
}

/*forceShow is a utility flag designed to facilitate testing of the provider. Given the complex logic behind displaying
the LogoutNotification modal, testing can be quite challenging. The flag ensures that the modal appears, making the
testing process easier.*/
export const KmliProvider: React.FC<KmliProviderProps> = ({ children, forceShow = false }) => {
  const [isKmliModalOpen, setIsKmliModalOpen] = useState(false);

  const { data, status: directorStatus } = useDirectorAPI({
    settingName: DirectorSettings.IS_LOGOUT_NOTIFICATION_ENABLED,
  });

  const directorSetting: DirectorSetting<boolean> = data;
  const isLogoutNotificationEnabled = directorStatus === ACTIONS.LOADED && directorSetting.value;

  const setKmliModalClosed = () => {
    setIsKmliModalOpen(false);
  };

  const setKmliModalOpen = () => {
    setIsKmliModalOpen(true);
  };

  return (
    <KmliContext.Provider value={{ isKmliModalOpen }}>
      {isLogoutNotificationEnabled ? (
        <LogoutNotification onCloseModal={setKmliModalClosed} onOpenModal={setKmliModalOpen} forceShow={forceShow} />
      ) : null}
      {children}
    </KmliContext.Provider>
  );
};

export const useKmliModalState = (): KmliContextValue => useContext<KmliContextValue>(KmliContext);
export default KmliProvider;

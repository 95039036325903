/* istanbul ignore file */
//todo: add test code, once we have our first end point, until then ignore

import { useContextAPI, useEditableContextAPI, useStateAPI, getLoadingAction } from '../context/data';
import { DataStateSetterType, InputParamsType } from '../context/data/typings';
import { ActionType, getLoadedAction } from '../context/data/actionCreators';
import { API_NAME_CONSTANTS } from './registry';

/*This file contains hooks, that will be called in UI
 * 1. useContextAPI is used when we need to store the data in context
 * 2. useStateAPI is used when we need to store the data in component state,
 *     when component is un-mounted, data will be lost, will be re-fetched when mounted back
 * */

const useAPI = (apiName: string, apiHook: (apiName: string, inputParams: any) => ActionType, inputData: any = null) => {
  const { data, status } = apiHook(apiName, inputData);

  if (status === 'loading') {
    return getLoadingAction(apiName);
  }

  return getLoadedAction(apiName, data);
};

const useUserAPI = (): ActionType => {
  return useAPI(API_NAME_CONSTANTS.USER, useContextAPI);
};
const useEbooksAPI = (): ActionType => {
  return useAPI(API_NAME_CONSTANTS.EBOOKS, useContextAPI);
};
const useHeaderLinksAPI = (): ActionType => {
  return useAPI(API_NAME_CONSTANTS.HEADER_LINKS, useStateAPI);
};
const useDirectorAPI = (inputData: InputParamsType): ActionType => {
  const [dataState] = useEditableContextAPI(API_NAME_CONSTANTS.DIRECTOR, inputData);
  const { data, status } = dataState;

  return status === 'loading'
    ? getLoadingAction(API_NAME_CONSTANTS.DIRECTOR)
    : getLoadedAction(API_NAME_CONSTANTS.DIRECTOR, data);
};

const useFacultyClassesAPI = (inputData: InputParamsType | undefined): [ActionType, DataStateSetterType] => {
  const [dataState, setDataState] = useEditableContextAPI(API_NAME_CONSTANTS.FACULTY_CLASSES_LINKS, inputData);

  const { data, status } = dataState;

  if (status === 'loading') {
    return [getLoadingAction(API_NAME_CONSTANTS.FACULTY_CLASSES_LINKS), setDataState];
  }

  return [getLoadedAction(API_NAME_CONSTANTS.FACULTY_CLASSES_LINKS, data), setDataState];
};

export { useUserAPI, useEbooksAPI, useHeaderLinksAPI, useFacultyClassesAPI, useDirectorAPI };

/* eslint-disable no-prototype-builtins */
import { useEffect, useContext, useState } from 'react';
import {
  getContextMissingAction,
  getFetcherMissingAction,
  getLoadingAction,
  ActionType,
  ACTIONS,
} from './../actionCreators';
import { StateGetterType, FetchedDataContextType, InputParamsType, DataStateSetterType } from './../typings';
import { fetchData } from './../helper';
// Step 1: Import the Created Context
import { FetchedDataContext } from './Provider';

const getDataState = (
  fetchDataContext: FetchedDataContextType,
  getter: StateGetterType,
  apiName: string
): ActionType => {
  if (
    !fetchDataContext.hasOwnProperty('fetcher') &&
    !fetchDataContext.hasOwnProperty('getter') &&
    !fetchDataContext.hasOwnProperty('dispatch')
  ) {
    return getContextMissingAction();
  }
  // checking context that was injected through prop
  if (!fetchDataContext.fetcher) {
    return getFetcherMissingAction();
  }

  //todo: context Key needs to be combination of apiName and InputParams to avoid conflict
  const apiDataState: ActionType | null = getter(apiName);
  const dataState: ActionType = apiDataState ? apiDataState : getLoadingAction(apiName);

  return dataState;
};

type useContextAPIType = (apiName: string) => ActionType;
const useContextAPI: useContextAPIType = (apiName: string, inputData: any = null) => {
  // Step 2: get values from the Fetch Data Context
  const fetchDataContext: FetchedDataContextType = useContext(FetchedDataContext);
  const { fetcher, getter, dispatch } = fetchDataContext;

  // Step 3: data from store
  const dataState: ActionType = getDataState(fetchDataContext, getter, apiName);

  useEffect(() => {
    // Step 5: if data is not fetched before trigger data fetch
    if (apiName && dataState.status !== ACTIONS.LOADED) {
      // Step 6: Trigger Data fetch, upon response trigger re-render using dispatch
      fetchData(fetcher, apiName, inputData, dispatch);
    }
  }, [apiName, inputData]);

  return dataState;
};

const getContextKey = (apiName: string, inputParams: InputParamsType | undefined): string => {
  if (!inputParams) {
    return apiName;
  }

  let formattedInputParams = '';

  const keys = Object.keys(inputParams);
  const keysCount = keys.length;
  for (let i = 0; i < keysCount; i++) {
    const key = keys[i];
    const value = inputParams[key];
    formattedInputParams = i === 0 ? `${key}:${value}` : `${formattedInputParams}-${key}:${value}`;
  }

  // example Key: user_userId:1234
  return `${apiName}_${formattedInputParams}`;
};

type useEditableContextAPIType = (
  apiName: string,
  inputData: InputParamsType | undefined
) => [ActionType, DataStateSetterType];

const useEditableContextAPI: useEditableContextAPIType = (
  apiName: string,
  initialInputData: InputParamsType | undefined
) => {
  const [inputParams, setInputParams] = useState(initialInputData);
  // Step 2: get values from the Fetch Data Context
  const fetchDataContext: FetchedDataContextType = useContext(FetchedDataContext);
  const { fetcher, getter, dispatch } = fetchDataContext;

  // Step 3: data from store
  const key = getContextKey(apiName, inputParams);
  const dataState: ActionType = getDataState(fetchDataContext, getter, key);

  // Step 5: for change in inputParams trigger data fetch
  useEffect(() => {
    if (apiName && inputParams && dataState.status !== ACTIONS.LOADED) {
      // Step 6: Trigger Data fetch, upon response trigger re-render using dispatch
      const key = getContextKey(apiName, inputParams);
      fetchData(fetcher, apiName, inputParams, dispatch, key);
    }
  }, [inputParams]);

  const setDataState = (inputData: InputParamsType) => {
    // change inputParams state
    // 1. will trigger re-render
    // 2. data-status will be updated to loading at step 3, when new key is not part of context
    // 3. on componentDidUpdate at Step 5, data will be fetched
    // 4. which eventually update context data status to loaded or error
    // 5. and re-renders
    setInputParams(inputData);
  };
  return [dataState, setDataState];
};

export { useContextAPI, useEditableContextAPI };

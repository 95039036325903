import React, { useState } from 'react';
import { Alert, AlertVariant, Flex, FlexBehavior, FlexJustify, Spinner } from 'react-magma-dom';
import { searchCxpItem } from 'facultyComponents/cxpQuestionManager/apiHelpers';
import ResultTable from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/ResultTable';
import InputRow from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/InputRow';
import ButtonGroup from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/ButtonRow';
import styled from '@emotion/styled';
import {
  HEADERS,
  ICxpSearchGroupQuestion,
  IFlattenedCxpResultQuestion,
} from 'context/CxpQuestionContextProvider/types';
import { SortDoubleArrowIcon } from 'react-magma-icons';
import {
  checkRequirements,
  formatQuestionGroupResult,
  sortQuestions,
} from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/cxpQuestionUtil';
import {
  direction,
  GENERAL_ERROR,
  ISort,
  ISortData,
  NOT_FOUND_CXP_ITEMS,
  ROWS_PER_PAGE,
} from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/types';

const Container = styled.div`
  margin: 0;
  width: 700px;
`;

export const CxpQuestionSearcher = (): JSX.Element => {
  // search params
  const [productCode, setProductCode] = React.useState<string>('');
  const [cxpId, setCxpId] = React.useState<string>('');
  const [questionName, setQuestionName] = React.useState<string>('');
  // questions
  const [questionsSort, setQuestionsSort] = useState<ISortData>({ sortedQuestions: [], page: 1, pages: 1 });
  const [sortVal, setSortVal] = useState<ISort>({
    sortDirection: direction.NEUTRAL,
    paint: <SortDoubleArrowIcon testId="sortPaintId" />,
  });

  const [hasCxpRepoError, setHasCxpRepoError] = React.useState(false);
  const [disableSpinner, setDisableSpinner] = React.useState(true);
  const [message, setMessage] = React.useState('');
  const [alertVar, setAlertVar] = React.useState<AlertVariant | undefined>(AlertVariant.info);
  const [blockSearch, setBlockSearch] = React.useState<boolean>(false);
  const [messageToast, setMessageToast] = React.useState({
    messageToast: '',
    typeToast: AlertVariant.success,
    dismissToast: false,
    showToast: false,
    disableAutoDismiss: false,
  });
  const [modalData, setModalData] = React.useState({
    cxpModalActive: false,
    cxpId: 0,
  });
  const sort = () => sortQuestions({ questionsSort, setSortVal, sortVal, setQuestionsSort });

  function errorHandling(message: string) {
    setMessageToast({ ...messageToast, showToast: false });
    setBlockSearch(false);
    setDisableSpinner(true);
    setQuestionsSort({ sortedQuestions: [], page: 1, pages: 1 });
    setAlertVar(AlertVariant.danger);
    setMessage(message);
  }

  const handleUploadClick = async () => {
    if (checkRequirements(cxpId, productCode, questionName, setHasCxpRepoError, setQuestionsSort)) {
      try {
        await getQuestions();
      } catch (error) {
        errorHandling(GENERAL_ERROR);
      }
    }
  };

  const getQuestions = async () => {
    setModalData({ cxpModalActive: false, cxpId: 0 });
    setMessageToast({ ...messageToast, showToast: false });
    setDisableSpinner(false);
    setBlockSearch(true);
    setMessage('');
    setSortVal({ sortDirection: direction.NEUTRAL, paint: <SortDoubleArrowIcon testId="sortPaintId" /> });
    const cxpQuestions: {
      data: { result: ICxpSearchGroupQuestion[]; status: number };
    } = await searchCxpItem({
      productCode,
      cxpId,
      questionName,
    });
    const arr: ICxpSearchGroupQuestion[] = cxpQuestions.data.result;
    const mappedQuestions = arr.map((element, index) => formatQuestionGroupResult(element, index, setModalData));
    setDisableSpinner(true);
    if (arr.length > 0) {
      setQuestionsSort({
        sortedQuestions: mappedQuestions,
        page: 1,
        pages: Math.ceil(mappedQuestions.length / ROWS_PER_PAGE),
      });
    } else {
      setQuestionsSort({ sortedQuestions: [], page: 1, pages: 1 });
      setAlertVar(AlertVariant.info);
      setMessage(NOT_FOUND_CXP_ITEMS);
    }
    setBlockSearch(false);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    func: {
      (value: React.SetStateAction<string>): void;
      (value: React.SetStateAction<string>): void;
      (value: React.SetStateAction<string>): void;
      (arg0: string): void;
    }
  ): void => {
    e.preventDefault();
    const { value } = e.target;
    setHasCxpRepoError(false);
    func(value);
  };

  const handleReset = (): void => {
    setQuestionName('');
    setCxpId('');
    setProductCode('');
  };

  const getCxpItem = (itemId: number): IFlattenedCxpResultQuestion | undefined => {
    return questionsSort.sortedQuestions.find(item => item[HEADERS.CXP_ITEM_ID].props.children === itemId);
  };

  const setCxpItem = async (cxpId: string, repo: string, index: number) => {
    const resp = await searchCxpItem({
      productCode: repo,
      cxpId: cxpId,
      questionName: questionName,
    });
    const resultItem: ICxpSearchGroupQuestion = resp.data.result[0];

    const arrIndex = questionsSort.sortedQuestions.findIndex(
      item => item.id == index && cxpId == item[HEADERS.CXP_ITEM_CXP_ID]
    );
    questionsSort.sortedQuestions[arrIndex] = formatQuestionGroupResult(resultItem, index, setModalData);

    setQuestionsSort({
      sortedQuestions: [...questionsSort.sortedQuestions],
      page: questionsSort.page,
      pages: questionsSort.pages,
    });
  };
  return (
    <>
      <Container>
        <InputRow
          testId="inputRepoId"
          hasError={hasCxpRepoError}
          handleOnChange={handleOnChange}
          fieldValue={productCode}
          setField={setProductCode}
          child="Geyser Repo:"
        />
        <InputRow
          testId="inputCxpId"
          handleOnChange={handleOnChange}
          fieldValue={cxpId}
          setField={setCxpId}
          child="CXP Item ID:"
        />
        <InputRow
          testId="inputQuestionId"
          handleOnChange={handleOnChange}
          fieldValue={questionName}
          setField={setQuestionName}
          child="WA Question name:"
        />
        <ButtonGroup handleReset={handleReset} handleUploadClick={handleUploadClick} isBlock={blockSearch} />
      </Container>
      {disableSpinner || (
        <Flex behavior={FlexBehavior.container} justify={FlexJustify.center}>
          <Spinner size="3em" />
        </Flex>
      )}
      <ResultTable
        questionsSort={questionsSort}
        setQuestionsSort={setQuestionsSort}
        setBlockFlag={setBlockSearch}
        getQuestions={getQuestions}
        messageToast={messageToast}
        setMessageToast={setMessageToast}
        errorHandling={errorHandling}
        sortQuestions={sort}
        sortVal={sortVal}
        getCxpItem={getCxpItem}
        setCxpItem={setCxpItem}
        modalData={modalData}
        setModalData={setModalData}
      />
      {message === '' || (
        <Alert testId="alertId" variant={alertVar}>
          {message}
        </Alert>
      )}
      <br />
    </>
  );
};

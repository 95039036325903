import React from 'react';
import { Button, ButtonColor, ButtonVariant, Container } from 'react-magma-dom';
import { WestIcon } from 'react-magma-icons';
interface IProps {
  setModalData: React.Dispatch<React.SetStateAction<{ cxpModalActive: boolean; cxpId: number }>>;
}
const ReturnButton = ({ setModalData }: IProps) => {
  const handleClick = () => {
    setModalData({ cxpModalActive: false, cxpId: 0 });
  };

  return (
    <Container>
      <Button
        onClick={() => handleClick()}
        color={ButtonColor.secondary}
        variant={ButtonVariant.link}
        style={{ padding: '0px', color: ' #004165' }}
        testId="return_button_id"
      >
        <WestIcon style={{ color: '#006298' }} /> Back to search results
      </Button>
    </Container>
  );
};

export default ReturnButton;

export const checkMinMax = (
  value: number,
  min: number,
  max: number,
  minMessage = '',
  maxMessage = ''
): { value: boolean; message: string } => {
  return {
    value: value < min || value > max,
    message: value < min ? minMessage : maxMessage,
  };
};

export const checkIsWholeNumber = (value: string, message: string): { value: boolean; message: string } => {
  const numberLength = value.split('.').length;
  return { value: numberLength > 1, message: numberLength > 1 ? message : '' };
};
export const checkIsDecimalOnly = (value: string, message: string): { value: boolean; message: string } => {
  const amountOfNumberAfterDot = value.split('.')[1] ? value.split('.')[1].length : 1;
  return { value: amountOfNumberAfterDot > 1, message: amountOfNumberAfterDot > 1 ? message : '' };
};

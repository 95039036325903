import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import QuestionDisplayGroup from 'facultyComponents/assignmentEditor/assignmentEditorSettings/CheatingDeterrents/CheatingDeterrentsElements/QuestionDisplayGroup';
import QuestionRandomizationGroup from 'facultyComponents/assignmentEditor/assignmentEditorSettings/CheatingDeterrents/CheatingDeterrentsElements/QuestionRandomizationGroup';

const FeedbackLayout = styled.div`
  display: flex;
`;
const LeftPan = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: 0 1.5rem;
`;
const RightPan = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(65% - 2rem);
  margin: 0 1rem;
`;

const CheatingDeterrentsTab = (): ReactElement => {
  return (
    <FeedbackLayout>
      <LeftPan>
        <QuestionDisplayGroup />
      </LeftPan>
      <RightPan>
        <QuestionRandomizationGroup />
      </RightPan>
    </FeedbackLayout>
  );
};

export default CheatingDeterrentsTab;

import React, { ReactElement, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  LoadingIndicator,
  Tab,
  TabPanel,
  TabPanelsContainer,
  Tabs,
  TabsContainer,
  TabsOrientation,
} from 'react-magma-dom';
import { AssignmentEditorAccordion } from '../helpers/Accordion/AssignmentEditorAccordion';
import ScoringTab from './ScoringTab';
import TemplatesSelect from '../helpers/TemplatesSelect/TemplatesSelect';
import FeedbackTab from './FeedbackTab';
import CheatingDeterrents from 'facultyComponents/assignmentEditor/assignmentEditorSettings/CheatingDeterrents/CheatingDeterrentsTab';
import { useTranslation } from 'react-i18next';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { getErrorForSubmitAnswerBy } from 'facultyComponents/assignmentEditor/helpers/ScoringTabSelect/selectHelper';
import { getFieldValue } from 'utils/utils';
import { setErrors, setAssignmentAndQuestionsShowMyWorkSettings } from 'context/AssignmentContextProvider/actions';
import { ShowMyWorkMode, Submission } from 'context/AssignmentContextProvider/types';

// General layout
const SettingsTemplate = styled.div`
  display: flex;
  flex-direction: column;
`;
const HorizontalLine = styled.hr`
  width: 100%;
  color: ${({ theme }) => theme.colors.darkGrey};
`;
const StyledTabsContainer = styled(TabsContainer)`
  padding: 1.25rem;
`;
const StyledTabPanelsContainer = styled(TabPanelsContainer)`
  border-left: 0.063rem solid ${({ theme }) => theme.colors.darkGrey};
  margin: 0 1rem;
`;

const ComboboxBlock = styled.div`
  margin: 1.25rem;
  margin-top: 0.25rem;
`;

const AssignmentEditorSettings = (): ReactElement => {
  const {
    state: {
      errors,
      accordionState,
      assignmentSettings: {
        currentAssignmentTemplate: {
          settings: {
            submissionAndWorkSettings,
            questionRandomizationSettings: { randomization },
          },
        },
      },
      questionsData,
      isSettingsLoading,
      isQuestionsLoading,
    },
    dispatch,
  } = useAssignmentContext();
  const { submission } = submissionAndWorkSettings;
  // LOADING FLAG FOR THE ASSIGNMENT TEMPLATE BLOCK
  const [isTemplateChanging, setIsTemplateChanging] = useState(false);
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState(0);

  useEffect(() => {
    if (getFieldValue(errors, 'settings.submitAnswerBy')) {
      setActiveIndex(0);
    }
  }, [accordionState]);

  const handleChange = (index: number) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    dispatch(
      setErrors({
        field: 'settings.submitAnswerBy',
        value: getErrorForSubmitAnswerBy({ submission, questionsData, randomization, t }),
      })
    );
  }, [submission, questionsData, randomization]);

  useEffect(() => {
    if (submission === Submission.ASSIGNMENT && questionsData.length) {
      dispatch(setAssignmentAndQuestionsShowMyWorkSettings(ShowMyWorkMode.DISABLED));
    }
  }, [submission, isQuestionsLoading]);

  return (
    <AssignmentEditorAccordion
      testId="settings"
      btnText={t('ASSIGNMENT_EDITOR.SETTINGS.TITLE')}
      accordionType={'settingsOpen'}
    >
      {isSettingsLoading ? (
        <LoadingIndicator />
      ) : (
        <SettingsTemplate>
          <ComboboxBlock>
            <TemplatesSelect setIsTemplateChanging={setIsTemplateChanging} />
          </ComboboxBlock>
          <HorizontalLine />
          <StyledTabsContainer activeIndex={activeIndex}>
            <Tabs
              aria-label={t('ASSIGNMENT_EDITOR.SETTINGS.TABS') || 'translate me'}
              orientation={TabsOrientation.vertical}
              onChange={handleChange}
            >
              <Tab testId="scoringTab">{t('ASSIGNMENT_EDITOR.SETTINGS.SCORING_TAB')}</Tab>
              <Tab testId="cheatingDeterrentsTab">{t('ASSIGNMENT_EDITOR.SETTINGS.CHEATING_DETERRENTS_TAB')}</Tab>
              <Tab testId="feedbackTab">{t('ASSIGNMENT_EDITOR.SETTINGS.STUDENT_FEEDBACK_TAB')}</Tab>
            </Tabs>
            <StyledTabPanelsContainer>
              <TabPanel testId="scoringTabPanel">{isTemplateChanging ? <LoadingIndicator /> : <ScoringTab />}</TabPanel>
              <TabPanel testId="cheatingDeterrentsTabPanel">
                {isTemplateChanging ? <LoadingIndicator /> : <CheatingDeterrents />}{' '}
              </TabPanel>
              <TabPanel testId="feedbackTabPanel">
                {isTemplateChanging ? <LoadingIndicator /> : <FeedbackTab />}
              </TabPanel>
            </StyledTabPanelsContainer>
          </StyledTabsContainer>
        </SettingsTemplate>
      )}
    </AssignmentEditorAccordion>
  );
};

export default AssignmentEditorSettings;

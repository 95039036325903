import { IAssignmentTemplate } from 'context/AssignmentContextProvider/types';
import { mapToSupportedPresetFormula } from '../PointsAdjustment/ConditionalPointsSettings';

export const assignmentSettingsDefaults = (template: IAssignmentTemplate): IAssignmentTemplate => {
  template.settings.conditionalPointsFormula = mapToSupportedPresetFormula(
    template.settings.conditionalPointsFormula,
    true
  );
  return template;
};

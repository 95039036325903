import React from 'react';

import { useTranslation } from 'react-i18next';

import { Tab } from 'react-magma-dom';
import { LibraryAddCheckIcon } from 'react-magma-icons';

import { SubmissionSettings } from './SubmissionSettings';

interface ISubmissionTabProps {
  id: string;
  testId: string;
  isMastery: boolean;
}

interface ISubmissionTabPanelProps {
  index: number;
}

export const SubmissionsTab = (props: ISubmissionTabProps): JSX.Element => {
  const { id, testId, isMastery } = props;
  const { t } = useTranslation();

  return (
    <Tab icon={<LibraryAddCheckIcon />} id={id} testId={testId}>
      {isMastery
        ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.SUBMISSION_SETTINGS_MASTERY')
        : t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.SUBMISSION_SETTINGS')}
    </Tab>
  );
};

export const SubmissionTabPanel = (props: ISubmissionTabPanelProps): JSX.Element => {
  const { index } = props;

  return <SubmissionSettings questionElementIndex={index} />;
};

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { validateField } from '../../commonHelpers';
import {
  setAdjustAllQuestionConditionalPoints,
  setAssignmentTemplatePointsAdjustment,
  setQuestionElementPointsAdjustment,
} from 'context/AssignmentContextProvider/actions';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { InputAutocorrect, StandardErrorFormat } from 'sharedComponents/InputAutocorrect/InputAutocorrect';
import { isAssignmentQuestion, isQuestionPool } from 'facultyComponents/assignmentEditor/helpers/questionElements';

import { IPointsAdjustments } from '../PointsAdjustment';
import { IConditionalPointSettings, makeConditionalPointsFormula, PresetDefaults } from '../ConditionalPointsSettings';
import {
  InputsWrapper,
  InputWithLeftLabel,
  InputWithPercent,
  MagmaInputContainerStyle,
  MagmaInputMessageStyle,
  VisuallyHiddenSpan,
} from '../InputContainers';

export interface ISubmissionBonusPenalty {
  isDeduct: boolean;
}

const Percent = styled.span`
  height: 3rem;
`;

export const SubmissionBonusPenalty = (
  props: IPointsAdjustments & ISubmissionBonusPenalty & IConditionalPointSettings
): JSX.Element => {
  const { isDeduct, questionElementIndex, amount, time } = props;
  const {
    state: {
      assignmentSettings: { currentAssignmentTemplate },
      questionsData,
    },
    dispatch,
  } = useAssignmentContext();
  const { t } = useTranslation();

  const questionElement = questionElementIndex !== undefined ? questionsData[questionElementIndex] : undefined;

  const assignmentAllowedSubmissions =
    currentAssignmentTemplate.settings.submissionAndWorkSettings.allowedSubmissionsCount;
  const questionAllowedSubmissions =
    questionElement && isAssignmentQuestion(questionElement)
      ? questionElement.boxes[0].gradingSettings.allowedSubmissionsCount
      : questionElement && isQuestionPool(questionElement)
      ? questionElement.gradingSettings.allowedSubmissionsCount
      : null;

  const allowedSubmissionsCount =
    questionAllowedSubmissions !== null ? questionAllowedSubmissions : assignmentAllowedSubmissions;

  const maxSubmissions = allowedSubmissionsCount == 1 ? 1 : allowedSubmissionsCount - 1;
  const submissionsHelperMessage = `1-${maxSubmissions}`;

  const maxTime = 168;
  const timeHelperMessage = `1-${maxTime}`;

  const validateTime = (value: string): StandardErrorFormat => {
    const max = isDeduct ? maxSubmissions : maxTime;
    const genericMessage = t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.ENTER_WHOLE_NUMBER', {
      maximum: max,
    });
    const error = validateField({
      value,
      min: 1,
      max,
      minMessage: genericMessage,
      maxMessage: genericMessage,
      message: genericMessage,
    });
    return { error: error.value, message: error.message };
  };

  const validateAmount = (value: string): StandardErrorFormat => {
    const genericMessage = t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.ENTER_WHOLE_NUMBER', {
      maximum: 100,
    });
    const error = validateField({
      value,
      min: 1,
      max: 100,
      minMessage: genericMessage,
      maxMessage: genericMessage,
      message: genericMessage,
    });
    return { error: error.value, message: error.message };
  };

  const defaults = isDeduct ? PresetDefaults.SUBMISSION_PENALTY : PresetDefaults.EARLY_SUBMISSION_BONUS;
  const [pointAdjTime, setPointAdjTime] = useState<number>(time || defaults.time || 1);
  const [pointAdjAmount, setPointAdjAmount] = useState<number>(amount || defaults.amount || 1);

  useEffect(() => {
    if (time) {
      setPointAdjTime(time);
    }
    if (amount) {
      setPointAdjAmount(amount);
    }
  }, [amount, time]);

  useEffect(() => {
    const settings = {
      ...PresetDefaults[isDeduct ? 'SUBMISSION_PENALTY' : 'EARLY_SUBMISSION_BONUS'],
    };
    settings.amount = pointAdjAmount;
    settings.time = pointAdjTime;
    if (questionElementIndex !== undefined) {
      dispatch(
        setQuestionElementPointsAdjustment({
          elementIndex: questionElementIndex,
          conditionalPointsFormula: makeConditionalPointsFormula(settings),
        })
      );
    } else {
      if (pointAdjAmount != amount || pointAdjTime != time) {
        const conditionalPointsFormula = makeConditionalPointsFormula(settings);
        dispatch(setAssignmentTemplatePointsAdjustment({ conditionalPointsFormula }));
        dispatch(setAdjustAllQuestionConditionalPoints({ conditionalPointsFormula }));
      }
    }
  }, [pointAdjTime, pointAdjAmount]);

  const updateTimeDependencies = (time: number) => {
    setPointAdjTime(time);
  };
  const updateAmountDependencies = (amount: number) => {
    setPointAdjAmount(amount);
  };

  const testIdSuffix = questionElementIndex !== undefined ? questionElementIndex : '';

  return (
    <InputsWrapper>
      <InputWithLeftLabel data-testid={`pointAdjTime${testIdSuffix}`}>
        <label data-testid="timeInputLabel" htmlFor={`pointAdjTimeInput${testIdSuffix}`}>
          {isDeduct
            ? t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.DEDUCTED_TIME_INPUT_LABEL')
            : t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.DUE_DATE_TIME_INPUT_LABEL')}
        </label>
        <InputWithPercent>
          <InputAutocorrect
            id={`pointAdjTimeInput${testIdSuffix}`}
            testId={`pointAdjTimeInput${testIdSuffix}`}
            value={pointAdjTime.toString()}
            validator={validateTime}
            validationError={{ error: false, message: '' }}
            dependency={allowedSubmissionsCount}
            onValidChange={(value: string) => updateTimeDependencies(parseFloat(value))}
            containerStyle={MagmaInputContainerStyle}
            messageStyle={MagmaInputMessageStyle}
            helperMessage={isDeduct ? submissionsHelperMessage : timeHelperMessage}
          />
        </InputWithPercent>
      </InputWithLeftLabel>
      <InputWithLeftLabel data-testid={`pointAdjAmount${testIdSuffix}`}>
        <label data-testid="amountInputLabel" htmlFor={`pointAdjAmountInput${testIdSuffix}`}>
          {isDeduct
            ? t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.DEDUCT_AMOUNT')
            : t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.ADD_AMOUNT')}
          <VisuallyHiddenSpan>{t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.PERCENT')}</VisuallyHiddenSpan>
        </label>
        <InputWithPercent>
          <InputAutocorrect
            id={`pointAdjAmountInput${testIdSuffix}`}
            testId={`pointAdjAmountInput${testIdSuffix}`}
            value={pointAdjAmount.toString()}
            validator={validateAmount}
            validationError={{ error: false, message: '' }}
            onValidChange={(value: string) => updateAmountDependencies(parseFloat(value))}
            containerStyle={MagmaInputContainerStyle}
            messageStyle={MagmaInputMessageStyle}
            helperMessage="1-100"
          />
          <Percent>%</Percent>
        </InputWithPercent>
      </InputWithLeftLabel>
    </InputsWrapper>
  );
};

import {
  IAssignmentTemplate,
  IQuestionElement,
  QuestionElementType,
  Randomization,
  ScoringAnswers,
  Submission,
  SubmissionType,
} from 'context/AssignmentContextProvider/types';
import { ISelectItem, SELECT_LABEL, SUBMISSIONS_OTHER } from '../types';
import { isAssignmentQuestion, isQuestionPool } from 'facultyComponents/assignmentEditor/helpers/questionElements';
import {
  getTemplateFeedbackForOneSubmissionAllowed,
  getTemplateFeedbackOnRandomizationAfterEverySubmission,
  setTemplateFeedbackOnSubmissionChange,
} from '../FeedbackTabItem/feedbackItemHelper';

export const submissionsAllowedOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: 'ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ALLOWED_SETTING.OPTIONS.UNLIMITED', value: '100' },
  { label: 'ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ALLOWED_SETTING.OPTIONS.OTHER', value: 'Other' },
];

export const submissionsAnswerOptions = [
  { label: 'ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ANSWER_SETTING.OPTIONS.PARTS', value: 'y' },
  { label: 'ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ANSWER_SETTING.OPTIONS.QUESTION', value: 'q' },
  { label: 'ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ANSWER_SETTING.OPTIONS.ASSIGNMENT', value: 'n' },
];
export const scoreUsingOptions = [
  { label: 'ASSIGNMENT_EDITOR.SCORING.SCORING_USING_SETTING.OPTIONS.LAST', value: 'last' },
  { label: 'ASSIGNMENT_EDITOR.SCORING.SCORING_USING_SETTING.OPTIONS.PART', value: 'part' },
  { label: 'ASSIGNMENT_EDITOR.SCORING.SCORING_USING_SETTING.OPTIONS.QUESTION', value: 'question' },
  { label: 'ASSIGNMENT_EDITOR.SCORING.SCORING_USING_SETTING.OPTIONS.ASSIGNMENT', value: 'assignment' },
];

export const saltOptions = [
  { label: 'ASSIGNMENT_EDITOR.SCORING.SALT_SETTING.OPTIONS.ON', value: 'on' },
  { label: 'ASSIGNMENT_EDITOR.SCORING.SALT_SETTING.OPTIONS.LITE', value: 'lite' },
  { label: 'ASSIGNMENT_EDITOR.SCORING.SALT_SETTING.OPTIONS.OFF', value: 'off' },
];
export const mapDynamicInfoItems = (dymamicInfo: { id: string; name: string }[]): ISelectItem[] => {
  return dymamicInfo.map(dynInfoItem => {
    return { label: dynInfoItem.name, value: dynInfoItem.id };
  });
};

export const mapScoreUsingItems = (submission_type: string): ISelectItem[] => {
  if (submission_type === SubmissionType.QUESTION_PART) {
    return scoreUsingOptions.slice(0, 2);
  }
  if (submission_type === SubmissionType.QUESTION) {
    return scoreUsingOptions.slice(0, 3);
  }
  if (submission_type === SubmissionType.ASSIGNMENT) {
    return scoreUsingOptions.slice(0, 4);
  }
  return scoreUsingOptions;
};

export const getSelectedItem = (
  label: string,
  SelectItems: ISelectItem[],
  SelectItemsSelector: string,
  grade_which: string
): ISelectItem => {
  if (SELECT_LABEL.ScoreUsing === label && !SelectItems.some(el => el.value === grade_which)) {
    return SelectItems[0];
  }

  // For the any other select we need just to find correct option from the necessary array of items
  if (SelectItems.find((subItem: ISelectItem) => subItem.value === SelectItemsSelector)) {
    return SelectItems.find((subItem: ISelectItem) => subItem.value === SelectItemsSelector) as ISelectItem;
  }

  return { label: SUBMISSIONS_OTHER, value: SUBMISSIONS_OTHER };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setChangedItem = (
  allowedSubmissionsCount: number | null,
  label: string,
  selectedItem: ISelectItem,
  scoringAnswers: string,
  currentAssignmentTemplate: IAssignmentTemplate,
  hasMastery: boolean
) => {
  const changedItems = {
    submissionAndWorkSettings: {}, //submissions_allowed, submission_type, grade_which
    assignment: {}, //category, category_name, point_adjustments, salt settings
    templateFeedback: {}, //showMark, showQuestionPartScore, showQuestionScore, showAssignmentScore
    questionRandomizationSettings: {},
  };
  switch (label) {
    case SELECT_LABEL.NumOfSubm:
      const processedSubmissionsCount = isNaN(+selectedItem.value) ? allowedSubmissionsCount || 1 : +selectedItem.value;
      changedItems.submissionAndWorkSettings = {
        allowedSubmissionsCount: processedSubmissionsCount,
      };
      if (processedSubmissionsCount === 1) {
        changedItems.templateFeedback = getTemplateFeedbackForOneSubmissionAllowed(currentAssignmentTemplate);
        changedItems.questionRandomizationSettings = {
          randomization:
            currentAssignmentTemplate.settings.questionRandomizationSettings.randomization === Randomization.SAME_VALUES
              ? Randomization.SAME_VALUES
              : Randomization.DIFFERENT_VALUES,
        };
      }
      if (
        processedSubmissionsCount !== 1 &&
        hasMastery &&
        currentAssignmentTemplate.settings.questionRandomizationSettings.randomization !== Randomization.AFTER_EVRY_SUBM
      ) {
        changedItems.templateFeedback = getTemplateFeedbackOnRandomizationAfterEverySubmission(
          currentAssignmentTemplate.settings.templateFeedback
        );
        changedItems.questionRandomizationSettings = {
          randomization: Randomization.AFTER_EVRY_SUBM,
          randomizationSubmissionsCount: 1,
        };
      }
      break;
    case SELECT_LABEL.SubmitAnsw:
      changedItems.submissionAndWorkSettings = { submission: selectedItem.value };
      if (!mapScoreUsingItems(selectedItem.value).some(el => el.value === scoringAnswers)) {
        changedItems.submissionAndWorkSettings = {
          submission: selectedItem.value,
          scoringAnswers: ScoringAnswers.LAST,
        };
      }
      changedItems.templateFeedback = setTemplateFeedbackOnSubmissionChange(
        selectedItem.value as Submission,
        currentAssignmentTemplate
      );
      break;
    case SELECT_LABEL.ScoreUsing:
      changedItems.submissionAndWorkSettings = { scoringAnswers: selectedItem.value as ScoringAnswers };
      break;
    case SELECT_LABEL.saltStat:
      changedItems.assignment = { saltStatus: selectedItem.value };
      break;
    case SELECT_LABEL.gradeBook:
      changedItems.assignment = {
        categoryId: +selectedItem.value,
        categoryName: selectedItem.label,
      };
      break;
  }
  return changedItems;
};

const containsAssignmentSubmissionNotAllowedQuestions = (questionElements: IQuestionElement[]): boolean => {
  return questionElements.some(element => {
    if (isAssignmentQuestion(element)) {
      return element.entireAssignmentSubmissionNotAllowed;
    }
    if (isQuestionPool(element)) {
      return element.questions.some(pQuestion => pQuestion.entireAssignmentSubmissionNotAllowed);
    }
  });
};

const containsMasterySet = (questionsData: IQuestionElement[]): boolean => {
  return questionsData.some(element => {
    return element.elementType === QuestionElementType.MASTERY_SET;
  });
};

const containsQuestionPartsSubmissionsRequiredQuestions = (questionElements: IQuestionElement[]): boolean => {
  return questionElements.some(element => {
    if (isAssignmentQuestion(element)) {
      return element.isQuestionPartSubmissionRequired;
    }
    if (isQuestionPool(element)) {
      return element.questions.some(pQuestion => pQuestion.isQuestionPartSubmissionRequired);
    }
  });
};

interface IDataForErrorForSubmitAnswerBy {
  submission: Submission;
  questionsData: IQuestionElement[];
  randomization: Randomization;
  t: (stringValue: string) => string;
}

export const getErrorForSubmitAnswerBy = (dataForGettingError: IDataForErrorForSubmitAnswerBy): string | undefined => {
  const { submission, questionsData, randomization, t } = dataForGettingError;
  const isAssignmentSubmissionNotAllowed =
    containsAssignmentSubmissionNotAllowedQuestions(questionsData) && submission === Submission.ASSIGNMENT;
  const isQuestionSubmissionRequired = containsMasterySet(questionsData) && submission !== Submission.QUESTION;
  const isQuestionPartsSubmissionRequired =
    containsQuestionPartsSubmissionsRequiredQuestions(questionsData) && submission !== Submission.QUESTION_PART;
  if (isAssignmentSubmissionNotAllowed || (isQuestionSubmissionRequired && isQuestionPartsSubmissionRequired)) {
    return t('ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ANSWER_SETTING.ERROR.ASSIGNMENT_SUBMISSION_NOT_ALLOWED');
  }
  if (isQuestionSubmissionRequired) {
    return t('ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ANSWER_SETTING.ERROR.QUESTION_REQUIRED_FOR_MASTERY');
  }
  if (isQuestionPartsSubmissionRequired) {
    return t('ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ANSWER_SETTING.ERROR.QUESTION_PARTS_REQUIRED');
  }
  if (submission === Submission.QUESTION_PART && randomization === Randomization.AFTER_EVRY_SUBM) {
    return t('ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ANSWER_SETTING.ERROR.QUESTION_REQUIRED_AS_RANDOMIZED');
  }
  return undefined;
};

import { NorthIcon, SouthIcon } from 'react-magma-icons';
import {
  HEADERS,
  ICxpRequest,
  ICxpSearchGroupQuestion,
  ICxpTextbookQuestion,
  IFlattenedCxpResultQuestion,
  IFlattenedTextbookResultQuestion,
} from 'context/CxpQuestionContextProvider/types';
import React from 'react';
import {
  direction,
  ISort,
  ISortData,
  NEXT_PAGE,
  PREVIOUS_PAGE,
} from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/types';
import { ToolTip } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/ToolTip';
import DateFormatter from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/DateFormatter';
import { format } from 'date-fns';

interface IProps {
  questionsSort: ISortData;
  setSortVal: React.Dispatch<React.SetStateAction<ISort>>;
  sortVal: ISort;
  setQuestionsSort: React.Dispatch<React.SetStateAction<ISortData>>;
}

export const sortQuestions = ({ questionsSort, setSortVal, sortVal, setQuestionsSort }: IProps): void => {
  if (direction.NEUTRAL === sortVal.sortDirection || direction.DESC === sortVal.sortDirection) {
    setSortVal({
      sortDirection: direction.ASC,
      paint: <SouthIcon />,
    });
    const sortedQuestions = questionsSort.sortedQuestions.sort((a, b) =>
      a[HEADERS.LAST_SYNCED].props.val > b[HEADERS.LAST_SYNCED].props.val ? 1 : -1
    );
    setQuestionsSort({ ...questionsSort, sortedQuestions: sortedQuestions });
  } else if (direction.ASC === sortVal.sortDirection) {
    setSortVal({
      sortDirection: direction.DESC,
      paint: <NorthIcon />,
    });
    const sortedQuestions = questionsSort.sortedQuestions.sort((a, b) =>
      a[HEADERS.LAST_SYNCED].props.val < b[HEADERS.LAST_SYNCED].props.val ? 1 : -1
    );
    setQuestionsSort({ ...questionsSort, sortedQuestions: sortedQuestions });
  }
};

export function checkRequirements(
  cxpId: string,
  productCode: string,
  questionName: string,
  setHasCxpRepoError: React.Dispatch<React.SetStateAction<boolean>>,
  setQuestionsSort: React.Dispatch<React.SetStateAction<ISortData>>
): boolean {
  return (
    isCxpIdAndRepoFilled(cxpId, productCode, setHasCxpRepoError) &&
    isAtLeastOneParamPresent(cxpId, productCode, questionName, setQuestionsSort)
  );
}

function isCxpIdAndRepoFilled(
  cxpId: string,
  productCode: string,
  setHasCxpRepoError: React.Dispatch<React.SetStateAction<boolean>>
): boolean {
  if (cxpId !== '') {
    if (productCode !== '') {
      return true;
    } else {
      setHasCxpRepoError(true);
      return false;
    }
  }
  return true;
}

function isAtLeastOneParamPresent(
  cxpId: string,
  productCode: string,
  questionName: string,
  setQuestionsSort: React.Dispatch<React.SetStateAction<ISortData>>
): boolean {
  if (!(productCode === '' && cxpId === '' && questionName === '')) {
    return true;
  } else {
    setQuestionsSort({ sortedQuestions: [], page: 1, pages: 1 });
    return false;
  }
}

export const paginationSteps = (
  step: string,
  questionsSort: ISortData,
  setQuestionsSort: React.Dispatch<React.SetStateAction<ISortData>>
): void => {
  if (PREVIOUS_PAGE === step) {
    const page: number = questionsSort.page - 1;
    setQuestionsSort({ ...questionsSort, page: page });
  } else if (NEXT_PAGE === step) {
    const page: number = questionsSort.page + 1;
    setQuestionsSort({ ...questionsSort, page: page });
  }
};

export const formatQuestionGroupResult = (
  importedQuestion: ICxpSearchGroupQuestion,
  index: number,
  setModalData: React.Dispatch<React.SetStateAction<{ cxpModalActive: boolean; cxpId: number }>>
): IFlattenedCxpResultQuestion => {
  return {
    id: index,
    [HEADERS.CXP_ITEM_ID]: (
      <div
        style={{ textDecorationColor: 'blue', color: 'blue', textDecorationLine: 'underline', cursor: 'pointer' }}
        onClick={() => setModalData({ cxpModalActive: true, cxpId: importedQuestion.cxpItem.id })}
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === ' ') {
            setModalData({ cxpModalActive: true, cxpId: importedQuestion.cxpItem.id });
          }
        }}
        role="button"
        tabIndex={0}
        id="cxp_item_id"
        aria-pressed={true}
      >
        {importedQuestion.cxpItem.id}
      </div>
    ),
    [HEADERS.GEYSER_REPO]: importedQuestion.cxpItem.productCode,
    [HEADERS.CXP_ITEM_CXP_ID]: importedQuestion.cxpItem.cxpId,
    [HEADERS.WA_QUESTION]: <ToolTip questionNames={importedQuestion.questionNames} />,
    [HEADERS.HANDLER_TYPE]: importedQuestion.cxpItem.handlerType,
    [HEADERS.FIELD_COUNT]: importedQuestion.cxpItem.boxCount,
    [HEADERS.IS_ALGORITHMIC]: String(importedQuestion.cxpItem.isAlgorithmic),
    [HEADERS.LIMIT_ONE_SUBMISSION]: String(importedQuestion.cxpItem.enforceOneSubmission),
    [HEADERS.LAST_SYNCED]: <DateFormatter val={importedQuestion.cxpItem.lastSynced} />,
  } as IFlattenedCxpResultQuestion;
};

export const formatQuestionResult = (question: ICxpTextbookQuestion): IFlattenedTextbookResultQuestion => {
  return {
    [HEADERS.CXP_ITEM_ID]: question.cxpItem.id.toString(),
    [HEADERS.WA_QUESTION_ID]: question.questionId.toString(),
    [HEADERS.WA_QUESTION]: question.questionName,
    [HEADERS.GEYSER_REPO]: question.cxpItem.productCode,
    [HEADERS.CXP_ITEM_CXP_ID]: question.cxpItem.cxpId,
    [HEADERS.HANDLER_TYPE]: question.cxpItem.handlerType,
    [HEADERS.FIELD_COUNT]: question.cxpItem.boxCount.toString(),
    [HEADERS.IS_ALGORITHMIC]: question.cxpItem.isAlgorithmic.toString(),
    [HEADERS.LIMIT_ONE_SUBMISSION]: question.cxpItem.enforceOneSubmission.toString(),
    [HEADERS.LAST_SYNCED]:
      question.cxpItem.lastSynced == null ? '' : format(new Date(question.cxpItem.lastSynced), 'MM/dd/yyyy'),
  } as IFlattenedTextbookResultQuestion;
};

export const mapToCxpItem = (cxpItem: IFlattenedCxpResultQuestion): ICxpRequest => {
  return {
    id: cxpItem[HEADERS.CXP_ITEM_ID].props.children,
    cxpId: cxpItem[HEADERS.CXP_ITEM_CXP_ID],
    productCode: cxpItem[HEADERS.GEYSER_REPO],
    handlerType: cxpItem[HEADERS.HANDLER_TYPE],
    isAlgorithmic: cxpItem[HEADERS.IS_ALGORITHMIC],
    boxCount: cxpItem[HEADERS.FIELD_COUNT],
    enforceOneSubmission: cxpItem[HEADERS.LIMIT_ONE_SUBMISSION],
  } as ICxpRequest;
};

export const createReportFileName = (name: string): string => {
  const date = format(new Date(), 'yyyy/MM/dd');
  return `${name}${date}.xlsx`;
};

import React, { SetStateAction, useState } from 'react';
import {
  AlertVariant,
  Button,
  ButtonColor,
  ButtonVariant,
  Card,
  CardBody,
  Flex,
  FlexBehavior,
  Heading,
  Toggle,
  TypographyVisualStyle,
} from 'react-magma-dom';
import { HEADERS, ICxpRequest, IFlattenedCxpResultQuestion } from 'context/CxpQuestionContextProvider/types';
import { syncCxpItem, updateCxpItemOneSubmission } from 'facultyComponents/cxpQuestionManager/apiHelpers';
import { mapToCxpItem } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/cxpQuestionUtil';
import { CHANGE_VALUE_ONE_SUBMISSION_ERROR } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/types';

interface IProps {
  cxpItem: IFlattenedCxpResultQuestion | undefined;
  setCxpItem: (cxpId: string, repo: string, index: number) => void;
  setBlockFlag: React.Dispatch<SetStateAction<boolean>>;
  errorHandling: (message: string) => void;
  setMessageToast: React.Dispatch<
    React.SetStateAction<{
      messageToast: string;
      typeToast: AlertVariant;
      dismissToast: boolean;
      showToast: boolean;
      disableAutoDismiss: boolean;
    }>
  >;
}

const CxpEditModal = ({ cxpItem, setCxpItem, setBlockFlag, errorHandling, setMessageToast }: IProps): JSX.Element => {
  const [oneSubmit, setOneSubmit] = useState<boolean>(
    cxpItem !== undefined ? cxpItem[HEADERS.LIMIT_ONE_SUBMISSION] === 'true' : false
  );
  const [disableSubmitFlag, setDisableSubmitFlag] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  async function handleUpdateOneSubmission(currentItem: IFlattenedCxpResultQuestion) {
    try {
      setDisableSubmitFlag(true);
      const response = await updateCxpItemOneSubmission(currentItem[HEADERS.CXP_ITEM_ID].props.children);
      if (cxpItem && response.status === 200) {
        cxpItem[HEADERS.LIMIT_ONE_SUBMISSION] = String(!oneSubmit);
        setOneSubmit(!oneSubmit);
      } else {
        setMessageToast({
          messageToast: CHANGE_VALUE_ONE_SUBMISSION_ERROR,
          typeToast: AlertVariant.danger,
          dismissToast: true,
          showToast: true,
          disableAutoDismiss: true,
        });
      }
      setDisableSubmitFlag(false);
    } catch (error) {
      setMessageToast({
        messageToast: CHANGE_VALUE_ONE_SUBMISSION_ERROR,
        typeToast: AlertVariant.danger,
        dismissToast: true,
        showToast: true,
        disableAutoDismiss: true,
      });
    }
  }

  const handleRefreshSingleItem = async (currentItem: IFlattenedCxpResultQuestion) => {
    try {
      setBlockFlag(true);
      setLoader(true);
      if (currentItem) {
        const item: ICxpRequest = mapToCxpItem(currentItem);
        const response = await syncCxpItem([item]);
        setMessageToast({
          messageToast: '',
          typeToast: AlertVariant.success,
          dismissToast: false,
          showToast: false,
          disableAutoDismiss: false,
        });
        setLoader(false);
        setBlockFlag(false);
        if (!response.data.result.successful) {
          response.data.result.cxpItemProperties.fieldCount === 0
            ? setMessageToast({
                messageToast: `CXP Metadata update failed for ${response.data.result.cxpItemProperties.productCode} with id ${response.data.result.cxpItemProperties.cxpId}. The field count for this CXP item is zero.`,
                typeToast: AlertVariant.danger,
                dismissToast: false,
                showToast: true,
                disableAutoDismiss: true,
              })
            : setMessageToast({
                messageToast: `CXP Metadata update failed for ${response.data.result.cxpItemProperties.productCode} with id ${response.data.result.cxpItemProperties.cxpId}.`,
                typeToast: AlertVariant.danger,
                dismissToast: false,
                showToast: true,
                disableAutoDismiss: true,
              });
        } else {
          setCxpItem(currentItem[HEADERS.CXP_ITEM_CXP_ID], currentItem[HEADERS.GEYSER_REPO], currentItem.id);
          setMessageToast({
            messageToast: `CXP Metadata successfully updated for ${currentItem[HEADERS.GEYSER_REPO]} with id ${
              currentItem[HEADERS.CXP_ITEM_CXP_ID]
            }.`,
            typeToast: AlertVariant.success,
            dismissToast: false,
            showToast: true,
            disableAutoDismiss: false,
          });
        }
      }
    } catch (error) {
      errorHandling(`Request failed, try again. If this persists create a support ticket and escalate to engineering.`);
    }
  };

  return (
    <>
      <div>
        {cxpItem && (
          <Card hasDropShadow testId="cxp_item_id" style={{ marginTop: '15px' }}>
            <CardBody>
              <Flex behavior={FlexBehavior.container} spacing={2}>
                <Flex behavior={FlexBehavior.item} xs={12}>
                  <Heading
                    css
                    level={2}
                    visualStyle={TypographyVisualStyle.headingSmall}
                    style={{ marginBottom: '0px', marginTop: '0px' }}
                  >
                    CXP ITEM DETAILS
                    <br />
                    <hr style={{ width: '100%', height: '1px' }} />
                  </Heading>
                </Flex>

                <Flex behavior={FlexBehavior.item} xs={2} style={{ fontWeight: 'bold' }}>
                  {HEADERS.CXP_ITEM_ID}:
                </Flex>
                <Flex behavior={FlexBehavior.item} xs={10}>
                  {cxpItem[HEADERS.CXP_ITEM_ID].props.children}
                </Flex>

                <Flex behavior={FlexBehavior.item} xs={2} style={{ fontWeight: 'bold' }}>
                  {HEADERS.GEYSER_REPO}:
                </Flex>
                <Flex behavior={FlexBehavior.item} xs={10}>
                  {cxpItem[HEADERS.GEYSER_REPO]}
                </Flex>

                <Flex behavior={FlexBehavior.item} xs={2} style={{ fontWeight: 'bold' }}>
                  {HEADERS.CXP_ITEM_CXP_ID}:
                </Flex>
                <Flex behavior={FlexBehavior.item} xs={10}>
                  {cxpItem[HEADERS.CXP_ITEM_CXP_ID]}
                </Flex>

                <Flex behavior={FlexBehavior.item} xs={2} style={{ fontWeight: 'bold' }}>
                  {HEADERS.HANDLER_TYPE}:
                </Flex>
                <Flex behavior={FlexBehavior.item} xs={10}>
                  {cxpItem[HEADERS.HANDLER_TYPE]}
                </Flex>

                <Flex behavior={FlexBehavior.item} xs={2} style={{ fontWeight: 'bold' }}>
                  {HEADERS.FIELD_COUNT}:
                </Flex>
                <Flex behavior={FlexBehavior.item} xs={10}>
                  {cxpItem[HEADERS.FIELD_COUNT]}
                </Flex>

                <Flex behavior={FlexBehavior.item} xs={2} style={{ fontWeight: 'bold' }}>
                  {HEADERS.IS_ALGORITHMIC}:
                </Flex>
                <Flex behavior={FlexBehavior.item} xs={10}>
                  {cxpItem[HEADERS.IS_ALGORITHMIC]}
                </Flex>

                <Flex behavior={FlexBehavior.item} xs={2} style={{ fontWeight: 'bold' }}>
                  {HEADERS.LIMIT_ONE_SUBMISSION}:
                </Flex>
                <Flex behavior={FlexBehavior.item} xs={10}>
                  <Toggle
                    disabled={cxpItem[HEADERS.HANDLER_TYPE] !== 'Multi' || disableSubmitFlag}
                    checked={oneSubmit}
                    isTextVisuallyHidden
                    labelText="Controlled toggle"
                    onChange={() => handleUpdateOneSubmission(cxpItem)}
                    testId="one_submit_toggle_id"
                  />
                </Flex>

                <Flex behavior={FlexBehavior.item} xs={2} style={{ fontWeight: 'bold' }}>
                  {HEADERS.WA_QUESTION}:
                </Flex>
                <Flex behavior={FlexBehavior.item} xs={10}>
                  {cxpItem[HEADERS.WA_QUESTION].props.questionNames.join(', ')}
                </Flex>

                <Flex behavior={FlexBehavior.item} xs={2} style={{ fontWeight: 'bold' }}>
                  {HEADERS.LAST_SYNCED}:
                </Flex>
                <Flex behavior={FlexBehavior.item} xs={10}>
                  {cxpItem[HEADERS.LAST_SYNCED]}
                </Flex>

                <br />
                <hr style={{ width: '100%', height: '1px' }} />
                <Button
                  onClick={() => handleRefreshSingleItem(cxpItem)}
                  variant={ButtonVariant.solid}
                  color={ButtonColor.success}
                  testId="syncCxpQuestionsButton"
                  isLoading={loader}
                >
                  Sync CXP metadata
                </Button>
              </Flex>
            </CardBody>
          </Card>
        )}
      </div>
    </>
  );
};

export default CxpEditModal;

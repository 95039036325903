import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  IQuestionElement,
  IAssignmentQuestion,
  IQuestionBrowserElement,
  IQuestionBrowserMasterySet,
  IQuestionBrowserQuestion,
  IQuestionBrowserQuestionPool,
  IQuestionPool,
  IMasterySet,
  QuestionElementType,
} from 'context/AssignmentContextProvider/types';
import { CanFocusWindow } from 'context/QuestionBrowserContextProvider/QuestionBrowserContextProvider';

interface IProps {
  contents: IQuestionElement[];
  assignmentId: number | '';
  userPass: string | '';
  setShowQuestionBrowserFalse: () => void;
  updateQuestionContent: (content: IQuestionBrowserElement[]) => void;
  ref: any;
}

const validateQuestionContent = (questionElement: string): boolean => {
  const questionPattern = new RegExp(/^\d+$/g);
  return questionPattern.test(questionElement);
};

const createQuestionBrowserQuestion = (questionElement: string): IQuestionBrowserQuestion => {
  const question: IQuestionBrowserQuestion = {
    elementType: QuestionElementType.QUESTION,
    id: parseInt(questionElement),
  };
  return question;
};

const createQuestionBrowserQuestionPool = (questionElement: string): IQuestionBrowserQuestionPool => {
  const poolInfo = splitComponentInfoString(questionElement);
  const questions = createQuestionBrowserQuestionList(poolInfo[1]);
  const questionPool: IQuestionBrowserQuestionPool = {
    elementType: QuestionElementType.QUESTION_POOL,
    includedNumberOfQuestions: parseInt(poolInfo[0]),
    numberOfQuestions: questions.length,
    questions: questions,
  };
  return questionPool;
};

const createQuestionBrowserMasterySet = (questionElement: string): IQuestionBrowserMasterySet => {
  const masterySetInfo = splitComponentInfoString(questionElement);
  const questions = createQuestionBrowserQuestionList(masterySetInfo[2]);
  const masterySet: IQuestionBrowserMasterySet = {
    id: null,
    elementType: QuestionElementType.MASTERY_SET,
    subsetSize: parseInt(masterySetInfo[0]),
    masteryThreshold: parseInt(masterySetInfo[1]),
    numberOfQuestions: questions.length,
    questions: questions,
  };
  return masterySet;
};

const splitComponentInfoString = (componentInfo: string): string[] => {
  return componentInfo.split(':');
};

const createQuestionBrowserQuestionList = (qIds: string): IQuestionBrowserQuestion[] => {
  const qidArray = qIds.split(';');
  return qidArray.map((questionElement: string) => {
    return createQuestionBrowserQuestion(questionElement);
  });
};

const validatePoolContent = (questionElement: string): boolean => {
  const poolPattern = new RegExp(/^\d+:(\d+;)*\d+$/g);
  return poolPattern.test(questionElement);
};

const validateMasterySetContent = (questionElement: string): boolean => {
  const masterySetPattern = new RegExp(/^\d+:\d+:(\d+;)*\d+$/g);
  return masterySetPattern.test(questionElement);
};

const getCurrentContents = (questionElements: IQuestionElement[]): string[] => {
  if (questionElements == null) {
    return [''];
  }
  const contentArray = questionElements?.map((questionElement: IQuestionElement) => {
    return createContentString(questionElement);
  });
  return contentArray;
};

const createContentString = (questionElement: IQuestionElement): string => {
  switch (questionElement.elementType) {
    case QuestionElementType.QUESTION:
      return createQuestionString(questionElement as IAssignmentQuestion);
    case QuestionElementType.QUESTION_POOL:
      return createPoolString(questionElement as IQuestionPool);
    case QuestionElementType.MASTERY_SET:
      return createMasterySetString(questionElement as IMasterySet);
    default:
      return '';
  }
};

const createMasterySetString = (masterySet: IMasterySet): string => {
  const choose = masterySet.subsetSize;
  const threshold = masterySet.masteryThreshold;
  const masteryQuestions = masterySet.questions.map(question => question.question.id).join('_');
  return `${choose}:${threshold}:${masteryQuestions}`;
};

const createQuestionString = (question: IAssignmentQuestion): string => {
  return `${question.question.id}`;
};

const createPoolString = (questionPool: IQuestionPool): string => {
  const choose = questionPool.includedNumberOfQuestions;
  const poolQuestions = questionPool.questions
    .map((poolQuestion: IAssignmentQuestion) => poolQuestion.question.id)
    .join('_');
  return `${choose}:${poolQuestions}`;
};

export const QuestionBrowserWindow: React.FC<IProps> = forwardRef<CanFocusWindow, IProps>((props, ref) => {
  const { assignmentId, userPass, contents, updateQuestionContent, setShowQuestionBrowserFalse } = props;
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const newWindow = useRef<Window | null>(null);

  useEffect(() => {
    setContainer(document.createElement('div'));
    container?.setAttribute('data-testid', 'question_browser_controller');
  }, []);

  useEffect(() => {
    window.addEventListener('message', processMessage);
    return () => {
      window.removeEventListener('message', processMessage);
    };
  }, [container]);

  useEffect(() => {
    if (container) {
      newWindow.current = window.open(
        constructUrl(contents),
        `Question Browser`,
        'width=1000,height=670,left=200,top=200'
      );

      if (newWindow.current) {
        // The legacy Question browser has 3 methods for closing: regular pop-up close button, Question Browser's "Close this window" link,
        //  and Question Browser's "Update Assignment" button. In all 3 cases, we need to reflect the closing of the window in the showQuestionBrowser state.
        // (Otherwise, later clicks of the "Add Question" button would not create this component, and no Question Browser window would show.)
        // To update the state as part of all 3 of these close window flows, the setShowQuestionBrowserFalse() method is passed a prop from the Question List.
        // Calling this method when the window calls its "beforeunload" event, results in the Question list unmounting this component, forcing the window to close and
        // conveying the appropriate state in the process.
        // Similar approaches to this should be avoided

        newWindow.current?.addEventListener('beforeunload', () => {
          // A workaround for Safari. Safari fires beforeunload event for about:blank in a moment when the window
          // switches to the actual url. So the pop-up window close itself immediately. This doesn't solve an issue when
          // we close the pop up while it is in the about:blank and Open Question Browser button doesn't re-open Question
          // Browser (the button works again only after reload of the AE page)
          if (newWindow.current?.document.URL !== 'about:blank') {
            setShowQuestionBrowserFalse();
          }
        });
      }
      const curWindow = newWindow.current;
      if (curWindow) return () => curWindow.close();
    }
  }, [container]);

  // In the legacy AE, clicking the Question Browser button while an existing Question Browser window is open places the focus on the existing Question Browser window, and
  // updates its location to reflect the current content on the AE.
  // In the new AE, we've tied the existence of the question browser window to this component. Mounting this component is controlled by a state in the QuestionList,
  // showQuestionBrowser. To mimic the behavior of legacy AE, "Add Questions" button's onclick event is set to show the Question browser if one is not open
  // (i.e.: if showQuestionBrower == false, then showQuestionBrowser => true), and if open then direct focus to it. (i.e. if showQuestionBrowser == true, then focus it)
  // To do so, we need to expose a method of this component to execute the focus().
  // This is not a good practice, and similar patterns should be avoided in the future.
  useImperativeHandle(ref, () => ({
    focusBrowser: (): void => {
      if (newWindow.current) {
        newWindow.current.focus();
      }
    },
  }));

  const constructUrl = (questionElements: IQuestionElement[]): string => {
    let url = window.location.origin;
    url += `/v4cgi/assignments/contents/index.tpl`;
    url += `?current=${getCurrentContents(questionElements).join()}`;
    url += `&page=both`;
    url += `&from=both`;
    url += `&contentsViewMode=short`;
    url += `&editPtsMode=total`;
    url += `&aid=${assignmentId}`;
    url += `&atype=edit`;
    url += `&UserPass=${userPass}`;
    url += `&newEditor=1`;
    return url;
  };

  const processMessage = (message: { data: string; origin: string }): void => {
    if (message.origin !== window.location.origin) {
      return;
    }
    const contentArray = message.data.replace(/\s/g, '').split(',');
    const questionBrowserContent = new Array<IQuestionBrowserElement>();
    const BreakException = {};
    try {
      // Build Question Content array while validating
      contentArray.forEach(questionElement => {
        if (validateQuestionContent(questionElement)) {
          questionBrowserContent.push(createQuestionBrowserQuestion(questionElement));
        } else if (validatePoolContent(questionElement)) {
          questionBrowserContent.push(createQuestionBrowserQuestionPool(questionElement));
        } else if (validateMasterySetContent(questionElement)) {
          questionBrowserContent.push(createQuestionBrowserMasterySet(questionElement));
        } else {
          throw BreakException;
        }
      });
    } catch (error) {
      // TODO: Consider reporting bad data in Toast
      return;
    }
    updateQuestionContent(questionBrowserContent);

    // In the legacy AE, the Question Browser window conveys the updated content by passing the string encoded question/pools data directly to a method on the legacy AE page.
    // This coupling has made a direct port over to the new AE tricky, as we don't have access to call a method from the new AE from this window.
    // The current strategy is have the question browser postMessage() containing the string encoded question/pools data to the new AE, and use this component to listen for, process
    // and validage that message. However, postMessage() is an asynchronous, one directional, process. In the legacy AE, after the question browser calls the legacy AE's updateContents()
    // method, the question browser call itself. WIth the postMessage() approach, letting the question browser close itself results in a significant number of postMessage() call not
    // completing before the window closes. To avoid this, the plan is to remove the new AE's question browser's ability to close itself, as part of the update assignment flow
    // Once a message has reached the new AE, the last step in the processMessage() method explicitly closes the window. An alternative to this would be to set up some callback method,
    // and this option can be explored as part of the follow up work.
    // This approach is a bad practice and should be avoid in future work.
    newWindow.current?.close();
    return;
  };

  return container && createPortal(props.children, container);
});

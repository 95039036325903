/* istanbul ignore file */
import React from 'react';
import { FetchedDataProvider } from './FetchedData';
import { FetchProvider } from './Fetch';
import { PropsType } from './typings';

const Provider: React.FC<PropsType> = props => {
  const { children, fetcher } = props;

  return (
    <FetchProvider fetcher={fetcher}>
      <FetchedDataProvider fetcher={fetcher}>{children}</FetchedDataProvider>
    </FetchProvider>
  );
};

export { Provider as default };

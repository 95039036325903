import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import {
  IAssignmentQuestion,
  IMasterySet,
  IQuestionElement,
  IQuestionPool,
  QuestionElementType,
  ShowMyWorkMode,
} from 'context/AssignmentContextProvider/types';
import { getMasteryPoints, getPoolPoints } from 'facultyComponents/assignmentEditor/helpers/commonHelpers';
import {
  isAssignmentQuestion,
  isMasterySet,
  isQuestionPool,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { setQuestionElementPoints } from 'context/AssignmentContextProvider/actions';

interface IQuestionPointsContext {
  questionLevelPoints: number;
  questionPartLevelPoints: number[];
  questionIndex: number;
  forceRevertFlag: boolean;
  updateQuestionLevelPoints: (points: number) => void;
  updateQuestionPartLevelPoints: (points: number, index: number) => void;
}

interface IQuestionPointsContextProviderProps {
  questionIndex: number;
  questionElement: IQuestionElement;
  children: React.ReactNode;
  totalPointsUpdatedFlag: boolean;
}

export const getQuestionPoints = (assignmentQuestion: IAssignmentQuestion): number | null => {
  const showMyWorkPoints = getQuestionShowMyWorkPoints(assignmentQuestion);
  const points = fixFloatingPoint(
    assignmentQuestion.boxes
      .map(box => box.gradingSettings.pointsValue)
      .reduce((total, boxPoints) => total + boxPoints) + showMyWorkPoints,
    2
  );
  return points || points === 0 ? points : null;
};

export const getQuestionShowMyWorkPoints = (questionElement: IQuestionElement): number => {
  return questionElement.showMyWorkMode == ShowMyWorkMode.REQUIRED ? questionElement.showMyWorkPointValue : 0;
};

export const getQuestionElementPoints = (questionElement: IQuestionElement): number => {
  if (isAssignmentQuestion(questionElement)) {
    return getQuestionPoints(questionElement) || 0;
  }
  if (isQuestionPool(questionElement)) {
    return getPoolPoints(questionElement) || 0;
  }
  if (isMasterySet(questionElement)) {
    return getMasteryPoints(questionElement) || 0;
  }
  return 0;
};

export const quotientFloorRemainder = (dividend: number, divisor: number, precision: number): [number, number] => {
  // This method assumes and input of a divident float with 2 decimal places at most
  if (dividend === 0) {
    return [0, 0];
  }
  if (!dividend || !divisor) {
    return [NaN, NaN];
  }
  const pow = Math.pow(10, precision);

  const scaledDividend = dividend * pow;
  const scaledQuotitent = Math.floor(scaledDividend / divisor);
  const scaledRemainder = Math.round(scaledDividend - scaledQuotitent * divisor);
  const quotient = scaledQuotitent / pow;
  const remainder = scaledRemainder / pow;

  return [quotient, remainder];
};

export const fixFloatingPoint = (value: number, precision: number): number => {
  const pow = Math.pow(10, precision);
  return Math.round(value * pow) / pow;
};

export const QuestionPointsContext = createContext<IQuestionPointsContext>({
  questionLevelPoints: 0,
  questionPartLevelPoints: [],
  questionIndex: 0,
  forceRevertFlag: false,
  updateQuestionLevelPoints: () => null,
  updateQuestionPartLevelPoints: () => null,
});

export const useQuestionPointsContext = (): IQuestionPointsContext => useContext(QuestionPointsContext);

export const QuestionPointsContextProvider = ({
  questionIndex,
  questionElement,
  children,
  totalPointsUpdatedFlag,
}: IQuestionPointsContextProviderProps): JSX.Element => {
  const { dispatch } = useAssignmentContext();

  const getQuestionPartLevelPoints = (questionElement: IQuestionElement): number[] => {
    switch (questionElement.elementType) {
      case QuestionElementType.QUESTION_POOL:
        return [(questionElement as IQuestionPool).gradingSettings.pointsValue];
      case QuestionElementType.MASTERY_SET:
        return [(questionElement as IMasterySet).gradingSettings.pointsValue];
      case QuestionElementType.QUESTION:
        return (questionElement as IAssignmentQuestion).boxes.map(box => box.gradingSettings.pointsValue);
      default:
        return [0];
    }
  };

  const [questionPartLevelPoints, setQuestionPartLevelPoints] = useState<number[]>(
    getQuestionPartLevelPoints(questionElement)
  );
  const [questionLevelPoints, setQuestionLevelPoints] = useState(getQuestionElementPoints(questionElement));
  const [forceRevertFlag, setForceRevertFlag] = useState(false);

  // Case when removing question shifts elements between context
  useEffect(() => {
    setQuestionLevelPoints(getQuestionElementPoints(questionElement));
    setQuestionPartLevelPoints(getQuestionPartLevelPoints(questionElement));
  }, [questionElement]);
  // end case

  // Case changes made to total points
  useEffect(() => {
    setQuestionLevelPoints(getQuestionElementPoints(questionElement));
    setQuestionPartLevelPoints(getQuestionPartLevelPoints(questionElement));
  }, [totalPointsUpdatedFlag]);
  // end case

  // Case changes made to SMW
  useEffect(() => {
    setQuestionLevelPoints(getQuestionElementPoints(questionElement));
  }, [questionElement.showMyWorkMode, questionElement.showMyWorkPointValue]);

  const distributeQuestionLevelPoints = (points: number): number[] => {
    const questionPartPoints = [];
    const numberOfBoxes = questionPartLevelPoints.length;
    const [pointsPerQuestionElement, remainder] = quotientFloorRemainder(points, numberOfBoxes, 2);
    for (let index = 0; index < numberOfBoxes; index++) {
      if (index == numberOfBoxes - 1) {
        questionPartPoints[index] = fixFloatingPoint(pointsPerQuestionElement + remainder, 2);
      } else {
        questionPartPoints[index] = fixFloatingPoint(pointsPerQuestionElement, 2);
      }
    }
    return questionPartPoints;
  };

  const updateQuestionLevelPoints = (points: number): void => {
    let newQuestionLevelPoints;
    let pointsToDistribute;
    if (points <= getQuestionShowMyWorkPoints(questionElement)) {
      newQuestionLevelPoints = getQuestionShowMyWorkPoints(questionElement);
      if (questionLevelPoints == newQuestionLevelPoints) {
        setForceRevertFlag(!forceRevertFlag);
      }
      pointsToDistribute = 0;
    } else {
      newQuestionLevelPoints = points;
      pointsToDistribute = fixFloatingPoint(points - getQuestionShowMyWorkPoints(questionElement), 2);
    }
    setQuestionLevelPoints(newQuestionLevelPoints);
    const newQuestionPartLevelPoints = distributeQuestionLevelPoints(pointsToDistribute);
    setQuestionPartLevelPoints(newQuestionPartLevelPoints);
    dispatch(setQuestionElementPoints({ elementIndex: questionIndex, points: newQuestionPartLevelPoints }));
  };

  const updateQuestionPartLevelPoints = (points: number, boxIndex: number): void => {
    const newQuestionPartLevelPoints = questionPartLevelPoints;
    newQuestionPartLevelPoints[boxIndex] = points;
    setQuestionPartLevelPoints(newQuestionPartLevelPoints);
    const showMyWorkPoints = getQuestionShowMyWorkPoints(questionElement);
    setQuestionLevelPoints(
      fixFloatingPoint(
        newQuestionPartLevelPoints.reduce((questionPoints, boxPoints) => questionPoints + boxPoints) + showMyWorkPoints,
        2
      )
    );
    dispatch(setQuestionElementPoints({ elementIndex: questionIndex, points: newQuestionPartLevelPoints }));
  };

  return (
    <QuestionPointsContext.Provider
      value={{
        questionLevelPoints,
        questionPartLevelPoints,
        questionIndex,
        forceRevertFlag,
        updateQuestionLevelPoints,
        updateQuestionPartLevelPoints,
      }}
    >
      {children}
    </QuestionPointsContext.Provider>
  );
};

import React from 'react';

import { useTranslation } from 'react-i18next';

import { Tab } from 'react-magma-dom';
import { AutoAwesomeIcon } from 'react-magma-icons';

import { PointsAdjustmentSettings } from './PointsAdjustmentSettings';
import { IQuestionElement } from 'context/AssignmentContextProvider/types';

interface IPointsAdjustmentTabProps {
  id: string;
  testId: string;
}

interface IPointsAdjustmentTabPanelProps {
  index: number;
  questionElement: IQuestionElement;
}

export const PointsAdjustmentTab = (props: IPointsAdjustmentTabProps): JSX.Element => {
  const { id, testId } = props;
  const { t } = useTranslation();

  return (
    <Tab icon={<AutoAwesomeIcon />} id={id} testId={testId}>
      {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.POINTS_ADJUSTMENT')}
    </Tab>
  );
};

export const PointsAdjustmentPanel = (props: IPointsAdjustmentTabPanelProps): JSX.Element => {
  const { index, questionElement } = props;

  return <PointsAdjustmentSettings questionElementIndex={index} questionElement={questionElement} />;
};

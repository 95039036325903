import React, { useState } from 'react';
import {
  AlertVariant,
  Button,
  ButtonColor,
  ButtonVariant,
  Flex,
  FlexAlignItems,
  FlexBehavior,
  FlexJustify,
  FlexWrap,
  Toast,
} from 'react-magma-dom';
import {
  HEADERS,
  ICxpImportRequest,
  ICxpImportRequestData,
  ICxpResult,
  ICxpResultQuestion,
  IFlattenedCxpImportResultQuestion,
  IMPORT_HEADERS,
} from 'context/CxpQuestionContextProvider/types';
import { importCxpQuestions } from '../apiHelpers';
import { useTranslation } from 'react-i18next';
import {
  addSheetToWorkbook,
  convertFileToJson,
  convertJsonToWorksheet,
  createAndDownloadXlsx,
  createWorkbook,
} from 'utils/dataConverter/XlsxManipulator';
import Guidance from 'facultyComponents/cxpQuestionManager/cxpQuestionImporter/components/Guidance';
import { createReportFileName } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/cxpQuestionUtil';

export const CxpQuestionImporter = (): JSX.Element => {
  const [cxpImportRequest, setCxpImportRequest] = useState<ICxpImportRequest | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const [validFileUpload, setValidFileUpload] = useState<boolean>(false);
  const [importResult, setImportResult] = useState<ICxpResult | null>(null);
  const [showDownloadBtn, setShowDownloadBtn] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);

  const { t } = useTranslation();

  const uploadBtnRef = React.createRef<HTMLInputElement>();

  const handleUploadClick = () => {
    if (uploadBtnRef.current) {
      uploadBtnRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files ? e.target.files[0] : null;
    e.target.value = '';
    setImportResult(null);
    setShowDownloadBtn(false);
    setHasError(false);
    if (newFile) {
      setToastMessage('');
      const fileData = await convertFileToJson(newFile);
      standardizeFileData(fileData);
      if (isValidRequestData(fileData as ICxpImportRequestData[])) {
        setValidFileUpload(true);
        const requestData = generateCxpImportData(fileData as ICxpImportRequestData[]);
        setCxpImportRequest(requestData);
        if (requestData.questions.length) {
          setToastMessage(
            t('CXP_QUESTION_MANAGER.IMPORTER.IMPORT_NUMBER_OF_QUESTIONS', {
              numberOfQuestions: requestData.questions.length,
            })
          );
        }
      } else {
        setHasError(true);
      }
      setShowToast(true);
    }
  };

  const standardizeFileData = (fileData: any[]) => {
    fileData.forEach(questionData => {
      for (const key in questionData) {
        questionData[key.toUpperCase()] = questionData[key];
      }
    });
  };

  const isValidRequestData = (data: ICxpImportRequestData[]) => {
    const questionNameSet = new Set<string>();
    let rowNumber = 2; // Spreadsheet data starts at row 2
    return !data.some(questionData => {
      const notValid = !isValidCxpQuestionData(questionData, rowNumber, questionNameSet);
      rowNumber++;
      return notValid;
    });
  };

  const generateCxpImportData = (data: ICxpImportRequestData[]): ICxpImportRequest => {
    const questions = data.map(questionData => ({
      questionName: questionData[IMPORT_HEADERS.IMPORT_WA_QUESTION],
      textbookCode: questionData[IMPORT_HEADERS.IMPORT_WA_QUESTION].split(/\s+/)[0],
      textbookChapter: questionData[IMPORT_HEADERS.IMPORT_WA_QUESTION].split(/\s+/)[1].split('.')[0],
      cxpId: questionData[IMPORT_HEADERS.IMPORT_CXP_ITEM_ID],
      productCode: questionData[IMPORT_HEADERS.IMPORT_GEYSER_REPO],
      enforceOneSubmission: isLimitOneSubmission(questionData[IMPORT_HEADERS.IMPORT_ONE_SUBMISSION]),
    }));
    return {
      authorUserName: 'webassign',
      questions: questions,
    };
  };

  const isLimitOneSubmission = (value: string | null) => {
    return value && value.toUpperCase() === 'Y' ? true : false;
  };

  const isValidCxpQuestionData = (
    cxpQuestionData: ICxpImportRequestData,
    index: number,
    questionNameSet: Set<string>
  ) => {
    return (
      isValidQuestionName(cxpQuestionData[IMPORT_HEADERS.IMPORT_WA_QUESTION], index, questionNameSet) &&
      isValidTextbookInformation(cxpQuestionData[IMPORT_HEADERS.IMPORT_WA_QUESTION], index) &&
      isValidCxpId(cxpQuestionData[IMPORT_HEADERS.IMPORT_CXP_ITEM_ID], index) &&
      isValidProductCode(cxpQuestionData[IMPORT_HEADERS.IMPORT_GEYSER_REPO], index)
    );
  };

  const isValidQuestionName = (questionName: string, index: number, questionNameSet: Set<string>) => {
    if (!questionName || !questionName.length) {
      setToastMessage(
        t('CXP_QUESTION_MANAGER.IMPORTER.ERRORS.MISSING_REQUIRED_ATTRIBUTE', {
          attribute: t('CXP_QUESTION_MANAGER.IMPORTER.QUESTION_ATTRIBUTES.NAME'),
          rowNumber: index,
        })
      );
      return false;
    }
    if (questionNameSet.has(questionName.toUpperCase())) {
      setToastMessage(
        t('CXP_QUESTION_MANAGER.IMPORTER.ERRORS.DUPLICATE_QUESTION_NAME', {
          question: questionName,
          rowNumber: index,
        })
      );
      return false;
    }
    questionNameSet.add(questionName.toUpperCase());
    return true;
  };

  const isValidTextbookInformation = (questionName: string, index: number) => {
    const [textbookCode, textbookChapterSegment] = questionName.split(/\s+/);
    if (!isValidTextbookCode(textbookCode) || !isValidTextbookChapter(textbookChapterSegment)) {
      setToastMessage(
        t('CXP_QUESTION_MANAGER.IMPORTER.ERRORS.FAILED_TO_PARSE_TEXTBOOK_INFO', {
          question: questionName,
          rowNumber: index,
        })
      );
      return false;
    }
    return true;
  };

  const isValidTextbookCode = (textbookCode: string) => {
    return textbookCode && textbookCode.length;
  };

  const isValidTextbookChapter = (textbookChapterSegment: string) => {
    const textbookChapter = textbookChapterSegment ? textbookChapterSegment.split('.')[0] : null;
    const chapterRegex = /^[a-zA-Z0-9]+$/;
    return (
      textbookChapterSegment &&
      textbookChapterSegment.indexOf('.') >= 1 &&
      textbookChapter &&
      textbookChapter.length &&
      chapterRegex.test(textbookChapter)
    );
  };

  const isValidCxpId = (cxpId: number, index: number) => {
    if (!cxpId || cxpId < 1) {
      setToastMessage(
        t('CXP_QUESTION_MANAGER.IMPORTER.ERRORS.MISSING_REQUIRED_ATTRIBUTE', {
          attribute: t('CXP_QUESTION_MANAGER.IMPORTER.QUESTION_ATTRIBUTES.CXP_ID'),
          rowNumber: index,
        })
      );
      return false;
    }
    return true;
  };

  const isValidProductCode = (productCode: string, index: number) => {
    if (!productCode || !productCode.length) {
      setToastMessage(
        t('CXP_QUESTION_MANAGER.IMPORTER.ERRORS.MISSING_REQUIRED_ATTRIBUTE', {
          attribute: t('CXP_QUESTION_MANAGER.IMPORTER.QUESTION_ATTRIBUTES.PRODUCT_CODE'),
          rowNumber: index,
        })
      );
      return false;
    }
    return true;
  };

  const handleDownload = () => {
    if (!importResult) return;
    const workbook = createWorkbook();
    //Format data for proper conversion to worksheet
    const formattedQuestions: IFlattenedCxpImportResultQuestion[] = [];
    importResult.importedQuestions.forEach(question => {
      formattedQuestions.push(formatQuestionResult(question));
    });
    const questions = convertJsonToWorksheet(formattedQuestions);
    addSheetToWorkbook(workbook, questions, 'Imported Questions');
    const formattedErrors: any[] = [];
    importResult.errors.forEach(function (error) {
      formattedErrors.push({ Error: error });
    });
    const errors = convertJsonToWorksheet(formattedErrors);
    addSheetToWorkbook(workbook, errors, 'Errors');
    createAndDownloadXlsx(workbook, createReportFileName('CXP_Question_Import_Results_'));
  };

  const formatQuestionResult = (importedQuestion: ICxpResultQuestion) => {
    return {
      [HEADERS.CXP_ITEM_ID]: importedQuestion.cxpItem.id,
      [HEADERS.WA_QUESTION_ID]: importedQuestion.questionId,
      [HEADERS.WA_QUESTION]: importedQuestion.questionName,
      [HEADERS.GEYSER_REPO]: importedQuestion.cxpItem.productCode,
      [HEADERS.CXP_ITEM_CXP_ID]: importedQuestion.cxpItem.cxpId,
      [HEADERS.HANDLER_TYPE]: importedQuestion.cxpItem.handlerType,
      [HEADERS.FIELD_COUNT]: importedQuestion.cxpItem.boxCount,
      [HEADERS.IS_ALGORITHMIC]: importedQuestion.cxpItem.isAlgorithmic,
      [HEADERS.LIMIT_ONE_SUBMISSION]: importedQuestion.cxpItem.enforceOneSubmission,
    } as IFlattenedCxpImportResultQuestion;
  };

  const handleImport = async () => {
    setImportResult(null);
    setShowDownloadBtn(false);
    setIsImporting(true);
    if (validFileUpload && !hasError && cxpImportRequest) {
      setShowToast(false);
      try {
        const result = (await importCxpQuestions(cxpImportRequest)).data.result;
        if (result.isSuccess) {
          setHasError(false);
          setToastMessage(
            t('CXP_QUESTION_MANAGER.IMPORTER.RESULTS.SUCCESS', { numberOfQuestions: result.importedQuestions.length })
          );
          setShowDownloadBtn(true);
        } else {
          setHasError(true);
          setValidFileUpload(false);
          setToastMessage(
            t('CXP_QUESTION_MANAGER.IMPORTER.RESULTS.ERROR.VALIDATION', {
              numberOfQuestions: result.importedQuestions.length,
            })
          );
          setShowDownloadBtn(true);
        }
        setImportResult(result);
      } catch (error) {
        setHasError(true);
        setValidFileUpload(false);
        setToastMessage(t('CXP_QUESTION_MANAGER.IMPORTER.RESULTS.ERROR.GENERIC'));
      }
      setShowDownloadBtn(true);
      setShowToast(true);
      setIsImporting(false);
    }
  };

  return (
    <>
      <Guidance />
      <Flex
        behavior={FlexBehavior.container}
        alignItems={FlexAlignItems.center}
        justify={FlexJustify.flexStart}
        spacing={2}
        wrap={FlexWrap.nowrap}
      >
        <div>
          <Flex behavior={FlexBehavior.item} justify={FlexJustify.center}>
            <label htmlFor="cxp_question_import_file">
              <Button
                style={{ marginLeft: '30px' }}
                variant={ButtonVariant.solid}
                onClick={handleUploadClick}
                testId="uploadCxpQuestionImportFileButton"
              >
                {t('CXP_QUESTION_MANAGER.IMPORTER.UPLOAD_FILE')}
              </Button>
            </label>
          </Flex>
          <input
            id="cxp_question_import_file"
            data-testid="cxpQuestionImportFileInput"
            type="file"
            accept=".xlsx"
            ref={uploadBtnRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          ></input>
        </div>
        <br />
        <Flex behavior={FlexBehavior.item} justify={FlexJustify.center}>
          <label htmlFor="cxp_question_import_file">
            {validFileUpload && !importResult && (
              <Button
                color={ButtonColor.success}
                disabled={isImporting}
                variant={ButtonVariant.solid}
                onClick={handleImport}
                testId="importCxpQuestionButton"
              >
                {t('CXP_QUESTION_MANAGER.IMPORTER.IMPORT_QUESTIONS')}
              </Button>
            )}
            {showDownloadBtn && (
              <Button
                color={ButtonColor.secondary}
                variant={ButtonVariant.solid}
                onClick={handleDownload}
                testId="importResultDownloadButton"
              >
                {t('CXP_QUESTION_MANAGER.IMPORTER.DOWNLOAD_RESULTS')}
              </Button>
            )}
          </label>
        </Flex>
      </Flex>
      {showToast ? (
        <Toast
          role="alert"
          testId="cxpAddToastTest"
          disableAutoDismiss
          onDismiss={() => setShowToast(false)}
          variant={hasError ? AlertVariant.danger : AlertVariant.success}
        >
          {toastMessage}
        </Toast>
      ) : null}
    </>
  );
};

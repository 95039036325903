import React from 'react';
import { Button } from 'react-magma-dom';
import {
  ISortData,
  NEXT_PAGE,
  PREVIOUS_PAGE,
} from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/types';
import { paginationSteps } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/cxpQuestionUtil';

interface IProps {
  page: number;
  pages: number;
  questionsSort: ISortData;
  setQuestionsSort: React.Dispatch<React.SetStateAction<ISortData>>;
}

export const Pagination = ({ page, pages, questionsSort, setQuestionsSort }: IProps): JSX.Element => {
  let pagePreviousDisable = false;
  let pageNextDisable = false;
  if (page === 1 || pages === 1) {
    pagePreviousDisable = true;
  }
  if (pages === 1 || page === pages) {
    pageNextDisable = true;
  }
  return (
    <>
      <div style={{ float: 'right' }}>
        You are on page {page} of Page(s) {pages}
        <Button
          testId="previousPageId"
          disabled={pagePreviousDisable}
          onClick={() => paginationSteps(PREVIOUS_PAGE, questionsSort, setQuestionsSort)}
        >
          previous page
        </Button>
        <Button
          testId="nextPageId"
          disabled={pageNextDisable}
          onClick={() => paginationSteps(NEXT_PAGE, questionsSort, setQuestionsSort)}
        >
          next page
        </Button>
      </div>
    </>
  );
};

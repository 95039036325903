import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from 'react-magma-dom';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';

import { IAccordionState, AssignmentEditorWorkflow } from 'context/AssignmentContextProvider/types';
import { setAccordionState } from 'context/AssignmentContextProvider/actions';

const AccordionLayout = styled(Accordion)<{ isAdvacedSettings: boolean }>`
  margin: ${({ isAdvacedSettings }) => (isAdvacedSettings ? '3rem 0 1rem 0' : '1rem 0')};
  border-radius: 0.25rem;
  width: 100%;
  border-bottom: ${({ isAdvacedSettings }) => (isAdvacedSettings ? 'none' : '0.063rem solid #DFDFDF')};
  box-shadow: ${({ isAdvacedSettings }) => (isAdvacedSettings ? 'none' : '0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.2)')};
`;
const AccordionContent = styled(AccordionPanel)<{ padContent: boolean }>`
  padding: ${({ padContent }) => (padContent ? '0.75rem 1rem 0.75rem' : '0rem')};
  min-height: 8.438rem;
`;
const BtnText = styled.span<{ isAdvacedSettings: boolean }>`
  font-weight: 600;
  ${({ isAdvacedSettings }) => (isAdvacedSettings ? '' : 'font-size: 1.25rem')};
  ${({ isAdvacedSettings }) => (isAdvacedSettings ? '' : 'color: #707070;')};
`;
const AccordionBtnWrapper = styled.div<{ isAdvacedSettings: boolean }>`
  button {
    padding: ${({ isAdvacedSettings }) => (isAdvacedSettings ? '0.75rem 0rem' : '0.75rem 1rem')};
    border-top: ${({ isAdvacedSettings }) => (isAdvacedSettings ? '0.063rem solid #DFDFDF' : 'none')};
  }
`;

interface IProps {
  btnText: string;
  testId?: string;
  isAdvacedSettings?: boolean;
  padContent?: boolean;
  accordionType?: keyof IAccordionState;
}

export const AssignmentEditorAccordion: React.FC<IProps> = (props): JSX.Element => {
  const { btnText, children, testId, isAdvacedSettings = false, padContent = true, accordionType } = props;

  const {
    dispatch,
    state: { accordionState, workflow },
  } = useAssignmentContext();

  const [expandedIndex, setExpandedIndex] = React.useState(
    workflow === AssignmentEditorWorkflow.CREATE && accordionType && accordionType === 'infoOpen' ? [0] : []
  );

  useEffect(() => {
    if (accordionType && accordionState[accordionType]) {
      setExpandedIndex([0]);
    }
  }, [accordionState]);

  const handleExpandedChange = (index: number) => {
    if (expandedIndex.includes(index)) {
      setExpandedIndex(expandedIndex.filter(item => item !== index));
      accordionType && dispatch(setAccordionState({ ...accordionState, [accordionType]: false }));
    } else {
      setExpandedIndex(expandedIndex.concat([index]));
    }
  };

  return (
    <AccordionLayout
      testId={testId}
      isAdvacedSettings={isAdvacedSettings}
      isMulti={true}
      index={expandedIndex}
      onExpandedChange={handleExpandedChange}
    >
      <AccordionItem>
        <h3>
          <AccordionBtnWrapper isAdvacedSettings={isAdvacedSettings}>
            <AccordionButton testId={`accordionBtn_${testId}`}>
              <BtnText isAdvacedSettings={isAdvacedSettings}>{btnText}</BtnText>
            </AccordionButton>
          </AccordionBtnWrapper>
        </h3>
        <AccordionContent padContent={padContent} testId={`accordionContent_${testId}`}>
          {children}
        </AccordionContent>
      </AccordionItem>
    </AccordionLayout>
  );
};

import {
  IAssignmentQuestion,
  IFeedbackConfigurable,
  IGradingSettings,
  IMasterySet,
  IQuestionBrowserElement,
  IQuestionBrowserMasterySet,
  IQuestionBrowserQuestion,
  IQuestionBrowserQuestionPool,
  IQuestionElement,
  IQuestionFeedbackSettings,
  IQuestionPool,
  IQuestionSet,
  QuestionElementType,
} from 'context/AssignmentContextProvider/types';
import {
  DEFAULT_GRADING_FORMULA,
  isAdvancedPointsAdjustment,
  mapToSupportedPresetFormula,
} from './PointsAdjustment/ConditionalPointsSettings';

interface IQuestionAllowedSubmissionCount {
  questionElement: IQuestionElement;
  allowedSubmissionsCount: number;
}

export const isQuestionPool = (questionElement: IQuestionElement): questionElement is IQuestionPool => {
  return (
    questionElement.elementType === QuestionElementType.QUESTION_POOL &&
    (questionElement as IQuestionPool).gradingSettings !== undefined
  );
};

export const isAssignmentQuestion = (questionElement: IQuestionElement): questionElement is IAssignmentQuestion => {
  return (
    questionElement.elementType === QuestionElementType.QUESTION &&
    (questionElement as IAssignmentQuestion).question !== undefined
  );
};

export const isMasterySet = (questionElement: IQuestionElement): questionElement is IMasterySet => {
  return questionElement.elementType === QuestionElementType.MASTERY_SET;
};

export const hasNumericBoxes = (questionElement: IQuestionElement): boolean => {
  if (isAssignmentQuestion(questionElement)) {
    return questionElement.boxes.some(box => box.mode === 'N');
  } else if (isQuestionPool(questionElement)) {
    return questionElement.questions.some(question => question.boxes.some(box => box.mode === 'N'));
  }
  return false;
};

export const hasCxpQuestion = (questionElement: IQuestionElement): boolean => {
  if (isAssignmentQuestion(questionElement)) {
    return questionElement.isCxpQuestion;
  } else if (isQuestionPool(questionElement)) {
    return questionElement.questions.some(question => question.isCxpQuestion);
  }
  return false;
};

export const compareQuestionElementQuestionBrowserElement = (
  questionElement: IQuestionBrowserElement,
  questionBrowserElement: IQuestionBrowserElement
): boolean => {
  if (questionElement.elementType != questionBrowserElement.elementType) {
    return false;
  }
  switch (questionElement.elementType) {
    case QuestionElementType.QUESTION_POOL: {
      return compareQuestionPoolQuestionBrowserPool(
        questionBrowserElement as IQuestionBrowserQuestionPool,
        questionElement as IQuestionPool
      );
    }
    case QuestionElementType.MASTERY_SET: {
      return compareMasterySetQuestionBrowserMasterySet(
        questionBrowserElement as IQuestionBrowserMasterySet,
        questionElement as IMasterySet
      );
    }
    case QuestionElementType.QUESTION: {
      return compareAssignmentQuestionQuestionBrowserQuestion(
        questionBrowserElement as IQuestionBrowserQuestion,
        questionElement as IAssignmentQuestion
      );
    }
    default:
      return false;
  }
};

const compareAssignmentQuestionQuestionBrowserQuestion = (
  questionBrowserQuestion: IQuestionBrowserQuestion,
  assignmentQuestion: IAssignmentQuestion
): boolean => {
  return questionBrowserQuestion.id === assignmentQuestion.question.id;
};

const compareNumberSets = (baseSet: number[], testSet: number[]): boolean => {
  const NotEqualPoolsException = {};
  try {
    testSet.forEach(qid => {
      if (!baseSet.includes(qid)) {
        throw NotEqualPoolsException;
      }
    });
    return true;
  } catch (error) {
    return false;
  }
};

const compareMasterySetQuestionBrowserMasterySet = (
  questionBrowserMasterySet: IQuestionBrowserMasterySet,
  masterySet: IMasterySet
): boolean => {
  if (questionBrowserMasterySet.numberOfQuestions != masterySet.numberOfQuestions) {
    return false;
  }
  const masterySetQids = masterySet.questions.map((assignmentQuestion: IAssignmentQuestion) => {
    return assignmentQuestion.question.id;
  });
  const questionBrowserMasterySetQids = questionBrowserMasterySet.questions.map(
    (questionBrowserQuestion: IQuestionBrowserQuestion) => {
      return questionBrowserQuestion.id;
    }
  );
  return compareNumberSets(masterySetQids, questionBrowserMasterySetQids);
};

const compareQuestionPoolQuestionBrowserPool = (
  questionBrowserPool: IQuestionBrowserQuestionPool,
  questionPool: IQuestionPool
): boolean => {
  if (
    questionBrowserPool.numberOfQuestions != questionPool.numberOfQuestions ||
    questionBrowserPool.includedNumberOfQuestions != questionPool.includedNumberOfQuestions
  ) {
    return false;
  } else {
    const questionPoolQids = questionPool.questions.map((assignmentQuestion: IAssignmentQuestion) => {
      return assignmentQuestion.question.id;
    });
    const questionBrowserPoolQids = questionBrowserPool.questions.map(
      (questionBrowserQuestion: IQuestionBrowserQuestion) => {
        return questionBrowserQuestion.id;
      }
    );
    return compareNumberSets(questionPoolQids, questionBrowserPoolQids);
  }
};

export const getQuestionBrowserStringRepresentation = (questionBrowserElement: IQuestionBrowserElement): string => {
  switch (questionBrowserElement.elementType) {
    case QuestionElementType.QUESTION: {
      return getQuestionBrowserQuestionString(questionBrowserElement as IQuestionBrowserQuestion);
    }
    case QuestionElementType.QUESTION_POOL: {
      return getQuestionBrowserPoolString(questionBrowserElement as IQuestionBrowserQuestionPool);
    }
    case QuestionElementType.MASTERY_SET: {
      return getQuestionBrowserMasterySetString(questionBrowserElement as IQuestionBrowserMasterySet);
    }
    default:
      return '';
  }
};

const getQuestionBrowserQuestionString = (questionBrowserQuestion: IQuestionBrowserQuestion): string => {
  return `${questionBrowserQuestion.id}`;
};

const getQuestionBrowserPoolString = (questionBrowserPool: IQuestionBrowserQuestionPool): string => {
  const poolContentString = questionBrowserPool.questions
    .map((poolQuestion: IQuestionBrowserQuestion) => poolQuestion.id)
    .join('_');
  return `${questionBrowserPool.includedNumberOfQuestions}:${poolContentString}`;
};

const getQuestionBrowserMasterySetString = (masterySet: IQuestionBrowserMasterySet): string => {
  const masterySetContentString = masterySet.questions
    .map((question: IQuestionBrowserQuestion) => question.id)
    .join('_');
  return `${masterySet.subsetSize}:${masterySet.masteryThreshold}:${masterySetContentString}`;
};

export const getQuestionElementStringRepresentation = (questionElement: IQuestionElement): string => {
  switch (questionElement.elementType) {
    case QuestionElementType.QUESTION: {
      return getQuestionString(questionElement as IAssignmentQuestion);
    }
    case QuestionElementType.QUESTION_POOL: {
      return getQuestionPoolString(questionElement as IQuestionPool);
    }
    case QuestionElementType.MASTERY_SET: {
      return getMasterySetString(questionElement as IMasterySet);
    }
    default: {
      return '';
    }
  }
};

const getQuestionString = (questionBrowserQuestion: IAssignmentQuestion): string => {
  return `${questionBrowserQuestion.question.id}`;
};

const getQuestionSetString = (questionSet: IQuestionSet): string => {
  return questionSet.questions.map((question: IAssignmentQuestion) => question.question.id).join('_');
};

const getQuestionPoolString = (questionBrowserPool: IQuestionPool): string => {
  const questionsString = getQuestionSetString(questionBrowserPool);
  return `${questionBrowserPool.includedNumberOfQuestions}:${questionsString}`;
};

const getMasterySetString = (masterySet: IMasterySet): string => {
  const questionsString = getQuestionSetString(masterySet);
  return `${masterySet.subsetSize}:${masterySet.masteryThreshold}:${questionsString}`;
};

export const updateToSupportedConditionalPoints = (
  questionElement: IQuestionElement,
  assignmentConditionalPointsFormula?: string
): void => {
  if (isAdvancedPointsAdjustment(questionElement)) {
    if (hasCxpQuestion(questionElement)) {
      removeAdvancedGradingSettings(questionElement);
    }
    return;
  }
  if (isAssignmentQuestion(questionElement)) {
    questionElement.boxes.map(box => {
      box.gradingSettings.conditionalPointsFormula = mapToSupportedPresetFormula(
        assignmentConditionalPointsFormula || box.gradingSettings.conditionalPointsFormula
      );
      return box;
    });
  } else if (isQuestionPool(questionElement)) {
    questionElement.gradingSettings.conditionalPointsFormula = mapToSupportedPresetFormula(
      assignmentConditionalPointsFormula || questionElement.gradingSettings.conditionalPointsFormula
    );
  }
};

export const removeAdvancedGradingSettings = (questionElement: IQuestionElement): void => {
  if (isAssignmentQuestion(questionElement)) {
    questionElement.boxes.forEach(box => resetGradingSettingsToDefault(box.gradingSettings));
  } else if (isQuestionPool(questionElement)) {
    resetGradingSettingsToDefault(questionElement.gradingSettings);
  }
};

const resetGradingSettingsToDefault = (gradingSettings: IGradingSettings): void => {
  gradingSettings.isAdvanced = false;
  gradingSettings.conditionalPointsFormula = DEFAULT_GRADING_FORMULA;
};

export const setQuestionsDefaults = (
  result: IQuestionElement[],
  assignmentConditionPointsFormula?: string
): IQuestionElement[] => {
  const setFeedbackDefaults = <T extends IFeedbackConfigurable>(
    question: T,
    isInPool: boolean,
    pav: boolean,
    hints: boolean
  ): void => {
    question.feedbackSettings = {
      showHintsBeforeDueDateAfterNumberOfSubmissions: hints
        ? question.feedbackSettings.showHintsBeforeDueDateAfterNumberOfSubmissions
        : null,
      showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions: pav
        ? question.feedbackSettings.showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions
        : null,
      customHintText: isInPool ? null : question.feedbackSettings.customHintText,
      showCustomHintBeforeDueDateAfterNumberOfSubmissions:
        !isInPool && question.feedbackSettings.customHintText ? 1 : null,
      showKeyBeforeDueDateAfterNumberOfSubmissions: null,
      showMarkBeforeDueDateAfterNumberOfSubmissions: null,
      showResponsesBeforeDueDateAfterNumberOfSubmissions: null,
      showSolutionBeforeDueDateAfterNumberOfSubmissions: null,
    };
  };

  const checkSubmissionsForPool = (pool: IQuestionPool, settings: keyof IQuestionFeedbackSettings): boolean => {
    const pav = pool.questions[0].feedbackSettings[settings];
    return !pool.questions.some((question: IAssignmentQuestion) => {
      const qPav = question.feedbackSettings[settings];
      return qPav !== pav;
    });
  };

  const setAllowedSubmissionsCountToOneIfRequired = (question: IAssignmentQuestion): void => {
    if (question.onlyOneSubmission) {
      question.boxes.forEach(box => {
        box.gradingSettings.allowedSubmissionsCount = 1;
      });
    }
  };

  result.forEach(element => {
    switch (element.elementType) {
      case QuestionElementType.QUESTION: {
        setFeedbackDefaults(element as IAssignmentQuestion, false, true, true);
        setAllowedSubmissionsCountToOneIfRequired(element as IAssignmentQuestion);
        break;
      }
      case QuestionElementType.QUESTION_POOL: {
        const pavSame = checkSubmissionsForPool(
          element as IQuestionPool,
          'showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions'
        );
        const hintsSame = checkSubmissionsForPool(
          element as IQuestionPool,
          'showHintsBeforeDueDateAfterNumberOfSubmissions'
        );
        (element as IQuestionPool).questions.forEach(question => {
          setFeedbackDefaults(question, true, pavSame, hintsSame);
          setAllowedSubmissionsCountToOneIfRequired(question);
        });
        break;
      }
      case QuestionElementType.MASTERY_SET: {
        setFeedbackDefaults(element as IMasterySet, false, false, true);
        break;
      }
    }
    updateToSupportedConditionalPoints(element, assignmentConditionPointsFormula);
  });
  return result;
};

export const getQuestionNumbersFromPoolWhichRequireQuestionPartsSubmissions = (pool: IQuestionPool): number[] => {
  const qNumbers: number[] = [];
  pool.questions.forEach((question, index) => {
    if (question.isQuestionPartSubmissionRequired) {
      qNumbers.push(index + 1);
    }
  });
  return qNumbers;
};

export const getQuestionNumbersFromPoolWithAssignmentSubmissionNotAllowed = (pool: IQuestionPool): number[] => {
  const qNumbers: number[] = [];
  pool.questions.forEach((question, index) => {
    if (question.entireAssignmentSubmissionNotAllowed) {
      qNumbers.push(index + 1);
    }
  });
  return qNumbers;
};

export const getQuestionElementGradingSettings = (questionElement: IQuestionElement): IGradingSettings | null => {
  if (isAssignmentQuestion(questionElement)) {
    return questionElement.boxes[0].gradingSettings;
  }
  if (isQuestionPool(questionElement)) {
    return questionElement.gradingSettings;
  }
  return null;
};

// The student assignment take page defines the number of submissions available as the
// max number of submissions allowed for all boxes in the question, regardless of whether
// the assignment supports whole question or question part submission
export const questionSubmissionsAllowed = (data: IQuestionAllowedSubmissionCount): number => {
  if (isQuestionPool(data.questionElement) || isMasterySet(data.questionElement)) {
    return data.questionElement.gradingSettings.allowedSubmissionsCount || data.allowedSubmissionsCount;
  }
  if (isAssignmentQuestion(data.questionElement)) {
    return Math.max(
      ...data.questionElement.boxes.map(box => {
        const boxSubmissions = box.gradingSettings.allowedSubmissionsCount;
        return boxSubmissions !== null ? boxSubmissions : data.allowedSubmissionsCount;
      })
    );
  }
  return data.allowedSubmissionsCount;
};

export const containsMasterySet = (questionsData: IQuestionElement[]): boolean => {
  return questionsData.some(element => element.elementType === QuestionElementType.MASTERY_SET);
};

import { useTranslation } from 'react-i18next';
import {
  FormatTips,
  IDisplayAndSyntaxErrorsSettings,
  ShowNames,
  Submission,
} from 'context/AssignmentContextProvider/types';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import React, { useEffect, useState } from 'react';
import {
  setDisplayAndSyntaxErrorsSettings,
  setIsQuestionRandomizedSettings,
  setDisplayAllQuestionsSettings,
} from 'context/AssignmentContextProvider/actions';
import { FormGroup, Radio, RadioGroup, Select, Toggle } from 'react-magma-dom';
import styled from '@emotion/styled';
import { HelpTooltip } from 'sharedComponents/Tooltip/HelpTooltip';

const OneAtTimeRadio = styled.div`
  display: flex;
  align-items: center;
`;
const formGroupLabelStyle = { fontSize: '1rem' };
const selectContainerStyle = { width: '21rem', marginTop: '1rem' };
const toggleLabelStyle = { fontSize: '0.875rem', fontWeight: 600 };
export enum DisplayQuestions {
  All = '1',
  One = '2',
}

const defaultDisplayAndSyntaxErrorsSettings: IDisplayAndSyntaxErrorsSettings = {
  syntaxErrorsOverridden: true, //override_syntax
  symbolicFormattingHelpIconEnabled: false, //symbolic_icon
  answerFormatTips: FormatTips.USE_COURSE_SETTING, //format_tips
  questionNameDisplay: ShowNames.TEXTBOOK_QUESTIONS, //show_names
  isIncorrectSubmissionsWithSyntaxErrorsForNumericalQuestions: false, //numerical_syntax
  isIncorrectSubmissionsWithSyntaxErrorsForSymbolicQuestions: false, //symbolic_syntax
  isIncorrectSubmissionsWithSyntaxErrorsForAlgebraicQuestions: false, //algebraic_syntax
};

const QuestionDisplayGroup = (): JSX.Element => {
  const { t } = useTranslation();

  const showNamesOptions: { [key: string]: { label: string; value: ShowNames } } = {
    [ShowNames.ALL]: {
      label: t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.DISPLAY_QUESTIONS.FOR_ALL'),
      value: ShowNames.ALL,
    },
    [ShowNames.TEXTBOOK_QUESTIONS]: {
      label: t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.DISPLAY_QUESTIONS.FOR_TEXTBOOK'),
      value: ShowNames.TEXTBOOK_QUESTIONS,
    },
    [ShowNames.NEVER]: {
      label: t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.DISPLAY_QUESTIONS.NEVER'),
      value: ShowNames.NEVER,
    },
  };

  const {
    state: {
      assignmentSettings: {
        currentAssignmentTemplate: { settings },
      },
    },
    dispatch,
  } = useAssignmentContext();

  const { displayAllQuestions, isQuestionOrderRandomized, displayAndSyntaxErrorsSettings, submissionAndWorkSettings } =
    settings;

  const [selectQuestionOrder, setSelectQuestionOrder] = useState<string>('');
  const [checkedShuffle, setCheckedShuffle] = useState<boolean>(false);
  const [selectedItemName, setSelectedItemName] = useState<{ label: string; value: ShowNames }>(
    showNamesOptions[ShowNames.TEXTBOOK_QUESTIONS]
  );

  useEffect(() => {
    setSelectQuestionOrder(displayAllQuestions ? DisplayQuestions.All : DisplayQuestions.One);
    setCheckedShuffle(isQuestionOrderRandomized);
    setSelectedItemName(showNamesOptions[displayAndSyntaxErrorsSettings.questionNameDisplay]);
  }, []);

  const handleItemOrder = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectQuestionOrder(e.target.value);
    dispatch(setDisplayAllQuestionsSettings(e.target.value === DisplayQuestions.All));
  };
  const handleShuffleOrder = (): void => {
    setCheckedShuffle(!checkedShuffle);
    dispatch(setIsQuestionRandomizedSettings(!checkedShuffle));
  };
  const handleItemSelect = (changes: any): void => {
    setSelectedItemName(changes.selectedItem);
    dispatch(
      setDisplayAndSyntaxErrorsSettings({
        ...defaultDisplayAndSyntaxErrorsSettings,
        questionNameDisplay: changes.selectedItem.value,
      })
    );
  };
  return (
    <FormGroup
      labelStyle={formGroupLabelStyle}
      labelText={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.QUESTION_DISPLAY_ORDER')}
      testId="displayOrderGroup"
    >
      <RadioGroup
        aria-label={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.DISPLAY_QUESTIONS_LABEL')}
        id="radioGroup"
        name="displayQuestionsOrder"
        onChange={handleItemOrder}
        value={selectQuestionOrder}
        testId="displayQuestionRadioGroup"
      >
        <Radio
          id="radioDisplayAll"
          labelText={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.DISPLAY_ALL_QUESTIONS_AT_ONCE')}
          value={DisplayQuestions.All}
          testId="displayQuestionRadioAll"
        />
        <OneAtTimeRadio>
          <Radio
            id="radioDisplayOne"
            labelText={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.DISPLAY_ONE_AT_A_TIME')}
            value={DisplayQuestions.One}
            testId="displayQuestionRadioOne"
            disabled={submissionAndWorkSettings.submission === Submission.ASSIGNMENT}
            aria-disabled={submissionAndWorkSettings.submission === Submission.ASSIGNMENT}
          />
          {submissionAndWorkSettings.submission === Submission.ASSIGNMENT && (
            <HelpTooltip
              content={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.DISPLAY_QUESTION_TOOLTIP')}
              labeledBy={'radioDisplayOne'}
            />
          )}
        </OneAtTimeRadio>
      </RadioGroup>
      <Toggle
        checked={checkedShuffle}
        onChange={handleShuffleOrder}
        labelText={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.SHUFFLE_QUESTION_ORDER')}
        testId="shuffleQuestionOrder"
        labelStyle={toggleLabelStyle}
      />
      <Select
        id="questionNamesSelect"
        labelText={t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.DISPLAY_QUESTION_NAMES')}
        containerStyle={selectContainerStyle}
        items={[
          showNamesOptions[ShowNames.NEVER],
          showNamesOptions[ShowNames.TEXTBOOK_QUESTIONS],
          showNamesOptions[ShowNames.ALL],
        ]}
        selectedItem={selectedItemName}
        onSelectedItemChange={handleItemSelect}
        testId="displayQuestionsNameSelect"
        helperMessage={
          selectedItemName.value === showNamesOptions[ShowNames.TEXTBOOK_QUESTIONS].value
            ? t('ASSIGNMENT_EDITOR.CHEATING_DETERRENTS.DISPLAY_TEXTBOOK_HELPER_MESSAGE')
            : ''
        }
      />
    </FormGroup>
  );
};

export default QuestionDisplayGroup;

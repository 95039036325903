import React, { useCallback, useEffect, useState } from 'react';
import {
  ICxpTextbookQuestion,
  IFlattenedTextbookResultQuestion,
  ITextbookShortName,
} from 'context/CxpQuestionContextProvider/types';
import {
  searchDistinctTextbookNames,
  searchQuestionsByTextbookId,
} from 'facultyComponents/cxpQuestionManager/apiHelpers';
import {
  GENERAL_REPORT_ERROR,
  GENERAL_TEXTBOOK_ERROR,
} from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/types';
import {
  addSheetToWorkbook,
  convertJsonToWorksheet,
  createAndDownloadXlsx,
  createWorkbook,
} from 'utils/dataConverter/XlsxManipulator';
import {
  createReportFileName,
  formatQuestionResult,
} from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/cxpQuestionUtil';
import {
  Alert,
  AlertVariant,
  Button,
  Combobox,
  Flex,
  FlexAlignItems,
  FlexBehavior,
  FlexJustify,
} from 'react-magma-dom';

export const QuestionReporter = (): JSX.Element => {
  const [textbookNames, setTextbookNames] = useState<ITextbookShortName[]>([]);
  const [disableDownload, setDisableDownload] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<ITextbookShortName>({ id: 0, shortName: '', code: '' });
  const [message, setMessage] = useState<string>('');

  const getTextbookNames = async () => {
    const response = await searchDistinctTextbookNames();
    const names: ITextbookShortName[] = response.data.result;
    return names;
  };

  useEffect(() => {
    getTextbookNames()
      .then(arr => {
        setTextbookNames(arr);
      })
      .catch(() => {
        setMessage(GENERAL_TEXTBOOK_ERROR);
      });
  }, []);

  const onSelectedItemChange = useCallback(changes => {
    if (changes.selectedItem) {
      setDisableDownload(false);
      setSelectedElement({
        id: changes.selectedItem.value,
        shortName: changes.selectedItem.label,
        code: changes.selectedItem.code,
      });
    }
  }, []);

  const downloadReport = async () => {
    try {
      setIsLoading(true);
      setMessage('');
      const workbook = createWorkbook();
      const response = await searchQuestionsByTextbookId(selectedElement.id);
      const questionsCxp: ICxpTextbookQuestion[] = response.data.result;

      const formattedQuestions: IFlattenedTextbookResultQuestion[] = [];
      questionsCxp.forEach(question => {
        formattedQuestions.push(formatQuestionResult(question));
      });

      const questions = convertJsonToWorksheet(formattedQuestions);

      addSheetToWorkbook(workbook, questions, 'Textbook question');
      createAndDownloadXlsx(workbook, createReportFileName(`${selectedElement.code}_CXP_report_`));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setMessage(GENERAL_REPORT_ERROR);
    }
  };
  return (
    <>
      <Flex behavior={FlexBehavior.container} spacing={4} style={{ width: '43em' }}>
        <Flex behavior={FlexBehavior.item} xs={12}>
          <Combobox
            testId="comboboxId"
            labelText="Select Textbook"
            items={textbookNames.map(name => ({ label: name.shortName, value: name.id, code: name.code }))}
            placeholder="Enter textbook"
            disableCreateItem={true}
            inputStyle={{ width: '40em' }}
            menuStyle={{ width: '40em' }}
            onSelectedItemChange={onSelectedItemChange}
          />
        </Flex>
        <Flex behavior={FlexBehavior.item} xs={12} justify={FlexJustify.flexEnd} alignItems={FlexAlignItems.flexEnd}>
          <Button
            testId="downLoadReportButtonId"
            style={{ float: 'right', marginRight: '1em' }}
            disabled={disableDownload}
            isLoading={isLoading}
            onClick={downloadReport}
          >
            Download Report
          </Button>
        </Flex>
      </Flex>
      {message === '' || (
        <Alert testId="alertId" variant={AlertVariant.danger}>
          {message}
        </Alert>
      )}
    </>
  );
};

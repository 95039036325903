import { ActionType } from '../actionCreators';

type StoreType = {
  [key: string]: ActionType;
};
function reducer(currentState: any, action: ActionType): StoreType {
  const { apiName, status, data } = action;

  const updatedStateForAPIName: StoreType = {};
  updatedStateForAPIName[apiName] = {
    status,
    apiName,
    data,
  };

  const updatedState: StoreType = { ...currentState, ...updatedStateForAPIName };
  return updatedState;
}

export { reducer as default };

import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonColor, ButtonSize, ButtonVariant, IconButton, Input, Textarea } from 'react-magma-dom';
import { AttachmentIcon } from 'react-magma-icons';
import { setAssignmentInstructions, setAssignmentName, setErrors } from 'context/AssignmentContextProvider/actions';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { AssignmentEditorAccordion } from '../helpers/Accordion/AssignmentEditorAccordion';
import { getFieldValue } from 'utils/utils';

const InfoBlockLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TextareaWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 60%;
  textarea {
    height: 6.5rem;
  }
`;
const BtnWrapper = styled.div`
  margin-top: 0.75rem;
`;

export const AssignmentEditorInfo = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    state: {
      errors,
      assignmentEditorContext: { assignmentInfo },
    },
    dispatch,
  } = useAssignmentContext();

  const assignmentInstrTextAreaRef = useRef<HTMLTextAreaElement>(null);

  const [assignmentNameCurrent, setAssignmentNameCurrent] = useState('');
  const [assignmentInstr, setAssignmentInstr] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorInstruction, setErrorInstruction] = useState('');

  useEffect(() => {
    const assignmentNameError = getFieldValue(errors, 'info.assignmentName');
    if (assignmentNameError) {
      setErrorName(assignmentNameError);
    }
    const infoError = getFieldValue(errors, 'info.instructionsField');
    if (infoError) {
      setErrorInstruction(infoError);
    }
    setAssignmentNameCurrent(assignmentInfo.name);
    setAssignmentInstr(assignmentInfo.instructions);
  }, [assignmentInfo, errors]);

  useEffect(() => {
    window.addEventListener('message', handleEventListener);
    return () => {
      window.removeEventListener('message', handleEventListener);
    };
  }, []);

  const handleEventListener = (e: MessageEvent) => {
    if (e.data && e.data.value && e.data.source === 'include_file') {
      setAssignmentInstr(e.data.value);
      assignmentInstrTextAreaRef?.current?.focus();
    }
  };
  const handleAssignmentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    setAssignmentNameCurrent(value);
    if (!value.trim().length) {
      setErrorName('ASSIGNMENT_EDITOR.INFORMATION.ERRORS.NAME.REQUIRED');
      return;
    }
    if (value.length > 50) {
      setErrorName('ASSIGNMENT_EDITOR.INFORMATION.ERRORS.NAME.LENGTH');
      return;
    }
    setErrorName('');
  };
  const handleAssignmentInstrChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { value } = e.target;
    setAssignmentInstr(value);
    if (value.length > 65536) {
      setErrorInstruction('ASSIGNMENT_EDITOR.INFORMATION.ERRORS.INSTRUCTIONS.LENGTH');
      return;
    }
    setErrorInstruction('');
  };

  const handleNameBlur = () => {
    dispatch(
      setErrors({
        field: 'info.assignmentName',
        value: errorName ? errorName : undefined,
      })
    );
    dispatch(setAssignmentName(assignmentNameCurrent));
  };

  const handleInstructionsBlur = () => {
    dispatch(
      setErrors({
        field: 'info.instructionsField',
        value: errorInstruction ? errorInstruction : undefined,
      })
    );
    dispatch(setAssignmentInstructions(assignmentInstr));
  };

  const openIncludeFileWindow = () => {
    const includeFileWindow = window.open(
      window.location.origin + '/v4cgi/folders/list.tpl?detached=1&from=assignments&destination=heading',
      'MyFolders',
      'width=730,height=560,scrollbars=yes,resizable=yes'
    );

    includeFileWindow?.sessionStorage.setItem('isNewAssignmentEditor', '1');
  };
  return (
    <AssignmentEditorAccordion
      testId="infoBlock"
      btnText={t('ASSIGNMENT_EDITOR.INFORMATION.TITLE')}
      accordionType={'infoOpen'}
    >
      <InfoBlockLayout data-testid="infoBlockLayout">
        <Input
          testId="assignmentName"
          labelText="Assignment name*"
          placeholder="50 characters maximum"
          value={assignmentNameCurrent}
          onChange={handleAssignmentNameChange}
          onBlur={handleNameBlur}
          helperMessage="Required*"
          containerStyle={{ width: '40%' }}
          errorMessage={errorName ? t(errorName) : null}
          required
        />
        <TextareaWrapper>
          <Textarea
            ref={assignmentInstrTextAreaRef}
            id="insructionsField"
            testId="assignmentInstructions"
            labelText={t('ASSIGNMENT_EDITOR.INFORMATION.INSTRUCTIONS_TO_STUDENTS_LABEL_TEXT')}
            placeholder={t('ASSIGNMENT_EDITOR.INFORMATION.INSTRUCTIONS_TO_STUDENTS_PLACE_HOLDER')}
            value={assignmentInstr}
            onBlur={handleInstructionsBlur}
            onChange={handleAssignmentInstrChange}
            containerStyle={{ width: '100%' }}
            errorMessage={errorInstruction ? t(errorInstruction) : null}
            required
          />
          <BtnWrapper>
            <IconButton
              testId="incluFileBtn"
              icon={<AttachmentIcon />}
              color={ButtonColor.primary}
              variant={ButtonVariant.link}
              size={ButtonSize.small}
              onClick={openIncludeFileWindow}
            >
              Include file
            </IconButton>
          </BtnWrapper>
        </TextareaWrapper>
      </InfoBlockLayout>
    </AssignmentEditorAccordion>
  );
};

import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { AlertVariant, Button, ButtonVariant, Select, styled, Toast } from 'react-magma-dom';
import { getAssignmentTemplate, createTemplate } from '../../apiHelpers';
import {
  setInitialAssignmentTemplate,
  setAssignmentTemplatesData,
  setAdjustAllQuestionConditionalPoints,
} from 'context/AssignmentContextProvider/actions';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { useTranslation } from 'react-i18next';
import { CreateTemplateModal } from 'facultyComponents/assignmentEditor/assignmentEditorSettings/CreateTemplateModal/CreateTemplateModal';
import {
  IAssignmentTemplate,
  ITemplateListItem,
  Randomization,
  Submission,
} from 'context/AssignmentContextProvider/types';
import {
  getConditionalPointSettingsFromFormula,
  makeConditionalPointsFormula,
  mapToSupportedPresetFormula,
  PresetDefaults,
} from 'facultyComponents/assignmentEditor/helpers/PointsAdjustment/ConditionalPointsSettings';
import { containsMasterySet } from 'facultyComponents/assignmentEditor/helpers/questionElements';

const TemplateGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const TemplateHeading = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 1rem;
  padding-bottom: 1rem;
`;

interface IProps {
  setIsTemplateChanging: Dispatch<SetStateAction<boolean>>;
}

interface IToastProps {
  message: string;
  variant: AlertVariant;
  disableAutoDismiss: boolean;
}

const TemplatesSelect: React.FC<IProps> = (props): ReactElement => {
  const {
    state: {
      userId,
      assignmentEditorContext: {
        assignmentInfo: { id, isModificationLimited },
        defaultTemplateId,
      },
      assignmentSettings: { templateList, currentAssignmentTemplate, isCustomTemplate },
      questionsData,
    },
    dispatch,
  } = useAssignmentContext();

  const { t } = useTranslation();

  const { setIsTemplateChanging } = props;
  // DETECT THE SELECTED ITEM IN TEMPLATES DROPDOWN BY DEFAULT || format: {label: string, value: number | string}
  const detectDefaultTemplate = (): { label: string; value: number | string | undefined } => {
    const currentAssignmentTemplateId = currentAssignmentTemplate.id || 0;

    if (currentAssignmentTemplateId > 0 && !isCustomTemplate) {
      return { label: currentAssignmentTemplate.name, value: currentAssignmentTemplate.id };
    }

    // for create flow we do not request assignment context that contains information about defaultTemplateId
    // for such case we put default template as first element in templates array on backend
    const defaultTemplate = templateList.find(template => +template.id === defaultTemplateId) || templateList[0];
    if (defaultTemplate && !isCustomTemplate) {
      if (currentAssignmentTemplateId < 1) {
        onTemplateChange({ selectedItem: { label: defaultTemplate?.name, value: defaultTemplateId } });
      }
      return { label: defaultTemplate?.name, value: defaultTemplate?.id };
    }

    return { label: '', value: '' };
  };

  const getTemplateNameWidth = (text: string, fontProperties: string): number => {
    const tag = document.createElement('div');
    tag.style.position = 'absolute';
    tag.style.font = fontProperties;
    tag.innerHTML = text;
    document.body.appendChild(tag);
    const result = tag.clientWidth;
    document.body.removeChild(tag);
    return result;
  };
  const getTemplateNameMaxWidth = (): string => {
    const additionSpaceForPaddingScroll = 80;
    const defaultFontProperties = '16px / 24px "Open Sans", Helvetica, sans-serif';
    const selectElement = document.querySelector('#templateSelect-toggle-button > div > span');
    const fontProperties = selectElement ? getComputedStyle(selectElement).font : defaultFontProperties;
    const templateNamesWidth = templateList.map(template => getTemplateNameWidth(template.name, fontProperties));
    return Math.max(...templateNamesWidth) + additionSpaceForPaddingScroll + 'px';
  };

  const [templateNameMaxWidth, setTemplateNameMaxWidth] = useState('');

  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [messageForAlert, setMessageForAlert] = useState<IToastProps>({
    message: '',
    variant: AlertVariant.info,
    disableAutoDismiss: false,
  });

  useEffect(() => {
    setTemplateNameMaxWidth(getTemplateNameMaxWidth());
  }, [templateList.length]);

  const handleCreateTemplateClick = (): void => {
    setShowCreateTemplateModal(true);
  };

  const handleCreateTemplateClose = (): void => {
    setShowCreateTemplateModal(false);
  };

  const handleCreateTemplateSave = async (templateName: string): Promise<void> => {
    const compareTemplateListItem = (templateA: ITemplateListItem, templateB: ITemplateListItem) => {
      const [lowerCaseA, lowerCaseB] = [templateA, templateB].map((template: ITemplateListItem) => {
        return template.name.toLowerCase();
      });
      if (lowerCaseA > lowerCaseB) {
        return 1;
      }
      if (lowerCaseA < lowerCaseB) {
        return -1;
      }
      if (templateA.name > templateB.name) {
        return 1;
      }
      if (templateA.name < templateB.name) {
        return -1;
      }
      return 0;
    };
    if (userId) {
      const createTemplateResponse = await createTemplate({
        templateName: templateName,
        templateSettings: currentAssignmentTemplate.settings,
        userId: userId,
      });
      const { templateId, templateName: newTemplateName, isRequestSuccessful } = createTemplateResponse.data.result;
      if (isRequestSuccessful) {
        templateList.push({
          id: templateId,
          name: newTemplateName,
          description: '',
        });
        templateList.sort(compareTemplateListItem);
        dispatch(setAssignmentTemplatesData({ templateList: templateList }));
        setSelectedTemplate({ label: newTemplateName, value: templateId });
        dispatch(
          setInitialAssignmentTemplate({
            ...currentAssignmentTemplate,
            id: templateId,
            name: newTemplateName,
            description: '',
          })
        );
        setMessageForAlert({
          message: t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.SAVE_SUCCESS'),
          variant: AlertVariant.success,
          disableAutoDismiss: false,
        });
      } else {
        setMessageForAlert({
          message: t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.ERROR.UNKNOWN_ERROR'),
          variant: AlertVariant.danger,
          disableAutoDismiss: false,
        });
      }
      setShowCreateTemplateModal(false);
      setShowToast(true);
    }
    return;
  };

  // LOADING FLAG FOR THE ASSIGNMENT TEMPLATE BLOCK
  const [selectedTemplate, setSelectedTemplate] = useState<{ label: string; value: number | undefined | string }>({
    label: '',
    value: undefined,
  });

  useEffect(() => {
    setSelectedTemplate(detectDefaultTemplate());
  }, [isCustomTemplate]);

  // TEMPLATE CHANGING
  const onTemplateChange = async (changes: any) => {
    const isSettingIncompatibleWithMastery = (template: IAssignmentTemplate): boolean => {
      if (
        template.settings.questionRandomizationSettings.randomization !== Randomization.AFTER_EVRY_SUBM ||
        template.settings.questionRandomizationSettings.randomizationSubmissionsCount !== 1
      ) {
        return true;
      }
      return template.settings.submissionAndWorkSettings.submission === Submission.QUESTION_PART;
    };
    const checkTemplateCompatibility = (
      template: IAssignmentTemplate
    ): { isCompatible: boolean; errorToast: IToastProps } => {
      const isTemplateRandomizationEqual = isEqual(
        currentAssignmentTemplate.settings.questionRandomizationSettings,
        template.settings.questionRandomizationSettings
      );
      if (isModificationLimited && !isTemplateRandomizationEqual) {
        return {
          isCompatible: false,
          errorToast: {
            message: t('ASSIGNMENT_EDITOR.SETTINGS.TEMPLATES_MODIFICATION_LIMITED_COMPATIBILITY_ERROR'),
            variant: AlertVariant.danger,
            disableAutoDismiss: true,
          },
        };
      } else if (containsMasterySet(questionsData) && isSettingIncompatibleWithMastery(template)) {
        return {
          isCompatible: false,
          errorToast: {
            message: t('ASSIGNMENT_EDITOR.SETTINGS.TEMPLATES_MASTERY_SET_COMPATIBILITY_ERROR'),
            variant: AlertVariant.danger,
            disableAutoDismiss: true,
          },
        };
      }
      return {
        isCompatible: true,
        errorToast: {
          message: '',
          variant: AlertVariant.info,
          disableAutoDismiss: false,
        },
      };
    };

    setIsTemplateChanging(true);
    const previousTemplate = { ...selectedTemplate };
    setSelectedTemplate(changes.selectedItem);
    // GET REQUEST: ASSIGNMENT TEMPLATE || WHEN WE CHANGE ITEM IN ASSIGNMENT TEMPLATES DROPDOWN
    const newTemplate = await getAssignmentTemplate({
      assignmentId: id,
      templateId: changes.selectedItem.value,
      defaultTemplateId,
      userId,
    });

    const { result: template } = newTemplate.data;
    const templateCompatibility = checkTemplateCompatibility(template);
    if (templateCompatibility.isCompatible) {
      const templateFormula = template.settings.conditionalPointsFormula;
      const formulaSupportedAtAssignmentLevel = getConditionalPointSettingsFromFormula(templateFormula, true);
      const formulaSupportedAtQuestionLevel = getConditionalPointSettingsFromFormula(templateFormula);
      const templateForInitiate =
        formulaSupportedAtAssignmentLevel?.mode === formulaSupportedAtQuestionLevel?.mode
          ? template
          : {
              ...template,
              settings: {
                ...template.settings,
                conditionalPointsFormula: makeConditionalPointsFormula(PresetDefaults.SET_AT_QUESTION_LEVEL),
              },
            };
      dispatch(setInitialAssignmentTemplate(templateForInitiate));
      dispatch(
        setAdjustAllQuestionConditionalPoints({
          conditionalPointsFormula: mapToSupportedPresetFormula(templateFormula),
        })
      );
    } else {
      setSelectedTemplate(previousTemplate);
      setMessageForAlert(templateCompatibility.errorToast);
      setShowToast(true);
    }
    setIsTemplateChanging(false);
  };

  return (
    <>
      <TemplateHeading>{t('ASSIGNMENT_EDITOR.SETTINGS.TEMPLATE_HEADING')}</TemplateHeading>
      <TemplateGroup>
        <Select
          testId="templatesSelect"
          id="templatesSelectComponent"
          labelText={t('ASSIGNMENT_EDITOR.SETTINGS.TEMPLATE_NAME')}
          items={templateList.map(template => ({ label: template.name, value: template.id }))}
          selectedItem={selectedTemplate}
          onSelectedItemChange={onTemplateChange}
          placeholder={t('ASSIGNMENT_EDITOR.SETTINGS.TEMPLATES_PLACEHOLDER')}
          containerStyle={{ width: templateNameMaxWidth }}
        />
        {currentAssignmentTemplate.id === 0 || isCustomTemplate ? (
          <Button variant={ButtonVariant.link} testId={'saveAsNewTemplateButton'} onClick={handleCreateTemplateClick}>
            {t('ASSIGNMENT_EDITOR.SETTINGS.CREATE_TEMPLATE.SAVE_AS_TEMPLATE')}
          </Button>
        ) : null}
        <CreateTemplateModal
          show={showCreateTemplateModal}
          close={handleCreateTemplateClose}
          templates={templateList}
          save={handleCreateTemplateSave}
        />
        {showToast ? (
          <Toast
            role="alert"
            testId="templateAlertToast"
            onDismiss={() => setShowToast(false)}
            variant={messageForAlert.variant as AlertVariant}
            disableAutoDismiss={messageForAlert.disableAutoDismiss}
          >
            {messageForAlert.message}
          </Toast>
        ) : null}
      </TemplateGroup>
    </>
  );
};

export default TemplatesSelect;

import React, { ReactElement, useEffect, useState } from 'react';
import { FacultyHeader, SkipLink } from 'wa-ui-components';
import { useFacultyClassesAPI } from '../../../service/dataHooks';
import { createCourseBtn, headerLinksData, myClassesBtn } from 'utils/facultyHeaderHelpers/facultyHeaderLinks';
import {
  getClassesLinks,
  getDropDownQuestionsLinks,
  getDropDownAssignmentLinks,
  onQuickSearch,
  getDropDownAdminLinks,
  IUserData,
} from 'utils/facultyHeaderHelpers/facultyHeaderMethods';
import { getWalkMeConfig, getUnreadMessagesCounter, IWalkMeConfig } from '../../../apiHelpers';

interface IProps {
  userData: IUserData;
  userStatus: string;
}

const getWalkme = async (): Promise<void> => {
  await getWalkMeConfig().then(result => {
    const walkmeConfig: IWalkMeConfig = result.data.result;
    if (walkmeConfig && walkmeConfig.isWalkmeEnabled && walkmeConfig.walkmeUrl) {
      const walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.src = walkmeConfig.walkmeUrl;
      walkme.async = true;
      walkme.id = 'walkmeSnippet';
      document.body.appendChild(walkme);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window._walkmeConfig = { smartLoad: true };
      return () => {
        document.body.removeChild(walkme);
      };
    }
  });
};

const FacultyOmniBar = (props: IProps): ReactElement => {
  const { userData, userStatus } = props;

  const [classesDataState, setClassesDataState] = useFacultyClassesAPI(undefined);
  const { data: classesData, status: classesLinksStatus } = classesDataState;
  const [counterResponse, setCounter] = useState('0');
  const userName = userData?.firstName || userData?.fullName;

  useEffect(() => {
    // once we fetch User Data, we fetch classes data for that user
    if (userStatus === 'loaded' && !!userData) {
      headerLinksData.dropDownQuestionsLink = JSON.stringify(getDropDownQuestionsLinks(userData));
      headerLinksData.dropDownAssignmentsLink = JSON.stringify(getDropDownAssignmentLinks(userData));
      headerLinksData.dropDownAdminLink = JSON.stringify(getDropDownAdminLinks(userData));
      headerLinksData.userFirstName = userName;
      headerLinksData.showAdminMenu = userData.admin || userData.authorizations.restricted_admin;

      setClassesDataState({ userId: userData.id });
    }
  }, [userStatus]);

  if (classesLinksStatus === 'loaded' && !!classesData) {
    const createCourseButton = userData.authorizations.course_creation_enabled ? createCourseBtn : { name: '' };
    headerLinksData.classesLinks = JSON.stringify([
      getClassesLinks(classesData.CURRENT_CLASSES, 'classes'),
      myClassesBtn,
      createCourseButton,
      getClassesLinks(classesData.FUTURE_CLASSES, 'future_classes'),
    ]);
  }

  useEffect(() => {
    const setUnreadMessagesCounter = async () => {
      const result = await getUnreadMessagesCounter(userData.id);
      if (result?.status == 200) {
        setCounter(result.data.result?.unreadMessagesCount);
      }
    };
    setUnreadMessagesCounter();
    getWalkme();
  }, []);

  if (counterResponse) {
    headerLinksData.unreadMessagesCounter = counterResponse;
  }

  return (
    <>
      {<SkipLink isFaculty={userData.faculty} />}
      <FacultyHeader
        {...{
          ...headerLinksData,
          userFirstName: userName,
          showAdminMenu: userData.admin || userData.authorizations.restricted_admin,
        }}
        onQuickSearch={onQuickSearch}
      />
      {/* HACK! This dummy div is required until we can work with walkme to use a better element */}
      <div id="js-faculty-header" style={{ display: 'none' }} data-user-name={userData.uid} />
    </>
  );
};

export default FacultyOmniBar;

import React, { useEffect } from 'react';

interface IQualtricsInformationProps {
  userSsoGuid: string;
}
const QualtricsInformation = (props: IQualtricsInformationProps): JSX.Element => {
  const { userSsoGuid } = props;
  useEffect(() => {
    const qualtricsSnippet = document.createElement('script');
    qualtricsSnippet.type = 'text/javascript';
    qualtricsSnippet.src = window.location.origin + '/static/common/js/qualtrics.js';
    qualtricsSnippet.async = true;
    qualtricsSnippet.id = 'qualtricsSnippet';
    document.head.appendChild(qualtricsSnippet);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._qualtricsConfig = { smartLoad: true };
    return () => {
      document.head.removeChild(qualtricsSnippet);
    };
  }, []);
  return (
    <span id="qualtricsInAppSurvey">
      <input type="hidden" id="sidata_user" name="sidata_user" value={userSsoGuid}></input>
      <input type="hidden" id="sidata_userType" name="sidata_userType" value="faculty"></input>
    </span>
  );
};

export default QualtricsInformation;

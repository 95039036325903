import React from 'react';
import { format } from 'date-fns';

interface IProps {
  val: number;
}
const DateFormatter = ({ val }: IProps): JSX.Element => {
  let date: string;
  if (val == null) {
    date = 'Null';
  } else {
    date = format(new Date(val), 'MM/dd/yyyy hh:mm a');
  }
  return <div>{date}</div>;
};

export default DateFormatter;

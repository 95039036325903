import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import {
  AlertVariant,
  Banner,
  Button,
  ButtonColor,
  ButtonVariant,
  Flex,
  FlexAlignItems,
  FlexBehavior,
  useGenerateId,
} from 'react-magma-dom';

import {
  IAssignmentQuestion,
  IMasterySet,
  IQuestionElement,
  IQuestionPool,
  QuestionElementType,
  ShowMyWorkMode,
  Submission,
} from 'context/AssignmentContextProvider/types';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';

import {
  getQuestionNumbersFromPoolWhichRequireQuestionPartsSubmissions,
  getQuestionNumbersFromPoolWithAssignmentSubmissionNotAllowed,
  isAssignmentQuestion,
  isMasterySet,
  isQuestionPool,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';

import {
  Controls,
  Drawer,
  Points,
  TimeToAnswer,
  getDrawerTabsForElement,
} from 'facultyComponents/assignmentEditor/assignmentEditorQuestionList/QuestionElements';

import { ControlsColumn, PointsColumn, QuestionColumn, TimeColumn } from '../AssignmentEditorQuestionList';
import { setSubmissionAndWorkSettings } from 'context/AssignmentContextProvider/actions';
import { QuestionNameControls } from '../QuestionElements/Controls/Controls';

interface IProps {
  index: number;
  questionElement: IQuestionElement;
  questionNumber: number[] | null;
  isExpanded?: boolean;
  isLast?: boolean;
  onClickRemove: () => void;
}

interface IAlertBannerProps {
  testId: string;
  onClickUpdate: () => void;
  alertMessage: string;
}

const QuestionListBody = styled(Flex)<{ isLast?: boolean }>`
  ${props => (!props.isLast ? `border-bottom: 2px solid ${props.theme.colors.greyAccent};` : '')}
  margin: 0.125rem 0rem;
  padding: 0.875rem 1rem;
`;

const StyledQuestionListItem = styled.li`
  display: contents;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  flex: auto;
`;

const SettingsRequirementBanner = styled(Banner)<{ variant: AlertVariant }>`
  flex: auto;
  white-space: pre-line;
  margin-top: 0.625rem;
`;

const QuestionAlertBanner = (props: IAlertBannerProps): JSX.Element => {
  const { t } = useTranslation();
  const { testId, onClickUpdate, alertMessage } = props;
  return (
    <SettingsRequirementBanner variant={AlertVariant.danger} testId={`question_${testId}`}>
      {alertMessage}
      <ButtonWrap>
        <Button onClick={onClickUpdate} isInverse color={ButtonColor.danger} variant={ButtonVariant.outline}>
          {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.ALERT_BANNER_CHANGE_BUTTON')}
        </Button>
      </ButtonWrap>
    </SettingsRequirementBanner>
  );
};

export const QuestionListItem = (props: IProps): JSX.Element => {
  const { index, questionElement, questionNumber, isExpanded = false, isLast, onClickRemove } = props;
  const {
    state: {
      assignmentSettings: {
        currentAssignmentTemplate: {
          settings: { submissionAndWorkSettings, conditionalPointsFormula: assignmentConditionalPointsFormula },
        },
      },
    },
    dispatch,
  } = useAssignmentContext();
  const { t } = useTranslation();
  const { allowedSubmissionsCount, submission } = submissionAndWorkSettings;

  const [expanded, setExpanded] = React.useState(isExpanded);
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  useEffect(() => {
    if (expanded) {
      const tabToFocus = document.getElementById(`question_${index}_drawer_tab_${activeTabIndex}`);
      if (tabToFocus) tabToFocus.focus();
    }
  }, [expanded]);

  const drawerId = useGenerateId();

  const elementTabs = getDrawerTabsForElement(questionElement.elementType);

  const getQuestionElementIdentifier = (): string => {
    switch (questionElement.elementType) {
      case QuestionElementType.QUESTION:
        return `${(questionElement as IAssignmentQuestion).question.name} (${
          (questionElement as IAssignmentQuestion).question.id
        })`;
      case QuestionElementType.QUESTION_POOL:
        const useNofM = t('QUESTION_ELEMENT_SETTINGS.USE_N_OF_M', {
          choose: (questionElement as IQuestionPool).includedNumberOfQuestions,
          total: (questionElement as IQuestionPool).numberOfQuestions,
        });
        return `${t('POOL')} ${(questionElement as IQuestionPool).positionAmongSets} (${useNofM})`;
      case QuestionElementType.MASTERY_SET:
        const getNofMCorrect = t('QUESTION_ELEMENT_SETTINGS.REQUIRE_N_OF_M_CORRECT', {
          minCorrectCount: (questionElement as IMasterySet).masteryThreshold,
          subsetSize: (questionElement as IMasterySet).subsetSize,
        });
        return `${t('MASTERY_SET')} ${(questionElement as IMasterySet).positionAmongSets} (${getNofMCorrect})`;
      default:
        return '';
    }
  };

  const getQuestionNumbers = () => {
    if (questionNumber === null) {
      return '';
    }
    return questionNumber.length === 2 && questionNumber[0] !== questionNumber[1]
      ? `${questionNumber[0]}-${questionNumber[1]}.`
      : `${questionNumber[0]}.`;
  };

  const elementIdentifier = `${getQuestionNumbers()} ${getQuestionElementIdentifier()}`;

  const singularQuestion =
    questionNumber?.length === 1 || (questionNumber?.length === 2 && questionNumber[0] === questionNumber[1]);

  const hasSubmissionOverride = (): boolean => {
    if (submission === Submission.ASSIGNMENT) {
      return false;
    }
    if (isQuestionPool(questionElement) || isMasterySet(questionElement)) {
      const elementSubmissionSetting = questionElement.gradingSettings.allowedSubmissionsCount;
      return elementSubmissionSetting !== null && elementSubmissionSetting !== allowedSubmissionsCount;
    }
    if (isAssignmentQuestion(questionElement)) {
      return (
        questionElement.boxes.findIndex(box => {
          const boxSubmissionSetting = box.gradingSettings.allowedSubmissionsCount;
          return boxSubmissionSetting !== null && boxSubmissionSetting !== allowedSubmissionsCount;
        }) !== -1
      );
    }
    return false;
  };

  const hasHintsOverride = (): boolean => {
    const isOverridden = (questionElement: IAssignmentQuestion | IMasterySet) => {
      const {
        showHintsBeforeDueDateAfterNumberOfSubmissions,
        showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions,
        customHintText,
      } = questionElement.feedbackSettings;
      return (
        (showHintsBeforeDueDateAfterNumberOfSubmissions != null &&
          showHintsBeforeDueDateAfterNumberOfSubmissions !== 0) ||
        (showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions != null &&
          showPracticeAnotherVersionBeforeDueDateAfterNumberOfSubmissions !== 0) ||
        customHintText != null
      );
    };
    if (isAssignmentQuestion(questionElement) || isMasterySet(questionElement)) {
      return isOverridden(questionElement);
    }
    if (isQuestionPool(questionElement)) {
      return isOverridden(questionElement.questions[0]);
    }
    return false;
  };

  const hasPercentTolerance = (): boolean => {
    if (isAssignmentQuestion(questionElement)) {
      return (
        questionElement.numericalToleranceSettings.numericalTolerance !== null &&
        questionElement.numericalToleranceSettings.isToleranceInPercent
      );
    } else if (isQuestionPool(questionElement)) {
      return (
        questionElement.questions[0].numericalToleranceSettings.numericalTolerance !== null &&
        questionElement.questions[0].numericalToleranceSettings.isToleranceInPercent
      );
    }
    return false;
  };

  const hasPointsAdjustment = (): boolean => {
    if (
      isAssignmentQuestion(questionElement) &&
      (questionElement.showMyWorkMode === ShowMyWorkMode.REQUIRED ||
        questionElement.boxes[0].gradingSettings.conditionalPointsFormula !== assignmentConditionalPointsFormula)
    ) {
      return true;
    }
    if (
      isQuestionPool(questionElement) &&
      questionElement.gradingSettings.conditionalPointsFormula !== assignmentConditionalPointsFormula
    ) {
      return true;
    }
    if (hasPercentTolerance()) {
      return true;
    }
    // other conditions to be added later
    return false;
  };

  const isQuestionPartsSubmissionRequired = (questionElement: IQuestionElement): boolean => {
    if (isAssignmentQuestion(questionElement)) {
      return submission !== Submission.QUESTION_PART && questionElement.isQuestionPartSubmissionRequired;
    }
    if (isQuestionPool(questionElement)) {
      return questionElement.questions.some(element => {
        return submission !== Submission.QUESTION_PART && element.isQuestionPartSubmissionRequired;
      });
    }
    return false;
  };

  const isAssignmentSubmissionNotAllowed = (questionElement: IQuestionElement): boolean => {
    if (isAssignmentQuestion(questionElement)) {
      return submission === Submission.ASSIGNMENT && questionElement.entireAssignmentSubmissionNotAllowed;
    }
    if (isQuestionPool(questionElement)) {
      return questionElement.questions.some(element => {
        return submission === Submission.ASSIGNMENT && element.entireAssignmentSubmissionNotAllowed;
      });
    }
    return false;
  };

  const isQuestionSubmissionNotSetForMastery = (questionElement: IQuestionElement): boolean => {
    return questionElement.elementType === QuestionElementType.MASTERY_SET && submission !== Submission.QUESTION;
  };

  const changeSubmissionToQuestionParts = (): void => {
    dispatch(
      setSubmissionAndWorkSettings({
        ...submissionAndWorkSettings,
        submission: Submission.QUESTION_PART,
      })
    );
  };

  const changeSubmissionToQuestion = (): void => {
    dispatch(
      setSubmissionAndWorkSettings({
        ...submissionAndWorkSettings,
        submission: Submission.QUESTION,
      })
    );
  };

  return (
    <StyledQuestionListItem>
      <QuestionListBody
        behavior={FlexBehavior.container}
        alignItems={FlexAlignItems.center}
        spacing={1}
        isLast={isLast}
      >
        <QuestionColumn testId={`question_${index}_name`}>
          <QuestionNameControls
            index={index}
            drawerId={drawerId}
            elementIdentifier={elementIdentifier}
            expanded={expanded}
            setExpanded={setExpanded}
            setActiveTabIndex={setActiveTabIndex}
            elementType={questionElement.elementType}
            singularQuestion={singularQuestion}
            onClickRemove={() => null}
            elementTabs={elementTabs}
          />
        </QuestionColumn>
        <TimeColumn testId={`question_${index}_time_to_answer`}>
          <TimeToAnswer
            questionElement={questionElement}
            elementIdentifier={elementIdentifier}
            singularQuestion={singularQuestion}
          />
        </TimeColumn>
        <PointsColumn testId={`question_${index}_points`}>
          <Points index={index} elementIdentifier={elementIdentifier} singularQuestion={singularQuestion} />
        </PointsColumn>
        <ControlsColumn testId={`question_${index}_controls`}>
          <Controls
            index={index}
            drawerId={drawerId}
            elementIdentifier={elementIdentifier}
            expanded={expanded}
            setExpanded={setExpanded}
            setActiveTabIndex={setActiveTabIndex}
            singularQuestion={singularQuestion}
            onClickRemove={onClickRemove}
            submissionSettings={hasSubmissionOverride()}
            pointsAdjustment={hasPointsAdjustment()}
            helpHints={hasHintsOverride()}
            elementType={questionElement.elementType}
            elementTabs={elementTabs}
          />
        </ControlsColumn>
        {isQuestionPartsSubmissionRequired(questionElement) ? (
          <QuestionAlertBanner
            testId={index + '_partialSubmissionRequiredBanner'}
            onClickUpdate={changeSubmissionToQuestionParts}
            alertMessage={
              isQuestionPool(questionElement)
                ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_ERRORS.QUESTION_PARTS_REQUIRED_POOL', {
                    questionNumberInPool:
                      getQuestionNumbersFromPoolWhichRequireQuestionPartsSubmissions(questionElement).join(', '),
                  })
                : t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_ERRORS.QUESTION_PARTS_REQUIRED')
            }
          />
        ) : null}
        {questionElement.showMyWorkMode === ShowMyWorkMode.WAS_DISABLED ? (
          <SettingsRequirementBanner variant={AlertVariant.info} testId={`question_${index}`}>
            {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.BANNER_SHOW_MY_WORK_WAS_DISABLED')}
          </SettingsRequirementBanner>
        ) : null}
        {isAssignmentSubmissionNotAllowed(questionElement) ? (
          <QuestionAlertBanner
            testId={index + '_assignmentSubmissionNotAllowedBanner'}
            onClickUpdate={changeSubmissionToQuestion}
            alertMessage={
              isQuestionPool(questionElement)
                ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_ERRORS.ASSIGNMENT_SUBMISSION_NOT_ALLOWED_POOL', {
                    questionNumberInPool:
                      getQuestionNumbersFromPoolWithAssignmentSubmissionNotAllowed(questionElement).join(', '),
                  })
                : t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_ERRORS.ASSIGNMENT_SUBMISSION_NOT_ALLOWED')
            }
          />
        ) : null}
        {isQuestionSubmissionNotSetForMastery(questionElement) ? (
          <QuestionAlertBanner
            testId={index + '_questionSubmissionRequiredMasteryBanner'}
            onClickUpdate={changeSubmissionToQuestion}
            alertMessage={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_ERRORS.QUESTION_REQUIRED_FOR_MASTERY_SET')}
          />
        ) : null}
        {expanded ? (
          <Drawer
            activeIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            id={drawerId}
            index={index}
            questionNumber={questionNumber}
            questionElement={questionElement}
          />
        ) : null}
      </QuestionListBody>
    </StyledQuestionListItem>
  );
};

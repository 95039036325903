import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { IQuestionElement } from 'context/AssignmentContextProvider/types';
import {
  isAssignmentQuestion,
  isMasterySet,
  isQuestionPool,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { ValidatedNumericInput } from 'facultyComponents/assignmentEditor/helpers/Input/ValidatedNumericInput';
import { validatePointsInput } from 'facultyComponents/assignmentEditor/helpers/commonHelpers';
import {
  fixFloatingPoint,
  getQuestionElementPoints,
  getQuestionShowMyWorkPoints,
  quotientFloorRemainder,
  useQuestionPointsContext,
} from 'context/QuestionPointsContextProvider/QuestionPointsContextProvider';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { setQuestionsData } from 'context/AssignmentContextProvider/actions';

interface IPointsProps {
  index: number;
  elementIdentifier: string;
  singularQuestion: boolean;
}

interface ITotalPointsProps {
  flagTotalPointsUpdated: () => void;
  questionsData: IQuestionElement[];
}

interface IQuestionPartPointsProps {
  questionIndex: number;
  boxPoints: number;
  boxIndex: number;
}

const PointsInputContainer = styled.div`
  display: block;
  width: 5.625rem;
  margin-right: 0.75rem;
  font-weight: normal;
`;

const QuestionPartPointsInputContainer = styled(PointsInputContainer)`
  padding-bottom: 1rem;
`;

const PointsInputMessageStyle: React.CSSProperties = { width: '17.5rem' };

const QuestionPartsInputLabelStyle: React.CSSProperties = { margin: '0px' };

const TotalPointsInputLabelStyle: React.CSSProperties = { fontSize: 'inherit', fontWeight: 'bold' };

export const Points = (props: IPointsProps): JSX.Element => {
  const { index, elementIdentifier, singularQuestion } = props;
  const { questionLevelPoints, updateQuestionLevelPoints, forceRevertFlag } = useQuestionPointsContext();
  const { t } = useTranslation();

  const [questionPoints, setQuestionPoints] = useState<number | null>(questionLevelPoints);

  useEffect(() => {
    setQuestionPoints(questionLevelPoints);
  }, [questionLevelPoints]);

  const distributeQuestionLevelPoints = (questionPoints: number): void => {
    updateQuestionLevelPoints(questionPoints);
  };

  return (
    <PointsInputContainer>
      <ValidatedNumericInput
        testId={`question_${index}_points_input`}
        value={questionPoints!}
        aria-label={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.QUESTION_POINTS', {
          count: singularQuestion ? 1 : 0,
          elementIdentifier,
        })}
        updateDependencies={distributeQuestionLevelPoints}
        forceRevertFlag={forceRevertFlag}
        validationMethod={value => {
          return validatePointsInput(value);
        }}
        messageStyle={PointsInputMessageStyle}
      />
    </PointsInputContainer>
  );
};

export const TotalPoints = (props: ITotalPointsProps): JSX.Element => {
  const { questionsData, flagTotalPointsUpdated } = props;
  const { dispatch } = useAssignmentContext();
  const { t } = useTranslation();

  const computeTotalPoints = (newQuestionData: IQuestionElement[] | null): number => {
    const questionDataToProcess = newQuestionData ? newQuestionData : questionsData;
    if (questionDataToProcess.length > 0) {
      return fixFloatingPoint(
        questionDataToProcess
          .map(questionElement => getQuestionElementPoints(questionElement))
          .reduce((totalPoints, questionPoints) => totalPoints + questionPoints),
        2
      );
    } else {
      return 0;
    }
  };

  const computeTotalShowMyWorkPoints = (newQuestionData: IQuestionElement[] | null): number => {
    const questionDataToProcess = newQuestionData ? newQuestionData : questionsData;
    if (questionDataToProcess.length > 0) {
      return fixFloatingPoint(
        questionDataToProcess
          .map(questionElement => getQuestionShowMyWorkPoints(questionElement))
          .reduce(
            (totalShowMyWorkPoints, questionShowMyWorkPoints) => totalShowMyWorkPoints + questionShowMyWorkPoints
          ),
        2
      );
    } else {
      return 0;
    }
  };

  const [totalPoints, setTotalPoints] = useState<number>(computeTotalPoints(questionsData));
  const [totalShowMyWorkPoints, setTotalShowMyWorkPoints] = useState<number>(
    computeTotalShowMyWorkPoints(questionsData)
  );
  const [forceRevertFlag, setForceRevertFlag] = useState<boolean>(false);

  const distributeTotalPoints = (value: number): void => {
    const newQuestionsData = questionsData;
    const numberOfQuestions = newQuestionsData
      .map(questionElement => (isQuestionPool(questionElement) ? questionElement.includedNumberOfQuestions : 1))
      .reduce((totalQuestions, questions) => totalQuestions + questions);

    let pointsToDistribute;
    if (value <= totalShowMyWorkPoints) {
      pointsToDistribute = 0;
      if (totalPoints == totalShowMyWorkPoints) {
        setForceRevertFlag(!forceRevertFlag);
      }
      setTotalPoints(totalShowMyWorkPoints);
    } else {
      pointsToDistribute = fixFloatingPoint(value - totalShowMyWorkPoints, 2);
      setTotalPoints(value);
    }
    const [pointsPerQuestionElement, remainder] = quotientFloorRemainder(pointsToDistribute, numberOfQuestions, 2);

    newQuestionsData.forEach((questionElement, index) => {
      const extraPoints = index == newQuestionsData.length - 1 ? remainder : 0;
      if (isQuestionPool(questionElement)) {
        questionElement.gradingSettings.pointsValue = fixFloatingPoint(
          pointsPerQuestionElement * questionElement.includedNumberOfQuestions + extraPoints,
          2
        );
      }
      if (isAssignmentQuestion(questionElement)) {
        const pointsToDistribute = pointsPerQuestionElement + extraPoints;
        const numberOfQuestionParts = questionElement.boxes.length;
        const [pointsPerQuestionPart, remainder] = quotientFloorRemainder(pointsToDistribute, numberOfQuestionParts, 2);

        questionElement.boxes.forEach((box, index) => {
          box.gradingSettings.pointsValue =
            index == numberOfQuestionParts - 1 ? pointsPerQuestionPart + remainder : pointsPerQuestionPart;
        });
      }
      if (isMasterySet(questionElement)) {
        questionElement.gradingSettings.pointsValue = fixFloatingPoint(pointsPerQuestionElement + extraPoints, 2);
      }
    });
    dispatch(setQuestionsData(newQuestionsData));
  };

  useEffect(() => {
    setTotalPoints(computeTotalPoints(questionsData));
    setTotalShowMyWorkPoints(computeTotalShowMyWorkPoints(questionsData));
  }, [questionsData]);

  const updateQuestionElements = (value: number): void => {
    distributeTotalPoints(value);
    flagTotalPointsUpdated();
  };

  return (
    <PointsInputContainer>
      <ValidatedNumericInput
        testId="totalPointsInput"
        labelText={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.COLUMN.POINTS')}
        labelStyle={TotalPointsInputLabelStyle}
        value={totalPoints}
        updateDependencies={updateQuestionElements}
        forceRevertFlag={forceRevertFlag}
        validationMethod={value => {
          return validatePointsInput(value);
        }}
        messageStyle={PointsInputMessageStyle}
      />
    </PointsInputContainer>
  );
};

export const QuestionPartPoints = (props: IQuestionPartPointsProps): JSX.Element => {
  const { questionIndex, boxIndex, boxPoints } = props;
  const { updateQuestionPartLevelPoints } = useQuestionPointsContext();
  const { t } = useTranslation();

  const [pointValue, setPointValue] = useState(boxPoints);

  useEffect(() => {
    setPointValue(boxPoints);
  }, [boxPoints]);

  const updateQuestionPartsContext = (questionPartPoints: number): void => {
    updateQuestionPartLevelPoints(questionPartPoints, boxIndex);
  };

  return (
    <QuestionPartPointsInputContainer key={boxIndex}>
      <ValidatedNumericInput
        testId={`question_${questionIndex}_part_${boxIndex}_points`}
        labelText={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.POINTS_SETTINGS.PARTS', { value: boxIndex + 1 })}
        labelStyle={QuestionPartsInputLabelStyle}
        value={pointValue}
        updateDependencies={updateQuestionPartsContext}
        validationMethod={value => {
          return validatePointsInput(value);
        }}
        messageStyle={PointsInputMessageStyle}
      />
    </QuestionPartPointsInputContainer>
  );
};

/* istanbul ignore file */
const baseUrl = '/v4cgi/faculty.pl';

const createCourseBtn = { name: 'CREATE_CLASS', href: '/web/Faculty/Course-Choose/create' };
const myClassesBtn = { name: 'MY_CLASSES', href: `${baseUrl}?action=faculty/my_classes` };

const headerLinksData = {
  homeLink: `${baseUrl}?action=faculty/home`,
  signOutLink: `${baseUrl}?action=logout`,
  studentViewLink: '/v4cgi/student.pl?openStudentView=1',
  optionsLink:
    "javascript:openPopUp('/v4cgi/options/index.tpl?ui=faculty','MYOPTIONS','width=800,height=720,scrollbars=yes,resizable=yes')",
  helpLink: "javascript:openPopUp('/manual/instructor_guide/','Help','width=937,height=623,scrollbars=yes')",
  myAccountLink: 'https://login.cengage.com',
  calendarLink: `${baseUrl}?action=calendar/home`,
  classContext: '{}',
  showBackLink: false,
  communicationLink: "javascript:setWAClassDate('com/index');", //todo: store course Info in Context
  currentLocale: 'en-US',
  userFirstName: '',
  unreadMessagesCounter: '0',
  unreadMessageLink:
    "javascript: openPopUp('com/check.tpl','CommCheck','width=700,height=400,scrollbars=yes,resizable=yes')",
  showAdminMenu: '',
  dropDownAdminLink: JSON.stringify([]),

  dropDownAssignmentsLink: JSON.stringify([]),

  dropDownQuestionsLink: JSON.stringify([]),

  dropDownGradesLink: JSON.stringify([
    {
      name: 'CLASS_SCORES',
      href: `${baseUrl}?action=grades/scores&deployment=''&assignmentListH=''`,
    },
    { name: 'CUSTOM_REPORTING', href: `${baseUrl}?action=grades/reports` },
    { name: 'DOWNLOAD_SCORES', href: `${baseUrl}?action=downloads/index` },
    { name: 'GRADEBOOK', href: `${baseUrl}?action=grades/book` },
    { name: 'IMPORT_SCORES_TO_GRADEBOOK', href: `${baseUrl}?action=grades/manualindex` },
    {
      name: 'CUSTOM_CATEGORIES',
      href: "javascript: openPopUp('assignments/categories.tpl','ACategories','width=670,height=510,scrollbars=yes,resizable=yes');",
    },
  ]),

  classesLinks: JSON.stringify([myClassesBtn, { name: 'classes', links: [] }, createCourseBtn]),
};

export { createCourseBtn, myClassesBtn, headerLinksData };

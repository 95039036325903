import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { InputAutocorrect, StandardErrorFormat } from 'sharedComponents/InputAutocorrect/InputAutocorrect';
import { IPointsAdjustments } from '../PointsAdjustment';
import { IConditionalPointSettings, makeConditionalPointsFormula, PresetDefaults } from '../ConditionalPointsSettings';
import { setQuestionElementPointsAdjustment } from 'context/AssignmentContextProvider/actions';
import { validateField } from '../../commonHelpers';

import {
  InputsWrapper,
  InputWithLeftLabel,
  InputWithPercent,
  MagmaInputContainerStyle,
  MagmaInputMessageStyle,
  VisuallyHiddenSpan,
} from '../InputContainers';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';

const Percent = styled.span`
  height: 3rem;
`;

export const AwardCreditToEveryone = (props: IPointsAdjustments & IConditionalPointSettings): JSX.Element => {
  const { questionElementIndex, amount } = props;
  const { dispatch } = useAssignmentContext();
  const { t } = useTranslation();

  const [awardedCreditNum, setAwardedCreditNum] = useState<number>(
    amount || PresetDefaults.AWARD_EVERYONE_CREDIT.amount || 100
  );

  const updateDependencies = (points: number) => {
    setAwardedCreditNum(points);
  };

  useEffect(() => {
    if (amount) {
      setAwardedCreditNum(amount);
    }
  }, [amount]);

  useEffect(() => {
    const settings = { ...PresetDefaults['AWARD_EVERYONE_CREDIT'] };
    settings.amount = awardedCreditNum;
    if (questionElementIndex !== undefined) {
      dispatch(
        setQuestionElementPointsAdjustment({
          elementIndex: questionElementIndex,
          conditionalPointsFormula: makeConditionalPointsFormula(settings),
        })
      );
    }
  }, [awardedCreditNum]);

  const validateCredit = (value: string): StandardErrorFormat => {
    const genericMessage = t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.ENTER_WHOLE_NUMBER', {
      maximum: 100,
    });
    const error = validateField({
      value: value,
      min: 1,
      max: 100,
      message: genericMessage,
      minMessage: genericMessage,
      maxMessage: genericMessage,
      withDecimal: false,
    });
    return { error: error.value, message: error.message };
  };

  return (
    <InputsWrapper>
      <InputWithLeftLabel data-testid={`awardEveryoneCredit${questionElementIndex}`}>
        <label data-testid={`amountCreditInputLabel`} htmlFor={`awardEveryoneCreditInput${questionElementIndex}`}>
          {t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.AMOUNT_TO_CREDIT')}
          <VisuallyHiddenSpan>{t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.PERCENT')}</VisuallyHiddenSpan>
        </label>
        <InputWithPercent>
          <InputAutocorrect
            id={`awardEveryoneCreditInput${questionElementIndex}`}
            testId={`awardEveryoneCreditInput${questionElementIndex}`}
            value={awardedCreditNum.toString()}
            validator={validateCredit}
            validationError={{ error: false, message: '' }}
            onValidChange={(value: string) => updateDependencies(parseInt(value))}
            containerStyle={MagmaInputContainerStyle}
            messageStyle={MagmaInputMessageStyle}
            helperMessage="1-100"
          />
          <Percent>%</Percent>
        </InputWithPercent>
      </InputWithLeftLabel>
    </InputsWrapper>
  );
};

import styled from '@emotion/styled';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { setMasterySetSettings } from 'context/AssignmentContextProvider/actions';
import { IMasterySet } from 'context/AssignmentContextProvider/types';
import { useQuestionBrowserContext } from 'context/QuestionBrowserContextProvider/QuestionBrowserContextProvider';
import { ISelectItem } from 'facultyComponents/assignmentEditor/helpers/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonTextTransform,
  ButtonVariant,
  Heading,
  Select,
  TypographyVisualStyle,
  VisuallyHidden,
  magma,
} from 'react-magma-dom';

interface IMasterySetSettingPanelProps {
  masterySet: IMasterySet;
  index: number;
}

interface IMasterySetSettingsSelectProps {
  index: number;
  value: number;
  labelText: string;
  property: string;
  lowerBound: number;
  upperBound: number;
  helperMessage: string;
  updateDependencies: (value: number) => void;
  disabled: boolean;
}

const Settings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5.5rem;
  width: 13.625rem;
`;

const SettingsInputStyle: React.CSSProperties = { width: '5.625rem' };

const SettingsTextContainer = styled.div`
  margin-top: 1.75rem;
  width: 2.5rem;
  text-align: center;
`;

const HelperMessageStyle = styled.div`
  color: ${magma.colors.neutral03};
  margin-bottom: 1rem;
`;

const ButtonDisplay = styled.div`
  height: 2rem;
  margin-left: -1.25rem;
`;

const QuestionBrowserButtonStyle: React.CSSProperties = { fontWeight: 400 };

const MAXIMUM_MASTERY_SUBSET_SIZE = 9;
const MINIMUM_MASTERY_SUBSET_SIZE = 2;
const MINIMUM_MASTERY_THRESHOLD = 1;

export const MasterySetSettingPanel = (props: IMasterySetSettingPanelProps): JSX.Element => {
  const { index, masterySet } = props;
  const { t } = useTranslation();
  const [masteryThreshold, setMasteryThreshold] = useState<number>(masterySet.masteryThreshold);
  const [masterySubsetSize, setMasterySubsetSize] = useState<number>(masterySet.subsetSize);
  const [masterySetSize] = useState<number>(masterySet.numberOfQuestions);
  const [subsetUpperBound] = useState<number>(Math.min(MAXIMUM_MASTERY_SUBSET_SIZE, masterySet.numberOfQuestions));
  const {
    state: {
      assignmentEditorContext: {
        assignmentInfo: { isModificationLimited },
      },
    },
    dispatch,
  } = useAssignmentContext();
  const { updateShowQuestionBrowser, openOrFocusQuestionBrowser } = useQuestionBrowserContext();

  const requiredSelectHelperMessage = isModificationLimited
    ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.ARIA_LABEL.LOCKED_SETTING')
    : t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.ARIA_LABEL.REQUIRE');

  const questionsShownHelperMessage = isModificationLimited
    ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.ARIA_LABEL.LOCKED_SETTING')
    : t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.ARIA_LABEL.QUESTIONS_SHOWN', {
        masterySetSize: masterySetSize,
      });

  const totalQuestionHelperMessage = isModificationLimited
    ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.LOCKED_SETTINGS')
    : t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.QUESTION_AVAILABLE', {
        masterySetSize: masterySetSize,
      });

  const updateThreshold = (value: number): void => {
    setMasteryThreshold(value);
  };
  const updateSubsetSize = (value: number): void => {
    if (masteryThreshold > value) {
      setMasteryThreshold(value);
    }
    setMasterySubsetSize(value);
  };
  useEffect(() => {
    // There is currently no business case for allowing mastery set reuse across assignments.
    // Thus if a mastery group has subsets but the assignment isn't "locked", the subsets
    // were generated by faculty opening the assignment in student view. Since the backend
    // prohibits mastery modification once subsets have been issued, in this case we'll null
    // the mastery id to create a new mastery set.
    dispatch(
      setMasterySetSettings({
        index: index,
        threshold: masteryThreshold,
        subsetSize: masterySubsetSize,
        resetId: masterySet.hasSubsets && !isModificationLimited,
      })
    );
    updateShowQuestionBrowser(false);
  }, [masteryThreshold, masterySubsetSize]);

  return (
    <>
      <Heading level={4} visualStyle={TypographyVisualStyle.headingXSmall} css>
        {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.MASTERY_SET_SETTINGS')}
      </Heading>
      <Settings>
        <MasterySettingsSelect
          index={index}
          value={masteryThreshold}
          labelText={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.REQUIRE')}
          property="threshold"
          upperBound={masterySubsetSize}
          lowerBound={MINIMUM_MASTERY_THRESHOLD}
          updateDependencies={updateThreshold}
          helperMessage={requiredSelectHelperMessage}
          disabled={isModificationLimited}
        />
        <SettingsTextContainer>
          {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.OF')}
        </SettingsTextContainer>
        <MasterySettingsSelect
          index={index}
          value={masterySubsetSize}
          labelText={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.QUESTIONS_SHOWN')}
          property="subset_size"
          upperBound={subsetUpperBound}
          lowerBound={MINIMUM_MASTERY_SUBSET_SIZE}
          updateDependencies={updateSubsetSize}
          helperMessage={questionsShownHelperMessage}
          disabled={isModificationLimited}
        />
      </Settings>
      <HelperMessageStyle data-testid={`mastery_set_${index}_total_questions_helper_text`}>
        {totalQuestionHelperMessage}
      </HelperMessageStyle>
      {isModificationLimited ? null : (
        <ButtonDisplay>
          <Button
            aria-label={
              t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.OPEN_QUESTION_BROWSER') || 'translate me'
            }
            variant={ButtonVariant.link}
            testId={`mastery_set_${index}_question_browser_button`}
            textTransform={ButtonTextTransform.none}
            onClick={() => {
              openOrFocusQuestionBrowser();
            }}
            style={QuestionBrowserButtonStyle}
          >
            {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.MASTERY_SET_SETTINGS.EDIT_IN_QUESTION_BROWSER')}
          </Button>
        </ButtonDisplay>
      )}
    </>
  );
};

const MasterySettingsSelect = (props: IMasterySetSettingsSelectProps): JSX.Element => {
  const { index, value, labelText, property, lowerBound, upperBound, updateDependencies, helperMessage, disabled } =
    props;
  const [selectedItem, setSelectedItem] = useState<ISelectItem>({ label: `${value}`, value: `${value}` });
  const getSelectItems = (lowerBound: number, upperBound: number): ISelectItem[] => {
    return Array.from({ length: upperBound - lowerBound + 1 }, (_, i) => {
      return { label: (i + lowerBound).toString(), value: (i + lowerBound).toString() } as ISelectItem;
    });
  };
  const updateDependency = (change: any): void => {
    updateDependencies(parseInt(change.selectedItem.value));
  };
  useEffect(() => {
    setSelectedItem({ label: `${value}`, value: `${value}` });
  }, [value]);
  return (
    <Select
      labelText={labelText}
      id={`mastery_set_${property}_${index}`}
      testId={`mastery_set_${index}_${property}_select`}
      items={getSelectItems(lowerBound, upperBound)}
      selectedItem={selectedItem}
      onSelectedItemChange={updateDependency}
      containerStyle={SettingsInputStyle}
      helperMessage={<VisuallyHidden>{helperMessage}</VisuallyHidden>}
      disabled={disabled}
    />
  );
};

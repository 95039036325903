import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Alert, AlertVariant, Input, InputType } from 'react-magma-dom';

import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { IQuestionElement, Submission } from 'context/AssignmentContextProvider/types';
import {
  removeRefWithError,
  setRefWithError,
  setQuestionElementSubmissionSettings,
} from 'context/AssignmentContextProvider/actions';

import {
  isQuestionPool,
  isAssignmentQuestion,
  questionSubmissionsAllowed,
  isMasterySet,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { validateField } from 'facultyComponents/assignmentEditor/helpers/commonHelpers';
import { SUBMISSION_COUNT } from 'facultyComponents/assignmentEditor/helpers/commonHelpers';
interface IProps {
  questionElementIndex: number;
}

interface SubmissionInputProps extends IProps {
  index?: number;
}

const InputContainer = styled.div`
  padding-bottom: 1rem;
  width: 6.25rem;
`;

const SubmissionInputHelperText = styled.span`
  white-space: nowrap;
`;

const questionPartsInputLabelStyle: React.CSSProperties = { margin: '0px' };

const SubmissionInput = (props: SubmissionInputProps): JSX.Element => {
  const { index, questionElementIndex } = props;
  const {
    state: {
      assignmentSettings: {
        currentAssignmentTemplate: {
          settings: {
            submissionAndWorkSettings: { allowedSubmissionsCount, submission },
          },
        },
      },
      questionsData,
    },
    dispatch,
  } = useAssignmentContext();
  const { t } = useTranslation();

  const questionElement = questionsData[questionElementIndex];

  const getElementValue = (index: number): number => {
    if (isAssignmentQuestion(questionElement)) {
      const boxValue = questionElement.boxes[index].gradingSettings.allowedSubmissionsCount;
      return boxValue !== null && boxValue !== allowedSubmissionsCount ? boxValue : allowedSubmissionsCount;
    }
    return allowedSubmissionsCount;
  };

  const getSubmissionsAllowed = (): number => {
    if (submission === Submission.ASSIGNMENT) {
      return allowedSubmissionsCount;
    }
    return questionSubmissionsAllowed({ questionElement, allowedSubmissionsCount });
  };

  const validateSubmissionsAllowed = (): boolean => {
    const submissionsAllowed = getSubmissionsAllowed();
    return submissionsAllowed < SUBMISSION_COUNT.MIN || submissionsAllowed > SUBMISSION_COUNT.MAX;
  };

  const questionElementHasOnlyOneSubmissionRequirement = (questionElement: IQuestionElement): boolean => {
    return (
      (isAssignmentQuestion(questionElement) && questionElement.onlyOneSubmission) ||
      (isQuestionPool(questionElement) && questionElement.questions.some(pQuestion => pQuestion.onlyOneSubmission))
    );
  };

  const getValue = (): number => {
    if (questionElementHasOnlyOneSubmissionRequirement(questionElement)) {
      return 1;
    }
    return index !== undefined ? getElementValue(index) : getSubmissionsAllowed();
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(getValue());
  const [validationError, setValidationError] = useState({
    value: validateSubmissionsAllowed(),
    message: '',
  });

  useEffect(() => {
    setValue(getValue());
  }, [allowedSubmissionsCount]);

  const getLabelText = () => {
    if (isMasterySet(questionElement)) {
      return t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.SUBMISSION_SETTINGS_MASTERY');
    }

    return index !== undefined
      ? t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.SUBMISSION_SETTINGS.PARTS', { value: index + 1 })
      : t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWER_TABS.SUBMISSION_SETTINGS');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number | undefined) => {
    const error = validateField({
      value: event.target.value,
      min: SUBMISSION_COUNT.MIN,
      max: SUBMISSION_COUNT.MAX,
      message: t('ASSIGNMENT_EDITOR.SCORING.SUBMISSION_ALLOWED_SETTING.ONLY_WHOLE_NUMBER'),
    });
    setValidationError(error);
    dispatch(error.value ? setRefWithError(inputRef) : removeRefWithError(inputRef));

    dispatch(
      setQuestionElementSubmissionSettings({
        boxIndex: index,
        elementIndex: questionElementIndex,
        allowedSubmissionsCount: +event.target.value,
      })
    );
  };

  return (
    <InputContainer key={index}>
      <Input
        ref={inputRef}
        disabled={questionElementHasOnlyOneSubmissionRequirement(questionElement)}
        testId={`questionSubmissions${index}`}
        labelText={getLabelText()}
        labelStyle={index !== undefined ? questionPartsInputLabelStyle : undefined}
        type={InputType.number}
        min="0"
        maxLength={4}
        value={value}
        onChange={event => handleChange(event, index)}
        errorMessage={
          validationError.value
            ? validationError.message
              ? validationError.message
              : `${SUBMISSION_COUNT.MIN}-${SUBMISSION_COUNT.MAX}`
            : null
        }
        helperMessage={
          questionElementHasOnlyOneSubmissionRequirement(questionElement) ? (
            <SubmissionInputHelperText>
              {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.SUBMISSION_SETTINGS.ONLY_ONE_SUBMISSION_ALLOWED')}
            </SubmissionInputHelperText>
          ) : null
        }
      />
    </InputContainer>
  );
};

export const SubmissionSettings = (props: IProps): JSX.Element => {
  const { questionElementIndex } = props;
  const {
    state: {
      assignmentSettings: {
        currentAssignmentTemplate: {
          settings: {
            submissionAndWorkSettings: { allowedSubmissionsCount, submission },
          },
        },
      },
      questionsData,
    },
  } = useAssignmentContext();
  const { t } = useTranslation();

  const questionElement = questionsData[questionElementIndex];

  return (
    <div data-testid={'submissions_wrapper_' + questionElementIndex}>
      {submission === Submission.ASSIGNMENT ? (
        <Alert variant={AlertVariant.muted}>
          {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.SUBMISSION_SETTINGS.FROM_ASSIGNMENT_SETTINGS', {
            assignmentSubmissions: allowedSubmissionsCount,
          })}
        </Alert>
      ) : submission === Submission.QUESTION_PART && isAssignmentQuestion(questionElement) ? (
        questionElement.boxes.map((_box, index) => (
          <SubmissionInput key={index} index={index} questionElementIndex={questionElementIndex} />
        ))
      ) : (
        <SubmissionInput questionElementIndex={questionElementIndex} />
      )}
    </div>
  );
};

import React, { ReactElement } from 'react';
import UnknownPage from '../../../pages/unknown/UnknownRoot';
import { useAuthorizationStatus, Privilege } from './AuthorizationStatus';

interface IProps {
  privilege: Privilege;
  children: JSX.Element;
}

export const AuthorizedElement = ({ privilege, children }: IProps): ReactElement => {
  const privileges =
    privilege === Privilege.REACT_ASSIGNMENT_EDITOR || privilege === Privilege.CXP_TOOL
      ? [Privilege.REACT_FACULTY_UI, privilege]
      : [privilege];
  const authorized = useAuthorizationStatus(privileges);
  return authorized ? children : <UnknownPage />;
};

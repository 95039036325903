import React, { useEffect, useState } from 'react';

import { Input, InputType, Toggle } from 'react-magma-dom';
import styled from '@emotion/styled';
import {
  MIN_AFTER_SPECIFIED_SUBMISSION,
  validateField,
} from 'facultyComponents/assignmentEditor/helpers/commonHelpers';
import { useTranslation } from 'react-i18next';

interface IQuestionSubmissionOverrideProps {
  allowedSubmissionsCount: number;
  dispatchFunction: (checkedToggle: boolean, submissions: number) => void;
  id: string;
  index: number;
  isAESettingsSetToAlways: boolean;
  isToggleChecked: boolean;
  labelHelper: string;
  labelName: string;
  submissionsValue: number;
}
const HorizontalLayout = styled.div`
  display: flex;
  margin-top: 0.938rem;
  margin-bottom: 0.938rem;
`;
const InputText = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  width: 21rem;
`;
const Label = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: 600;
  font-size: 0.875rem;
`;
const HelperText = styled.div`
  color: #707070;
  font-size: 0.875rem;
`;
const InfoText = styled.div`
  display: flex;
  margin: 0.5rem;
  font-size: 0.875rem;
`;
const inputContainerStyle = { margin: '-0.75rem 0.75rem', width: '7rem' };
const toggleLabelStyle = { width: '21rem', marginRight: '0.75rem' };
const SubmissionInputHelperText = styled.span`
  white-space: nowrap;
`;

export const QuestionSubmissionOverride = (props: IQuestionSubmissionOverrideProps): JSX.Element => {
  const {
    allowedSubmissionsCount,
    dispatchFunction,
    id,
    index,
    isAESettingsSetToAlways,
    isToggleChecked,
    labelHelper,
    labelName,
    submissionsValue,
  } = props;

  const { t } = useTranslation();

  const [checkedToggle, updateCheckedToggle] = useState<boolean>(isToggleChecked);
  const [submissionInputValue, setSubmissionInputValue] = useState(submissionsValue);
  const [submissionInputValueError, setSubmissionInputValueError] = useState({
    value: false,
    message: '',
  });

  useEffect(() => {
    if (submissionInputValue > allowedSubmissionsCount || allowedSubmissionsCount === 1) {
      setSubmissionInputValue(allowedSubmissionsCount);
      dispatchFunction(checkedToggle, allowedSubmissionsCount);
    } else if (submissionInputValue === 1) {
      setSubmissionInputValue(MIN_AFTER_SPECIFIED_SUBMISSION);
      dispatchFunction(checkedToggle, MIN_AFTER_SPECIFIED_SUBMISSION);
    }
  }, [allowedSubmissionsCount]);

  useEffect(() => {
    updateCheckedToggle(isToggleChecked);
  }, [isToggleChecked]);

  const handleToggleChange = () => {
    dispatchFunction(!checkedToggle, submissionInputValue);
    updateCheckedToggle(!checkedToggle);
  };
  const handleSubmissionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.focus();
    e.preventDefault();
    const { value } = e.target;
    const error = validateField({
      value: value,
      min: MIN_AFTER_SPECIFIED_SUBMISSION,
      max: allowedSubmissionsCount,
    });
    setSubmissionInputValueError(error);
    setSubmissionInputValue(+value);
  };
  const handleInputBlur = () => {
    let submissionsCount = submissionInputValue;
    if (submissionInputValueError.value) {
      submissionsCount = [MIN_AFTER_SPECIFIED_SUBMISSION, allowedSubmissionsCount].reduce((prev, curr) => {
        return Math.abs(curr - submissionInputValue) < Math.abs(prev - submissionInputValue) ? curr : prev;
      });
      setSubmissionInputValue(submissionsCount);
      setSubmissionInputValueError({
        value: false,
        message: '',
      });
    }
    dispatchFunction(checkedToggle, submissionsCount);
  };

  return (
    <>
      <HorizontalLayout id={id}>
        <Toggle
          id={'toggle_' + id + '_' + index}
          testId={'toggle_' + id + '_' + index}
          checked={checkedToggle}
          onChange={handleToggleChange}
          labelStyle={toggleLabelStyle}
          labelText={
            <div data-testid="selectLabel">
              <Label>{labelName}</Label>
              <HelperText>{labelHelper}</HelperText>
            </div>
          }
          disabled={isAESettingsSetToAlways}
        />
        {isAESettingsSetToAlways ? (
          <InfoText>
            {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.HELP_HINTS_SETTINGS.SET_IN_ASSIGNMENT_SETTINGS')}
          </InfoText>
        ) : null}
      </HorizontalLayout>
      {checkedToggle ? (
        <HorizontalLayout data-testid={'input_' + id + '_' + index}>
          <InputText>{t('ASSIGNMENT_EDITOR.FEEDBACK.SELECT.AFTER_SPECIFIED')}</InputText>
          <Input
            testId={'submissionsInput_' + id + '_' + index}
            value={submissionInputValue}
            onChange={handleSubmissionInputChange}
            labelText={t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.HELP_HINTS_SETTINGS.SUBMISSIONS')}
            type={InputType.number}
            helperMessage={
              allowedSubmissionsCount === 1 ? (
                <SubmissionInputHelperText>
                  {t('ASSIGNMENT_EDITOR.QUESTIONS_LIST.DRAWERS.SUBMISSION_SETTINGS.ONLY_ONE_SUBMISSION_ALLOWED')}
                </SubmissionInputHelperText>
              ) : (
                `${MIN_AFTER_SPECIFIED_SUBMISSION}-${allowedSubmissionsCount}`
              )
            }
            min="0"
            containerStyle={inputContainerStyle}
            onBlur={handleInputBlur}
            errorMessage={
              submissionInputValueError.value && `${MIN_AFTER_SPECIFIED_SUBMISSION}-${allowedSubmissionsCount}`
            }
            disabled={allowedSubmissionsCount === 1}
          />
        </HorizontalLayout>
      ) : null}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { Button, ButtonColor, ButtonVariant, magma, Modal, Radio, RadioGroup, Select } from 'react-magma-dom';
import { InfoIcon } from 'react-magma-icons';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Permission } from 'context/AssignmentContextProvider/types';

const ModalStyle = styled(Modal)`
  width: 37.5rem;
  height: 32.625rem;
`;
const RadioLabelText = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: 400;
  font-size: 1rem;
`;
const RadioHelperText = styled.div`
  color: ${magma.colors.neutral03};
  font-weight: 400;
  font-size: 1rem;
`;
const ButtonsLayout = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const GroupsInfoWrapper = styled.div`
  padding-left: 4rem;
`;

const NoGroupsWrapper = styled.div`
  font-weight: 400;
  font-size: 1rem;
  display: flex;
`;

const IconWrapper = styled.div`
  margin: 0 0.5rem 0 2rem;
`;

const selectStyles = {
  width: '20.625rem',
};

const radioGroupLabelStyle = { fontSize: '1rem', fontWeight: 400 };

interface IProps {
  show: boolean;
  close: () => void;
  privacySetting: string;
  groups: { label: string; value: string }[];
  groupId: number;
  applyPrivacySettingChanges: (privacySetting: string, groupId: number) => void;
}

export const PrivacySettingModal = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { show, close, privacySetting, groups, groupId, applyPrivacySettingChanges } = props;
  const [permission, setPermission] = useState(groupId ? Permission.PROTECTED : privacySetting);
  const [groupIdPermission, setGroupIdPermission] = useState(groupId);
  const noGroupSelected = { label: t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.GROUP_SELECT.PLACEHOLDER'), value: '0' };
  if (groups.length && !groups.find(group => group.value === '0')) {
    groups.unshift(noGroupSelected);
  }
  const initialGroup = groupId ? groups.find(group => group.value === String(groupId)) : noGroupSelected;
  const [selectedGroup, setSelectedGroup] = useState(initialGroup);

  useEffect(() => {
    if (groupId) {
      setSelectedGroup(groups.find(group => group.value === String(groupId)));
    }
  }, [groups]);

  useEffect(() => {
    if (privacySetting !== Permission.PROTECTED && !groupId) {
      setPermission(privacySetting);
    }
  }, [privacySetting]);

  const handlePermissionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPermission(e.target.value);
    if (e.target.value !== Permission.PROTECTED) {
      setGroupIdPermission(0);
      setSelectedGroup(noGroupSelected);
    }
  };
  const closeModal = () => {
    setPermission(groupId ? Permission.PROTECTED : privacySetting);
    setGroupIdPermission(groupId);
    setSelectedGroup(initialGroup);
    close();
  };

  const applyChanges = () => {
    setPermission(Number(groupIdPermission) ? Permission.PROTECTED : privacySetting);
    applyPrivacySettingChanges(Number(groupIdPermission) ? Permission.GROUP : permission, groupIdPermission);
  };

  const handleGroupChange = (changes: any): void => {
    setSelectedGroup(changes.selectedItem);
    setGroupIdPermission(changes.selectedItem ? changes.selectedItem.value : 0);
  };
  return (
    <ModalStyle
      onClose={closeModal}
      isOpen={show}
      header={t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.TITLE')}
      testId="assignmentPrivacySetting"
    >
      <RadioGroup
        aria-label={t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.DESCRIPTION')}
        id="privacySettingRadioGroup"
        value={permission}
        testId="displayQuestionRadioGroup"
        name="displayPrivacySetting"
        labelText={t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.DESCRIPTION')}
        labelStyle={radioGroupLabelStyle}
        onChange={handlePermissionChange}
      >
        <Radio
          id="privateSettingRadio"
          labelText={
            <div>
              <RadioLabelText>{t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.RADIO.PRIVATE')}</RadioLabelText>
              <RadioHelperText>
                {t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.RADIO.PRIVATE_EXPLANATION')}
              </RadioHelperText>
            </div>
          }
          value={Permission.PRIVATE}
          testId="privatePrivacySetting"
        />
        <Radio
          id="restrictedSettingRadio"
          labelText={
            <div>
              <RadioLabelText>{t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.RADIO.RESTRICTED')}</RadioLabelText>
              <RadioHelperText>
                {t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.RADIO.RESTRICTED_EXPLANATION')}
              </RadioHelperText>
            </div>
          }
          value={Permission.PROTECTED}
          testId="restrictedPrivacySetting"
        />
        {permission === Permission.PROTECTED ? (
          <>
            {groups.length ? (
              <GroupsInfoWrapper>
                <Select
                  labelId="selectGroupLabelId"
                  testId="selectGroupPrivacySetting"
                  items={groups}
                  selectedItem={selectedGroup}
                  onSelectedItemChange={handleGroupChange}
                  labelText={t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.GROUP_SELECT.LABEL')}
                  helperMessage={t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.GROUP_SELECT.HELPER')}
                  containerStyle={selectStyles}
                />
              </GroupsInfoWrapper>
            ) : (
              <NoGroupsWrapper>
                <IconWrapper>
                  <InfoIcon />
                </IconWrapper>
                <span>{t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.NO_GROUPS')}</span>
              </NoGroupsWrapper>
            )}
          </>
        ) : (
          <></>
        )}

        <Radio
          id="publicSettingRadio"
          labelText={
            <div>
              <RadioLabelText>{t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.RADIO.PUBLIC')}</RadioLabelText>
              <RadioHelperText>{t('ASSIGNMENT_EDITOR.PRIVACY_SETTING_MODAL.RADIO.PUBLIC_EXPLANATION')}</RadioHelperText>
            </div>
          }
          value={Permission.PUBLIC}
          testId="publicPrivacySetting"
        />
      </RadioGroup>
      <ButtonsLayout>
        <Button color={ButtonColor.secondary} variant={ButtonVariant.solid} onClick={closeModal} testId="cancelButton">
          {t('ASSIGNMENT_EDITOR.BAR.CANCEL')}
        </Button>
        <Button color={ButtonColor.primary} variant={ButtonVariant.solid} onClick={applyChanges} testId="applyButton">
          {t('ASSIGNMENT_EDITOR.BAR.APPLY')}
        </Button>
      </ButtonsLayout>
    </ModalStyle>
  );
};

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Checkbox, Input, InputMessage, InputType, VisuallyHidden } from 'react-magma-dom';
import { useAssignmentContext } from '../../../../../context/AssignmentContextProvider/AssignmentContextProvider';
import { IAssignmentSettings } from '../../../../../context/AssignmentContextProvider/types';
import {
  removeRefWithError,
  setNumericalToleranceSettings,
  setRefWithError,
  setSignificantFiguresSettings,
} from '../../../../../context/AssignmentContextProvider/actions';
import { validateField } from '../commonHelpers';
import { SelectComponent } from '../../helpers/ScoringTabSelect/ScoringTabSelect';
import { SELECT_LABEL } from '../../helpers/types';
import { useTranslation } from 'react-i18next';

const AdvancedSettingsLayout = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWithLeftLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin: 0.25rem 0 0.25rem 2rem;
  label {
    width: 26rem;
    font-weight: 400;
  }
`;
const InputWithPercent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 5.125rem;
  div {
    margin-right: 0.2rem;
  }
`;
const Percent = styled.span`
  height: 3rem;
`;

const MessageWrapper = styled.div`
  margin-left: 2.1rem;
`;

const AdvancedScoringTabSettings = (): ReactElement => {
  const { t } = useTranslation();
  const {
    state: {
      assignmentSettings: { currentAssignmentTemplate },
    },
    dispatch,
  } = useAssignmentContext();

  const toleranceOverrideInputRef = useRef<HTMLInputElement>(null);
  const sigFigsErrorInputRef = useRef<HTMLInputElement>(null);
  const sigFigsCreditInputRef = useRef<HTMLInputElement>(null);

  const {
    numericalToleranceSettings: { toleranceOverridden, numericalTolerance },
    significantFiguresSettings: {
      significantFiguresOverridden,
      significantFiguresCheckEnabled,
      significantFiguresTolerance,
      significantFiguresPartialCreditPercentage,
    },
  } = currentAssignmentTemplate.settings as IAssignmentSettings;

  const [checkedToleranceOverride, setCheckedToleranceOverride] = useState(toleranceOverridden);
  const [customToleranceInputValue, setCustomToleranceInputValue] = useState(0);
  const [customToleranceErrorValue, setCustomToleranceErrorValue] = useState({
    value: +numericalTolerance < 0 || +numericalTolerance > 100,
    message: '',
  });

  const [checkedSigFigsErrors, setCheckedSigFigsErrors] = useState(
    significantFiguresOverridden && significantFiguresCheckEnabled
  );
  const [sigFigsToleranceInputValue, setSigFigsToleranceInputValue] = useState<number | null>(null);
  const [sigFigsToleranceErrorValue, setSigFigsToleranceErrorValue] = useState({
    value: significantFiguresTolerance < 1 || significantFiguresTolerance > 9,
    message: '',
  });

  const [sigFigsCreditChecked, setSigFigsCreditChecked] = useState(!!significantFiguresPartialCreditPercentage);
  const [sigFigsCreditInputValue, setSigFigsCreditInputValue] = useState<number | null>(null);
  const [sigFigsCreditErrorValue, setSigFigsCreditErrorValue] = useState({
    value: false,
    message: '',
  });

  useEffect(() => {
    setCustomToleranceInputValue(numericalTolerance);
    setSigFigsToleranceInputValue(significantFiguresTolerance);
    setSigFigsCreditInputValue(significantFiguresPartialCreditPercentage);
  }, [numericalTolerance, significantFiguresTolerance, significantFiguresPartialCreditPercentage]);

  const handleUpdateToleranceOverrideChecked = () => {
    if (currentAssignmentTemplate) {
      setCheckedToleranceOverride(!checkedToleranceOverride);
      if (!checkedToleranceOverride) {
        dispatch(
          setNumericalToleranceSettings({
            ...currentAssignmentTemplate.settings.numericalToleranceSettings,
            ...{
              toleranceOverridden: !checkedToleranceOverride,
              isToleranceInPercent: true,
            },
          })
        );
      }
      if (checkedToleranceOverride) {
        setCustomToleranceErrorValue({
          value: false,
          message: '',
        });
        dispatch(
          setNumericalToleranceSettings({
            ...currentAssignmentTemplate.settings.numericalToleranceSettings,
            ...{
              toleranceOverridden: !checkedToleranceOverride,
              isToleranceInPercent: true,
              numericalTolerance: 1,
            },
          })
        );
      }
    }
  };
  const handleToleranceInputUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateField({
      value: event.target.value,
      min: 0,
      max: 100,
      message: t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.ONLY_ONE_DECIMAL'),
      withDecimal: true,
    });
    setCustomToleranceErrorValue(error);
    dispatch(setRefWithError(toleranceOverrideInputRef));

    if (currentAssignmentTemplate && !error.value) {
      dispatch(removeRefWithError(toleranceOverrideInputRef));
      setCustomToleranceInputValue(parseInt(event.target.value));
      dispatch(
        setNumericalToleranceSettings({
          ...currentAssignmentTemplate.settings.numericalToleranceSettings,
          ...{ numericalTolerance: parseFloat(event.target.value) },
        })
      );
    }
  };
  // SigFigs
  const handleUpdateSigFigsChecked = () => {
    if (currentAssignmentTemplate) {
      setCheckedSigFigsErrors(!checkedSigFigsErrors);
      if (!checkedSigFigsErrors) {
        dispatch(
          setSignificantFiguresSettings({
            ...currentAssignmentTemplate.settings.significantFiguresSettings,
            ...{
              significantFiguresOverridden: !checkedSigFigsErrors,
              significantFiguresCheckEnabled: !checkedSigFigsErrors,
              significantFiguresHelpIconEnabled: true,
              isSignificantFiguresHelpIconClickable: true,
            },
          })
        );
      }
      if (checkedSigFigsErrors) {
        setSigFigsToleranceErrorValue({
          value: false,
          message: '',
        });
        setSigFigsCreditErrorValue({
          value: false,
          message: '',
        });
        setSigFigsCreditChecked(false);
        dispatch(
          setSignificantFiguresSettings({
            ...currentAssignmentTemplate.settings.significantFiguresSettings,
            ...{
              significantFiguresTolerance: 1,
              significantFiguresOverridden: !checkedSigFigsErrors,
              significantFiguresCheckEnabled: !checkedSigFigsErrors,
              significantFiguresHelpIconEnabled: true,
              isSignificantFiguresHelpIconClickable: true,
              significantFiguresPartialCreditPercentage: 0,
            },
          })
        );
      }
    }
  };
  const handleSigFigsToleranceInputValueInputUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateField({ value: event.target.value, min: 1, max: 9 });
    setSigFigsToleranceErrorValue(error);
    dispatch(setRefWithError(sigFigsErrorInputRef));

    if (currentAssignmentTemplate && !error.value) {
      dispatch(removeRefWithError(sigFigsErrorInputRef));
      setSigFigsToleranceInputValue(+event.target.value);
      dispatch(
        setSignificantFiguresSettings({
          ...currentAssignmentTemplate.settings.significantFiguresSettings,
          ...{
            significantFiguresTolerance: +event.target.value,
          },
        })
      );
    }
  };
  const handleUpdateSigFigsCreditChecked = () => {
    if (currentAssignmentTemplate) {
      setSigFigsCreditChecked(!sigFigsCreditChecked);
      if (!sigFigsCreditChecked && !significantFiguresPartialCreditPercentage) {
        dispatch(
          setSignificantFiguresSettings({
            ...currentAssignmentTemplate.settings.significantFiguresSettings,
            ...{ significantFiguresPartialCreditPercentage: 1 },
          })
        );
      }
      if (sigFigsCreditChecked) {
        setSigFigsCreditErrorValue({
          value: false,
          message: '',
        });
        dispatch(
          setSignificantFiguresSettings({
            ...currentAssignmentTemplate.settings.significantFiguresSettings,
            ...{ significantFiguresPartialCreditPercentage: 0 },
          })
        );
      }
    }
  };
  const handleUpdateSigFigsCreditInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateField({ value: event.target.value, min: 1, max: 99 });
    setSigFigsCreditErrorValue(error);
    dispatch(setRefWithError(sigFigsCreditInputRef));

    if (currentAssignmentTemplate && !error.value) {
      dispatch(removeRefWithError(sigFigsCreditInputRef));
      setSigFigsCreditInputValue(+event.target.value);
      dispatch(
        setSignificantFiguresSettings({
          ...currentAssignmentTemplate.settings.significantFiguresSettings,
          ...{ significantFiguresPartialCreditPercentage: +event.target.value },
        })
      );
    }
  };

  return (
    <AdvancedSettingsLayout>
      <Checkbox
        testId="toleranceOverrideCheckbox"
        defaultChecked={checkedToleranceOverride}
        onChange={handleUpdateToleranceOverrideChecked}
        labelText={t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.OVERRIDE')}
      />
      {checkedToleranceOverride ? (
        <InputWithLeftLabel>
          <label data-testid="toleranceOverrideInputLabel" htmlFor="toleranceOverrideInput">
            {t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.TOLERANCE_LABEL')}
          </label>
          <InputWithPercent>
            <Input
              ref={toleranceOverrideInputRef}
              testId="toleranceOverrideInput"
              id="toleranceOverrideInput"
              helperMessage="0-100"
              type={InputType.number}
              min="0"
              step="0.1"
              containerStyle={{ width: '4rem' }}
              messageStyle={{ width: '25rem' }}
              value={customToleranceInputValue}
              errorMessage={
                customToleranceErrorValue.value
                  ? customToleranceErrorValue.message
                    ? customToleranceErrorValue.message
                    : t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.TOLERANCE_INPUT_FRAMES')
                  : null
              }
              onChange={handleToleranceInputUpdate}
            />
            <Percent>%</Percent>
          </InputWithPercent>
        </InputWithLeftLabel>
      ) : (
        <MessageWrapper data-testid={`assignmentToleranceOverrideMessage`}>
          <InputMessage>{t('NOT_RECOMMENDED')}</InputMessage>
        </MessageWrapper>
      )}
      <Checkbox
        testId="sigFigsErrorCheckbox"
        defaultChecked={checkedSigFigsErrors}
        onChange={handleUpdateSigFigsChecked}
        labelText={t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.SIG_FIGS_CHECKBOX_LABEL')}
      />
      {checkedSigFigsErrors && (
        <>
          <InputWithLeftLabel>
            <label data-testid="sigFigsErrorInputLabel" htmlFor="sigFigsErrorInput">
              {t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.SIG_FIGS_ERROR_LABEL')}
            </label>
            <InputWithPercent>
              <Input
                ref={sigFigsErrorInputRef}
                testId="sigFigsErrorInput"
                id="sigFigsErrorInput"
                type={InputType.number}
                containerStyle={{ width: '4rem' }}
                messageStyle={{ width: '25rem' }}
                helperMessage="1-9"
                min="0"
                errorMessage={
                  sigFigsToleranceErrorValue.value
                    ? t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.SIG_FIGS_INPUT_FRAMES')
                    : null
                }
                value={sigFigsToleranceInputValue || ''}
                onChange={handleSigFigsToleranceInputValueInputUpdate}
              />
            </InputWithPercent>
          </InputWithLeftLabel>
          <InputWithLeftLabel>
            <Checkbox
              testId="sigFigsCreditCheckbox"
              defaultChecked={sigFigsCreditChecked}
              onChange={handleUpdateSigFigsCreditChecked}
              labelText={t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.SIG_FIGS_CREDIT_LABEL')}
            />
            {sigFigsCreditChecked && (
              <InputWithPercent>
                <VisuallyHidden>
                  <label htmlFor="sigFigsCreditInput">
                    {t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.SIG_FIGS_CREDIT_INPUT_LABEL')}
                  </label>
                </VisuallyHidden>
                <Input
                  ref={sigFigsCreditInputRef}
                  testId="sigFigsCreditInput"
                  id="sigFigsCreditInput"
                  type={InputType.number}
                  containerStyle={{ width: '4rem' }}
                  messageStyle={{ width: '25rem' }}
                  helperMessage="1-99"
                  min="0"
                  errorMessage={
                    sigFigsCreditErrorValue.value
                      ? t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.SIG_FIGS_CREDIT_INPUT_FRAMES')
                      : null
                  }
                  value={sigFigsCreditInputValue || ''}
                  onChange={handleUpdateSigFigsCreditInputChange}
                />
                <Percent>%</Percent>
              </InputWithPercent>
            )}
          </InputWithLeftLabel>
        </>
      )}
      <SelectComponent label={SELECT_LABEL.saltStat} />
    </AdvancedSettingsLayout>
  );
};

export default AdvancedScoringTabSettings;

import React from 'react';
import { AlertVariant, Datagrid, Heading, Toast, ToastsContainer, TypographyVisualStyle } from 'react-magma-dom';
import { HEADERS, IFlattenedCxpResultQuestion } from 'context/CxpQuestionContextProvider/types';
import CxpSynchronizer from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/CxpSynchronizer';
import { Pagination } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/Pagination';
import { ISortData, ROWS_PER_PAGE } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/helpers/types';
import CxpEditModal from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/CxpEditModal';
import ReturnButton from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/components/ReturnButton';

interface ResultTableProps {
  questionsSort: ISortData;
  setQuestionsSort: React.Dispatch<React.SetStateAction<ISortData>>;
  setBlockFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageToast: React.Dispatch<
    React.SetStateAction<{
      messageToast: string;
      typeToast: AlertVariant;
      dismissToast: boolean;
      showToast: boolean;
      disableAutoDismiss: boolean;
    }>
  >;
  messageToast: {
    messageToast: string;
    typeToast: AlertVariant;
    dismissToast: boolean;
    showToast: boolean;
    disableAutoDismiss: boolean;
  };
  getQuestions: () => void;
  errorHandling: (message: string) => void;
  sortQuestions: () => void;
  sortVal: { sortDirection: string; paint: JSX.Element };
  getCxpItem: (itemId: number) => IFlattenedCxpResultQuestion | undefined;
  setCxpItem: (cxpId: string, repo: string, index: number) => void;
  modalData: { cxpModalActive: boolean; cxpId: number };
  setModalData: React.Dispatch<React.SetStateAction<{ cxpModalActive: boolean; cxpId: number }>>;
}

function ResultTable({
  questionsSort,
  setQuestionsSort,
  setBlockFlag,
  setMessageToast,
  messageToast,
  getQuestions,
  errorHandling,
  sortQuestions,
  sortVal,
  getCxpItem,
  setCxpItem,
  modalData,
  setModalData,
}: ResultTableProps): JSX.Element {
  function handleDismiss() {
    setMessageToast({ ...messageToast, showToast: messageToast.dismissToast });
  }

  function handleSortKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      sortQuestions();
      const lastSyncElement = document.getElementById('lastSyncedId');
      if (lastSyncElement) {
        lastSyncElement.focus();
      }
    }
  }

  const fields = [
    { field: HEADERS.CXP_ITEM_ID, header: HEADERS.CXP_ITEM_ID, testId: HEADERS.CXP_ITEM_ID },
    { field: HEADERS.GEYSER_REPO, header: HEADERS.GEYSER_REPO, testId: HEADERS.GEYSER_REPO },
    { field: HEADERS.CXP_ITEM_CXP_ID, header: HEADERS.CXP_ITEM_CXP_ID, testId: HEADERS.CXP_ITEM_CXP_ID },
    { field: HEADERS.WA_QUESTION, header: HEADERS.WA_QUESTION, testId: HEADERS.WA_QUESTION },
    { field: HEADERS.HANDLER_TYPE, header: HEADERS.HANDLER_TYPE, testId: HEADERS.HANDLER_TYPE },
    { field: HEADERS.FIELD_COUNT, header: HEADERS.FIELD_COUNT, testId: HEADERS.FIELD_COUNT },
    { field: HEADERS.IS_ALGORITHMIC, header: HEADERS.IS_ALGORITHMIC, testId: HEADERS.IS_ALGORITHMIC },
    { field: HEADERS.LIMIT_ONE_SUBMISSION, header: HEADERS.LIMIT_ONE_SUBMISSION, testId: HEADERS.LIMIT_ONE_SUBMISSION },
    {
      field: HEADERS.LAST_SYNCED,
      header: (
        <div
          id="lastSyncedId"
          onClick={sortQuestions}
          role="button"
          tabIndex={0}
          onKeyDown={event => handleSortKeyDown(event)}
        >
          {HEADERS.LAST_SYNCED} {sortVal.paint}
        </div>
      ) as unknown as string,
    },
  ];

  function sliceQuestions(arr: IFlattenedCxpResultQuestion[]): IFlattenedCxpResultQuestion[] {
    return arr.slice((questionsSort.page - 1) * ROWS_PER_PAGE, questionsSort.page * ROWS_PER_PAGE);
  }

  return (
    <>
      {questionsSort.sortedQuestions.length > 0 && !modalData.cxpModalActive && (
        <div>
          <Heading
            css
            level={2}
            visualStyle={TypographyVisualStyle.headingSmall}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div>
              {new Set(questionsSort.sortedQuestions.map(cxpItem => cxpItem[HEADERS.CXP_ITEM_ID])).size} CXP items
              found.
            </div>
            <CxpSynchronizer
              questions={questionsSort.sortedQuestions}
              setBlockFlag={setBlockFlag}
              setMessageToast={setMessageToast}
              getQuestions={getQuestions}
              errorHandling={errorHandling}
            />
          </Heading>

          <div>
            <Datagrid
              hasPagination={false}
              columns={fields}
              rows={sliceQuestions(questionsSort.sortedQuestions)}
              hasZebraStripes={true}
              testId="resultTable"
            />
            <Pagination
              page={questionsSort.page}
              pages={questionsSort.pages}
              setQuestionsSort={setQuestionsSort}
              questionsSort={questionsSort}
            />
          </div>
        </div>
      )}

      {messageToast.showToast && (
        <ToastsContainer>
          <Toast
            id="toast_id"
            onDismiss={handleDismiss}
            disableAutoDismiss={messageToast.disableAutoDismiss}
            variant={messageToast.typeToast}
            style={{ width: 'fit-content' }}
            containerStyle={{ width: 'fit-content' }}
          >
            {messageToast.messageToast}
          </Toast>
        </ToastsContainer>
      )}
      {modalData.cxpModalActive && (
        <>
          <ReturnButton setModalData={setModalData} />
          <CxpEditModal
            cxpItem={getCxpItem(modalData.cxpId)}
            setCxpItem={setCxpItem}
            setBlockFlag={setBlockFlag}
            errorHandling={errorHandling}
            setMessageToast={setMessageToast}
          />
        </>
      )}
    </>
  );
}

export default ResultTable;

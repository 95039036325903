/* istanbul ignore file */
import axios from 'axios';
import {
  IAssignmentEditorContext,
  IAssignmentTemplate,
  ITemplateListItem,
  IDynamicInfoItem,
  IAssignmentInfo,
  IResultSaveAssignmentEditor,
  IQuestionElement,
  ITrashAssignment,
  IAssignmentSettings,
  IAssignmentTemplateActionResult,
} from 'context/AssignmentContextProvider/types';

const instance = axios.create({
  baseURL: window.location.origin,
});

const getAssignmentTemplates = (
  userId: number | null,
  assignmentId: number | null
): Promise<{
  data: { result: ITemplateListItem[]; status: number };
}> => {
  const endOfRequest = assignmentId ? `?assignmentId=${assignmentId}` : '';
  return instance.get(`wa-ui-services/assignment/user/${userId}/templates${endOfRequest}`);
};

const getAssignmentTemplate = ({
  assignmentId,
  templateId,
  defaultTemplateId,
  userId,
}: {
  assignmentId?: number | string;
  templateId?: number;
  defaultTemplateId: number;
  userId: number | null;
}): Promise<{ data: { result: IAssignmentTemplate; status: number } }> => {
  let endOfRequest: string;
  // default
  endOfRequest = `templateId=${defaultTemplateId}`;

  // assignment id overrides the default
  if (assignmentId) {
    endOfRequest = `assignmentId=${assignmentId}`;
  }

  // template id overrides the default or assignment id
  if (templateId) {
    endOfRequest = `templateId=${templateId}`;
  }
  return instance.get(`wa-ui-services/assignment/user/${userId}/template/settings?${endOfRequest}`);
};

const createTemplate = ({
  templateName,
  templateSettings,
  userId,
}: {
  templateName: string;
  templateSettings: IAssignmentSettings;
  userId: number;
}): Promise<{ data: { result: IAssignmentTemplateActionResult; status: number } }> => {
  const data = {
    name: templateName,
    description: '',
    settings: templateSettings,
  };
  return instance.post(`wa-ui-services/assignment/user/${userId}/template`, data);
};

const getAssignmentEditorContext = (
  assignmentId: number | undefined,
  userId: number | null
): Promise<{ data: { result: IAssignmentEditorContext; status: number } }> => {
  return instance.get(`wa-ui-services/assignment/${assignmentId}/user/${userId}`);
};

const getAssignmentDynamicInfo = (
  assignmentId: number | undefined,
  userId: number | null
): Promise<{ data: { result: { categories: IDynamicInfoItem[]; groups: IDynamicInfoItem[] }; status: number } }> => {
  return instance.get(`wa-ui-services/assignment/${assignmentId}/user/${userId}/template/dynamicInfo`);
};
const getQuestionsData = (
  assignmentId: number | undefined,
  userId: number | null
): Promise<{ data: { result: IQuestionElement[]; status: number } }> => {
  return instance.get(`wa-ui-services/assignment/${assignmentId}/user/${userId}/questions`);
};
const getQuestionsDataUpdates = (
  userId: number | null,
  contents: string | null
): Promise<{ data: { result: IQuestionElement[]; status: number } }> => {
  return instance.get(`wa-ui-services/assignment/user/${userId}/questionUpdates?contents=${contents}`);
};
const getIsScheduled = (
  assignmentId: number | undefined,
  userId: number | null
): Promise<{ data: { result: boolean; status: number } }> => {
  return instance.get(`wa-ui-services/assignment/${assignmentId}/user/${userId}/scheduled`);
};
const trashAssignment = (
  assignmentId: number | undefined,
  userId: number | null
): Promise<{ data: { result: ITrashAssignment; status: number } }> => {
  return instance.post(`wa-ui-services/assignment/${assignmentId}/user/${userId}/trash`, {});
};
const untrashAssignment = (
  assignmentId: number | undefined,
  userId: number | null
): Promise<{ data: { result: ITrashAssignment; status: number } }> => {
  return instance.post(`wa-ui-services/assignment/${assignmentId}/user/${userId}/untrash`, {});
};

const saveAssignment = (
  userId: number | null,
  assignmentId: number | null,
  isCustomTemplate: boolean,
  currentAssignmentTemplate: IAssignmentTemplate,
  assignmentInfo: IAssignmentInfo,
  questionsData: IQuestionElement[]
): Promise<{
  data: { result: IResultSaveAssignmentEditor; status: number };
}> => {
  const data = {
    assignmentId: assignmentId,
    templateId: isCustomTemplate || currentAssignmentTemplate?.id === 0 ? null : currentAssignmentTemplate?.id,
    assignmentSettings:
      isCustomTemplate || currentAssignmentTemplate?.id === 0 ? currentAssignmentTemplate?.settings : null,
    questionsContext: {
      assignmentInfo: assignmentInfo,
      questionElements: questionsData,
    },
  };
  return instance.post(`wa-ui-services/assignment/user/${userId}`, data);
};

export {
  getAssignmentTemplates,
  getAssignmentTemplate,
  getAssignmentEditorContext,
  getAssignmentDynamicInfo,
  getQuestionsDataUpdates,
  getQuestionsData,
  saveAssignment,
  trashAssignment,
  getIsScheduled,
  createTemplate,
  untrashAssignment,
};

/* istanbul ignore file */
import React from 'react';
import { TabsContainer, Tabs, Tab, TabPanelsContainer, TabPanel } from 'react-magma-dom';
import { CxpQuestionImporter } from 'facultyComponents/cxpQuestionManager/cxpQuestionImporter/CxpQuestionImporter';
import { CxpQuestionSearcher } from 'facultyComponents/cxpQuestionManager/cxpQuestionsSearcher/CxpQuestionSearcher';
import { QuestionReporter } from 'facultyComponents/cxpQuestionManager/questionReporter/QuestionReporter';
import CxpToolHeader from 'facultyComponents/cxpQuestionManager/shared/CxpToolHeader';

export const CxpQuestionManager: React.FC = () => {
  return (
    <>
      <CxpToolHeader />
      <TabsContainer activeIndex={0} style={{ marginLeft: '30px' }}>
        <Tabs>
          <Tab>Import</Tab>
          <Tab>Search</Tab>
          <Tab>Reports</Tab>
        </Tabs>
        <TabPanelsContainer>
          <TabPanel>
            <CxpQuestionImporter />
          </TabPanel>
          <TabPanel>
            <CxpQuestionSearcher />
          </TabPanel>
          <TabPanel>
            <QuestionReporter />
          </TabPanel>
        </TabPanelsContainer>
      </TabsContainer>
    </>
  );
};

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Checkbox, InputMessage } from 'react-magma-dom';

import { validateField } from '../../commonHelpers';
import { setQuestionElementNumericalTolerance } from 'context/AssignmentContextProvider/actions';
import { useAssignmentContext } from 'context/AssignmentContextProvider/AssignmentContextProvider';
import { IQuestionNumericalToleranceSettings } from 'context/AssignmentContextProvider/types';
import { InputAutocorrect, StandardErrorFormat } from 'sharedComponents/InputAutocorrect/InputAutocorrect';
import {
  isAssignmentQuestion,
  hasNumericBoxes,
  isQuestionPool,
} from 'facultyComponents/assignmentEditor/helpers/questionElements';
import { IPointsAdjustments } from '../PointsAdjustment';
import {
  InputsWrapper,
  InputWithLeftLabel,
  InputWithPercent,
  MagmaInputContainerStyle,
  MagmaInputMessageStyle,
  Percent,
  VisuallyHiddenSpan,
} from '../InputContainers';

const CheckBoxWithMessage = styled.div`
  margin-top: 2rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  label {
    padding-bottom: 0;
  }
`;

const MessageWrapper = styled.div`
  margin-left: 2.1rem;
`;

const defaultNumericalToleranceSettings: IQuestionNumericalToleranceSettings = {
  numericalTolerance: null,
  isToleranceInPercent: true,
};

const newNumericalToleranceSettings: IQuestionNumericalToleranceSettings = {
  numericalTolerance: 1,
  isToleranceInPercent: true,
};

export const NumericToleranceOverride = (props: IPointsAdjustments): JSX.Element | null => {
  const { questionElementIndex } = props;
  const {
    state: { questionsData },
    dispatch,
  } = useAssignmentContext();
  const { t } = useTranslation();

  const questionElement = questionElementIndex !== undefined ? questionsData[questionElementIndex] : undefined;

  const initialSettings =
    questionElement && isAssignmentQuestion(questionElement)
      ? questionElement.numericalToleranceSettings
      : questionElement && isQuestionPool(questionElement)
      ? questionElement.questions[0].numericalToleranceSettings
      : defaultNumericalToleranceSettings;

  const [numericalToleranceSettings, setNumericalToleranceSettings] = useState(
    initialSettings.isToleranceInPercent ? initialSettings : defaultNumericalToleranceSettings
  );

  const minTolerance = 0;
  const maxTolerance = 100;

  const validateTolerance = (value: string): StandardErrorFormat => {
    const genericMessage = t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.ONLY_ONE_DECIMAL');
    const rangeMessage = t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.TOLERANCE_INPUT_FRAMES');
    const error = validateField({
      value,
      min: minTolerance,
      max: maxTolerance,
      minMessage: rangeMessage,
      maxMessage: rangeMessage,
      message: genericMessage,
      withDecimal: true,
    });
    return { error: error.value, message: error.message };
  };

  useEffect(() => {
    if (questionElementIndex === undefined) {
      return;
    }
    if (
      numericalToleranceSettings.numericalTolerance !== initialSettings.numericalTolerance ||
      numericalToleranceSettings.isToleranceInPercent !== initialSettings.isToleranceInPercent
    ) {
      dispatch(
        setQuestionElementNumericalTolerance({
          elementIndex: questionElementIndex,
          numericalToleranceSettings: numericalToleranceSettings,
        })
      );
    }
  }, [numericalToleranceSettings]);

  const updateDependencies = (value: number) => {
    setNumericalToleranceSettings({
      ...numericalToleranceSettings,
      numericalTolerance: value,
    });
  };

  const handleUpdateToleranceOverrideChecked = () => {
    if (numericalToleranceSettings.numericalTolerance !== null) {
      setNumericalToleranceSettings(defaultNumericalToleranceSettings);
    } else {
      setNumericalToleranceSettings(newNumericalToleranceSettings);
    }
  };

  const testIdSuffix = questionElementIndex !== undefined ? questionElementIndex : '';

  return questionElement && hasNumericBoxes(questionElement) ? (
    <CheckBoxWithMessage data-testid={`toleranceOverride${testIdSuffix}`}>
      <Checkbox
        testId={`toleranceOverrideCheckbox${questionElementIndex}`}
        defaultChecked={numericalToleranceSettings.numericalTolerance !== null}
        onChange={handleUpdateToleranceOverrideChecked}
        labelText={t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.OVERRIDE')}
      />
      {numericalToleranceSettings.numericalTolerance !== null ? (
        <InputsWrapper>
          <InputWithLeftLabel>
            <label htmlFor={`toleranceOverrideInput${testIdSuffix}`}>
              {t('ASSIGNMENT_EDITOR.SCORING.ADVANCED_SETTING.TOLERANCE_LABEL')}
              <VisuallyHiddenSpan>{t('ASSIGNMENT_EDITOR.SCORING.POINTS_ADJUST_SETTING.PERCENT')}</VisuallyHiddenSpan>
            </label>
            <InputWithPercent>
              <InputAutocorrect
                id={`toleranceOverrideInput${testIdSuffix}`}
                testId={`toleranceOverrideInput${testIdSuffix}`}
                value={numericalToleranceSettings.numericalTolerance?.toString() || '1'}
                validator={validateTolerance}
                validationError={{ error: false, message: '' }}
                onValidChange={(value: string) => updateDependencies(parseFloat(value))}
                containerStyle={MagmaInputContainerStyle}
                messageStyle={MagmaInputMessageStyle}
                helperMessage={`${minTolerance}-${maxTolerance}`}
                min="0"
                step="0.1"
              />
              <Percent>%</Percent>
            </InputWithPercent>
          </InputWithLeftLabel>
        </InputsWrapper>
      ) : (
        <MessageWrapper data-testid={`toleranceOverrideMessage${questionElementIndex}`}>
          <InputMessage>{t('NOT_RECOMMENDED')}</InputMessage>
        </MessageWrapper>
      )}
    </CheckBoxWithMessage>
  ) : null;
};
